import { marginLoanPage, nplExecutedByAmountPage, openPositionPage, portfolioInterestRateSupportPage, TABS_ON_FOOTER } from '../../utils/constants';
import actionTypes from '../actions/actionTypes';

const initialState = {
    //tab trade
    symbolSelector: {
        // currentCollectionId: localStorage.getItem("currentCollectionId") ? localStorage.getItem("currentCollectionId") : '', //Lấy tab lưu từ local khi mà logout
        currentCollectionId: '',
        // currentCollectionId:  currentCollectionId,
        currentUserCollectionId: 'favourite',
        currentHnxCollectionId: 'HNX',
        currentHoseCollectionId: 'HOSE',
        currentUpcomCollectionId: 'UPCOM',
        currentAnalyticCollectionId: 'industryEvent',
        currentIndustryCollectionId: '',
        currentDerivativeCollectionId: 'derivatives',
        currentOddlotCollectionId: 'OddlotHNX',
        currentPutthroughCollectionId: 'putthroughHOSE',
        currentIndexCollectionId: 'VN30',
        currentCWCollectionId: 'CWALL'
    },
    widgetGrid: {
        listActiveSection: []
    },
    //tab account
    accountOpenPositions: {
        currentPage: openPositionPage.ALL_PORTFOLIO
    },
    nplExecutedByAmount: {
        currentPage: nplExecutedByAmountPage.REPORT_TABLE
    },
    accountMarginLoan: {
        currentPage: marginLoanPage.MARGINLOANS
    },
    portfolioInterestRateSupport: {
        currentPage: portfolioInterestRateSupportPage.PORTFOLIO_INTEREST_RATE_SUPPORT
    },
    accountFooter: {
        currentPage: TABS_ON_FOOTER.BASEORDER
    }
};

const componentStateReducer = (state = initialState, action) => {
    switch (action.type) {
        /**
         * cac case lien quan den bang gia
         */
        case actionTypes.SET_SYMBOL_SELECTOR_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentCollectionId: action.collectionId
                }
            }
        };
        case actionTypes.SET_SYMBOL_SELECTOR_USER_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentUserCollectionId: action.collectionId
                }
            }
        };
        case actionTypes.SET_SYMBOL_SELECTOR_HNX_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentHnxCollectionId: action.collectionId
                }
            }
        };
        case actionTypes.SET_SYMBOL_SELECTOR_HOSE_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentHoseCollectionId: action.collectionId
                }
            }
        };
        case actionTypes.SET_SYMBOL_SELECTOR_UPCOM_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentUpcomCollectionId: action.collectionId
                }
            }
        };

        case actionTypes.SET_SYMBOL_SELECTOR_PUTTHROUGH_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentPutthroughCollectionId: action.collectionId
                }
            }
        };

        case actionTypes.SET_SYMBOL_SELECTOR_INDEX_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentIndexCollectionId: action.collectionId
                }
            }
        };

        case actionTypes.SET_SYMBOL_SELECTOR_CW_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentCWCollectionId: action.collectionId
                }
            }
        };

        case actionTypes.SET_SYMBOL_SELECTOR_ANALYTIC_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentAnalyticCollectionId: action.collectionId
                }
            }
        };

        case actionTypes.SET_SYMBOL_SELECTOR_INDUSTRY_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentIndustryCollectionId: action.collectionId
                }
            }
        };

        case actionTypes.SET_SYMBOL_SELECTOR_DERIVATIVE_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentDerivativeCollectionId: action.collectionId
                }
            }
        };

        case actionTypes.SET_SYMBOL_SELECTOR_ODDLOT_COLLECTION_ID: {
            return {
                ...state,
                symbolSelector: {
                    ...state.symbolSelector,
                    currentOddlotCollectionId: action.collectionId
                }
            }
        };

        /**
         * cac case lien quan den widget grid
         */
        case actionTypes.SET_LIST_ACTIVE_SECTION: {
            return {
                ...state,
                widgetGrid: {
                    ...state.widgetGrid,
                    listActiveSection: action.listActiveSection
                }
            };
        };
        /**
         * luu tab dang chon o danh muc dau tu
         */
        case actionTypes.SET_OPEN_POSITION_CURRENT_PAGE: {
            return {
                ...state,
                accountOpenPositions: {
                    currentPage: action.currentPage
                }
            };
        };
        /**
         * luu tab dang chon o lai lo dong tien
         */
        case actionTypes.SET_NPL_EXECUTED_BY_AMOUNT_CURRENT_PAGE: {
            return {
                ...state,
                nplExecutedByAmount: {
                    currentPage: action.currentPage
                }
            }
        }
        /**
        * luu tab dang chon o Cac khoan no
        */
        case actionTypes.SET_MARGIN_LOANS_CURRENT_PAGE: {
            return {
                ...state,
                accountMarginLoan: {
                    currentPage: action.currentPage
                }
            }
        }
        /**
        * luu tab dang chon o Hỗ trợ lãi suất
        */
        case actionTypes.SET_PORTFOLIO_INTEREST_RATE_SUPPORT_PAGE: {
            return {
                ...state,
                portfolioInterestRateSupport: {
                    currentPage: action.currentPage
                }
            }
        }
        /**
        * luu tab dang chon Footer
        */
        case actionTypes.SET_FOOTER_CURRENT_PAGE: {
            return {
                ...state,
                accountFooter: {
                    currentPage: action.currentPage
                }
            }
        }
        default:
            return state;
    };
};

export default componentStateReducer;