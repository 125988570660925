import CommonUtils from '../../utils/CommonUtils';
import { ACTION_METHOD_CONDITION } from "../../utils/constants";
import actionTypes from '../actions/actionTypes';

const globalVar = window._env_

function getDataLocal(role, key) {
    let newKey = role + "_" + key
    let value = localStorage.getItem(newKey)
    return (value && value != '') ? value : undefined
}

function setDataLocal(role, key, value) {
    let newKey = role + "_" + key
    return localStorage.setItem(newKey, value)
}


export const df_WidthMenuSideBar = {
    0: '0px',
    // 1: '226px',
    1: '300px',
}

let themeGuest = localStorage.getItem('theme-Guest') ? localStorage.getItem('theme-Guest') : 'dark'
// let themeGuest = 'dark'; //Hardcode theme dark
let unauthenticatedState = {
    isLoggedIn: false,
    isLoggingIn: false,
    isRefreshTokenFail: false,
    token: null,
    usersToken: {},
    isOpenLoginPopUp: false,
    custodycdOnOpenPopup: '',
    customerFullName: null,
    userInfo: {
        username: "Guest (Not Login)",
        role: 'C',
        config: [],
        authinfo: [{
            authtype: "1"
        }],
        symbol: "AAA",
        defaultSymbol: "AAA",
        defaultSymbolFds: "AAA",
        // defaultAccount: action.defaultAccount,
        // defaultBenefitAccount: action.defaultBenefitAccount,
        defaultAccount: '',
        defaultBenefitAccount: '',
        defaultTheme: themeGuest,
        isVerified: false,
        isreset: "N",
        language: "vi",
        actionMethodConditon: ACTION_METHOD_CONDITION.FINAL_MATCH_PRICE,
        brokerGroups: [],
        scope: (6)["account-read", "account-write", "order-read", "order-write", "balance-read", "balance-write"],
        tlid: "",
        usertype: 'GUEST',
        custid: 'GUEST',
        permissionInfo: '',
        permissionModuleInfo: [],
        accounts: {},
        accountid: "",
        benefitaccount: "",
        showReconfirmOrder: true,
        confirm: {
            "GTC": false,
            "STO": false,
            "SEO": false,
            "SO": false,
            "OCO": false,
            "MCO": false,
            "OTO": false,
            "ICO": false,
            "PCO": false,
            "CPO": false,
            "TSO": false
        },
        "symbolFDS": "",
        menuHeader: {
            activeMenuHeader: "MH_CKCS",
            menuSideBar: {
                statusMenuSideBar: 0,
                widthMenuSideBar: df_WidthMenuSideBar[0],
                MH_CKCS: {
                    activeMenuSideBar: null,
                    activeURLSideBar: null,
                    isOpenListMenuSideBar: [],
                }
            },
        },
        popupFooterPage: {
            lastPopupFooterPage: 'CLOSE',
            statusPopupFooterPage: 'CLOSE',
        }
    },
    role: null,
    priceBoardConfig: {},
    isOpenMenuTool: true // Trạng thái mới menutool, mặc định là đang mở
};


const initialState = {
    ...unauthenticatedState
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.USER_LOGIN_START:
            return {
                ...state,
                isLoggedIn: false,
                isLoggingIn: true,
                isLogginFail: false,
                token: null,
                userInfo: unauthenticatedState.userInfo
            };
        case actionTypes.AUTHORIZATION_SUCCESS:
            let currentUsersToken = {};
            if (globalVar.switch.enableSaveLoginAccount) {
                currentUsersToken = JSON.parse(localStorage.getItem('token-users'));
                currentUsersToken = currentUsersToken ? currentUsersToken : {};
                let custodycd = action.data.access_token.split('||')[0];
                // Nếu đã tồn tại thông tin đăng nhập trong local ==> ghi đè lại các data về authen (case cần lưu trữ các trường khác như tài khoản chọn gần nhất của môi giới)
                if (currentUsersToken[`${custodycd}`]) {
                    currentUsersToken[`${custodycd}`] = { ...currentUsersToken[`${custodycd}`], ...action.data }
                } else {
                    currentUsersToken[`${custodycd}`] = action.data;
                }
                // Lưu lại token người dùng 
                localStorage.setItem(`token-users`, JSON.stringify(currentUsersToken));
                // Active Custodycd cho tab hiện tại
                sessionStorage.setItem('activeCustID', custodycd);
            }
            CommonUtils.setCookie("isOpenBrowser", true, '/', true, '');
            return {
                ...state,
                token: action.data,
                usersToken: currentUsersToken
            };
        case actionTypes.FETCH_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: {
                    ...unauthenticatedState.userInfo, // Tạm vẫn ghi nhận config từ mặc định.
                    ...action.info,
                    config: action.config,
                    authinfo: action.authinfo,
                    defaultSymbol: action.defaultSymbol,
                    defaultSymbolFds: action.defaultSymbolFds,
                    defaultAccount: action.defaultAccount,
                    defaultBenefitAccount: action.defaultBenefitAccount,
                    defaultTheme: action.theme,  //Hardcode theme dark
                    isFirstLogin: action.isFirstLogin,
                    actionMethodConditon: action.actionMethodConditon
                }
            };
        case actionTypes.FETCH_USER_PERMISSION_INFO:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    permissionInfo: action.permissionInfo
                }
            };
        case actionTypes.FETCH_USER_PERMISSION_MODULE_INFO:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    permissionModuleInfo: action.permissionModuleInfo
                }
            };
        case actionTypes.CHANGE_DEFAULT_THEME:
            unauthenticatedState.userInfo.defaultTheme = action.theme //Hardcode theme dark
            localStorage.setItem('theme-Guest', action.theme) //Hardcode theme dark
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    defaultTheme: action.theme  //Hardcode theme dark
                }
            }
        case actionTypes.USER_LOGIN_SUCCESS:
            // fix
            // localStorage.clear()
            let role = state.userInfo.role;
            // thêm check url_new so với localstorage
            // let _menuHeader = getInitMenu(role)
            return {
                ...state,
                isLoggedIn: true,
                isLoggingIn: false,
                isRefreshTokenFail: false,
                userInfo: {
                    ...state.userInfo,
                    // menuHeader: _menuHeader
                }
            };
        case actionTypes.USER_LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                isLoggingIn: false,
                isLogginFail: true,
                token: null,
                userInfo: unauthenticatedState.userInfo
            };
        case actionTypes.REFRESH_TOKEN_SUCCESS: {
            const newToken = {
                ...state.token,
                ...action.token
            };
            return {
                ...state,
                token: newToken
            };
        }
        case actionTypes.REFRESH_TOKEN_FAIL: {
            return {
                ...state,
                isLoggedIn: false,
                isLoggingIn: false,
                isLogginFail: true,
                token: null,
                userInfo: unauthenticatedState.userInfo,
                isRefreshTokenFail: true
            };
        }
        case actionTypes.FETCH_USER_DEFAULT_CONFIG: {
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    defaultSymbol: action.defaultSymbol,
                    defaultSymbolFds: action.defaultSymbolFds,
                    defaultAccount: action.defaultAccount,
                    defaultBenefitAccount: action.defaultBenefitAccount
                }
            };
        }
        case actionTypes.FETCH_USER_PRICEBOARD_CONFIG: {
            return {
                ...state,
                priceBoardConfig: action.priceBoardConfig
            };
        }
        case actionTypes.SAVE_USER_PRICEBOARD_CONFIG: {
            return {
                ...state,
                priceBoardConfig: action.priceBoardConfig
            };
        }
        case actionTypes.USER_LOGOUT:
            // trước khi clear thì backup dataGrid
            // bk bằng cái lấy ra từ localStorage rồi set lại sau khi clear
            //get ở đây
            let storageKey = 'LookUpOrderNew-' + state.userInfo.username
            let dataGrid = localStorage.getItem(storageKey)
            let usersTokenTest = {};
            usersTokenTest = globalVar.switch.enableSaveLoginAccount && localStorage.getItem('token-users') ? JSON.parse(localStorage.getItem('token-users')) : {};
            //console.log("Sổ lệnh - localStorage - userReducer - dataGrid before clear: ", dataGrid);
            localStorage.clear()
            //console.log("Sổ lệnh - localStorage - userReducer - dataGrid after clear: ", dataGrid)
            localStorage.setItem(storageKey, dataGrid);
            if (globalVar.switch.enableSaveLoginAccount) {
                localStorage.setItem('token-users', JSON.stringify(usersTokenTest));
                sessionStorage.setItem('activeCustID', '');
            }
            return {
                ...unauthenticatedState,
                usersToken: usersTokenTest,
            };
        case actionTypes.SINGLE_USER_LOGOUT:
            // set lại store redux + xóa thông tin của tk cần đăng xuất, đảm bảo không ảnh hưởng đến các tk khác đang đăng nhập trên tab khác
            sessionStorage.setItem('activeCustID', ''); // Xóa acticeCustID của tab
            let savedUserToken = JSON.parse(localStorage.getItem('token-users'));
            if (action.logoutWithStore && savedUserToken && action.custodycd && savedUserToken[`${action.custodycd}`] && savedUserToken[`${action.custodycd}`]['access_token']) {
                savedUserToken[`${action.custodycd}`] = { ...savedUserToken[`${action.custodycd}`], access_token: '', refresh_token: '', expires_in: 0 }; // xóa token lưu trong localStorage
                localStorage.setItem('token-users', JSON.stringify(savedUserToken));
            }
            return {
                ...unauthenticatedState, // set lại token cũng như state redux về trạng thái chưa đăng nhập
                usersToken: state.usersToken,
                isOpenLoginPopUp: state.isOpenLoginPopUp,
                custodycdOnOpenPopup: state.custodycdOnOpenPopup
            };
        case actionTypes.UPDATE_TOKEN_STORE:
            // set lại store redux cho usersToken //{002C100523: {access_token: '...',refresh_token:'...',},002C100666: {access_token: '...',refresh_token:'...',}...}
            return {
                ...state,
                usersToken: action.usersToken
            };
        case actionTypes.SET_CUSTOMER_FULL_NAME:
            return {
                ...state,
                customerFullName: action.customerFullName
            };
        case actionTypes.SET_ACTION_METHOD_CONDITION:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    actionMethodConditon: action.actionMethodConditon
                }
            }
        case actionTypes.SET_LOGIN_POPUP_ON_START:
            return {
                ...state,
                isOpenLoginPopUp: action.isOpen,
                custodycdOnOpenPopup: action.custodycd,
            }
        case actionTypes.ON_CHANGE_ACTIVE_MENU: {
            const keyName = action.keyName;
            let role = state.userInfo.role;

            // localStorage.setItem('C_headerActive', keyName)
            setDataLocal(role, 'headerActive', keyName)
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    menuHeader: {
                        ...state.userInfo.menuHeader,
                        activeMenuHeader: keyName,
                    }
                }
            };
        }

        case actionTypes.ON_CHANGE_ACTIVE_MENU_SIDE_BAR: {
            const keyName = action.keyName;
            let role = state.userInfo.role;

            let activeMenu = state.userInfo.menuHeader.activeMenuHeader
            // let menuActiveStorage = localStorage.getItem('C_menuActive') ? JSON.parse(localStorage.getItem('C_menuActive')) : {}
            let menuActiveStorage = getDataLocal(role, "menuActive") ? JSON.parse(getDataLocal(role, "menuActive")) : {}

            let activeListItem = { ...menuActiveStorage, [activeMenu]: keyName }

            // localStorage.setItem('C_menuActive', JSON.stringify(activeListItem))
            setDataLocal(role, 'menuActive', JSON.stringify(activeListItem))

            const activeMenuHeader = state.userInfo.menuHeader.activeMenuHeader

            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    menuHeader: {
                        ...state.userInfo.menuHeader,
                        menuSideBar: {
                            ...state.userInfo.menuHeader.menuSideBar,
                            [activeMenuHeader]: {
                                ...state.userInfo.menuHeader.menuSideBar[activeMenuHeader],
                                activeMenuSideBar: activeListItem[activeMenuHeader],
                            },
                        },
                    }
                }
            };
        }

        case actionTypes.ON_CHANGE_ACTIVE_URL_SIDE_BAR: {
            const newUrl = action.newUrl;
            let role = state.userInfo.role;

            let activeMenu = state.userInfo.menuHeader.activeMenuHeader
            // let activeURLStorage = localStorage.getItem('C_urlActive') ? JSON.parse(localStorage.getItem('C_urlActive')) : {}
            let activeURLStorage = getDataLocal(role, "urlActive") ? JSON.parse(getDataLocal(role, "urlActive")) : {}

            let activeURL = { ...activeURLStorage, [activeMenu]: newUrl }

            // localStorage.setItem('C_urlActive', JSON.stringify(activeURL))
            setDataLocal(role, 'urlActive', JSON.stringify(activeURL))

            const activeMenuHeader = state.userInfo.menuHeader.activeMenuHeader
            //console.log("ON_CHANGE_ACTIVE_URL_SIDE_BAR_1", newUrl)
            //console.log("ON_CHANGE_ACTIVE_URL_SIDE_BAR_2", activeURLStorage, activeURL)
            //console.log("ON_CHANGE_ACTIVE_URL_SIDE_BAR_3", activeMenuHeader, activeURL[activeMenuHeader])
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    menuHeader: {
                        ...state.userInfo.menuHeader,
                        menuSideBar: {
                            ...state.userInfo.menuHeader.menuSideBar,
                            [activeMenuHeader]: {
                                ...state.userInfo.menuHeader.menuSideBar[activeMenuHeader],
                                activeURLSideBar: activeURL[activeMenuHeader],
                            },
                        },
                    }
                }
            };
        }

        case actionTypes.ON_CHANGE_OPEN_LIST_MENU_SIDE_BAR: {
            let role = state.userInfo.role;

            const activeMenuHeader = state.userInfo.menuHeader.activeMenuHeader
            const isOpenList = action.isOpenList;

            // localStorage.setItem('C_listOpenSidebar', isOpenList)
            setDataLocal(role, 'listOpenSidebar', isOpenList)

            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    menuHeader: {
                        ...state.userInfo.menuHeader,
                        menuSideBar: {
                            ...state.userInfo.menuHeader.menuSideBar,
                            [activeMenuHeader]: {
                                ...state.userInfo.menuHeader.menuSideBar[activeMenuHeader],
                                isOpenListMenuSideBar: isOpenList,
                            },
                        },
                    }
                }
            };
        }


        case actionTypes.ON_CHANGE_OPEN_MENU_TOOL: {

            return {
                ...state,
                isOpenMenuTool: action.isOpen
            };
        }



        case actionTypes.ON_CHANGE_STATUS_MENU_SIDE_BAR: {
            let role = state.userInfo.role;

            let statusMenuSideBar = action.statusMenuSideBar || null;
            if (!statusMenuSideBar) {
                statusMenuSideBar = state.userInfo.menuHeader.menuSideBar.statusMenuSideBar
                if (statusMenuSideBar > 0) {
                    statusMenuSideBar = statusMenuSideBar - 1
                }
            }
            // localStorage.setItem('C_statusMenuSideBar', statusMenuSideBar)
            setDataLocal(role, 'statusMenuSideBar', statusMenuSideBar)

            let widthMenuSideBar = df_WidthMenuSideBar[statusMenuSideBar]
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    menuHeader: {
                        ...state.userInfo.menuHeader,
                        menuSideBar: {
                            ...state.userInfo.menuHeader.menuSideBar,
                            statusMenuSideBar: statusMenuSideBar,
                            widthMenuSideBar: widthMenuSideBar
                        },
                    }
                }
            };
        }


        case actionTypes.ON_CHANGE_STATUS_POPUP_FOOTER_PAGE: {
            let statusPopupFooterPage = action.status || null;
            // let _statusPopupFooterPage = state.userInfo.popupFooterPage.statusPopupFooterPage
            let lastPopupFooterPage = state.userInfo.popupFooterPage.lastPopupFooterPage
            statusPopupFooterPage = action.typeStatus
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    popupFooterPage: {
                        lastPopupFooterPage: lastPopupFooterPage,
                        statusPopupFooterPage: statusPopupFooterPage,
                        footerTabActivated: action.tabActivated,
                    }
                }
            };
        }

        default:
            return state;
    }
};

export default userReducer;