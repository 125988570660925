import _ from 'lodash';
import { TYPE_ACCOUNT, ORDER_TYPE_USE, LAYOUT_STATUS, SCREEN } from "../../utils/constants"
import { CommonWidgetUtils, ToastUtil } from "../../utils"
import { path } from "../../utils/constants"
import { userService } from "../../services";
import actionTypes from '../actions/actionTypes';
import { getSessionStorage } from 'common/utils';
import $ from "jquery";
const breakpoints = { lg: 1514, md: 1280, sm: 938 }; // Bị trừ đi 86 do phần menu-tool

const LoadStatus = { NONE: 'NONE', LOADING: 'LOADING', LOADED: 'LOADED', FAIL: 'FAIL' };
const saveLayoutsCustom = (_layoutsCustom, from) => {
    let layoutsCustom = _.cloneDeep(_layoutsCustom)
    //console.log("binh_layoutsCustomStorage1", layoutsCustom)
    const persistUser = getSessionStorage("persist:user") || {}
    let { isLoggedIn } = persistUser
    let ischeckLoggedIn = false
    if (isLoggedIn === "true") {
        ischeckLoggedIn = true
    }
    let msgSuccess = "CM000005"
    if (from.includes("addLayout")) {
        msgSuccess = "CM000006"
    } else if (from.includes("deleteLayout")) {
        msgSuccess = "CM000007"
    } else if (from.includes("resetLayout")) {
        msgSuccess = "CM000008"
    } else if (from.includes("saveLayout")) {
        msgSuccess = "CM000005"
    }
    //console.log("binh_layoutsCustomStorage", layoutsCustom)
    let timer = setTimeout(function () {
        if (ischeckLoggedIn) {
            userService.saveLayoutConfig({ config: layoutsCustom })
                .then((res) => {
                    if (res && res.s == 'ok') {
                        ToastUtil.success(msgSuccess);
                    } else {
                        ToastUtil.errorApi("", 'common.fail-to-save-layout-config');
                    }
                    layoutsCustom = null
                    msgSuccess = null
                })
                .catch((error) => {
                    layoutsCustom = null
                    msgSuccess = null
                    ToastUtil.errorApi(error, 'common.fail-to-save-layout-config');
                });
        } else {
            clearTimeout(timer)
            return // off logic lưu layout lên local
            // localStorage.setItem("layoutsCustomStorage", JSON.stringify(layoutsCustom));
        }
        clearTimeout(timer)
    }, 10)
}

const checkBreakpointLayout = (width) => {
    width = parseFloat(width)
    width = width ? width : $(".content-container").width()
    let _breakpoint = 'sm'
    if (width >= breakpoints.lg) {
        _breakpoint = 'lg'
    }
    if (breakpoints.lg > width && width >= breakpoints.md) {
        _breakpoint = 'md'
    }
    if (breakpoints.md > width && width >= breakpoints.sm) {
        _breakpoint = 'sm'
    }
    return _breakpoint
}

const layoutCustomDefault = {
    id: '',
    grid: { "lg": [], "md": [], "sm": [] },
    // layoutName: '', // Ẩn để fix lỗi update bị clear layoutName
    visibleSectionIds: [],
    mergeSections: {},
    floatLayout: [],
    sourceLayoutId: '',
    layoutStatus: LAYOUT_STATUS.NONE
}
let initialState = {
    breakpoint: undefined,
    //Layout sinh Tab
    currentLayoutPageActive: "C#CS#PRICEBOARD#CB",
    // currentLayoutPageActive: "C#CS#MARKET_WATCH",
    currentMenuActive: "CS#PRICEBOARD#CB",
    //currentLayoutPageActive: "C#CS#TRADE#NC",
    // currentLayoutPageActive: "C#CS#TRADE#CB",
    // currentLayoutPageActive: "",
    // currentLayoutPageActive: "C#CS#PRICEBOARD#CB",
    layoutPage: {
        //quản lý các layout, mặc đỊnh layout bảng giá
        "C#CS#PRICEBOARD#CB": {             //Key layout
            "keyName": "CS#PRICEBOARD#CB",         //Key của menu active
            "activeURL": "/priceboard",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": true,
            "typeUser": "C", // Khách hàng : C, Broker: B
            "isEditLayout": false,
        },
        "B#CS#PRICEBOARD#CB": {             //Key layout
            "keyName": "CS#PRICEBOARD#CB",         //Key của menu active
            "activeURL": "/priceboard",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": true,
            "typeUser": "B",                    // Khách hàng : C, Broker: B   
            "isEditLayout": false,
        },
        "C#CS#SYMBOL_DETAIL": {             //Key layout
            "keyName": "CS#SYMBOL_DETAIL",         //Key của menu active
            "activeURL": "/symbol-detail",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "C",                    // Khách hàng : C, Broker: B 
            "isEditLayout": false,
        },
        "B#CS#SYMBOL_DETAIL": {             //Key layout
            "keyName": "CS#SYMBOL_DETAIL",         //Key của menu active
            "activeURL": "/symbol-detail",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
        "C#CS#TRADE#CB": {             //Key layout
            "keyName": "CS#TRADE#CB",         //Key của menu active
            "activeURL": "/webtrade",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "DC",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "C", // Khách hàng : C, Broker: B
            "isEditLayout": false,
        },
        "C#CS#TRADE#NC": {             //Key layout
            "keyName": "CS#TRADE#NC",         //Key của menu active
            "activeURL": "/protrade",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "DC",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "C",                    // Khách hàng : C, Broker: B                   
            "isEditLayout": false,
        },
        "B#CS#TRADE#CB": {             //Key layout
            "keyName": "CS#TRADE#CB",         //Key của menu active
            "activeURL": "/webtrade",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "DC",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B   
            "isEditLayout": false,
        },
        "B#CS#TRADE#NC": {             //Key layout
            "keyName": "CS#TRADE#NC",         //Key của menu active
            "activeURL": "/protrade",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "DC",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B   
            "isEditLayout": false,
        },
        "C#CS#TRADE#TPRL_NM": {             //Key layout
            "keyName": "CS#TRADE#TPRL_NM",         //Key của menu active
            "activeURL": path.TPRLTRADE_NM,          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "DC",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "C", // Khách hàng : C, Broker: B
            "isEditLayout": false,
        },
        "B#CS#TRADE#TPRL_NM": {             //Key layout
            "keyName": "CS#TRADE#TPRL_NM",         //Key của menu active
            "activeURL": path.TPRLTRADE_NM,          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "DC",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B   
            "isEditLayout": false,
        },
        "C#CS#TRADE#TPRL_OR": {             //Key layout
            "keyName": "CS#TRADE#TPRL_OR",         //Key của menu active
            "activeURL": path.TPRLTRADE_OR,          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "DC",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "C", // Khách hàng : C, Broker: B
            "isEditLayout": false,
        },
        "B#CS#TRADE#TPRL_OR": {             //Key layout
            "keyName": "CS#TRADE#TPRL_OR",         //Key của menu active
            "activeURL": path.TPRLTRADE_OR,          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "DC",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B   
            "isEditLayout": false,
        },
        "C#CS#TRADE#TPRL_RP": {             //Key layout
            "keyName": "CS#TRADE#TPRL_RP",         //Key của menu active
            "activeURL": path.TPRLTRADE_RP,          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "DC",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "C", // Khách hàng : C, Broker: B
            "isEditLayout": false,
        },
        "B#CS#TRADE#TPRL_RP": {             //Key layout
            "keyName": "CS#TRADE#TPRL_RP",         //Key của menu active
            "activeURL": path.TPRLTRADE_RP,          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "DC",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B   
            "isEditLayout": false,
        },
        "B#CS#TRADE#TT": {             //Key layout
            "keyName": "CS#TRADE#TT",         //Key của menu active
            "activeURL": "/putthroughtrade",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
        "C#CS#MARKET_WATCH": {             //Key layout
            "keyName": "CS#MARKET_WATCH",         //Key của menu active
            "activeURL": "/market-watch",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "C",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
        "B#CS#MARKET_WATCH": {             //Key layout
            "keyName": "CS#MARKET_WATCH",         //Key của menu active
            "activeURL": "/market-watch",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
        "C#CS#AI_TRADING": {             //Key layout
            "keyName": "CS#AI_TRADING",         //Key của menu active
            "activeURL": "/ai-trading",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "C",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
        "B#CS#AI_TRADING": {             //Key layout
            "keyName": "CS#AI_TRADING",         //Key của menu active
            "activeURL": "/ai-trading",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
        "C#CS#ASSET_TOTAL": {             //Key layout
            "keyName": "CS#ASSET_TOTAL",         //Key của menu active
            "activeURL": "/asset-total",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "C",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
        "B#CS#ASSET_TOTAL": {             //Key layout
            "keyName": "CS#ASSET_TOTAL",         //Key của menu active
            "activeURL": "/asset-total",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
        "B#CS#CUSTOMER_ORGANIZATION": {             //Key layout
            "keyName": "CS#CUSTOMER_ORGANIZATION",         //Key của menu active
            "activeURL": "/customer-organization",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
        "B#CS#IBROKER": {             //Key layout
            "keyName": "CS#IBROKER",         //Key của menu active
            "activeURL": "/i-broker",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "B",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
        "C#CS#IBROKER": {             //Key layout
            "keyName": "CS#IBROKER",         //Key của menu active
            "activeURL": "/i-broker",          //Đường dẫn của menu active
            "accountInfo": {
                "curCustomer": [],               //Thông tin khách hàng dành cho MG
                "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                "currentAccountId": "",          //Tiểu khoản đang active
                "typeAccount": TYPE_ACCOUNT.BASE,    //Xem phải tiểu khoản phái sinh không
            },
            "symbols": {
                "curSymbolTrade": null,            //Mã CK active của Đặt lệnh  
                "curSymbolDetail": null           //Mã CK active của Chi tiết mã
            },
            "typeLayout": "D",                  // Loại layout: D = Default , C = Tùy chỉnh, T = Toolbar, N = newTab
            "showMenu": false,
            "typeUser": "C",                    // Khách hàng : C, Broker: B  
            "isEditLayout": false,
        },
    },
    listIsOpenModal: {
        "C&B": {
            "TimeOpenModal": {
                listTimeOpen: []
            },
            "QuickOrder": {
                dataShow: {},
                isOpenQuickOrder: false,
                isOpenModalTradeQuickOrder: false,
                onTradeQuickOrderAuthenCallback: null,
                loadStatusQuickOrderModalTrade: LoadStatus.NONE,
            },
            "ProTrade": {
                dataShow: {},
                isOpenModalProTrade: false,
                onProTradeAuthenCallback: null,
                loadStatusProTradeModalTrade: LoadStatus.NONE,
            },
            "Putthrough": {
                isOpenModalPutthrough: false,
                onPutthrougOrderCallback: null,
                loadStatusPutthroughModalTrade: LoadStatus.NONE,
                currentReconfirmOrderList: []
            },
            "BlbOrder": {
                isOpenModalBlb: false,
                onBlbCallback: null,
                loadStatusBlbModalTrade: LoadStatus.NONE,
                currentReconfirmOrderList: [],
                orderTypeUse: ORDER_TYPE_USE.BLB_TRADE_ORDER // Có thay đổi
            },
            "ScreenConfirm": {
                isOpenScreenConfirm: false, // Modal cho xác thực
                isCheckAuth2: true, // xác thực modal 1 lớp hay 2 lớp
                loadStatusScreenConfirm: LoadStatus.NONE,
                //confirmOnly: false // false nếu có nút quay lại và xác nhận, true khi chỉ có nút xác nhận
            },
            "ScreenModal": {
                //isOpenScreenModal: false,                     //Trạng thái mở màn hình không sinh tab
                //curScreenModalActive: "",                    //Màn hình không sinh tab đang active   
                isOpenScreenModal: false,                     //Trạng thái mở màn hình không sinh tab
                curScreenModalActive: "",                    //Màn hình không sinh tab đang active
                accountInfo: {
                    "curCustomer": [],               //Thông tin khách hàng dành cho MG
                    "accounts": [],                  //Danh sách tiểu khoản (accountid) của tài khoản
                    "currentAccountId": "",          //Tiểu khoản đang active
                    "typeAccount": TYPE_ACCOUNT.FDS,    //Xem phải tiểu khoản phái sinh không
                },
                useCustomHeader: false,               // Dùng header tự tạo
                headerCustom: {},// custom header
                data: {}
            },
            "WaringQuoteChange": {
                isOpenWaringQuoteChange: false,                     //Trạng thái mở màn hình không sinh tab
                data: {}
            },
            "CreatLayout": {// modal lưu layout đặt lệnh
                isOpenModalCreatLayout: false,
                data: {}
            },
            customerSelectorModalOpen: false,
            isSaveTradeAuthenticated: false, // Lưu xác thực đặt lệnh
            isOpenModalActionMethodCondition: false,
            isOpenBlbSelectAckType: false,
            isOpenBlbMatchOrderDetail: false,
            isOpenAdvertisingModal: false,
            isOpenChartIndayModal: false,
            // Chi tiết mã chứng khoán
            isOpenSymbolDetailModal: false,
            isOpenContactModal: false,
            isOpenCorpBondDetailModal: false
            // isOpen ??
        }
    },

    //Layout không sinh Tab 
    layoutsCustom: undefined,
    layoutsConfig: undefined,
    isOpenTradeMoneyBIDV: false,
    isOpenPayment: false,
    isOpenNetwork: false,
    isExpiredTokenErrorScreen: false,
    typeScreenSelectorAccount: "", // Lưu lại màn hình loại nào đang active selector account
    openningScreenInfo: null // Lưu thông tin key màn hình muốn vào sau khi đăng nhập (Sau khi đăng nhập nếu không rỗng ===> Active key trong menuSidebar)
};

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_INIT_LAYOUT_DEFAULT:
            return {
                ...state,
                layoutPage: action.data
            };
        case actionTypes.ADD_LAYOUT_PAGE:
            // Dùng khi tạo layout mới
            let _layoutPage = action.layoutPage
            //console.log('ADD_LAYOUT_PAGE.:1=', action.isSaveByBtn, _layoutPage, state.layoutPage)
            // clone accountInfo từ currentLayoutPageActive
            let _actionLayout = Object.keys(_layoutPage) ? Object.keys(_layoutPage)[0] : "C#CS#PRICEBOARD#CB"
            let _currentLayout = state.currentLayoutPageActive
            _layoutPage[_actionLayout].accountInfo = { ...state.layoutPage[_currentLayout].accountInfo }
            // if (action.isSaveByBtn) {
            //     Object.keys(state.layoutPage).map((key) => {
            //         if (key.indexOf('$TC_TEMP') > -1) {
            //             delete state.layoutPage[key]
            //         }
            //     })
            // }
            // //console.log('ADD_LAYOUT_PAGE.:2=', state.layoutPage)
            return {
                ...state,
                layoutPage: { ...state.layoutPage, ..._layoutPage }
            };
        case actionTypes.UPDATED_LAYOUT_PAGE:
            let _currentLayoutPageActive = state.currentLayoutPageActive
            if (action.key) {
                _currentLayoutPageActive = action.key
            }
            // Dùng upadte trong trường hợp chuyển Tab Active và thay đổi thông tin về tài khoản, mã chứng khoán 
            const data = action.layoutPage
            const _updatedData = { ...state.layoutPage[_currentLayoutPageActive], ...data }
            // //console.log('UPDATED_LAYOUT_PAGE.:', _currentLayoutPageActive, _updatedData)
            return {
                ...state,
                layoutPage: {
                    ...state.layoutPage,
                    [_currentLayoutPageActive]: _updatedData
                }
            };
        case actionTypes.DELETE_LAYOUT_PAGE:
            // //console.log('DELETE_LAYOUT_PAGE.:', action.layoutPage)

            // Dùng để xóa layout newtab
            const newItems = { ...state.layoutPage };
            delete newItems[action.layoutPage]; //
            return {
                ...state,
                layoutPage: newItems
            };
        case actionTypes.CHANGE_LAYOUT_PAGE_ACTIVE:
            // Dùng khi chuyển tab active
            return {
                ...state,
                currentLayoutPageActive: action.currentLayoutPageActive,
                currentLayoutPageActiveTemp: action.currentLayoutPageActiveTemp || undefined
            };
        case actionTypes.SET_IS_OPEN_MODAL_BY_KEY:
            // Dùng set modal theo key
            const _updateListIsOpen = {
                ...state.listIsOpenModal["C&B"],
                [action.key]: action.status
            }
            return {
                ...state,
                listIsOpenModal: {
                    ...state.listIsOpenModal["C&B"],
                    "C&B": _updateListIsOpen
                },
            };
        case actionTypes.SET_IS_OPEN_MODAL_HAVE_DATA:
            // Dùng set Modal theo key
            let _updateModalHaveData = {
                ...state.listIsOpenModal["C&B"][action.key],
                ...action.data,
            }
            if (!action.data['listTimeOpen']) {
                _updateModalHaveData = {
                    ..._updateModalHaveData,
                    useCustomHeader: action.data['useCustomHeader'] ? action.data['useCustomHeader'] : false,
                    headerCustom: action.data['headerCustom'] ? action.data['headerCustom'] : {},
                    data: action.data['data'] ? action.data['data'] : {},
                }
            }
            return {
                ...state,
                listIsOpenModal: {
                    "C&B": {
                        ...state.listIsOpenModal["C&B"],
                        [action.key]: _updateModalHaveData
                    }
                }

            };
        case actionTypes.IS_OPEN_SCREEN_MODAL:
            // Dùng set Modal theo key
            return {
                ...state,
                isOpenScreenModal: action.isOpenScreenModal,
                curScreenModalActive: action.curScreenModalActive
            };

        case actionTypes.IS_OPEN_TRADE_MONEY_BIDV:
            // Dùng set Modal theo key
            return {
                ...state,
                isOpenTradeMoneyBIDV: action.isOpenTradeMoneyBIDV,
            };
        case actionTypes.IS_OPEN_PAYMENT:
            // Dùng set Modal theo key
            return {
                ...state,
                isOpenPayment: action.isOpenPayment,
            };
        case actionTypes.IS_OPEN_NETWORK:
            // Dùng set Modal theo key
            return {
                ...state,
                isOpenNetwork: action.isOpenNetwork,
            };
        case actionTypes.IS_OPEN_EXPIRED_TOKEN_ERROR_SCREEN:
            // Dùng set Modal theo key
            return {
                ...state,
                isExpiredTokenErrorScreen: action.isExpiredTokenErrorScreen,
            };
        case actionTypes.UPDATED_SCREEN_MODAL:
            let _accountInfo = action.layoutPage.accountInfo
            let cloneScreenModal = { ...state.listIsOpenModal['C&B'].ScreenModal }
            cloneScreenModal.accountInfo = _accountInfo
            return {
                ...state,
                listIsOpenModal: {
                    "C&B": {
                        ...state.listIsOpenModal["C&B"],
                        'ScreenModal': cloneScreenModal
                    }
                }
            };


        case actionTypes.SET_BREAKPOINT:
            // //console.log('LOAD_LAYOUTS_CUSTOM=====START', action.layoutsCustom)
            return {
                ...state,
                breakpoint: action.breakpoint,
            };

        case actionTypes.LOAD_LAYOUTS_CUSTOM:
            // //console.log('LOAD_LAYOUTS_CUSTOM=====START', action.layoutsCustom)
            //console.log('UPDATED_LAYOUTS_CUSTOM=====END_11', { layoutsCustom: action.layoutsCustom })
            return {
                ...state,
                layoutsCustom: [...action.layoutsCustom],
                layoutsConfig: [...action.layoutsConfig],
            };

        case actionTypes.UPDATED_LAYOUTS_CUSTOM:
            let isSave = action && action.from && (action.from.indexOf('_BTN_SAVE_TO_SERVER') > -1)
            // alert('UPDATED_LAYOUTS_CUSTOM 1')
            //console.log('UPDATED_LAYOUTS_CUSTOM=====START=======', action.from, JSON.stringify(action))
            // let layoutCustom = { ...layoutCustomDefault, ...action.layoutCustom }
            // let layoutCustom = { ...layoutCustomDefault, ...action.layoutCustom }
            // let _layoutCustomDefault = { ...layoutCustomDefault }
            let layoutCustom = { ...layoutCustomDefault, ...action.layoutCustom }
            layoutCustom.grid = { ...layoutCustomDefault.grid }
            //console.log('UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======2222.00>>>>>>>>>>>>>', JSON.stringify(layoutCustom.grid), JSON.stringify(layoutCustomDefault.grid))

            let curLayoutCustom = {}
            // //console.log('UPDATED_LAYOUTS_CUSTOM=====10.000000 Start==>SAVE:saveLayoutsCustom=', JSON.stringify(action.layoutCustom))
            // let reduxStorea = getStateStore()
            // //console.log("binh_saveLayoutsCustom", reduxStorea)
            // //console.log('UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======000', JSON.stringify(state.layoutsCustom && state.layoutsCustom))
            if (action.layoutCustom && action.layoutCustom.id && state.layoutsCustom) {
                curLayoutCustom = state.layoutsCustom.filter(el => el.id == action.layoutCustom.id);
                curLayoutCustom = curLayoutCustom && curLayoutCustom[0]
            }
            //console.log('1UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======000', JSON.stringify(curLayoutCustom))
            if (action.layoutCustom) {
                // Thêm layoutMerge Single keyMerge = [] và mergeSections
                let mergeSections = {}
                if (action.layoutCustom.mergeSections) {
                    mergeSections = _.cloneDeep(action.layoutCustom.mergeSections)
                }
                let keyMaps = action.layoutCustom.mergeSections && Object.keys(action.layoutCustom.mergeSections)
                if (keyMaps && keyMaps.length > 0) {
                    // Xóa mergeSections thừa
                    _.map(keyMaps, (e) => {
                        if (mergeSections[e] && mergeSections[e].length == 0) {
                            delete mergeSections[e]
                            delete action.layoutCustom.mergeSections[e]
                        }
                    })
                }
                mergeSections = JSON.stringify(mergeSections)
                // //console.log('UPDATED_LAYOUTS_CUSTOM=====START 1.2===', JSON.stringify(mergeSections),)

                let visibleSectionIds = _.cloneDeep(action.layoutCustom.visibleSectionIds)
                if (visibleSectionIds && visibleSectionIds.length > 0) {
                    _.map(action.layoutCustom.visibleSectionIds, (e) => {
                        let isCheck = mergeSections.indexOf(`"${e}"`) > -1
                        if (!isCheck) {
                            // //console.log('UPDATED_LAYOUTS_CUSTOM=====START 1.3===', JSON.stringify(action.layoutCustom.mergeSections))
                            // Thêm mergeSections mặc định tương ứng với visibleSectionIds
                            action.layoutCustom.mergeSections[e] = []
                        }
                    })
                }
                // gọt lại Grid theo keyMaps của mergeSections
                mergeSections = _.cloneDeep(action.layoutCustom.mergeSections)
                // keyMaps = mergeSections && Object.keys(mergeSections)
                // keyMaps = JSON.stringify(keyMaps)
                layoutCustom.mergeSections = mergeSections
                // if (action.layoutCustom.grid && action.layoutCustom.grid.length > 0) {
                if (action.layoutCustom.grid && action.layoutCustom.grid) {


                    // let breakpoint = state.breakpoint
                    let breakpoint = state.breakpoint ? state.breakpoint : checkBreakpointLayout()
                    // //console.log("binh_check_breakpoint", state, breakpoint)
                    let grids = []
                    _.map(action.layoutCustom.grid[breakpoint], (e, i) => {
                        //console.log("UPDATED_LAYOUTS_CUSTOM===== action.layoutCustom.grid[breakpoint] 1", breakpoint, e, i)
                        //console.log("UPDATED_LAYOUTS_CUSTOM===== action.layoutCustom.grid[breakpoint] 2", Object.keys(action.layoutCustom.mergeSections), e.i)
                        if (Object.keys(action.layoutCustom.mergeSections).findIndex(key => key == e.i) > -1) {
                            // xóa grid không tồn tại trong list merge
                            grids.push(e)
                        }
                    })
                    action.layoutCustom.grid[breakpoint] = grids
                    //console.log("UPDATED_LAYOUTS_CUSTOM===== action.layoutCustom.grid[breakpoint] 3", action.layoutCustom.grid, grids)
                    // layoutCustom.grid[breakpoint] = _.filter(grid, g => keyMaps.indexOf(g.i) > -1);
                    //console.log('Update layoutCustom.grid by breakpoint 1=', breakpoint, JSON.stringify(action.layoutCustom.grid))
                    // if (isSave) {
                    // Update lại layoutCustom.grid theo breakpoint
                    //console.log('Update_layoutCustom.grid_0', breakpoint, JSON.stringify(layoutCustom.grid))
                    //console.log('UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======0000.0000 breakpoint', breakpoint)

                    if (breakpoint) {
                        //console.log('UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======0000.0')
                        //console.log('UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======0000.1', JSON.stringify(curLayoutCustom))
                        // let curLayoutCustom = { ..._curLayoutCustom }
                        if (!curLayoutCustom) {
                            _.map(Object.keys(SCREEN), (key) => {
                                layoutCustom.grid[key] = action.layoutCustom.grid[breakpoint]
                            })
                        }
                        else {
                            let isCheck = true
                            let selectBreakpointTmp = SCREEN.lg;
                            _.map(Object.keys(SCREEN), (key) => {
                                let isCheckBreakpoint = curLayoutCustom && curLayoutCustom.grid && curLayoutCustom.grid[key] && (curLayoutCustom.grid[key].length > 0)
                                if (isCheckBreakpoint) {
                                    selectBreakpointTmp = key
                                    isCheck = false
                                }
                            })
                            _.map(Object.keys(SCREEN), (key) => {
                                if (key == breakpoint) {
                                    layoutCustom.grid[breakpoint] = action.layoutCustom.grid[breakpoint]
                                    //console.log('UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======1111', key, JSON.stringify(layoutCustom.grid))

                                } else {
                                    //console.log('UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======2222.1', key, JSON.stringify(layoutCustom.grid))
                                    let curLayoutCustomGrid = curLayoutCustom && curLayoutCustom.grid && _.cloneDeep(curLayoutCustom.grid[key])
                                    let isCheckBreakpoint = curLayoutCustomGrid && (curLayoutCustomGrid.length > 0)
                                    if (isCheckBreakpoint) {
                                        _.remove(curLayoutCustomGrid, function (f) {
                                            // Xóa Đồng bộ lại curLayoutCustom.grid theo mergeSections
                                            let isRemove = Object.keys(mergeSections).findIndex(e => e === f.i) < 0
                                            //console.log('1UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======2222.2222 1111', isRemove, f.i)
                                            return isRemove;
                                        })
                                        layoutCustom.grid[key] = curLayoutCustom && curLayoutCustom.grid && curLayoutCustomGrid
                                        _.map(Object.keys(mergeSections), m => {
                                            let isCheck = layoutCustom.grid[key].findIndex(e => e.i === m)
                                            if (isCheck < 0) {
                                                let item = Object.values(action.layoutCustom.grid[breakpoint]).filter(e => e.i === m)
                                                if (item && item.length > 0) {
                                                    layoutCustom.grid[key].push(item[0])
                                                }
                                            }
                                        })
                                        //console.log('UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======2222.00000', JSON.stringify(layoutCustom.grid))
                                    }
                                    else {
                                        //console.log('1UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======3333.3333 START', JSON.stringify(curLayoutCustom.grid))
                                        _.remove(curLayoutCustom.grid[key], function (f) {
                                            // Xóa Đồng bộ lại curLayoutCustom.grid theo mergeSections
                                            let isRemove = Object.keys(mergeSections).findIndex(e => e === f.i) < 0
                                            //console.log('1UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======3333.3333 1111', isRemove, f.i)
                                            return isRemove;
                                        })
                                        layoutCustom.grid[key] = curLayoutCustom && curLayoutCustom.grid && curLayoutCustom.grid[selectBreakpointTmp]
                                        mergeSections && _.map(Object.keys(mergeSections), m => {
                                            let isCheck = layoutCustom.grid[key].findIndex(e => e.i === m)
                                            if (isCheck < 0) {
                                                let item = Object.values(action.layoutCustom.grid[breakpoint]).filter(e => e.i === m)
                                                if (item && item.length > 0) {
                                                    layoutCustom.grid[key].push(item[0])
                                                }
                                            }
                                        })
                                        //console.log('1UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======3333', key, JSON.stringify(layoutCustom.grid))
                                    }
                                }
                            })
                        }
                    }
                    else {
                        layoutCustom.grid = curLayoutCustom.grid
                    }
                    //console.log('1UPDATED_LAYOUTS_CUSTOM=====START=======layoutCustom=======4444', layoutCustom, JSON.stringify(layoutCustom.grid))

                    //console.log('Update layoutCustom.grid by breakpoint 3=', breakpoint, JSON.stringify(layoutCustom))

                    //console.log('action.layoutCustom===2=', layoutCustom.breakpoint, layoutCustom.grid)
                }
            }
            // //console.log('UPDATED_LAYOUTS_CUSTOM=====START 0000', JSON.stringify(layoutCustom))

            // //console.log('UPDATED_LAYOUTS_CUSTOM=====START 1', JSON.stringify(action.layoutCustom && action.layoutCustom.mergeSections))
            let layoutsCustom = _.cloneDeep(state.layoutsCustom) || []
            let layoutsConfig = _.cloneDeep(state.layoutsConfig) || []
            let LayoutsCustomSave = []
            // let isChange = false
            let typeLayout = (layoutCustom && layoutCustom.typeLayout) ? layoutCustom.typeLayout : "C"
            // alert(typeLayout + '____' + layoutCustom.id)
            // //console.log('UPDATED_LAYOUTS_CUSTOM=====START 2', layoutsCustom)
            // const isLayoutDefault = (layoutCustom.id.indexOf('$TC_TEMP') > -1) || (layoutCustom.id.indexOf('$TC') < 0)

            const isLayoutDefault = (layoutCustom.typeLayout == "D") || (layoutCustom.typeLayout == "DC")
            const currentLayoutPageActive = state.currentLayoutPageActive
            let isLayoutChange = false // check layout có thay đổi không
            // if (action.isSaveByBtn) {
            if (isSave) {
                // clear TEMP sau khi save layout
                // layoutsCustom = layoutsCustom.filter(el => el.id.indexOf('$TC_TEMP') < 0);
                layoutsCustom = layoutsCustom.filter(el => {
                    if ((el.id == currentLayoutPageActive) || (el.id == (currentLayoutPageActive + "$TC_TEMP"))) return false
                    return true
                })
                // layoutsCustom = layoutsCustom.filter(el => {
                //     //console.log('UPDATED_LAYOUTS_CUSTOM=====4', { id: el.id, currentLayoutPageActive }, el.id != currentLayoutPageActive)
                //     if (el.id === currentLayoutPageActive || el.id !== (currentLayoutPageActive + "$TC_TEMP")) {
                //         return true
                //     }
                //     return false
                // }
                // );
                //console.log('UPDATED_LAYOUTS_CUSTOM=====4', layoutsCustom, currentLayoutPageActive, { a: currentLayoutPageActive + "$TC_TEMP", currentLayoutPageActive })
            }
            if (action.layoutCustom) {
                // dùng upadte trong trường hợp chuyển tabactive và thay đổi thông tin về tài khoản, mã chứng khoán 
                let index = layoutsCustom && layoutsCustom.findIndex((e) => e.id === layoutCustom.id)
                if (index > -1) {
                    // update
                    // //console.log('UPDATED_LAYOUTS_CUSTOM=====START3', layoutsCustom[index])
                    layoutsCustom[index] = _.cloneDeep({ ...layoutsCustom[index], ...layoutCustom })
                    // //console.log('UPDATED_LAYOUTS_CUSTOM=====START4', layoutsCustom[index])
                }
                else {
                    // create
                    // //console.log('UPDATED_LAYOUTS_CUSTOM=====START5', layoutCustom)
                    layoutsCustom.push(layoutCustom);
                }
                // let a = [...layoutsCustom]
                // //console.log('UPDATED_LAYOUTS_CUSTOM=====6', layoutCustom, layoutsCustom, index)
                // alert('UPDATED_LAYOUTS_CUSTOM' + layoutCustom.grid.lg.length + a[0].grid.lg.length + layoutsCustom.length)
                // //console.log('UPDATED_LAYOUTS_CUSTOM=====7', typeLayout, isLayoutDefault, action.from, layoutCustom, layoutsCustom, index)
                if (!isLayoutDefault || typeLayout == "DC") {
                    // Haki.: Lưu hơi nhiều do logic merge code đang xử lý nhiều case => cần turning lại
                    // //console.log('UPDATED_LAYOUTS_CUSTOM=====8', action.from, isChange, state.layoutsCustom, layoutsCustom)
                    // if (action.from && action.from.indexOf('MergeSections_') > -1) {
                    //     if (action.from.indexOf('_SAVE_TO_SERVER') > -1) {
                    //         isChange = true
                    //     }
                    // } else {
                    //     (isChange = !_.isEqual(state.layoutsCustom, layoutsCustom) || (action.from && action.from.indexOf('changeIndexWidget') > -1))
                    // }
                    if (isSave) {
                        //console.log('UPDATED_LAYOUTS_CUSTOM=====9==>SAVE:saveLayoutsCustom=', action.from, layoutsCustom)
                        // //console.log('UPDATED_LAYOUTS_CUSTOM=====10.0==>SAVE:saveLayoutsCustom=', JSON.stringify(layoutsCustom))
                        layoutsCustom = CommonWidgetUtils.onConvertLayoutsCustom({ layoutsCustom, currentLayoutPageActive, from: "LayoutsCustom_btnsave_1", statusUpdate: LAYOUT_STATUS.NONE })
                        // //console.log('UPDATED_LAYOUTS_CUSTOM=====10.1==>SAVE:saveLayoutsCustom=', JSON.stringify(layoutsCustom))
                        LayoutsCustomSave = layoutsCustom.filter(el => el.id.indexOf('$TC_TEMP') < 0);
                        // //console.log('UPDATED_LAYOUTS_CUSTOM=====10.2==>SAVE:saveLayoutsCustom=', JSON.stringify(layoutsCustom))
                        saveLayoutsCustom(LayoutsCustomSave, action.from)
                        // //console.log('UPDATED_LAYOUTS_CUSTOM=====10.3==>SAVE:saveLayoutsCustom=', JSON.stringify(layoutsCustom))
                        layoutsConfig = _.cloneDeep(LayoutsCustomSave)
                    }
                }
                //console.log('UPDATED_LAYOUTS_CUSTOM=====11==>SAVE:saveLayoutsCustom=', { layoutsCustom, layoutsConfig }, currentLayoutPageActive)
                // //console.log('UPDATED_LAYOUTS_CUSTOM=====10.4==>SAVE:saveLayoutsCustom=', JSON.stringify(layoutsCustom))
                isLayoutChange = CommonWidgetUtils.onCheckLayoutChange(layoutsCustom, layoutsConfig, currentLayoutPageActive) // check layout có thay đổi không
                // //console.log('UPDATED_LAYOUTS_CUSTOM=====10.5==>SAVE:saveLayoutsCustom=', JSON.stringify(layoutsCustom))
                if (!isSave) {
                    layoutsCustom = CommonWidgetUtils.onConvertLayoutsCustom({ layoutsCustom, currentLayoutPageActive, from: "LayoutsCustom_not_save_1", isLayoutChange })
                }
                // state.layoutsCustom = [...layoutsCustom]
                //console.log('UPDATED_LAYOUTS_CUSTOM=====END_1_3', { isLayoutChange, layoutsCustom })
                // //console.log('UPDATED_LAYOUTS_CUSTOM=====10.6==>SAVE:saveLayoutsCustom=', JSON.stringify(layoutsCustom))
                return {
                    ...state,
                    layoutsCustom: layoutsCustom,
                    layoutsConfig,
                    // gridL: layoutCustom.grid.lg.length,
                };
            }
            if (!isLayoutDefault || typeLayout == "DC") {
                // isChange = (!_.isEqual(state.layoutsCustom, layoutsCustom) || action.from == 'changeIndexWidget')
                // //console.log('UPDATED_LAYOUTS_CUSTOM=====9', isChange, state.layoutsCustom, layoutsCustom)
                if (isSave) {
                    layoutsCustom = CommonWidgetUtils.onConvertLayoutsCustom({ layoutsCustom, currentLayoutPageActive, from: "LayoutsCustom_btnsave_2", statusUpdate: LAYOUT_STATUS.NONE })
                    LayoutsCustomSave = layoutsCustom.filter(el => el.id.indexOf('$TC_TEMP') < 0);
                    //console.log('UPDATED_LAYOUTS_CUSTOM=====10==>SAVE:saveLayoutsCustom=', action.from, layoutsCustom, LayoutsCustomSave)
                    saveLayoutsCustom(LayoutsCustomSave, action.from)
                    layoutsConfig = _.cloneDeep(LayoutsCustomSave)
                }
            }

            isLayoutChange = CommonWidgetUtils.onCheckLayoutChange(layoutsCustom, layoutsConfig, currentLayoutPageActive) // check layout có thay đổi không
            if (!isSave) {
                layoutsCustom = CommonWidgetUtils.onConvertLayoutsCustom({ layoutsCustom, currentLayoutPageActive, from: "LayoutsCustom_not_save_2", isLayoutChange })
            }
            //console.log('UPDATED_LAYOUTS_CUSTOM=====END_2', isLayoutChange, layoutsCustom)

            // Tạm xử lý thêm dữ liệu mergeSections
            return {
                ...state,
                layoutsCustom: [...layoutsCustom],
                layoutsConfig,
            };

        case actionTypes.DELETE_LAYOUTS_CUSTOM:
            // alert('UPDATED_LAYOUTS_CUSTOM 1')
            let deleteLayoutsCustomFrom = action.from
            // //console.log('DELETE_LAYOUTS_CUSTOM=====START', state.from)
            // dùng upadte trong trường hợp chuyển tabactive và thay đổi thông tin về tài khoản, mã chứng khoán 
            let OBJLAYOUT = action.OBJLAYOUT
            // //console.log('DELETE_LAYOUTS_CUSTOM=====START 1', OBJLAYOUT)
            let _layoutsCustom = _.cloneDeep(state.layoutsCustom) || []
            let _layoutsConfig = _.cloneDeep(state.layoutsConfig) || []
            // //console.log('DELETE_LAYOUTS_CUSTOM=====START 2', { OBJLAYOUT, stateLayoutsConfig: state.layoutsConfig, _layoutsCustom, _layoutsConfig })
            let _index = _layoutsCustom && _layoutsCustom.findIndex((e) => (e.id === OBJLAYOUT + "$TC_TEMP") || (e.id === OBJLAYOUT))
            if (_index > -1) {
                // update
                // delete _layoutsCustom[index]
                // _layoutsCustom= _layoutsCustom.splice(_index, 1)
                // layoutsCustom = _layoutsCustom.filter(el => el.id !== OBJLAYOUT);
                _layoutsCustom = _layoutsCustom.filter(e => {
                    if ((e.id == OBJLAYOUT) || (e.id == (OBJLAYOUT + "$TC_TEMP"))) return false
                    return true
                })
            }
            // //console.log('DELETE_LAYOUTS_CUSTOM=====START 2', { OBJLAYOUT, stateLayoutsConfig: state.layoutsConfig, _layoutsCustom, _layoutsConfig })
            // let a = [...layoutsCustom]
            // //console.log('DELETE_LAYOUTS_CUSTOM=====6', _layoutsCustom, _index)
            // alert('UPDATED_LAYOUTS_CUSTOM' + layoutCustom.grid.lg.length + a[0].grid.lg.length + layoutsCustom.length)

            // //console.log('DELETE_LAYOUTS_CUSTOM=====7', _layoutsCustom, _index)
            // const _isLayoutDefault = (OBJLAYOUT.indexOf('$TC_TEMP') > -1) || (OBJLAYOUT.indexOf('$TC') < 0)
            // const _isLayoutDefault = (_layoutsCustom.typeLayout == "D") || (layoutCustom.typeLayout == "DC")

            // if (!_isLayoutDefault) {
            let _isChange = !_.isEqual(state.layoutsCustom, _layoutsCustom)
            // || action.from == 'changeIndexWidget')
            // //console.log('DELETE_LAYOUTS_CUSTOM=====7', isChange, state.layoutsCustom, _layoutsCustom)
            if (_isChange) {
                let _LayoutsCustomSave = _layoutsCustom.filter(el => el.id.indexOf('$TC_TEMP') < 0);
                saveLayoutsCustom(_LayoutsCustomSave, action.from)
            }
            // }
            // state.layoutsCustom = [..._layoutsCustom]
            //console.log('UPDATED_LAYOUTS_CUSTOM=====END_11', { layoutsCustom })
            return {
                ...state,
                layoutsCustom: [..._layoutsCustom],
                layoutsConfig: [..._layoutsCustom],
                // gridL: layoutCustom.grid.lg.length,
            };
        case actionTypes.UPDATE_LAYOUTS_CUSTOM_DATA:
            let _layoutsCustomData = action.layoutsCustom
            return {
                ...state,
                layoutsCustom: [..._layoutsCustomData],
            };
        case actionTypes.SET_TYPE_SCREEN_SELECTOR_ACCOUNT:
            return {
                ...state,
                typeScreenSelectorAccount: action.typeScreenSelectorAccount
            }
        case actionTypes.UPDATE_MENU_ACTIVE:
            return {
                ...state,
                currentMenuActive: action.currentMenuActive
            };

        case actionTypes.SINGLE_USER_LOGOUT:
            return {
                ...initialState,
            };
        case actionTypes.SET_OPENING_KEYNAME:
            // Dùng khi nhấn vào màn hình menu khi chưa đăng nhập
            let newDataInfo = { keyName: action.keyName, screenType: action.screenType, data: action.data ? action.data : {} };
            return {
                ...state,
                openningScreenInfo: {
                    ...newDataInfo
                }
            };
        case actionTypes.CLEAR_OPENNING_INFO:
            // Dùng khi nhấn vào màn hình menu khi chưa đăng nhập
            return {
                ...state,
                openningScreenInfo: null
            };
        default:
            return state;
    }
};

export default layoutReducer;
