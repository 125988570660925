import _ from 'lodash';
import { batch } from 'react-redux'

import { inquiryService, userService } from "../../services";
import { CommonUtils, CommonWidgetUtils, Role, ToastUtil } from "../../utils";
import * as actions from '../actions';

import actionTypes from './actionTypes';
import { updatedLayoutPage } from './layoutActions';
import { updatePlaceQuickOrder } from './orderNewActions';
import { ORDER_TYPE_USE, TYPE_ACCOUNT } from './../../utils/constants';
const globalVar = window._env_

/**
 * Fetch accounts of current user. If user is a broker, this method will do nothing
 */
export const fetchAccountsSuccess = (accounts, info) => {
    return (dispatch, getState) => {
        // fetch account thành công ban đầu cho khách hàng
        if (info['role'] !== Role.CUSTOMER) {
            return;
        }
        //Check dữ liệu tiểu khoản đã lưu để lấy theo tiểu khoản đã lưu
        let accountIdToset = accounts[0].id;
        if (globalVar.switch.enableSaveLoginAccount) {
            let custodycd = info['username'];
            let currentUsersToken = JSON.parse(localStorage.getItem('token-users'));
            let savedAccountId = currentUsersToken[`${custodycd}`] && currentUsersToken[`${custodycd}`]['savedAccountInfo'] ? currentUsersToken[`${custodycd}`]['savedAccountInfo'] : '';
            if (savedAccountId) {
                let foundIndex = _.findIndex(accounts, item => { return item.id === savedAccountId })
                if (foundIndex !== -1) {
                    accountIdToset = savedAccountId;
                }
            }
        }


        const state = getState();
        let listLayoutPage = state.layout.layoutPage
        let listLayoutPageArr = Object.keys(listLayoutPage)
        // Lọc ra tiểu khoản TPRL
        let accountIdTPRL = null
        _.forEach(accounts, item => {
            if (item.typename.includes("TPRL")) {
                accountIdTPRL = item.id
            }
        });
        if (listLayoutPageArr.length > 0) {
            _.forEach(listLayoutPageArr, (item, index) => {
                if (listLayoutPage[item].typeUser === "C") {
                    let _accountInfo = listLayoutPage[item].accountInfo
                    let _accountIdToset = accountIdToset
                    // Đăng nhập nếu là layout TPRL thì set lại accountId
                    if (CommonUtils.getOrderTypeUseLayout(item) == ORDER_TYPE_USE.TPRL_OR_ORDER || CommonUtils.getOrderTypeUseLayout(item) == ORDER_TYPE_USE.TPRL_NM_ORDER && _accountInfo) {
                        if (accountIdTPRL) {
                            _accountInfo.currentAccountId = accountIdTPRL
                            _accountIdToset = accountIdTPRL
                        } else {
                            _accountInfo.currentAccountId = null
                            _accountIdToset = null
                        }
                    }
                    const currentAccount = _.find(accounts, (element) => {
                        return element.id === _accountInfo.currentAccountId;
                    });
                    // //console.log("fetchAccountsSuccess1", currentAccount, item, _accountInfo, currentAccount, _accountIdToset)

                    if (currentAccount) {
                        _accountInfo.accounts = accounts
                        dispatch(updatedLayoutPage(_accountInfo, item))
                    } else {
                        dispatch(updatedLayoutPage(
                            {
                                accountInfo: {
                                    "curCustomer": {},
                                    "accounts": accounts,
                                    "currentAccountId": _accountIdToset,
                                    "typeAccount": (_accountIdToset && _accountIdToset.includes("FDS")) ? TYPE_ACCOUNT.FDS : TYPE_ACCOUNT.BASE
                                }
                            }, item))
                    }
                }
            })
        }

        let placeQuickOrder = state.orderNew.placeQuickOrder["C&B"]
        if (placeQuickOrder) {
            let _accountInfo = placeQuickOrder.accountQuickOrderInfo
            const currentAccount = _.find(accounts, (element) => {
                return element.id === _accountInfo.currentAccountId;
            });

            if (currentAccount) {
                _accountInfo.accounts = accounts
                dispatch(updatePlaceQuickOrder({
                    accountQuickOrderInfo: {
                        ..._accountInfo,
                    }
                }))
            } else {
                dispatch(updatePlaceQuickOrder({
                    accountQuickOrderInfo: {
                        "curCustomer": {},
                        "accounts": accounts,
                        "currentAccountId": accountIdToset,
                        "typeAccount": accountIdToset.includes("FDS") ? TYPE_ACCOUNT.FDS : TYPE_ACCOUNT.BASE,
                    }
                }))
            }
        }
    };
};

/**
 * Fetch accounts list of current customer
 */
export const fetchCustomerAccounts = (callback) => {
    return (dispatch, getState) => {

        const state = getState();
        const currentCustomer = state.customer.currentCustomer;

        dispatch({
            type: actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST
        });

        if (currentCustomer) {
            inquiryService.getCustomerAccounts(currentCustomer['custodycd'])
                .then((data) => {
                    const tranformedData = tranformCustomerAccountList(data);
                    dispatch(fetchCustomerAccountsSuccess(tranformedData, callback));
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                })
        }
    };
};

export const setDefaultAccountId = () => {
    return (dispatch, getState) => {
        userService.getDefaultConfig()
            .then((data) => {
                dispatch({
                    type: actionTypes.SET_DEFAULT_ACCOUNT_ID,
                    defaultAccountId: data ? data.accountid : null
                });
            })
    }
}

export const setCustomerAccounts = (accounts) => {
    return dispatch => {
        dispatch(fetchCustomerAccountsSuccess(accounts));
    }
};

const fetchCustomerAccountsSuccess = (accounts, callback) => {
    return (dispatch, getState) => {
        const state = getState();
        const currentAccountId = state.account.currentAccountId;

        // dispatch({
        //     type: actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST_SUCCESS,
        //     accounts: accounts
        // });

        if (accounts && accounts.length > 0) {
            let selectedAccount;
            if (currentAccountId != null) {
                const currentAccount = _.find(accounts, (element) => {
                    return element.id === currentAccountId;
                });
                if (currentAccount != null) {
                    selectedAccount = currentAccount.id;
                }
            }

            // If currentAccount not set or not found in accounts list
            if (selectedAccount == null) {
                selectedAccount = accounts[0].id;
            }

            batch(() => {
                dispatch({
                    type: actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST_SUCCESS,
                    accounts: accounts
                });
                dispatch(setCurrentAccount(selectedAccount));
                if (callback) callback();
            })
            // dispatch(setCurrentAccount(selectedAccount));
            // if (callback) callback();
        }
    };
};

export const setCurrentAccount = (accountId) => {
    return (dispatch, getState) => {
        const currentState = getState();
        const currentAccountId = currentState.account.currentAccountId;
        if (accountId !== currentAccountId) {
            batch(() => {
                dispatch(actions.clearTradeOrderInput());
                dispatch(actions.clearTradeOrderInputGroup());
            })
        };
        dispatch({
            type: actionTypes.SET_CURRENT_ACCOUNT,
            accountId: accountId
        });
    };
};

export const tranformCustomerAccountList = (accounts) => {
    return _.map(accounts, (account) => {
        return {
            producttypename: account['producttypename'],
            id: account['account'],
            name: account['fullName'],
            accountFull: account['accountFull'],
            custodycd: account['custodycd'],
            corebank: account['corebank'],
            afacctnoext: account['afacctnoext']
        };
    })
};

export const fetchAccounts = (customer, callback) => {
    return (dispatch, getState) => {
        const state = getState();
        const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        const currentCustomer = state.layout.layoutPage[currentLayoutPageActive].accountInfo.curCustomer;
        dispatch({
            type: actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST
        });
        if (currentCustomer) {
            inquiryService.getCustomerAccounts(currentCustomer['custodycd'])
                .then((data) => {
                    const tranformedData = tranformCustomerAccountList(data);
                    dispatch(fetchAccountCustomerSuccess(tranformedData, callback));
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                })
        }
    };
};

const fetchAccountCustomerSuccess = (accounts, callback) => {
    return (dispatch, getState) => {
        const state = getState();
        // const currentAccountId = state.account.currentAccountId;
        const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        const currentAccountId = state.layout.layoutPage[currentLayoutPageActive].account.currentAccountId;
        // dispatch({
        //     type: actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST_SUCCESS,
        //     accounts: accounts
        // });

        if (accounts && accounts.length > 0) {
            let selectedAccount;
            if (currentAccountId != null) {
                const currentAccount = _.find(accounts, (element) => {
                    return element.id === currentAccountId;
                });
                if (currentAccount != null) {
                    selectedAccount = currentAccount.id;
                }
            }

            // If currentAccount not set or not found in accounts list
            if (selectedAccount == null) {
                selectedAccount = accounts[0].id;
            }

            batch(() => {
                dispatch({
                    type: actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST_SUCCESS,
                    accounts: accounts
                });
                dispatch(setCurrentAccount(selectedAccount));
                if (callback) callback();
            })
            // dispatch(setCurrentAccount(selectedAccount));
            // if (callback) callback();
        }
    };
};