export const sessionKeyFactory = {
    reportKeys: 'reportKeys'
};

export const getValueFromSessionStorage = (key) => {
    if (sessionStorage) {
        try {
            const value = sessionStorage.getItem(key);
            return value;
        } catch (e) {
            /*Ignore*/
            return null;
        }
    }
    return null;
};

export const setValueToSessionStorage = (key, value) => {
    if (sessionStorage) {
        return sessionStorage.setItem(key, value);
    }
    return null;
};