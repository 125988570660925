import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
// import inquiryReducer from "./inquiryReducer";
import { createTransform, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// import { CookieStorage } from 'redux-persist-cookie-storage'
// import Cookies from 'cookies-js';
// import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import accountReducer from "./accountReducer";
import appReducer from "./appReducer";
import checkAuthReducer from './checkAuthReducer'
import componentStateReducer from './componentStateReducer';
import customerReducer from './customerReducer';
import layoutReducer from './layoutReducer'
import marketInfoStore from './marketinfo.reducer';
import orderNewReducer from './orderNewReducer';
import orderReducer from './orderReducer';
import serverReducer from './serverReducer';
import socketRealtimeReducer from './socketRealtimeReducer'
import symbolReducer from "./symbolReducer";
import triggerReducer from './triggerReducer';
import userReducer from './userReducer';

const persistCommonConfig = {
    // storage: new CookieStorage(Cookies),
    // storage: storage,
    storage: storageSession,
    stateReconciler: autoMergeLevel2,
};

/**
 * huy.quang
 * Nested persist can be useful for including different storage adapters, code splitting, or deep filtering
 * createFilter not work with deep nested object
 */
const accountPersistConfig = {
    ...persistCommonConfig,
    key: 'account',
    whitelist: ['currentAccountId', 'accounts', `currentAccount`],
    transforms: [
        createTransform(
            (inboundState, key) => {
                let persistAccounts = [];
                /**
                 * huy.quang
                 * transform because cookie size exceeded at 4096KB
                 * sử dụng storage khác k xóa thông tin này khi hết session
                 */
                inboundState.forEach(account => {
                    const { corebank, name, custodycd, id, cftype, accountFull, producttypename, afacctnoext } = account; //get all neccessary with customer & broker  
                    persistAccounts.push({
                        corebank, name, custodycd, corebank, cftype, id, accountFull, producttypename, afacctnoext
                    });
                })
                return persistAccounts;
            },
            (outboundState, key) => {
                return outboundState ? outboundState : [];
            },
            { whitelist: ['accounts'] }
        )
    ]
};

const appPersistConfig = {
    ...persistCommonConfig,
    key: 'app',
    whitelist: ['language', 'tradeAuthenticated', 'currentTradeLayoutId', 'isCheckClickPromotion']
};

const userPersistConfig = {
    ...persistCommonConfig,
    key: 'user',
    whitelist: ['token', 'customerFullName', 'isLoggedIn']
};

const orderNewPersistConfig = {
    ...persistCommonConfig,
    key: 'orderNew',
    whitelist: ['placeQuickOrder', 'placeNormalOrder', 'placeProTradeOrder']
};

const layoutPersistConfig = {
    ...persistCommonConfig,
    key: 'layout',
    whitelist: ['layoutPage', 'currentLayoutPageActive', 'currentMenuActive', 'layoutsCustom', 'layoutsConfig', 'openningScreenInfo', 'breakpoint']
};

const customerPersistConfig = {
    ...persistCommonConfig,
    key: 'customer',
    whitelist: ['currentCustomer']
};

const componentStatePersistConfig = {
    ...persistCommonConfig,
    key: 'componentState',
    whitelist: ['symbolSelector', 'widgetGrid', 'accountOpenPositions', 'nplExecutedByAmount', 'accountMarginLoan']
};
let isPersistorderNew = true
let isPersistLayout = true

export default (history) => combineReducers({
    router: connectRouter(history),
    app: persistReducer(appPersistConfig, appReducer),
    user: persistReducer(userPersistConfig, userReducer),
    account: persistReducer(accountPersistConfig, accountReducer),
    symbol: symbolReducer,
    order: orderReducer,
    orderNew: isPersistorderNew ? persistReducer(orderNewPersistConfig, orderNewReducer) : orderNewReducer,
    customer: persistReducer(customerPersistConfig, customerReducer),
    server: serverReducer,
    triggerActions: triggerReducer,
    layout: isPersistLayout ? persistReducer(layoutPersistConfig, layoutReducer) : layoutReducer,
    checkAuth: checkAuthReducer,
    marketInfoStore,
    componentState: persistReducer(componentStatePersistConfig, componentStateReducer),
    socketRealtime: socketRealtimeReducer,
    // inquiry: inquiryReducer
});
