import { inquiryService } from 'services';

import { ToastUtil } from '../../utils';
import * as actions from '../actions';

import actionTypes from './actionTypes';

export const setCurrentCustomer = (customer) => {
    return (dispatch, getState) => {
        const state = getState();
        const currentCustomer = state.customer.currentCustomer;
        if (!currentCustomer || !customer || customer['custodycd'] !== currentCustomer['custodycd']) {
            dispatch({
                type: actionTypes.SET_CURRENT_CUSTOMER,
                customer: customer
            });
            dispatch(actions.fetchCustomerAccounts());
        }
    }
};
export const changeCustomerInfoEventtype = (event) => ({    //giang.ngo handler event thông báo thay đổi thông tin tài khoản
    type: actionTypes.CHANGE_CUSTOMER_INFO_EVENTTYPE,
    event: event
});
export const changeAccountInfoEventtype = (event) => ({    //giang.ngo handler event thông báo thay đổi thông tin tài khoản
    type: actionTypes.CHANGE_ACCOUNT_INFO_EVENTTYPE,
    event: event
});

export const changePassWordInfoEventtype = (event) => ({ // handler event thông báo thay đổi mật khẩu
    type: actionTypes.CHANGE_PASSWORD_INFO_EVENTTYPE,
    event: event
})

export const setCurrentCustomerByCustodycd = (custodycd, callback) => {  //huy.quang: tìm kiếm customer theo custodycd & gán vào current customer
    return (dispatch, getState) => {
        const state = getState();
        const currentCustomer = state.customer.currentCustomer;
        if (!currentCustomer || currentCustomer['custodycd'] !== custodycd) {
            inquiryService.getCustomers(custodycd).then(data => {
                if (data && data.length === 1) {
                    dispatch(setCurrentCustomer(data[0]));
                    dispatch(actions.fetchCustomerAccounts(callback));
                };
            }).catch(error => {
                dispatch(setCurrentCustomer(null));
                ToastUtil.errorApi(error, 'common.fail-to-load-customer-info');
            });
        } else {
            if (callback) callback();
        }
    };
};