import actionTypes from './actionTypes';

//huy.quang: lưu lại state của 1 số component

/** 
 * huy.quang: luu cac tab bang gia
*/
export const setSymbolSelectorCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorUserCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_USER_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorHnxCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_HNX_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorHoseCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_HOSE_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorUpcomCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_UPCOM_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorPutthroughCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_PUTTHROUGH_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorIndexCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_INDEX_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorCWCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_CW_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorAnalyticCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_ANALYTIC_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorIndustryCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_INDUSTRY_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorDerivativeCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_DERIVATIVE_COLLECTION_ID,
    collectionId: collectionId
});
export const setSymbolSelectorOddlotCollectionId = (collectionId) => ({
    type: actionTypes.SET_SYMBOL_SELECTOR_ODDLOT_COLLECTION_ID,
    collectionId: collectionId
});
/**
 * huy.quang: luu cac widget dang chon tren widget grid
 */
export const setListActiveSection = (listActiveSection) => ({
    type: actionTypes.SET_LIST_ACTIVE_SECTION,
    listActiveSection: listActiveSection
});

/**
 * huy.quang: luu tab dang chon o danh muc dau tu
 */
export const setOpenPositionCurrentPage = (currentPage) => ({
    type: actionTypes.SET_OPEN_POSITION_CURRENT_PAGE,
    currentPage: currentPage
});
/**
 * huy.quang: luu tab dang chon o lai lo dong tien
 */
export const setNplExecutedCurrentPage = (currentPage) => ({
    type: actionTypes.SET_NPL_EXECUTED_BY_AMOUNT_CURRENT_PAGE,
    currentPage: currentPage
});
//luu tab dang chon o Cac khoan no
export const setMarginLoanCurrentPage = (currentPage) => ({
    type: actionTypes.SET_MARGIN_LOANS_CURRENT_PAGE,
    currentPage: currentPage
});
//luu tab dang chon o Hỗ trợ lãi suất
export const setPortfolioInterestRateSupportPage = (currentPage) => ({
    type: actionTypes.SET_PORTFOLIO_INTEREST_RATE_SUPPORT_PAGE,
    currentPage: currentPage
});
//luu tab dang chon o Footer
export const setFooterCurrentPage = (currentPage) => ({
    type: actionTypes.SET_FOOTER_CURRENT_PAGE,
    currentPage: currentPage
});