import { LoadStatus } from "../../utils/constants";
import actionTypes from '../actions/actionTypes';

const initialState = {
    draftOrders: [],
    draftOrdersLoadStatus: LoadStatus.NONE,
    draftOrdersAccountId: null,
    showReconfirmOrder: false,
    currentReconfirmOrderList: [],
    currentAdvanceConditionOrder: null,
    orderTransactionInfo: {}, // transactionId cần truyền vào transAuth trong các xác thực liên quan đến lệnh
    lookUpOrderNew: [], // Dữ liệu màn hình Sổ lệnh mới
    orderPutthroughInfo: {}
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DRAFT_ORDERS:
            return {
                ...state,
                draftOrders: [],
                draftOrdersLoadStatus: LoadStatus.LOADING,
                draftOrdersAccountId: action.account.id
            };
        case actionTypes.CLEAR_DRAFT_ORDER:
            return {
                ...state,
                draftOrders: [],
            };
        case actionTypes.FETCH_DRAFT_ORDERS_SUCCESS:
            return {
                ...state,
                draftOrders: action.orders,
                draftOrdersLoadStatus: LoadStatus.LOADED
            };
        case actionTypes.FETCH_DRAFT_ORDERS_FAIL:
            return {
                ...state,
                draftOrdersLoadStatus: LoadStatus.FAIL
            };
        case actionTypes.SET_SHOW_RECONFIRM_ORDER:
            return {
                ...state,
                showReconfirmOrder: action.status
            };
        case actionTypes.SET_LIST_RECONFIRM_ORDER:
            return {
                ...state,
                currentReconfirmOrderList: action.currentReconfirmOrderList
            };
        case actionTypes.CLEAR_LIST_RECONFIRM_ORDER:
            return {
                ...state,
                currentReconfirmOrderList: []
            };
        case actionTypes.CHANGE_ADVANCE_CONDITION_ORDER:
            return {
                ...state,
                currentAdvanceConditionOrder: action.conditionOrder
            };
        case actionTypes.SET_ORDER_TRANSACTION_INFO:
            return {
                ...state,
                orderTransactionInfo: action.orderTransactionInfo
            };
        case actionTypes.SET_LOOKUP_ORDER_NEW:
            return {
                ...state,
                lookUpOrderNew: action.lookUpOrderNew
            };
        case actionTypes.SET_ORDER_PUTTHROUGH_INFO:
            return {                
                ...state,
                orderPutthroughInfo: action.orderPutthroughInfo
            };
        case actionTypes.CLEAR_ORDER_PUTTHROUGH_INFO:
            return {
                ...state,
                orderPutthroughInfo: {}
            };
        case actionTypes.SINGLE_USER_LOGOUT:
                return {
                    ...initialState,
                };
        default:
            return state;
    }
};

export default appReducer;