import _ from "lodash";

import { initialNonmalOrderInputState, initialProTradeOrderInputState, initialQuickOrderInputState, initialPutthroughOrderInputState, initialBlbOrderInputState, initialChildBlbOrderInputState } from "../../utils/constants";
import actionTypes from '../actions/actionTypes';

let _defaultProTradeOrder = _.cloneDeep(initialProTradeOrderInputState)
const initialState = {
    placeQuickOrder: { // Đặt lệnh nhanh
        "C&B": { ...initialQuickOrderInputState }
    },
    placeNormalOrder: { // Đặt lệnh thông thường
        "C#CS#TRADE#CB": { ...initialNonmalOrderInputState },
        "B#CS#TRADE#CB": { ...initialNonmalOrderInputState },
        "C#CS#TRADE#NC": { ...initialNonmalOrderInputState },
        "B#CS#TRADE#NC": { ...initialNonmalOrderInputState },
        "C#CS#TRADE#TPRL_NM": { ...initialNonmalOrderInputState },
        "B#CS#TRADE#TPRL_NM": { ...initialNonmalOrderInputState },
        "C#CS#TRADE#TPRL_OR": { ...initialNonmalOrderInputState },
        "B#CS#TRADE#TPRL_OR": { ...initialNonmalOrderInputState },
        "C#CS#TRADE#TPRL_RP": { ...initialNonmalOrderInputState },
        "B#CS#TRADE#TPRL_RP": { ...initialNonmalOrderInputState },
    },
    placeProTradeOrder: { // Đặt lệnh protrade
        "C#CS#TRADE#NC": _defaultProTradeOrder,
        "B#CS#TRADE#NC": _defaultProTradeOrder,
        "C#CS#TRADE#CB": _defaultProTradeOrder,
        "B#CS#TRADE#CB": _defaultProTradeOrder,
    },
    placePutthroughOrder: {
        "B#CS#TRADE#TT": { ...initialPutthroughOrderInputState }
    },
    placeBlbOrder: {
        "B#CS#CUSTOMER_ORGANIZATION": { ...initialBlbOrderInputState }
    },
    placeChildBlbOrder: {
        "B#CS#CUSTOMER_ORGANIZATION": { ...initialChildBlbOrderInputState }
    },
    focusOnRender: false
};

const orderNewReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_QUICK_ORDER":
            return {
                ...state,
                placeQuickOrder: { ...state.placeQuickOrder, ...action.data }
            };
        case "DELETE_QUICK_ORDER":
            // dùng để xóa placeQuickOrder key
            const newItems = { ...state.placeQuickOrder };
            delete newItems[action.key];
            return {
                ...state,
                placeQuickOrder: newItems
            };
        case "UPDATE_QUICK_ORDER":
            return {
                ...state,
                placeQuickOrder: action.data,
            };
        case "UPDATE_NORMAL_ORDER":
            return {
                ...state,
                placeNormalOrder: action.data,
            };
        case "UPDATE_PUTTHROUGH_ORDER":
            return {
                ...state,
                placePutthroughOrder: action.data,
            };
        case "UPDATE_BLB_ORDER":
            return {
                ...state,
                placeBlbOrder: action.data,
            };
        case "UPDATE_CHILD_BLB_ORDER":
            return {
                ...state,
                placeChildBlbOrder: action.data,
            };
        case "UPDATE_PROTRADE_ORDER":
            return {
                ...state,
                placeProTradeOrder: action.data,
            };
        case "CLEAR_DATA_QUICK_ORDER":
            return {
                ...state,
                placeQuickOrder: action.data,
            };
        case "ADD_DATA_NORMAL_ORDER":
            return {
                ...state,
                placeNormalOrder: { ...state.placeNormalOrder, ...action.data }
            };
        case "CLEAR_DATA_NORMAL_ORDER":
            return {
                ...state,
                placeNormalOrder: action.data,
            };
        case "CLEAR_DATA_PUTTHROUGH_ORDER":
            return {
                ...state,
                placePutthroughOrder: action.data,
            };
        case "CLEAR_DATA_BLB_ORDER":
            return {
                ...state,
                placeBlbOrder: action.data,
            };
        case "CLEAR_DATA_CHILD_BLB_ORDER":
            return {
                ...state,
                placeChildBlbOrder: action.data,
            };

        case "ADD_DATA_PROTRADE_ORDER":
            return {
                ...state,
                placeProTradeOrder: { ...state.placeProTradeOrder, ...action.data }
            };
        case "CLEAR_DATA_PROTRADE_ORDER":
            //console.log("dataDefault3", action.data)
            return {
                ...state,
                placeProTradeOrder: action.data,
            };
        case actionTypes.SINGLE_USER_LOGOUT:
            return {
                ...initialState,
            };
        case "SET_FOCUS_ON_RENDER":
            return {
                ...state,
                focusOnRender: action.focusKey
            };
        default:
            return state;
    }
};

export default orderNewReducer;