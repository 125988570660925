import actionTypes from "./actionTypes";

/**
 * huy.quang: trigger component's function
 */

export const setWidgetGridActions = (listActionName) => ({
    type: actionTypes.SET_WIDGET_GRID_ACTIONS,
    listActionName: listActionName
});

export const setTabForOpenPossitionsAction = (listActionName) => ({
    type: actionTypes.SET_TAB_FOR_OPEN_POSSITIONS_ACTION,
    listActionName: listActionName
});

export const loadConditionOrderData = (listActionName) => ({
    type: actionTypes.LOAD_CONDITION_ORDER_DATA,
    listActionName: listActionName
});