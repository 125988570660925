import _ from 'lodash';
import { batch } from 'react-redux'
import { push } from 'connected-react-router';
import { inquiryService, userService } from "../../services";
import { CommonWidgetUtils, ToastUtil, Role, CommonUtils, CommonOrderUtils, ORDER_TYPE_USE } from "../../utils";
import * as actions from '../actions';
import actionTypes from './actionTypes';

export const loadIntiDataLayout = (info) => {
    return (dispatch, getState) => {
        const state = getState();

        // Tạm rolback tránh gây lỗi mới login không active tab bảng giá
        let currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let firstChar = currentLayoutPageActive[0];
        if (info['role'] === Role.BROKER && firstChar == Role.CUSTOMER) {
            const replaced = currentLayoutPageActive.replace(firstChar, CommonUtils.getRole());
            batch(() => {
                dispatch(changeLayoutPageActive(replaced))
            })
        }

        let layoutPage = state.layout.layoutPage
        let layoutsCustom = state.layout.layoutsCustom || []
        //console.log("binh_layoutPage", layoutPage, layoutsCustom)
        let flag = false
        if (layoutsCustom && layoutsCustom.length > 0) {
            layoutsCustom.forEach((item) => {
                let layoutItem = layoutPage[item.id]
                //console.log("binh_layoutPage2", layoutItem)
                if (layoutItem && layoutItem.showMenu) {
                    delete layoutPage[item.id]
                    flag = true
                }
            });
            if (flag) {
                dispatch(changeLayoutPageActive(`${info['role']}#CS#PRICEBOARD#CB`))
                dispatch({
                    type: actionTypes.LOAD_INIT_LAYOUT_DEFAULT,
                    data: layoutPage
                });
            }
        }
    };
};

export const getLayoutConfig = () => {
    return (dispatch, getState) => {
        // const state = getState();
        userService.getLayoutConfig()
            .then((data) => {
                // //console.log('getLayoutConfig.:data==', data)
                if (data && data.configLayout) {
                    // //console.log('getLayoutConfig.:data.configLayout==1', data.configLayout)
                    let _configLayout = CommonWidgetUtils.overrideConfigLayoutServer(data.configLayout)
                    // //console.log('getLayoutConfig.:data.configLayout==2', { dataConfigLayout: data.configLayout, _configLayout })
                    batch(() => {
                        dispatch({
                            type: actionTypes.LOAD_LAYOUTS_CUSTOM,
                            layoutsCustom: _configLayout,
                            layoutsConfig: _configLayout,
                        });
                        // dispatch({
                        //     type: actionTypes.UPDATED_LAYOUTS_CUSTOM,
                        //     layoutCustom: undefined,
                        //     isSaveByBtn: true,
                        //     from: 'initializeApp'
                        // });
                        // let currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
                        // currentLayoutPageActive = currentLayoutPageActive.replace('$TC_TEMP', '')
                        // dispatch(changeLayoutPageActive(currentLayoutPageActive))
                    })
                }

            })
            .catch((error) => {
                // dispatch({
                //     type: actionTypes.LOAD_LAYOUTS_CUSTOM,
                //     layoutsCustom: []
                // });
                //console.log('getLayoutConfig.:error==', error)
            });
    };
};


export const getLayoutConfigLocalStorage = () => {
    return (dispatch, getState) => {
        return // off logic lưu layout lên local
        const state = getState();
        const { user } = state
        const { isLoggedIn } = user
        if (!isLoggedIn) {
            const layoutsCustomStorage = JSON.parse(localStorage.getItem("layoutsCustomStorage")) || []
            batch(() => {
                dispatch({
                    type: actionTypes.LOAD_LAYOUTS_CUSTOM,
                    layoutsCustom: layoutsCustomStorage
                });
            })
        }
    };
};



export const addLayoutPage = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.ADD_LAYOUT_PAGE,
            layoutPage: data,
        });
        // dispatch(actions.addPlaceQuickOrder(Object.keys(data)[0]))
    };
};

export const updatedLayoutPage = (data, key) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.UPDATED_LAYOUT_PAGE,
            layoutPage: data,
            key: key
        });
    };
};

export const updatedScreenModal = (data, key) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.UPDATED_SCREEN_MODAL,
            layoutPage: data,
            key: key
        });
    };
};

export const deleteLayoutPage = (keyLayout) => {
    return (dispatch, getState) => {
        let id = CommonWidgetUtils.getReplaceTempLayoutPageActive(keyLayout)
        const state = getState();
        const layoutPage = state.layout.layoutPage
        const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        const _role = state.user.userInfo.role || "C"
        //console.log('deleteLayoutPage==1=', id, currentLayoutPageActive, layoutPage)
        let layoutShowing = Object.keys(layoutPage).filter((item, index) => {
            if (layoutPage[item].showMenu && layoutPage[item].typeUser.includes(_role)) {
                return true
            } return false
        })
        //console.log('deleteLayoutPage==2=', layoutShowing)
        batch(() => {
            const index = layoutShowing.findIndex(e => e == id);
            const key = layoutShowing[index - 1]
            //console.log('deleteLayoutPage==3=', { id, index, layoutShowing, layoutPage: layoutPage, layoutPageKey: layoutPage[key], key })
            dispatch(actions.onChangeActiveURLSideBar(layoutPage[key].activeURL))
            dispatch(actions.onChangeMenuActiveSideBar(layoutPage[key].keyName))
            dispatch(push(layoutPage[key].activeURL));
            dispatch(actions.changeLayoutPageActive(key))
            //console.log('deleteLayoutPage==4=', key)
            dispatch({
                type: actionTypes.DELETE_LAYOUT_PAGE,
                layoutPage: id,
            });
            dispatch(actions.deletePlaceQuickOrder(id))
        });
    };
};

export const updateDataSymbolDetailLayoutPage = (symbolObj) => {
    return (dispatch, getState) => {
        //console.log("binh_updateDataSymbolDetailLayoutPage", symbolObj)
        if (symbolObj) {
            if (symbolObj.isDetailIndex) {
                dispatch(actions.updatedLayoutPage({
                    "symbols": {
                        "curSymbolTrade": symbolObj
                    }
                }))
            } else {
                if (symbolObj.id) {
                    let key = 'C#CS#SYMBOL_DETAIL'
                    if (CommonUtils.isBroker()) {
                        key = 'B#CS#SYMBOL_DETAIL'
                    }
                    dispatch(actions.updatedSymbolLayoutPage(symbolObj.id, "search_symbol_detail", key)) // id từ ngoài truyền vào
                }
            }
        } else {
            dispatch(actions.updatedLayoutPage({
                "symbols": {
                    "curSymbolTrade": null
                }
            }))
        }
    };
}

export const updatedSymbolLayoutPage = (symbolId, from = "search_layout", key) => {
    //from 2 loại:
    // + "search_layout": Dùng cho truyền symbol trong layout
    // + "search_symbol_detail": Dùng cho search mã chứng khoán tại màn chi tiết mã, màn hình bảng giá
    return async (dispatch, getState) => {
        // //console.log("binh_updatedSymbolLayoutPage", { symbolId, from, key })
        const state = getState();
        let _allQuotes = state.symbol.allQuotes
        let symbolWithIndex = state.symbol.symbolWithIndex

        let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
        let _curSymbolTrade = (_layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]) || {}
        let id = key ? key : _currentLayoutPageActive
        if (_curSymbolTrade && _curSymbolTrade["id"] === symbolId) {
            return
        }
        // //console.log("binh_updatedSymbolLayoutPage_1", { symbolId, from, key })
        if (symbolId) {
            let foundSymbol = {};
            // Lấy thông tin instrument nếu hiển thị dữ liệu cho chi tiết mã
            // if (id && ((id === 'C#CS#SYMBOL_DETAIL' || id === 'B#CS#SYMBOL_DETAIL') || (id === 'C#CS#PRICEBOARD#CB' || id === 'B#CS#PRICEBOARD#CB'))) {
            if (from && from.includes("symbol_detail")) {
                CommonUtils.fetchApiInstrumentsBySymbol([symbolId], [ORDER_TYPE_USE.NORMAL_ORDER, ORDER_TYPE_USE.PRO_TRADE_ORDER]) // thêm async để loadapi xong thì mới cập nhật giá vào input giá
                foundSymbol = symbolWithIndex[symbolId];
            } else {
                foundSymbol = _.find(_allQuotes, (element) => (element.id === symbolId))
            }
            if (foundSymbol) {
                dispatch(actions.updatedLayoutPage({
                    "symbols": {
                        "curSymbolTrade": foundSymbol
                    }
                }, id))
            }
            // //console.log("binh_updatedSymbolLayoutPage_2", { symbolId, from, key })
        } else {
            dispatch(actions.updatedLayoutPage({
                "symbols": {
                    "curSymbolTrade": null
                }
            }, id))
        }
        // //console.log("binh_updatedSymbolLayoutPage_3", { symbolId, from, key })

        if (from.includes("layout")) {
            let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
            const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
            let arrKeep = ['action'];
            if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
                if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                    dispatch(actions.clearDataPlaceQuickOrder(ORDER_TYPE_USE.NORMAL_ORDER, arrKeep))
                    dispatch(actions.updatePlaceQuickOrder({
                        symbolCurrent: symbolId,
                    }, ORDER_TYPE_USE.NORMAL_ORDER))
                }
                if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                    dispatch(actions.clearDataPlaceProTrade())
                    dispatch(actions.updateProTrade({
                        symbolCurrent: symbolId,
                    }))
                }
            }
            // else {
            //     dispatch(actions.clearDataPlaceQuickOrder(ORDER_TYPE_USE.NORMAL_ORDER, arrKeep))
            //     dispatch(actions.clearDataPlaceProTrade())
            //     dispatch(actions.updatePlaceQuickOrder({
            //         symbolCurrent: symbolId,
            //     }, ORDER_TYPE_USE.NORMAL_ORDER))
            //     dispatch(actions.updateProTrade({
            //         symbolCurrent: symbolId,
            //     }))
            // }
        }
    };
};


export const hideLayoutPage = (keyLayout) => {
    return (dispatch, getState) => {
        let id = CommonWidgetUtils.getReplaceTempLayoutPageActive(keyLayout)
        const state = getState();
        const layoutPage = state.layout.layoutPage
        const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        const _role = state.user.userInfo.role || ""
        //console.log('hideLayoutPage==1=', id, currentLayoutPageActive, layoutPage)
        let layoutShowing = Object.keys(layoutPage).filter((item, index) => {
            if (layoutPage[item].showMenu && layoutPage[item].typeUser.includes(_role)) {
                return true
            } return false
        })
        //console.log('hideLayoutPage==2=', layoutShowing)
        batch(() => {
            const index = layoutShowing.findIndex(e => e == id);
            const key = layoutShowing[index - 1]
            //console.log('hideLayoutPage==3=', { id, key, layoutShowing, layoutPage, index })
            if (key) {
                //console.log('hideLayoutPage==4=', layoutPage[key], key, layoutShowing, layoutPage, index)
                dispatch(actions.onChangeMenuActiveSideBar(layoutPage[key].keyName))
                dispatch(actions.onChangeActiveURLSideBar(layoutPage[key].activeURL))
                dispatch(push(layoutPage[key].activeURL));
                dispatch(actions.changeLayoutPageActive(key))
                dispatch(actions.updatedLayoutPage({
                    "showMenu": false,
                    "isEditLayout": false,
                }, id))
            }
        });
    };
};


export const changeLayoutPageActive = (currentLayoutPageActive) => {
    //console.log("changeLayoutPageActive_currentLayoutPageActive", currentLayoutPageActive)
    return (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.CHANGE_LAYOUT_PAGE_ACTIVE,
                currentLayoutPageActive: currentLayoutPageActive,
            });
            // // Chuyển tab active mới thì xóa layoutcustom TEMP
            // dispatch({
            //     type: actionTypes.UPDATED_LAYOUTS_CUSTOM,
            //     layoutCustom: undefined,
            //     isSaveByBtn: true,
            //     from: 'changeLayoutPageActive'
            // });
        })
    };
};

export const setIsOpenScreenModal = (isOpenScreenModal, curScreenModalActive) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_OPEN_SCREEN_MODAL,
            isOpenScreenModal: isOpenScreenModal,
            curScreenModalActive: curScreenModalActive
        });
    };
};

export const setIsOpenTradeMoneyBIDV = (isOpen) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_OPEN_TRADE_MONEY_BIDV,
            isOpenTradeMoneyBIDV: isOpen,
        });
    };
};

export const setIsOpenPayment = (isOpen) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_OPEN_PAYMENT,
            isOpenPayment: isOpen,
        });
    };
};

export const setIsOpenNetwork = (isOpen) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_OPEN_NETWORK,
            isOpenNetwork: isOpen,
        });
    };
};

export const setIsOpenExpiredTokenErrorScreen = (isOpen) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_OPEN_EXPIRED_TOKEN_ERROR_SCREEN,
            isExpiredTokenErrorScreen: isOpen,
        });
    };
};


export const tranformCustomerAccountList = (accounts) => {
    return _.map(accounts, (account) => {
        return {
            producttypename: account['producttypename'],
            id: account['account'],
            name: account['fullName'],
            accountFull: account['accountFull'],
            custodycd: account['custodycd'],
            corebank: account['corebank'],
            afacctnoext: account['afacctnoext']
        };
    })
};

export const setCurrentAccountLayoutTop = (customer) => {
    return (dispatch, getState) => {
        const state = getState();
        const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        const currentCustomer = state.layout.layoutPage[currentLayoutPageActive].accountInfo.curCustomer;
        if (!currentCustomer || !customer || customer['custodycd'] !== currentCustomer['custodycd']) {
            dispatch({
                type: actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST
            });

            if (customer) {
                inquiryService.getCustomerAccounts(customer['custodycd'])
                    .then((data) => {
                        const tranformedData = tranformCustomerAccountList(data);
                        dispatch(fetchAccountCustomerSuccess(tranformedData, customer));
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                    })
            }
            dispatch(actions.fetchAccounts(customer));
        }
    };
};

const fetchAccountCustomerSuccess = (accounts, customer) => {
    return (dispatch, getState) => {
        const state = getState();
        const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        const currentAccountId = state.layout.layoutPage[currentLayoutPageActive].accountInfo.currentAccountId;
        if (accounts && accounts.length > 0) {
            let selectedAccount;
            if (currentAccountId != null) {
                const currentAccount = _.find(accounts, (element) => {
                    return element.id === currentAccountId;
                });
                if (currentAccount != null) {
                    selectedAccount = currentAccount.id;
                }
            }
            // If currentAccount not set or not found in accounts list
            if (selectedAccount == null) {
                selectedAccount = accounts[0].custodycd;
            }
            const data = {
                accountInfo: {
                    curCustomer: customer,
                    accounts: accounts[0],
                    currentAccountId: selectedAccount
                }
            }
            dispatch({
                type: actionTypes.UPDATED_LAYOUT_PAGE,
                layoutPage: data,
                key: currentLayoutPageActive
            });
        }
    };
};


export const setIsOpenModalByKey = (key, status) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_IS_OPEN_MODAL_BY_KEY,
            key: key,
            status: status,
        });
    }
};


export const setIsOpenModalHaveData = (key, data) => {
    return (dispatch, getState) => {
        //console.log("binh_setIsOpenModalHaveData", key, data)
        dispatch({
            type: actionTypes.SET_IS_OPEN_MODAL_HAVE_DATA,
            key: key,
            data: data
        });
    }
};



// Haki_lt.:LayoutCustom [Start]
export const addNewLayoutCustom = (newData, OBJLAYOUT_CUSTOM, layoutCustom, isSaveByBtn, isOverRidesDf, from) => {
    // //console.log("addNewLayoutCustom from==", isOverRidesDf, from)
    return (dispatch, getState) => {
        //console.log('addNewLayoutCustom.:', newData, OBJLAYOUT_CUSTOM, layoutCustom, isSaveByBtn, isOverRidesDf, from)
        let _layoutCustom = _.cloneDeep(layoutCustom)
        batch(() => {
            dispatch({
                type: actionTypes.ADD_LAYOUT_PAGE,
                layoutPage: newData,
                isSaveByBtn: isSaveByBtn
            });

            //sửa tạm khi ghi đè bị clear mã chứng khoán
            if (!isOverRidesDf) {
                dispatch(actions.addPlaceQuickOrder(Object.keys(newData)[0]))
                dispatch(actions.addPlaceNomalOder(Object.keys(newData)[0]))
            }
            dispatch(actions.addProTradeOder(Object.keys(newData)[0]))
            dispatch({
                type: actionTypes.CHANGE_LAYOUT_PAGE_ACTIVE,
                currentLayoutPageActive: OBJLAYOUT_CUSTOM,
            });
            if (isSaveByBtn) {
                CommonWidgetUtils.toggleEnableEditLayout(false, "from_addNewLayoutCustom")
            }
            // if (layoutCustom) {
            // if (layoutCustom) {
            dispatch({
                type: actionTypes.UPDATED_LAYOUTS_CUSTOM,
                layoutCustom: _layoutCustom,
                isSaveByBtn: isSaveByBtn,
                // from: isOverRidesDf ? 'addNewLayoutCustom_BTN_SAVE_TO_SERVER' : 'addNewLayoutCustom'
                from: isOverRidesDf ? `${from}_BTN_SAVE_TO_SERVER` : from
            });
            // }
            dispatch(push(newData[OBJLAYOUT_CUSTOM].activeURL));
        })
    }
};

export const updateLayoutCustom = (layoutCustom, from, isSaveByBtn) => {
    //console.log('updateLayoutCustom.:', layoutCustom, from)
    return (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.UPDATED_LAYOUTS_CUSTOM,
                layoutCustom: layoutCustom,
                // isSaveByBtn: isSaveByBtn,
                from
            });
        })
    }
};

export const updateLayoutCustomData = (layoutsCustom, from) => {
    //console.log('updateLayoutCustomData.:', layoutsCustom)

    return (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.UPDATE_LAYOUTS_CUSTOM_DATA,
                layoutsCustom: layoutsCustom,
                from
            });
        })
    }
};

export const deleteLayoutCustom = (OBJLAYOUT, from) => {
    // //console.log('updateLayoutCustom.:', layoutCustom, from)
    return (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.DELETE_LAYOUTS_CUSTOM,
                OBJLAYOUT: OBJLAYOUT,
                from
            });
        })
    }
};

export const setCurrentTypeScreenSelectorAccount = (typeScreen) => {
    return (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.SET_TYPE_SCREEN_SELECTOR_ACCOUNT,
                typeScreenSelectorAccount: typeScreen
            });
        })
    }
};



export const saveTradeAuthenticated = () => {
    return (dispatch, getState) => {
        batch(() => {
            dispatch(setIsOpenModalByKey(
                "isSaveTradeAuthenticated",
                true
            ))
        })
    }
};


export const clearTradeAuthenticated = () => {
    return (dispatch, getState) => {
        batch(() => {
            dispatch(setIsOpenModalByKey(
                "isSaveTradeAuthenticated",
                false
            ))
        })
    }
};

export const setIsDeskTop = (isDeskTop) => {
    // //console.log('updateLayoutCustom.:', layoutCustom, from)
    return (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.SET_IS_DESKTOP,
                isDeskTop
            });
        })
    }
};


export const setBreakpoint = (breakpoint) => {
    // //console.log('updateLayoutCustom.:', layoutCustom, from)
    return (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.SET_BREAKPOINT,
                breakpoint
            });
        })
    }
};


export const setOpenningScreenInfo = (keyName, screenType, data = {}) => {
    // //console.log('updateLayoutCustom.:', layoutCustom, from)
    sessionStorage.setItem('openningScreenInfo', JSON.stringify({ keyName, screenType, data }));
    return (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.SET_OPENING_KEYNAME,
                keyName,
                screenType,
                data
            });
        })
    }
};

export const clearOpenningScreenInfo = () => {
    // //console.log('updateLayoutCustom.:', layoutCustom, from)
    sessionStorage.setItem('openningScreenInfo', null);
    return (dispatch, getState) => {
        batch(() => {
            dispatch({
                type: actionTypes.CLEAR_OPENNING_INFO
            });
        })
    }
};



// Haki_lt.:LayoutCustom [End]