//socket thị trường'
import _ from 'lodash';
import { batch } from 'react-redux';
import io from 'socket.io-client';

import { Event } from 'constants/config';
import { emitter } from 'utils/EventEmitter';

import config from './combineConfig';
import reduxStore, { dispatch } from './redux';
import { symbolService, logService } from './services';
import * as actions from './store/actions';
import actionTypes from './store/actions/actionTypes';
import { CommonUtils, SocketStatus, Random } from "./utils";
import { queueProcessCommon } from './queueProcess'
const queueProcess = new queueProcessCommon()

const globalVar = window._env_
const IS_CHECK_SOCKET_SEQ = globalVar.app.IS_CHECK_SOCKET_SEQ;
const configSendLog = globalVar.app.configSendLog || {};
const isLogMsgSocket = configSendLog && configSendLog.isLogMsgSocket || false

const TAG_NAME = 'socket.js.:'// Socket Market
const isDevelopment = process.env.NODE_ENV === "development";

// let LocalConfigWeb = JSON.parse(localStorage.getItem('LocalConfigWeb'))
// LocalConfigWeb.isOpenLogSocket = true // Hardcode ghi log
// const logDebug = (from, msg) => {
//     if (LocalConfigWeb) {
//         if (LocalConfigWeb.isOpenLogSocket) {
//             let timeLog = moment(new Date()).format('YYYY-MM-DD HH:MM:SS')
//             console.debug(`${timeLog} ${from}.:= ${JSON.stringify(msg)}`)
//         }
//     }
// }

export let sidSocket = undefined
let isLogSocketFull = isLogMsgSocket // = true: ghi log chi tiết msg
let queueTime = 0 // time call func queue
function logDebug(from, msg) {
    from = sidSocket ? sidSocket + " " + from : from
    CommonUtils.logDebug(from, msg)
}
logDebug("socket.:logDebug", "START LOG")

export const socketRoomName = {
    'instrument': "i",    //room publish thông tin mã chứng khoán
    'instrument_ol': "i_ol",    //room publish thông tin mã chứng khoán lô lẻ
    'trade': "t",         //room publish thông tin chi tiết khớp mã chứng khoán
    'trade_ol': "t_ol",         //room publish thông tin chi tiết khớp mã chứng khoán lô lẻ
    'tradeExchange': "et",      //room publish thông tin chi tiết khớp mã chứng khoán theo sàn (sub theo floor code: HOSE: 10, HNX: 02, UPCOM: 04)
    'orderBook10': "o10",   //room publish thông tin top10 giá mua giá bán mã chứng khoán
    // 'orderBook_ol10': "o_ol10",   //room publish thông tin top10 giá mua giá bán mã chứng khoán lô lẻ
    // 'orderBook3': "o3",    //room publish thông tin top3 giá mua giá bán mã chứng khoán
    // 'orderBook_ol3': "o_ol3",    //room publish thông tin top3 giá mua giá bán mã chứng khoán lô lẻ
    'orderBook': "o",    //Loại event publish thông tin top3 giá mua giá bán mã chứng khoán
    // 'orderBook_ol': "o_ol",    //Loại event publish thông tin top3 giá mua giá bán mã chứng khoán lô lẻ
    'exchange': "e",    //room subscribe thông tin mã chứng khoán theo sàn
    'exchange_ol': "e_ol",    //room subscribe thông tin mã chứng khoán lô lẻ theo sàn
    'index': "idx",          //room publish thông tin chỉ số
    'putthrough': "pth",  //room publish thông tin mua bán thỏa thuận theo sàn
    'ptmatch': "ptm",     //room publish thông tin mua bán thỏa thuận theo sàn

    'channel': "ch",      //room publish các loại thông tin cho các kênh kết nối dạng server2server

    // 'account': "acc",      //room publish tín hiệu thay đổi balance tiểu khoản
    // 'customer': "cus",    //room publish thông tin liên quan đến tài khoản (Vd: thông báo đã download báo cáo xong)
    // 'message': "msg",      //room publish thông điệp, cảnh báo liên quan tới tài khoản, hoặc các tiểu khoản
    // 'report': "rpt",      //Loại event report bắn chung vào room customer
    // 'message_analytic': "msg.analyt",      //room publish thông điệp tin phân tích do công ty ck soạn

    'corpbond': 'c', // Kênh subscribe cho TPRL
    'corpbondmatch': 'cm' // Kênh subscribe cho tín hiệu khớp TPRL
};

var reTryGetInstruments = 0
var reTryGetOddLotInstruments = 0
// var countReconnect = 0
// var countConnect = 0
var countSeqTest = {};       // countSeqTest Socket
const socketAction = {    // socket action từ server trả về
    partial: "p",    // socket action partial
    update: "u",    // socket action update
    insert: "i",    // socket action insert
    delete: "d",    // socket action delete
    signal: "s",    // socket action signal
};
const SDK_INFO = {
    '__sails_io_sdk_version': '1.2.1',
    '__sails_io_sdk_platform': 'browser',
    '__sails_io_sdk_language': 'javascript',
    // clientid: config.api.CLIENT_ID,
    // clientsecret: config.api.CLIENT_SECRET
};
//socket thị trường'
const socket = io(
    window._env_.api.API_MARKET_URL, {
    // 'https://trading.kss.com.vn/', {

    path: '/market/socket.io',
    // path: '/market/socket.io',
    transports: ['websocket'],
    query: SDK_INFO,
    autoConnect: false
});

const textGreen = '\x1b[32m';
let subscribedTopics = [];
const registeredTopics = {};
const registeredCalledIds = {};

let listSymbolMissSeq = {
    "instrument": [],
    "instrument_ol": [],
};
let TIME_BLOCK_CALL_API = 1000;
let LAST_TIME_CALL_API = {
    "instrument": new Date().getTime(),
    "instrunmet_ol": new Date().getTime(),
};

const is_Check_Call_Api = (type) => {
    let now = new Date().getTime()
    if (now > LAST_TIME_CALL_API[type] + TIME_BLOCK_CALL_API) {
        LAST_TIME_CALL_API[type] = now;
        return true;
    }
    return false;
}
let statusConnectSocket = "connecting"
const timeoutEmit = 500; // timeout phản hồi từ server

const TabID = CommonUtils.getTabID()
let token = null;
let isReloadData = false
logService.debug({ type: "socket.:infoBrowser", tabID: TabID, isInfoBrowser: true, logBy: "socket.js" }) // log thông tin Browser
let listIdSubReTry = []

const maxReSubSocket = 5 // Tối đa resub
let countReSubSocket = {} // count theo từng key là idReSub

const reSubSocket = (topics, data) => {
    // return null; // Tạm chặn logic resub chờ check log server Socket vì sao trả ra chậm.
    if (statusConnectSocket !== "connected") {
        // reconnectSocketHandler()
        return null;
    }
    let idReSub = data && data.idSub || undefined
    if (!idReSub) return null;
    if (countReSubSocket[idReSub] > maxReSubSocket) {
        // reconnectSocketHandler()
        return null;
    }
    if (countReSubSocket[idReSub]) {
        countReSubSocket[idReSub] = countReSubSocket[idReSub] + 1
    } else {
        countReSubSocket[idReSub] = 1
    }
    logDebug("socket.:reSubSocket().:=", { idReSub, countReSubSocket: countReSubSocket[idReSub], countReSubSocket })
    let _index = _.findIndex(listIdSubReTry, (item) => { return item === idReSub })
    if (_index == -1) {
        listIdSubReTry.push(idReSub)
    }
    let _subscribedTopics = Object.keys(registeredTopics) || []
    // logDebug("socket.:reSubSocket().:topics=", { topics, _subscribedTopics })
    if (!registeredTopics || registeredTopics.length === 0) {
        return false;
    }
    let _topics = []
    _.map(topics, (topic) => {
        if (_subscribedTopics.indexOf(topic) > -1) {
            _topics.push(topic)
        }
    })
    if (_topics && _topics.length > 0) {
        _subscribeToTopics(_topics, idReSub, countReSubSocket[idReSub])
    }
    return null;
}

const _subscribeToTopics = (topics, idReSub, countReSub) => {
    let idSub = idReSub || new Date().getTime() + "_" + Random.randomIdChars(6)
    logDebug("socket.:_subscribeToTopics().:topics=", { topics })
    // //console.log("_subscribeToTopics.:topics=", topics)
    // ==== Bỏ logic clear InstrumentSeq=> Thay bằng logic group call api trong khoảng time LAST_TIME_CALL_API ==== 
    // Clear Seq truoc khi subscribe (e và i) de tranh bi miss SEQ do lech SEQ thoi diem dau
    // let _topics = JSON.stringify(topics)
    // if (_topics.indexOf("e:") > -1 || _topics.indexOf("i:") > -1) {
    //     // console.debug("Socket_subscribeToTopics.:Clear Seq", _topics)
    //     dispatch(actions.updateInstrumentSeq(true));
    // }
    // if (_topics.indexOf("e_ol:") > -1) {
    //     // console.debug("Socket_subscribeToTopics.:Clear Seq", _topics)
    //     dispatch(actions.updateOddLotInstrumentSeq(true));
    // }
    // ==== Bỏ logic clear InstrumentSeq=> Thay bằng logic group call api trong khoảng time LAST_TIME_CALL_API ==== 
    try {
        if (!topics || topics.length === 0) {
            logDebug("socket.:subscribe.:topics length = 0");
            return false;
        }

        if (!socket.connected) {
            logDebug("socket.:subscribe.:socket not connected");
            logService.debug({ typeCall: "_subscribeToTopics", tabId: TabID, sidSocket: sidSocket, token: token, topics, socketStatus: socket.connected, statusConnectSocket, logBy: "socket.js" })
            return false;
        }

        let state, tabId, SID_socket;
        // state = reduxStore.getState();
        // token = state.user.token != null ? state.user.token['access_token'] : null;
        token = CommonUtils.getTokenCurr(40)
        tabId = TabID
        SID_socket = sessionStorage.getItem("sidSocketCurr")

        // const token = state.user.token != null ? state.user.token['access_token'] : null;
        let timeSub = new Date().getTime()
        // https://socket.io/docs/v4/client-api/
        let data = {
            op: 'subscribe',
            args: topics,
            token: token,
            tabId: tabId,
            idSub: idSub,
            timeSub: timeSub,
            SID_socket,
            countReSub
        }
        logDebug("socket.:subscribe.:START 1", { typeCall: "_subscribeToTopics", data, t: data.timeSub })
        logService.debug({ typeCall: "_subscribeToTopics.:START", data, logBy: "socket.js" })
        timeSub = new Date().getTime()
        logDebug("socket.:subscribe.:START 2", { typeCall: "_subscribeToTopics", data, t: data.timeSub })
        data.timeSub = timeSub
        socket.timeout(timeoutEmit).emit("get",
            {
                // url: '/client/send',
                url: '/client/subscribe', // Server xử lý Tuần tự
                method: 'get',
                headers: {

                },
                data: data
            }, (err, response) => {
                let timeRes = new Date().getTime() - idSub
                data.idSub = idSub
                data.timeRes = timeRes
                data.isReloadData = isReloadData
                logDebug("socket.:subscribe.:response=", { err, response, isReloadData, data })
                logService.debug({ typeCall: "_subscribeToTopics.:response", err, response, data, logBy: "socket.js" })
                if (err) {
                    state = reduxStore.getState();
                    let IsActiveTabBrowser = state.app.isActiveTabBrowser
                    data.IsActiveTabBrowser = IsActiveTabBrowser
                    // the server did not acknowledge the event in the given delay
                    logDebug("socket.:subscribe.:err=", { err, data: data })
                    logService.error({ typeCall: "_subscribeToTopics", err: err, data: data, logBy: "socket.js" })
                    // reconnectSocketHandler(0)
                    reSubSocket(topics, data)
                    return false;
                }
                if (response && response.statusCode === 200) {
                    // delete listIdSubReTry[idSub]
                    // reset lại countRetryReconnectSocket khi nhận được phản hồi sub
                    countRetryReconnectSocket = 0
                    logDebug("socket.:subscribe.:response=reconnectSocketHandler.:", { countRetryReconnectSocket })
                    console.debug(`socket.:subscribe.:response=reconnectSocketHandler.:`, socket, socket.io.subs, socket.rooms)

                    // let  otps  = socket
                    if (countReSubSocket[idReSub]) {
                        delete countReSubSocket[idReSub]
                    }
                    if (isReloadData) {
                        // call lại api snapshot trong trường hợp (reconnect và sub lại) thành công
                        reloadData("_subscribeToTopics")
                        isReloadData = false
                    } else {
                        if (idReSub) {
                            // call lại api snapshot trong trường hợp reSub thành công
                            reloadData("_subscribeToTopics", topics)
                        }
                    }
                    return true;
                    //console.log(TAG_NAME + 'Subscribed to topics ' + topics.join(', '));
                }
                else {
                    logService.error({ typeCall: "_subscribeToTopics", tabId: TabID, sidSocket: sidSocket, token: token, idSub: idSub, response: response, timeRes: timeRes, logBy: "socket.js" })
                }
                return true;
            }
        );
    } catch (e) {
        console.debug("socket.:_subscribeToTopics.:err=", e)
        return false;
    }
    return true;
};

const _unsubscribeFromTopics = (topics, idReSub) => {
    let idSub = idReSub || new Date().getTime() + "_" + Random.randomIdChars(6)
    if (!topics || topics.length === 0) {
        return;
    }

    if (!socket.connected) {
        //console.log(TAG_NAME + 'Socket not ready, unsubscribe is skipped');
        return;
    }
    logDebug("socket.:unSubscribe.:", { idSub, topics: topics })
    let state, tabId, SID_socket;
    token = CommonUtils.getTokenCurr(40)
    tabId = TabID
    SID_socket = sessionStorage.getItem("sidSocketCurr")
    let timeSub = new Date().getTime()
    // https://socket.io/docs/v4/client-api/
    let data = {
        op: 'unsubscribe',
        args: topics,
        token: token,
        tabId: tabId,
        idSub: idSub,
        timeSub: timeSub,
        SID_socket
    }
    logDebug("socket.:unsubscribe.:START 1", { typeCall: "_unsubscribeFromTopics", data, t: data.timeSub })
    logService.debug({ typeCall: "_unsubscribeFromTopics.:START", data, logBy: "socket.js" })
    timeSub = new Date().getTime()
    logDebug("socket.:unsubscribe.:START 2", { typeCall: "_unsubscribeFromTopics", data, t: data.timeSub })
    data.timeSub = timeSub
    socket.timeout(timeoutEmit).emit("get",
        {
            // url: '/client/send',
            url: '/client/subscribe', // Server xử lý Tuần tự
            method: 'get',
            headers: {},
            data: data
        }, (err, response) => {
            let timeRes = new Date().getTime() - timeSub
            data.timeRes = timeRes
            data.isReloadData = isReloadData
            logDebug("socket.:unsubscribe.:response=", { err, response, isReloadData, data })
            logService.debug({ typeCall: "_unsubscribeFromTopics.:response", err, response, data, logBy: "socket.js" })
            if (err) {
                state = reduxStore.getState();
                let IsActiveTabBrowser = state.app.isActiveTabBrowser
                data.IsActiveTabBrowser = IsActiveTabBrowser
                logDebug("socket.:unsubscribe.:err=", { err, data: data })
                logService.error({ typeCall: "_unsubscribeFromTopics", err: err, data: data, logBy: "socket.js" })
                return false;
            }
            if (response && response.statusCode === 200) {
                return true;
            }
            else {
                logService.error({ typeCall: "_unsubscribeFromTopics", tabId: TabID, sidSocket: sidSocket, token: token, idSub: idSub, response: response, timeRes: timeRes, logBy: "socket.js" })
            }
            return true;
        });
};
// Clear callerId with empty topic and clear topic with empty callerId
const _prunce = () => {
    const willBeDeleteTopics = [];
    _.forIn(registeredTopics, (callerIds, topic) => {
        if (!callerIds || callerIds.length === 0) {
            willBeDeleteTopics.push(topic);
        }
    });
    _.forEach(willBeDeleteTopics, (topic) => {
        delete registeredTopics[topic];
    });

    const willBeDeleteCallerIds = [];
    _.forIn(registeredCalledIds, (topics, callerId) => {
        if (!topics || topics.length === 0) {
            willBeDeleteCallerIds.push(callerId);
        }
    });
    _.forEach(willBeDeleteCallerIds, (callerId) => {
        delete registeredCalledIds[callerId];
    });
};

const _clearSubscribedTopics = () => {
    subscribedTopics.length = 0;
};

const _registerTopic = (topic, callerId) => {
    if (registeredTopics.hasOwnProperty(topic)) {
        const callerIds = registeredTopics[topic];
        if (!_.includes(callerIds, callerId)) {
            callerIds.push(callerId);
        }
    } else {
        registeredTopics[topic] = [callerId];
    }

    if (registeredCalledIds.hasOwnProperty(callerId)) {
        const topics = registeredCalledIds[callerId];
        if (!_.includes(topics, topic)) {
            topics.push(topic);
        }
    } else {
        registeredCalledIds[callerId] = [topic];
    }
};

const _unregisterTopic = (topic, callerId) => {
    if (registeredTopics.hasOwnProperty(topic)) {
        const callerIds = registeredTopics[topic];
        _.remove(callerIds, (element) => {
            return callerId === element;
        });
    }

    if (registeredCalledIds.hasOwnProperty(callerId)) {
        const topics = registeredCalledIds[callerId];
        _.remove(topics, (element) => {
            return topic === element;
        });
    }
};

const _unregisterTopics = (topics, callerId, dontCallApply) => {
    logDebug(`socket.:send.:${"_unregisterTopics"}`, { topics, callerId, dontCallApply })
    if (topics && topics.length > 0) {
        for (let i = 0; i < topics.length; i++) {
            _unregisterTopic(topics[i], callerId);
        }
    }

    _prunce();

    if (!dontCallApply) {
        _apply();
    }
};

const _unregisterCallerId = (callerId, dontCallApply) => {
    if (registeredCalledIds.hasOwnProperty(callerId)) {
        const topics = registeredCalledIds[callerId];
        if (topics && topics.length > 0) {
            _unregisterTopics([...topics], callerId, dontCallApply);
        }
    }
};

const _registerTopics = (topics, callerId) => {
    logDebug(`socket.:send.:${"_registerTopics"}`, { topics, callerId })
    // Unregister all topic previously registered by this callerId
    _unregisterCallerId(callerId, true);

    if (topics && topics.length > 0) {
        for (let i = 0; i < topics.length; i++) {
            _registerTopic(topics[i], callerId);
        }
    }

    _apply();
};

const _apply = () => {
    let needToSubscribeTopics = [];
    _.forIn(registeredTopics, (callerIds, topic) => {
        if (callerIds.length > 0) {
            needToSubscribeTopics.push(topic);
        }
    });


    // Haki.: UnSubscribeTopics ====>
    // Haki.: Clear needToSubscribeTopics i để unSubscribe lại All danh sách instrument
    // let _needToSubscribeTopics = needToSubscribeTopics.filter(function (item) {
    //     // return item.indexOf('i:') < 0
    //     return item.indexOf('idx:') > -1
    // })
    const willBeUnSubscribeTopics = _.difference(subscribedTopics, needToSubscribeTopics);

    // Haki.: SubscribeTopics ====>
    // Haki.: Clear subscribedTopics i để Subscribe lại All danh sách instrument
    // let _subscribedTopics = subscribedTopics.filter(function (item) {
    //     // return item.indexOf('i:') < 0
    //     return item.indexOf('idx:') > -1
    // })
    _unsubscribeFromTopics(willBeUnSubscribeTopics);
    _.forEach(willBeUnSubscribeTopics, (topic) => {
        if (_.includes(subscribedTopics, topic)) {
            _.remove(subscribedTopics, (element) => {
                return element === topic;
            });
        }
    });

    const willBeSubscribeTopics = _.difference(needToSubscribeTopics, subscribedTopics);
    if (_subscribeToTopics(willBeSubscribeTopics)) {
        _.forEach(willBeSubscribeTopics, (topic) => {
            if (!_.includes(subscribedTopics, topic)) {
                subscribedTopics.push(topic);
            }
        });
    }

};

const _executeOperation = (operation) => {
    const { action, data } = operation;
    switch (action) {
        case 're-subscribe':
            _apply();
            break;
        case 'clear-subscribed':
            _clearSubscribedTopics();
            break;
        case 'register': {
            const { topics, callerId } = data;
            _registerTopics(topics, callerId);
            break;
        }
        case 'unregister': {
            const { topics, callerId } = data;
            _unregisterTopics(topics, callerId);
            break;
        }
        case 'unregister-callerId': {
            const { callerId } = data;
            _unregisterCallerId(callerId, false);
            break;
        }
        default:
            //console.log(TAG_NAME + 'Unknown socket action ' + action);
            break;
    }
};

const _requestReSubscribeToAllSubscribedTopics = () => {
    let context = {
        action: 're-subscribe'
    }
    queueProcess.add(_executeOperation, context, queueTime)
};

const _requestClearSubscribedTopics = () => {
    let context = {
        action: 'clear-subscribed'
    }
    queueProcess.add(_executeOperation, context, queueTime)
};

// Fired upon a connection including a successful reconnection
socket.on('connect', (e) => {
    // countRetryReconnectSocket = 0
    if (sidSocket !== undefined) {
        isReloadData = true
    }
    sidSocket = socket && socket.id // sid mà server socket ghi nhận được log (sid ở dòng thứ 3)
    // sidSocket = socket && (socket.io && socket.io.engine && socket.io.engine.id || socket.id)
    logDebug(`socket.:on.:${"connect"}.:sid.:1=`, { sid: sidSocket })
    let _sidSocket = sessionStorage.getItem("sidSocket") || ""
    _sidSocket = _sidSocket + "||" + sidSocket
    sessionStorage.setItem("sidSocket", _sidSocket)
    sessionStorage.setItem("sidSocketCurr", sidSocket)
    statusConnectSocket = "connected"
    logService.debug({ typeOn: "connect", tabId: TabID, sidSocket: sidSocket, token: token, logBy: "socket.js" })
    logDebug(`socket.:on.:${"connect"}.:sid.:2=`, { listSid: _sidSocket })
    // logDebug(`socket.:on.:${"connect"}`, { registeredTopics, subscribedTopics })
    _requestReSubscribeToAllSubscribedTopics();
    dispatch(actions.setSocketMarketConnectStatus(SocketStatus.CONNECTED));
    // dispatch(actions.setSocketMarketConnectFirstTime(false));
    // if (countConnect > 0) {
    //     // HakiBỏ qua lần đầu kết nối socket để tránh lặp việc gọi API
    //     reloadData('Connect')
    // } else {
    //     countConnect++
    // }
});

// Fired upon a disconnection including a abnormal disconnection
socket.on('disconnect', (reason) => {
    statusConnectSocket = "disconnect"
    logDebug(`socket.:on.:${"disconnect"}`, { registeredTopics, subscribedTopics, reason })
    //console.log(TAG_NAME + 'Socket disconnected');
    logService.debug({ typeOn: "disconnect", tabId: TabID, sidSocket: sidSocket, token: token, reason, logBy: "socket.js" })
    _requestClearSubscribedTopics();

    dispatch(actions.setSocketMarketConnectStatus(SocketStatus.DISCONNECTED));
});


socket.on('connect_error', err => {
    statusConnectSocket = "connect_error"
    // dispatch(actions.setSocketMarketConnectFirstTime(false));
    dispatch(actions.setSocketMarketConnectStatus(SocketStatus.ERROR));
    logDebug(`socket.:on.:${"connect_error"}.:err=`, { err })
    logService.error({ typeOn: "connect_error", tabId: TabID, sidSocket: sidSocket, token: token, err: err, logBy: "socket.js" })
});

socket.on('connect_failed', err => {
    statusConnectSocket = "connect_failed"
    dispatch(actions.setSocketMarketConnectStatus(SocketStatus.ERROR));
    logDebug(`socket.:on.:${"connect_failed.:"}.:err=`, { err })
    logService.error({ typeOn: "connect_failed", tabId: TabID, sidSocket: sidSocket, token: token, err: err, logBy: "socket.js" })
});

socket.io.on("reconnect_attempt", (attempt) => {
    statusConnectSocket = "reconnect_attempt"
    dispatch(actions.setSocketMarketConnectStatus(SocketStatus.WARNING));
    logDebug(`socket.:on.:${"reconnect_attempt"}.:attempt=`, { attempt })
    logService.error({ typeOn: "reconnect_attempt", tabId: TabID, sidSocket: sidSocket, token: token, attempt_err: attempt, logBy: "socket.js" })
});

socket.io.on("reconnect_error", (error) => {
    statusConnectSocket = "reconnect_error"
    dispatch(actions.setSocketMarketConnectStatus(SocketStatus.ERROR));
    logDebug(`socket.:on.:${"reconnect_error"}.:err=`, { error })
    logService.error({ typeOn: "reconnect_error", tabId: TabID, sidSocket: sidSocket, token: token, err: error, logBy: "socket.js" })
});

socket.io.on("reconnect_failed", (err) => {
    statusConnectSocket = "reconnect_failed"
    dispatch(actions.setSocketMarketConnectStatus(SocketStatus.ERROR));
    logDebug(`socket.:on.:${"reconnect_failed"}.:err=`, { err })
    logService.error({ typeOn: "reconnect_failed", tabId: TabID, sidSocket: sidSocket, token: token, err: err, logBy: "socket.js" })
});

socket.on('reconnecting', () => {
    statusConnectSocket = "reconnecting"
    logDebug(`socket.:msg.:${"reconnecting"}`)
    dispatch(actions.setSocketMarketConnectStatus(SocketStatus.CONNECTING));
    logService.debug({ typeOn: "reconnecting", tabId: TabID, sidSocket: sidSocket, token: token, logBy: "socket.js" })
});


const timerReconnect = 100
const maxRetryReconnectSocket = 20
let countRetryReconnectSocket = 0
function reconnectSocketHandler() {
    countRetryReconnectSocket++
    console.debug("reconnectSocketHandler.:", countRetryReconnectSocket, statusConnectSocket)
    let timerInc = timerReconnect * countRetryReconnectSocket
    if (countRetryReconnectSocket > 0 && countRetryReconnectSocket <= 5) {
        // ưu tiên 5 lần đầu connect nhanh 
        timerInc = 50
    }
    if (countRetryReconnectSocket <= maxRetryReconnectSocket) {
        // giảm tốc độ của việc reconect
        let timer = setTimeout(function () {
            disconnect()
            connect()
            clearTimeout(timer)
        }, timerInc)
    }
}




// Haki TEST socket instrument
var list = [
    "AAV",
    "ADC",
    "ALT",
    "AMC",
    "AME",
    "AMV",
    "API",
    "APS",
    "ARM",
    "ATS",
    "BAB",
    "BAX",
    "BBS",
    "BCC",
    "BCF",
    "BDB",
    "BED",
    "BKC",
    "BNA",
    "BPC",
    "BSC",
    "BST",
    "BTS",
    "BTW",
    "BVS",
    "BXH",
    "C69",
    "CAG",
    "CAN",
    "CAP",
    "CCR",
    "CDN",
    "CEO",
    "CET",
    "CIA",
    "CJC",
    "CKV",
    "CLH",
    "CLM",
    "CMC",
    "CMS",
    "CPC",
    "CSC",
    "CTB",
    "CTC",
    "CTP",
    "CTT",
    "CVN",
    "CX8",
    "D11",
    "DAD",
    "DAE",
    "DC2",
    "DDG",
    "DHP",
    "DHT",
    "DIH",
    "DL1",
    "DNC",
    "DNP",
    "DP3",
    "DPC",
    "DS3",
    "DST",
    "DTC",
    "DTD",
    "DTG",
    "DTK",
    "DVG",
    "DVM",
    "DXP",
    "EBS",
    "ECI",
    "EID",
    "EVS",
    "FBA",
    "FID",
    "GDW",
    "GIC",
    "GKM",
    "GLT",
    "GMA",
    "GMX",
    "HAD",
    "HAT",
    "HBS",
    "HCC",
    "HCT",
    "HDA",
    "HEV",
    "HGM",
    "HHC",
    "HJS",
    "HKT",
    "HLC",
    "HLD",
    "HMH",
    "HMR",
    "HOM",
    "HSA",
    "HTC",
    "HTP",
    "HUT",
    "HVT",
    "ICG",
    "IDC",
    "IDJ",
    "IDV",
    "INC",
    "INN",
    "IPA",
    "ITQ",
    "IVS",
    "KDM",
    "KHS",
    "KKC",
    "KMT",
    "KSD",
    "KSF",
    "KSQ",
    "KST",
    "KSV",
    "KTS",
    "KTT",
    "L14",
    "L18",
    "L40",
    "L43",
    "L61",
    "L62",
    "LAS",
    "LBE",
    "LCD",
    "LDP",
    "LHC",
    "LIG",
    "MAC",
    "MAS",
    "MBG",
    "MBS",
    "MCC",
    "MCF",
    "MCO",
    "MDC",
    "MED",
    "MEL",
    "MHL",
    "MKV",
    "MST",
    "MVB",
    "NAG",
    "NAP",
    "NBC",
    "NBP",
    "NBW",
    "NDN",
    "NDX",
    "NET",
    "NFC",
    "NHC",
    "NRC",
    "NSH",
    "NST",
    "NTH",
    "NTP",
    "NVB",
    "OCH",
    "ONE",
    "PBP",
    "PCE",
    "PCG",
    "PCH",
    "PCT",
    "PDB",
    "PEN",
    "PGN",
    "PGS",
    "PGT",
    "PHN",
    "PIA",
    "PIC",
    "PJC",
    "PLC",
    "PMB",
    "PMC",
    "PMP",
    "PMS",
    "POT",
    "PPE",
    "PPP",
    "PPS",
    "PPT",
    "PPY",
    "PRC",
    "PRE",
    "PSC",
    "PSD",
    "PSE",
    "PSI",
    "PSW",
    "PTD",
    "PTI",
    "PTS",
    "PV2",
    "PVB",
    "PVC",
    "PVG",
    "PVI",
    "PVS",
    "QHD",
    "QST",
    "QTC",
    "RCL",
    "S55",
    "S99",
    "SAF",
    "SCG",
    "SCI",
    "SD5",
    "SD6",
    "SD9",
    "SDA",
    "SDC",
    "SDG",
    "SDN",
    "SDU",
    "SEB",
    "SED",
    "SFN",
    "SGC",
    "SGD",
    "SGH",
    "SHE",
    "SHN",
    "SHS",
    "SJ1",
    "SJE",
    "SLS",
    "SMN",
    "SMT",
    "SPC",
    "SPI",
    "SRA",
    "SSM",
    "STC",
    "STP",
    "SVN",
    "SZB",
    "TA9",
    "TAR",
    "TBX",
    "TC6",
    "TDN",
    "TDT",
    "TET",
    "TFC",
    "THB",
    "THD",
    "THS",
    "THT",
    "TIG",
    "TJC",
    "TKG",
    "TKU",
    "TMB",
    "TMC",
    "TMX",
    "TNG",
    "TOT",
    "TPH",
    "TPP",
    "TSB",
    "TTC",
    "TTH",
    "TTL",
    "TTT",
    "TV3",
    "TV4",
    "TVC",
    "TVD",
    "TXM",
    "UNI",
    "V12",
    "V21",
    "VBC",
    "VC1",
    "VC2",
    "VC3",
    "VC6",
    "VC7",
    "VC9",
    "VCC",
    "VCM",
    "VCS",
    "VDL",
    "VE1",
    "VE3",
    "VE4",
    "VE8",
    "VFS",
    "VGP",
    "VGS",
    "VHE",
    "VHL",
    "VIF",
    "VIG",
    "VIT",
    "VKP",
    "VLA",
    "VMC",
    "VMS",
    "VNC",
    "VNF",
    "VNR",
    "VNT",
    "VSA",
    "VSM",
    "VTC",
    "VTH",
    "VTJ",
    "VTV",
    "VTZ",
    "WCS",
    "WSS",
    "X20"
]

list = [
    "AAA",
    "AAM",
    "AAT",
    "ABR",
    "ABS",
    "ABT",
    "ACB",
    "ACC",
    "ACG",
    "ACL",
    "ADG",
    "ADP",
    "ADS",
    "AGG",
    "AGM",
    "AGR",
    "ANV",
    "APG",
    "APH",
    "ASG",
    "ASM",
    "ASP",
    "AST",
    "BAF",
    "BBC",
    "BCE",
    "BCG",
    "BCM",
    "BFC",
    "BHN",
    "BIC",
    "BID",
    "BKG",
    "BMC",
    "BMI",
    "BMP",
    "BRC",
    "BSI",
    "BTP",
    "BTT",
    "BVH",
    "BWE",
    "C32",
    "C47",
    "CAV",
    "CCI",
    "CCL",
    "CDC",
    "CHP",
    "CIG",
    "CII",
    "CKG",
    "CLC",
    "CLL",
    "CLW",
    "CMG",
    "CMV",
    "CMX",
    "CNG",
    "COM",
    "CRC",
    "CRE",
    "CSM",
    "CSV",
    "CTD",
    "CTF",
    "CTG",
    "CTI",
    "CTR",
    "CTS",
    "CVT",
    "D2D",
    "DAG",
    "DAH",
    "DAT",
    "DBC",
    "DBD",
    "DBT",
    "DC4",
    "DCL",
    "DCM",
    "DGC",
    "DGW",
    "DHA",
    "DHC",
    "DHG",
    "DHM",
    "DIG",
    "DLG",
    "DMC",
    "DPG",
    "DPM",
    "DPR",
    "DQC",
    "DRC",
    "DRH",
    "DRL",
    "DSN",
    "DTA",
    "DTL",
    "DTT",
    "DVP",
    "DXG",
    "DXS",
    "DXV",
    "EIB",
    "ELC",
    "EVE",
    "EVF",
    "EVG",
    "FCM",
    "FCN",
    "FDC",
    "FIR",
    "FIT",
    "FMC",
    "FPT",
    "FRT",
    "FTS",
    "GAS",
    "GDT",
    "GEG",
    "GEX",
    "GIL",
    "GMC",
    "GMD",
    "GMH",
    "GSP",
    "GTA",
    "GVR",
    "HAG",
    "HAH",
    "HAP",
    "HAR",
    "HAS",
    "HAX",
    "HBC",
    "HCD",
    "HCM",
    "HCMA0307",
    "HDB",
    "HDC",
    "HDG",
    "HHP",
    "HHS",
    "HHV",
    "HID",
    "HII",
    "HMC",
    "HNA",
    "HNG",
    "HPG",
    "HPX",
    "HQC",
    "HRC",
    "HSG",
    "HSL",
    "HT1",
    "HTG",
    "HTI",
    "HTL",
    "HTN",
    "HTV",
    "HU1",
    "HUB",
    "HVH",
    "HVN",
    "HVX",
    "ICT",
    "IDI",
    "IJC",
    "ILB",
    "IMP",
    "ITA",
    "ITC",
    "ITD",
    "JVC",
    "KBC",
    "KDC",
    "KDH",
    "KHG",
    "KHP",
    "KMR",
    "KOS",
    "KPF",
    "KSB",
    "L10",
    "LAF",
    "LBM",
    "LCG",
    "LDG",
    "LEC",
    "LGC",
    "LGL",
    "LHG",
    "LIX",
    "LM8",
    "LPB",
    "LSS",
    "MBB",
    "MCP",
    "MDG",
    "MHC",
    "MIG",
    "MSB",
    "MSH",
    "MSN",
    "MWG",
    "NAB",
    "NAF",
    "NAV",
    "NBB",
    "NCT",
    "NHA",
    "NHH",
    "NHT",
    "NKG",
    "NLG",
    "NNC",
    "NO1",
    "NSC",
    "NT2",
    "NTL",
    "NVL",
    "NVT",
    "OCB",
    "OGC",
    "OPC",
    "ORS",
    "PAC",
    "PAN",
    "PC1",
    "PDN",
    "PDR",
    "PET",
    "PGC",
    "PGD",
    "PGI",
    "PGV",
    "PHC",
    "PHR",
    "PIT",
    "PJT",
    "PLP",
    "PLX",
    "PMG",
    "PNC",
    "PNJ",
    "POM",
    "POW",
    "PPC",
    "PSH",
    "PTB",
    "PTC",
    "PTL",
    "PVD",
    "PVP",
    "PVT",
    "QBS",
    "QCG",
    "QNP",
    "RAL",
    "RDP",
    "REE",
    "S4A",
    "SAB",
    "SAM",
    "SAV",
    "SBA",
    "SBG",
    "SBT",
    "SBV",
    "SC5",
    "SCD",
    "SCR",
    "SCS",
    "SFC",
    "SFG",
    "SFI",
    "SGN",
    "SGR",
    "SGT",
    "SHA",
    "SHB",
    "SHI",
    "SHP",
    "SIP",
    "SJD",
    "SJF",
    "SJS",
    "SKG",
    "SMA",
    "SMB",
    "SMC",
    "SPM",
    "SRC",
    "SRF",
    "SSB",
    "SSC",
    "SSI",
    "ST8",
    "STB",
    "STG",
    "STK",
    "SVC",
    "SVD",
    "SVI",
    "SVT",
    "SZC",
    "SZL",
    "TBC",
    "TCB",
    "TCD",
    "TCH",
    "TCI",
    "TCL",
    "TCM",
    "TCO",
    "TCR",
    "TCT",
    "TDC",
    "TDG",
    "TDH",
    "TDM",
    "TDP",
    "TDW",
    "TEG",
    "THG",
    "TIP",
    "TIX",
    "TLD",
    "TLG",
    "TLH",
    "TMP",
    "TMS",
    "TMT",
    "TN1",
    "TNA",
    "TNC",
    "TNH",
    "TNI",
    "TNT",
    "TPB",
    "TPC",
    "TRA",
    "TRC",
    "TSC",
    "TTA",
    "TTE",
    "TTF",
    "TV2",
    "TVB",
    "TVS",
    "TVT",
    "TYA",
    "UIC",
    "VAF",
    "VCA",
    "VCB",
    "VCF",
    "VCG",
    "VCI",
    "VDP",
    "VDS",
    "VFG",
    "VGC",
    "VHC",
    "VHM",
    "VIB",
    "VIC",
    "VIC11725",
    "VID",
    "VIP",
    "VIX",
    "VJC",
    "VMD",
    "VND",
    "VNE",
    "VNG",
    "VNL",
    "VNM",
    "VNS",
    "VOS",
    "VPB",
    "VPD",
    "VPG",
    "VPH",
    "VPI",
    "VPS",
    "VRC",
    "VRE",
    "VSC",
    "VSH",
    "VSI",
    "VTB",
    "VTO",
    "VTP",
    "YBM",
    "YEG"
]
// list = ["ASG", "SSI","ASG", "SSI"]
const enableTestSocket = false
// let count = 0
setTimeout(() => {
    if (enableTestSocket) {
        setInterval(function () {
            var min = 120;
            var max = 400;
            var rand = (min + (Math.random() * (max - min))) * 100 + 111;

            var min2 = 1;
            var max2 = list.length - 300;
            var rand2 = Math.round((min2 + (Math.random() * (max2 - min2)))); // Chọn mã CK
            // 420["get",{"url":"/client/subscribe","method":"get","headers":{},"data":{"op":"subscribe","args":["i:AAA","i:AAM","i:ABC","i:ACB","i:ACC","i:AMD","i:BBS","i:BIC","i:HPG","i:SHB","i:SSI","i:VCC","i:VIC","i:VND","i:VNM","i:VNM_WFT"]}}]
            // var list = ['SSI', 'SSI', 'SSI']
            // var list = ['ACB', 'BCM', 'BID', 'BVH', 'CTG', 'FPT', 'GAS', 'GVR', 'HDB', 'HPG', 'MBB', 'MSN', 'MWG', 'NVL', 'PDR', 'PLX', 'POW', 'SAB', 'SSI', 'STB', 'TCB', 'TPB', 'VCB', 'VHM', 'VIB', 'VIC', 'VJC', 'VNM', 'VPB', 'VRE']
            // var list = ['CEO', 'DIG', 'EIB', 'VIC', 'VND', 'CEO', 'DIG', 'EIB', 'VIC', 'VND', 'CEO', 'DIG', 'EIB', 'VIC', 'VND', 'CEO', 'DIG', 'EIB', 'VIC', 'VND', 'CEO', 'DIG', 'EIB', 'VIC', 'VND', 'CEO', 'DIG', 'EIB', 'VIC', 'VND',]
            // var list = ['VIX', 'VIX', 'VIX', 'VIX', 'VIX', 'VIX', 'VIX', 'CEO', 'DIG', 'EIB', 'VIC', 'VND', 'VIX', 'VIX', 'VIX', 'VIX', 'VIX', 'VIX', 'VIX', 'VIX', 'VIX', ]
            // var list = ['ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB', 'ACB',]

            var min3 = 1;
            var max3 = 10;
            var rand3 = Math.round((min3 + (Math.random() * (max3 - min3)))); // Số lượng listProperty thay đổi

            var listProperty = ['CP', 'U1', 'U2', 'U3', 'P1', 'P2', 'P3', 'B1', 'B2', 'B3', 'V1', 'V2', 'V3', 'S1', 'S2', 'S3', 'P4A', 'percent', 'SI', 'FN', 'TD', 'FC', 'ST', 'CL', 'FL', 'RE', 'CV', 'CH', 'TT', 'TV', 'AP', 'OP', 'HI', 'LO', 'FB', 'FS', 'FR', 'EX', 'OI', 'LTD', 'ULS', 'IN', 'MD', 'CHP', 'EP', 'FO', 'PMP', 'PMQ', 'PTQ', 'PTV', 'PP', 'TO', 'TB']
            var min4 = 0;
            var max4 = listProperty.length;
            // var listProperty = ['CP', 'U1', 'U2', 'U3', 'B1', 'B2', 'B3', 'V1', 'V2', 'V3', 'S1', 'S2', 'S3']
            let SB = list[rand2]
            // SB = "ACB" // HOSE
            // SB= "ACB" // VN30
            // SB= "ACB" // HOSE
            // SB = "AAV" // HNX
            // SB= "AAH" // UPCOM
            // SB= "GB05F2409" // PS
            // SB= "CACB2304" // CQ
            // SB= "E1VFVN30" // ETF
            // SB= "AAA12101" // TPRL

            countSeqTest[SB] = countSeqTest[SB] !== undefined ? countSeqTest[SB] + 1 : 100 // START SEQ từ 100
            let instrument = [{
                // t: 1966682910000,
                SB,
                CP: parseInt((rand.toString()).split(".")[0]),
                // RE:  parseInt((rand.toString()).split(".")[0]),
                // CL:  parseInt((rand.toString()).split(".")[0])+10000,
                // FL:  parseInt((rand.toString()).split(".")[0])-10000,
                // FMP: SB == "ACB" ? 28750 : 33550,
                // FMP: parseInt((rand.toString()).split(".")[0]),
                // FV: 1000000,
                // FV: parseInt((rand.toString()).split(".")[0]),
                // UT: 1715753739209,
                // CV: parseInt((rand.toString()).split(".")[0]),
                // V1: parseInt((rand.toString()).split(".")[0]),
                // B1: parseInt((rand.toString()).split(".")[0]),
                // CP: parseInt((rand.toString()).split(".")[0]),
                // PTV: parseInt((rand.toString()).split(".")[0]),
                s: new Date().getTime(),
            }]
            for (let i = 0; i < rand3; i++) {
                var rand4 = Math.round((min4 + (Math.random() * (max4 - min4)))); // Số lượng listProperty
                // alert(rand4)
                if (listProperty[rand4] !== "CP") {
                    instrument[0][listProperty[rand4]] = parseInt((rand.toString()).split(".")[0])
                }
            }

            let data = instrument
            // emitter.emit('TRADE' + data[0].SB, data[0]);
            // dispatch(actions.insertTradeData(data));
            dispatch(actions.updateInstrumentData(data, "update"));
            data = null
            instrument = null
            // dispatch(actions.updateOddLotInstrumentData(data, "update"));
            return null
            // alert(data.length)
            // console.debug('Socket_updateInstrumentData---symbol, lastSeqInstrument, socketSeq, data = START 0')
            // let isDebugModeOn = isDevelopment || JSON.parse(localStorage.getItem('mode-debug'))
            // // isDebugModeOn = false
            // let isDebugModeFormat = JSON.parse(localStorage.getItem('mode-debug-format'));
            // var symbol = data[0].SB ? data[0].SB : 0; //timestamp
            // // var t = data[0].t ? data[0].t : 0; //timestamp
            // let seqSocket = data[0].s ? data[0].s : undefined; //seqSocket

            // // console.debug(textGreen + 'Socket_updateInstrumentData---symbol, lastSeqInstrument, socketSeq, data = START 1', seqSocket)
            // if (symbol && seqSocket && seqSocket) {
            //     let redux = reduxStore.getState()
            //     let instrumentsSeq = redux.symbol.instrumentsSeq
            //     let seqInstrument = instrumentsSeq[symbol] && instrumentsSeq[symbol].SB
            //     // A.1. Get seqInstrument xong thì mới được update vào redux [trong mọi trường hợp]
            //     dispatch(actions.updateInstrumentData(data, 'update'));
            //     dispatch(actions.updateOddLotInstrumentData(data, 'update'));
            //     // console.debug(textGreen + 'Socket_updateInstrumentData---symbol, lastSeqInstrument, socketSeq, data = START 2', symbol, seqInstrument, seqSocket)
            //     // dispatch(actions.updateInstrumentData(data));
            //     if (!seqInstrument || seqInstrument == 0 || (seqInstrument && seqSocket == seqInstrument + 1)) {
            //         // B.1. KHÔNG MISS socket => ghi log
            //         if (isDebugModeOn) {
            //             let isDebugModeFormat = JSON.parse(localStorage.getItem('mode-debug-format'));
            //             if (isDebugModeFormat)
            //                 console.debug(textGreen + 'Socket_updateInstrumentData---symbol, lastSeqInstrument, socketSeq, data = ', symbol, seqInstrument, seqSocket, data)
            //             else console.debug(textGreen + 'Socket_updateInstrumentData---symbol, lastSeqInstrument, socketSeq, data = ', symbol, seqInstrument, seqSocket, JSON.stringify(data))
            //         }
            //     }
            //     else {
            //         // C.1. MISS socket
            //         // call lại api instrument by symbol và set seq = -1 để ngừng update socket vào instrument(ở step Step A.1 )
            //         if (isDebugModeOn) {
            //             if (isDebugModeFormat)
            //                 console.debug(textGreen + 'Socket_MissedSequence---symbol, lastSeqInstrument, socketSeq, data = ', symbol, seqInstrument, seqSocket, data)
            //             else console.debug(textGreen + 'Socket_MissedSequence---symbol, lastSeqInstrument, socketSeq, data = ', symbol, seqInstrument, seqSocket, JSON.stringify(data))
            //         }
            //         _getInstrumentsBySymbols(symbol, true, "miss")
            //         _getOddLotInstrumentsBySymbols(symbol, true, "miss")
            //     }
            // }
        }, 100)
    }
}, 5000)




socket.on(socketRoomName.instrument, (message) => {
    isLogSocketFull && logDebug(`socket.:msg.:${socketRoomName.instrument}`, { message })
    let { a: action, d: data, k: keys } = message;
    if (action === socketAction.update) {
        var symbol = data[0].SB ? data[0].SB : 0; //timestamp
        // var t = data[0].t ? data[0].t : 0; //timestamp
        let seqSocket = data[0].s ? data[0].s : undefined; //seqSocket
        // dispatch(actions.updateInstrumentData(data));
        // return null
        if (IS_CHECK_SOCKET_SEQ == true) {
            if (symbol && seqSocket) {
                let redux = reduxStore.getState()
                let instrumentsSeq = redux.symbol.instrumentsSeq
                let seqInstrument = instrumentsSeq[symbol] && instrumentsSeq[symbol].SB
                // A.1. Get seqInstrument xong thì mới được update vào redux [trong mọi trường hợp]
                if ((seqInstrument && seqSocket == seqInstrument)) {
                    // Bỏ qua việc update dữ liệu socket trùng lặp do logic vừa sub e: vừa sub i:
                    return null;
                }
                dispatch(actions.updateInstrumentData(data, 'update', true));
                if (!seqInstrument || seqInstrument == 0 || (seqInstrument && seqSocket == seqInstrument + 1)) {
                    // B.1. KHÔNG MISS socket => ghi log
                    // logDebug('socket.:msg.:i.:[NOT miss] socket', { symbol, seqInstrument, seqSocket, data })
                }
                else {
                    // C.1. MISS socket
                    // call lại api instrument by symbol và set seq = -1 để ngừng update socket vào instrument(ở step Step A.1 )
                    isLogSocketFull && logDebug('socket.:msg.:i=[miss] socket', { symbol, seqInstrument, seqSocket, data })
                    // _getInstrumentsBySymbols(symbol, true, "miss")
                    let type = "instrument";
                    if (_.findIndex(listSymbolMissSeq[type], function (o) { return o == symbol }) < 0) {
                        listSymbolMissSeq[type].push(symbol);
                    }
                    let isCheckCallApi = is_Check_Call_Api(type);
                    if (isCheckCallApi) {
                        _getInstrumentsBySymbols(listSymbolMissSeq[type].toString(), true, "miss")
                        listSymbolMissSeq[type] = []
                    }
                }
            } else {
                dispatch(actions.updateInstrumentData(data, 'update'));
            }
        }
        else {
            dispatch(actions.updateInstrumentData(data, 'update'));
            // action = null
            // data = null
            // keys= null
        }
    }
});

socket.on(socketRoomName.corpbond, (message) => {
    const { a: action, d: data, k: keys } = message;
    isLogSocketFull && logDebug(`socket.:msg.:${socketRoomName.corpbond}`, { message })
    // if (isDebugModeOn) {
    //     console.debug('Socket_updateCorpbondtData---symbol START= ', action)
    // }

    if (action === socketAction.update) {
        dispatch(actions.updateCorpbondData(data));
    }
});

socket.on(socketRoomName.instrument_ol, (message) => {
    isLogSocketFull && logDebug(`socket.:msg.:${socketRoomName.instrument_ol}`, { message })
    const { a: action, d: data, k: keys } = message;
    // logDebug("socket.:msg.:i_ol", { a: action, d: data, k: keys })
    // logDebug('Socket_updateInstrumentData---symbol START= ', action)
    if (action === socketAction.update) {
        let isDebugModeOn = isDevelopment || JSON.parse(localStorage.getItem('mode-debug'))
        // isDebugModeOn = false
        let isDebugModeFormat = JSON.parse(localStorage.getItem('mode-debug-format'));
        var symbol = data[0].SB ? data[0].SB : 0; //timestamp
        // var t = data[0].t ? data[0].t : 0; //timestamp
        let seqSocket = data[0].s ? data[0].s : undefined; //seqSocket
        // dispatch(actions.updateInstrumentData(data));
        // return null
        // logDebug('Socket_updateInstrumentData---symbol seqSocket= ', seqSocket)
        if (IS_CHECK_SOCKET_SEQ == true) {
            if (symbol && seqSocket) {
                let redux = reduxStore.getState()
                let oddLotInstrumentsSeq = redux.symbol.oddLotInstrumentsSeq
                let seqOddLotInstrument = oddLotInstrumentsSeq[symbol] && oddLotInstrumentsSeq[symbol].SB
                // A.1. Get seqInstrument xong thì mới được update vào redux [trong mọi trường hợp]
                if ((seqOddLotInstrument && seqSocket == seqOddLotInstrument)) {
                    // Bỏ qua việc update dữ liệu socket trùng lặp do logic vừa sub e: vừa sub i:
                    return null;
                }
                dispatch(actions.updateOddLotInstrumentData(data, 'update', true));
                if (!seqOddLotInstrument || seqOddLotInstrument == 0 || (seqOddLotInstrument && seqSocket == seqOddLotInstrument + 1)) {

                    // B.1. KHÔNG MISS socket => ghi log
                    // logDebug('socket.:msg.:i_ol.:[NOT miss] socket', { symbol, seqOddLotInstrument, seqSocket, data })
                }
                else {
                    // C.1. MISS socket
                    // call lại api instrument by symbol và set seq = -1 để ngừng update socket vào instrument(ở step Step A.1 )
                    isLogSocketFull && logDebug('socket.:msg.:i_ol.:[miss] socket', { symbol, seqOddLotInstrument, seqSocket, data })
                    // _getOddLotInstrumentsBySymbols(symbol, true)
                    let type = "instrument_ol";
                    if (_.findIndex(listSymbolMissSeq[type], function (o) { return o == symbol }) < 0) {
                        listSymbolMissSeq[type].push(symbol);
                    }
                    let isCheckCallApi = is_Check_Call_Api(type);
                    if (isCheckCallApi) {
                        _getOddLotInstrumentsBySymbols(listSymbolMissSeq[type].toString(), true, "miss")
                        listSymbolMissSeq[type] = []
                    }
                }
            } else {
                dispatch(actions.updateOddLotInstrumentData(data, 'update'));
            }
        }
        else {
            dispatch(actions.updateOddLotInstrumentData(data, 'update'));
        }
    }
});

socket.on(socketRoomName.orderBook10, (message) => {
    isLogSocketFull && logDebug(`socket.:msg.:${socketRoomName.orderBook10}`, { message })
    const { a: action, d: data, k: keys } = message;
    if (action === socketAction.partial) {
        // dispatch(actions.setOrderbookData(data, keys));
    }
});
socket.on(socketRoomName.orderBook, (message) => {
    isLogSocketFull && logDebug(`socket.:msg.:${socketRoomName.orderBook}`, { message })
    const { a: action, d: data } = message;
    // logDebug(`socket.:msg.:${socketRoomName.orderBook}`, { a: action, d: data })
    if (action === socketAction.update) {
        dispatch(actions.updateOrderbookData(data));
    } else if (action === socketAction.insert) {
        dispatch(actions.insertOrderbookData(data));
    } else if (action === socketAction.delete) {
        dispatch(actions.deleteOrderbookData(data));
    }
});

// setInterval(()=>{
//     let data =[{SB:"SBT", FMP: 15000, FV:1000, FT:"12:00:00"}]
//     emitter.emit('TRADE' + data[0].SB, data[0]);
//     dispatch(actions.insertTradeData(data));
// }, 3000)

socket.on(socketRoomName.trade, (message) => {
    isLogSocketFull && logDebug(`socket.:msg.:${socketRoomName.trade}`, { message })
    const { a: action, d: data, k: keys } = message;
    // logDebug(`socket.:msg.:${socketRoomName.trade}`, { a: action, d: data, k: keys })
    if (action === socketAction.partial) {
        // dispatch(actions.setTradeData(data, keys));
    } else if (action === socketAction.insert) {
        emitter.emit('TRADE' + data[0].SB, data[0]);
        emitter.emit('RECENT_TRADES' + data[0].SB, data);
        // dispatch(actions.insertTradeData(data));
    }
});

socket.on(socketRoomName.tradeExchange, (message) => {
    isLogSocketFull && logDebug(`socket.:msg.:${socketRoomName.tradeExchange}`, { message })
    const { a: action, d: data, k: keys } = message;
    if (action === socketAction.partial) {
        // dispatch(actions.setTradeData(data, keys));
    } else if (action === socketAction.insert) {
        emitter.emit('TRADEEXCHANGE', data[0]);
    }
});

// socket.on(socketRoomName.exchange, (message) => {
//     const { a: action, d: data, k: keys, t } = message;
//     if (action === socketAction.partial) {
//         dispatch(actions.setInstrumentData(data, keys, t));
//     }
// });


// Haki TEST socket index
const enableTestSocketIndex = false
if (enableTestSocketIndex) {
    setInterval(function () {
        var min = 120;
        var max = 400;
        var rand = (min + (Math.random() * (max - min))) * 100 + 111;
        let message = {
            "a": "u",
            "d": [
                {
                    "TD": "25/04/2024",
                    "MS": "13",
                    "MC": "HNX",
                    "SM": "11567",
                    "IT": "08:33:58",
                    "TV": parseInt((rand.toString()).split(".")[0]),
                    "MI": parseInt((rand.toString()).split(".")[0])
                }
            ],
            "t": 1714008838399
        }
        // let message = { a: "u", d: [{ MC: "HOSE", MI: parseInt((rand.toString()).split(".")[0]), t: new Date().getTime(), TD: "24/04/2024" }] }
        let { a: action, d: data } = message;
        logDebug(`socket.:msg.:${socketRoomName.index}`, { a: action, d: data })
        var t = data[0].t ? data[0].t : 0; //timestamp
        // data[0].MI = new Date().getTime() /10000000000
        // t = 1712890800
        if (action === socketAction.update) {
            let timer = setTimeout(function () {
                dispatch(actions.updateMarketInfos(data, t));
                data[0].MC = "HOSE"
                dispatch(actions.updateMarketInfos(data, t));
                data[0].MC = "UPCOM"
                dispatch(actions.updateMarketInfos(data, t));
                data[0].MC = "30"
                dispatch(actions.updateMarketInfos(data, t));

                emitter.emit(
                    `${Event.UPDATE_LIST_MARKET_INDEX}`,
                    { data, t }
                );
                clearTimeout(timer)
            }, 0)
        }
    }, 10)
}

socket.on(socketRoomName.index, (message) => {
    let { a: action, d: data } = message;
    // logDebug(`socket.:msg.:${socketRoomName.index}`, { message })
    var t = data[0].t ? data[0].t : 0; //timestamp
    // data[0].MI = new Date().getTime() /10000000000
    // t = 1712890800
    if (action === socketAction.update) {
        let timer = setTimeout(function () {
            dispatch(actions.updateMarketInfos(data, t));
            emitter.emit(
                `${Event.UPDATE_LIST_MARKET_INDEX}`,
                { data, t }
            );
            clearTimeout(timer)
        }, 0)
    }
});


// setTimeout(()=>{
//     dispatch(actions.onInitDay());
//     let timer = setTimeout(function () {
//         batch(() => {
//             let currentListChart = CommonUtils.getListMarketChartCurrent("arr")
//             dispatch(actions.loadAllMarketInfo('', currentListChart, 'ALL'));
//         })
//         clearTimeout(timer)
//     }, 1000)
// }, 5000)

socket.on('initday', () => {
    logDebug(`socket.:msg.:${"initday"}`)
    dispatch(actions.onInitDay());
    emitter.emit('EMITTER_ON_INIT_DAY');
    let timer = setTimeout(function () {
        batch(() => {
            let currentListChart = CommonUtils.getListMarketChartCurrent("arr")
            dispatch(actions.loadAllMarketInfo('', currentListChart, 'ALL'));
        })
        clearTimeout(timer)
    }, 1000)
    logService.debug({ typeOn: "initday", tabId: TabID, sidSocket: sidSocket, token: token, logBy: "socket.js" })
});

socket.on('quotes_change', () => {
    logDebug(`socket.:msg.:${"quotes_change"}`)
    logService.debug({ typeOn: "quotes_change", tabId: TabID, sidSocket: sidSocket, token: token, logBy: "socket.js" })
    dispatch(actions.fetchAllQuotes());
});
socket.on('mk_stt_end', () => {
    logDebug(`socket.:msg.:${"mk_stt_end"}`)
    logService.debug({ typeOn: "mk_stt_end", tabId: TabID, sidSocket: sidSocket, token: token, logBy: "socket.js" })
    dispatch(actions.fetchAllQuotes());
});
socket.on('system_open', () => {
    logDebug(`socket.:msg.:${"system_open"}`)
    logService.debug({ typeOn: "system_open", tabId: TabID, sidSocket: sidSocket, token: token, logBy: "socket.js" })
    dispatch(actions.fetchAllQuotes());
});

//socket giao dịch thỏa thuận
socket.on(socketRoomName.ptmatch, (message) => {
    isLogSocketFull && logDebug(`socket.:msg.:${socketRoomName.ptmatch}`, { message })
    const { a: action, d: data, k: keys } = message;
    // logDebug(`socket.:msg.:${socketRoomName.ptmatch}`, { a: action, d: data, k: keys })
    dispatch(actions.updateDataPTMatch(data)); //Khớp lệnh
});
socket.on(socketRoomName.putthrough, (message) => {
    isLogSocketFull && logDebug(`socket.:msg.:${socketRoomName.putthrough}`, { message })
    const { a: action, d: data, k: keys } = message;
    // logDebug(`socket.:msg.:${socketRoomName.putthrough}`, { a: action, d: data, k: keys })
    dispatch(actions.updateDataPutthrough(data)); //Chào mua chào bán
});
export const on = (eventName, eventHandler) => {
    socket.on(eventName, (data) => {
        eventHandler(data);
    })
};

export const connect = () => {
    logDebug(`socket.:msg.:${"connect"}`)
    // dispatch(actions.setSocketMarketConnectFirstTime(true));
    dispatch(actions.setSocketMarketConnectStatus(SocketStatus.CONNECTING));
    logService.debug({ typeCall: "connect", tabId: TabID, sidSocket_Old: sidSocket, token: token, logBy: "socket.js" })
    socket.open();
};

export const disconnect = () => {
    logDebug(`socket.:msg.:${"disconnect"}`)
    // dispatch(actions.setSocketMarketConnectFirstTime(true));
    logService.debug({ typeCall: "disconnect", tabId: TabID, sidSocket: sidSocket, token: token, logBy: "socket.js" })
    return socket.close();
};

export const registerTopics = (topics, callerId) => {
    if (!callerId) {
        console.error('CallerId need when register interested topics');
        return;
    }
    let context = {
        action: 'register',
        data: {
            topics: topics,
            callerId: callerId
        }
    }
    queueProcess.add(_executeOperation, context, queueTime)
};

export const unregisterTopics = (topics, callerId) => {
    if (!callerId) {
        console.error('CallerId need when unregister interested topics');
        return;
    }
    let context = {
        action: 'unregister',
        data: {
            topics: topics,
            callerId: callerId
        }
    }
    queueProcess.add(_executeOperation, context, queueTime)
};

export const unregisterTradeTopic = (symbol, callerId) => {
    unregisterTopics([socketRoomName.trade + ':' + symbol], callerId);
};

export const unregisterTradeExchangeTopic = (exchange, callerId) => {
    unregisterTopics([socketRoomName.tradeExchange + ':' + exchange], callerId);
};

// export const unregisterAnalyticMessage = (language, custid, callerId) => {
//     unregisterTopics([socketRoomName.message_analytic + ':' + language, socketRoomName.message_analytic + ':' + language + ':' + custid], callerId);
// };

export const unregisterCallerId = (callerId) => {
    if (!callerId) {
        console.error('CallerId need when unregister interested topics');
        return;
    }
    let context = {
        action: 'unregister-callerId',
        data: {
            callerId: callerId
        }
    }
    queueProcess.add(_executeOperation, context, queueTime)
};

// Utility functions
export const registerInstrumentTopic = (symbol, callerId) => {
    symbol && symbol.length > 0 && registerTopics([socketRoomName.instrument + ':' + symbol], callerId);
};

export const registerCorpbondTopic = (symbol, callerId) => {
    symbol && symbol.length > 0 && registerTopics([socketRoomName.corpbond + ':' + symbol], callerId);
};

export const registerOrderbookTopic = (symbol, callerId) => {
    symbol && symbol.length > 0 && registerTopics([socketRoomName.orderBook10 + ':' + symbol], callerId);
};

export const registerTradeTopic = (symbol, callerId) => {
    symbol && symbol.length > 0 && registerTopics([socketRoomName.trade + ':' + symbol], callerId);
};

export const registerTradeExchangeTopics = (exchange, callerId) => {
    registerTopics([socketRoomName.tradeExchange + ':' + exchange], callerId);
};

export const registerExchangeTopics = (exchange, callerId) => {
    registerTopics([socketRoomName.exchange + ':' + exchange], callerId);
};

export const registerOddlotExchangeTopics = (exchange, callerId) => {
    registerTopics([socketRoomName.exchange_ol + ':' + exchange], callerId);
};
export const registerMarketInforTopics = (callerId) => {
    // const listMarketFromSessionStorage = getSessionStorage(SESSION_STORAGE_LIST_MARKET_CHART)
    // let marketCodeArr = (listMarketFromSessionStorage && listMarketFromSessionStorage.length !== 0 ? listMarketFromSessionStorage : config.ALL_INDEXES.slice(0, MAX_NUM_MARKET_CHART)).map(element => {
    //     return element.code
    // });
    //Lấy ở hàm chung sau dễ maintain
    let marketCodeArr = CommonUtils.getListMarketChartCurrent("arr");
    // HOSE và HNX luôn được sub để lấy trạng thái phiên ở footer
    if (!marketCodeArr.includes('HOSE')) marketCodeArr.push('HOSE');
    if (!marketCodeArr.includes('HNX')) marketCodeArr.push('HNX');
    let subArr = marketCodeArr.map(element => {
        return socketRoomName.index + ":" + element;
    })
    registerTopics(subArr, callerId);
};

export const unregisterMarketInforTopics = (callerId, marketCode) => {
    let marketCodeArr = marketCode || CommonUtils.getListMarketChartCurrent("arr");
    let subArr = marketCodeArr.map(element => {
        return socketRoomName.index + ":" + element;
    })
    unregisterTopics(subArr, callerId);
};

export const registerAllMarketInfoTopics = (callerId) => {
    let marketCodeArr = config.ALL_INDEXES.map(element => {
        return element.code
    });
    let subArr = marketCodeArr.map(element => {
        return socketRoomName.index + ":" + element;
    })
    registerTopics(subArr, callerId);
};

export const registerSingleMarketInfo = (marketCode, callerId) => {
    marketCode && registerTopics([socketRoomName.index + ':' + marketCode], callerId);
}

export const unregisterAllMarketInfoTopics = (callerId) => {
    let marketCodeArr = config.ALL_INDEXES.map(element => {
        return element.code
    });
    let subArr = marketCodeArr.map(element => {
        return socketRoomName.index + ":" + element;
    })
    unregisterTopics(subArr, callerId, false);
};

socket.on('reconnect', () => {
    statusConnectSocket = "reconnect"
    logDebug(`socket.:on.:${"reconnect"}`, { registeredTopics, subscribedTopics })
    logService.debug({ typeOn: "reconnect", tabId: TabID, sidSocket_Old: sidSocket, token: token, logBy: "socket.js" })
    //console.log(TAG_NAME + 'Reconnect');
    // reloadData('Reconnect')
})

//==================================
function _loadAllMarketInfo(code, floorCode, loadType) {
    try {
        dispatch(actions.loadAllMarketInfo(code, floorCode, loadType))
    }
    catch (e) {
    }
}

function _getInstrumentsByExchange(exchange) {
    try {
        symbolService.getInstrumentByExchange(exchange).then(data => {
            isLogSocketFull && logDebug("socket.:msg.:i_4.:reloadData.:getInstrumentByExchange=", { data })
            let tranformedData = CommonUtils.transformSymbolsDataInstruments(data);
            let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
            dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments: tranformedData.symbolInfoForInstrument });
        })
    }
    catch (e) { }
}

// setTimeout(() => { _getInstrumentsByExchange("HOSE") }, 5000)
// setTimeout(() => { _getInstrumentsBySymbols("AAM") }, 5000)

function _getOddLotInstrumentsByExchange(exchange) {
    try {
        dispatch(actions.fetchOddlotListByExchange(exchange))
    }
    catch (e) { }
}

function _getInstrumentsBySymbols(symbols, isForce, callType) {
    try {
        //cho phép retry 2 lần
        if (isForce || reTryGetInstruments <= 2) {
            symbolService.getInstrument(symbols, callType).then(data => {
                isLogSocketFull && logDebug("socket.:msg.:i.:reloadData.:getInstrument=", { data })
                reTryGetInstruments = 0
                //console.log(TAG_NAME + '_getInstrumentsBySymbols().:OK=data.length=', data && data.length);
                let tranformedData = CommonUtils.transformSymbolsDataInstruments(data);
                //console.log(TAG_NAME + '_getInstrumentsBySymbols().:OK=tranformedData.symbolInfoForInstrument=', tranformedData.symbolInfoForInstrument);
                // dispatch({ type: actionTypes.SET_INSTRUMENT_DATA, instruments: tranformedData.symbolInfoForInstrument, keys: ['SB'] });
                let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
                dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments: tranformedData.symbolInfoForInstrument });
            })
        }
    }
    catch (e) {
        reTryGetInstruments++
        _getInstrumentsBySymbols(symbols, isForce, callType)
        //console.log(TAG_NAME + '_getInstrumentsBySymbols().:e=', e);
    }
}

function _getOddLotInstrumentsBySymbols(symbols, isForce, callType) {
    try {
        //cho phép retry 2 lần
        if (isForce || reTryGetOddLotInstruments <= 2) {
            symbolService.getOddlotByExchange(symbols, callType).then(data => {
                reTryGetOddLotInstruments = 0
                //console.log(TAG_NAME + '_getOddLotInstrumentsBySymbols().:OK=data.length=', data && data.length);
                let tranformedData = CommonUtils.transformSymbolsDataInstruments(data);
                //console.log(TAG_NAME + '_getOddLotInstrumentsBySymbols().:OK=tranformedData.symbolInfoForInstrument=', tranformedData.symbolInfoForInstrument);
                // dispatch({ type: actionTypes.SET_INSTRUMENT_DATA, instruments: tranformedData.symbolInfoForInstrument, keys: ['SB'] });
                let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
                dispatch({ type: actionTypes.UPDATE_ODDLOT_INSTRUMENT_DATA, rowKey, oddLotInstruments: tranformedData.symbolInfoForInstrument });
            })
        }
    }
    catch (e) {
        reTryGetOddLotInstruments++
        _getOddLotInstrumentsBySymbols(symbols, isForce, callType)
        //console.log(TAG_NAME + '_getOddLotInstrumentsBySymbols().:e=', e);
    }
}
function _fetchSymbolOrderbook(symbol) {
    try {
        dispatch(actions.fetchSymbolOrderbook(symbol))
    }
    catch (e) {
    }
}

function _getTradeData(symbol) {
    try {
        dispatch(actions.getTradeData(symbol))
    }
    catch (e) {
    }
}

export function reloadData(type, topics) {
    // let count = 0
    // if (type == 'Reconnect') {
    //     countReconnect++
    //     count = countReconnect
    // }
    // else {
    //     countConnect++
    //     count = countConnect
    // }
    try {
        //// 'instrument': "i",    //room publish thông tin mã chứng khoán
        //// 'instrument_ol': "i_oappl",    //room publish thông tin mã chứng khoán lô lẻ
        //// 'trade': "t",         //room publish thông tin chi tiết khớp mã chứng khoán
        // 'trade_ol': "t_ol",         //room publish thông tin chi tiết khớp mã chứng khoán lô lẻ
        // 'tradeExchange': "et",      //room publish thông tin chi tiết khớp mã chứng khoán theo sàn (sub theo floor code: HOSE: 10, HNX: 02, UPCOM: 04)
        //// 'orderBook10': "o10",   //room publish thông tin top10 giá mua giá bán mã chứng khoán
        // 'orderBook_ol10': "o_ol10",   //room publish thông tin top10 giá mua giá bán mã chứng khoán lô lẻ
        // 'orderBook3': "o3",    //room publish thông tin top3 giá mua giá bán mã chứng khoán
        // 'orderBook_ol3': "o_ol3",    //room publish thông tin top3 giá mua giá bán mã chứng khoán lô lẻ
        //// 'orderBook': "o",    //Loại event publish thông tin top3 giá mua giá bán mã chứng khoán
        // 'orderBook_ol': "o_ol",    //Loại event publish thông tin top3 giá mua giá bán mã chứng khoán lô lẻ
        //// 'exchange': "e",    //room subscribe thông tin mã chứng khoán theo sàn
        //// 'exchange_ol': "e_ol",    //room subscribe thông tin mã chứng khoán lô lẻ theo sàn
        //// 'index': "idx",          //room publish thông tin chỉ số
        // 'putthrough': "pth",  //room publish thông tin mua bán thỏa thuận theo sàn
        // 'ptmatch': "ptm",     //room publish thông tin mua bán thỏa thuận theo sàn
        // 'channel': "ch",      //room publish các loại thông tin cho các kênh kết nối dạng server2server
        let list_idx = []
        //
        let list_e = []
        let list_e_ol = []
        //
        let list_i = []
        let list_i_ol = []
        //
        let list_o = []
        let list_o_ol = []
        let list_o10 = []
        let list_o_ol10 = []
        //
        let list_t = []
        let list_t_ol = []
        //
        let list_pth = []
        let list_ptm = []
        // console.debug("reloadData.:1=", subscribedTopics, list_idx, list_i, list_i_ol, list_o10)
        let _subscribedTopics = ((topics && topics.length > 0) ? topics : Object.keys(registeredTopics)) || []
        isLogSocketFull && logDebug("reloadData.:", { type, _subscribedTopics })
        logDebug("reloadData.:", { typeCall: "reloadData", type, topics, _subscribedTopics })
        logService.debug({ typeCall: "reloadData", tabId: TabID, sidSocket_Old: sidSocket, token: token, type, topics, _subscribedTopics, logBy: "socket.js" })

        if (_subscribedTopics && _subscribedTopics.length > 0) {
            _.map(_subscribedTopics, (e) => {
                // index 1
                if (e.substring(0, 4).indexOf("idx:") > -1) {
                    list_idx.push(e.replace("idx:", ""))
                }
                // exchange 2
                if (e.substring(0, 4).indexOf("e:") > -1) {
                    list_e.push(e.replace("e:", ""))
                }
                if (e.substring(0, 5).indexOf("e_ol:") > -1) {
                    list_e_ol.push(e.replace("e_ol:", ""))
                }
                // instrument 4
                if (e.substring(0, 4).indexOf("i:") > -1) {
                    list_i.push(e.replace("i:", ""))
                }
                if (e.substring(0, 5).indexOf("i_ol:") > -1) {
                    list_i_ol.push(e.replace("i_ol:", ""))
                }
                // orderbook 4
                if (e.substring(0, 4).indexOf("o:") > -1) {
                    list_o.push(e.replace("o:", ""))
                }
                if (e.substring(0, 5).indexOf("o_ol:") > -1) {
                    list_o_ol.push(e.replace("o_ol:", ""))
                }
                if (e.substring(0, 5).indexOf("o10:") > -1) {
                    list_o10.push(e.replace("o10:", ""))
                }
                if (e.substring(0, 8).indexOf("o_ol10:") > -1) {
                    list_o_ol10.push(e.replace("o_ol10:", ""))
                }
                // trade 2
                if (e.substring(0, 4).indexOf("t:") > -1) {
                    list_t.push(e.replace("t:", ""))
                }
                if (e.substring(0, 5).indexOf("t_ol:") > -1) {
                    list_t_ol.push(e.replace("t_ol:", ""))
                }
                //pth + ptm
                if (e.substring(0, 5).indexOf("pth:") > -1) {
                    list_pth.push(e.replace("pth:", ""))
                }
                if (e.substring(0, 5).indexOf("ptm:") > -1) {
                    list_ptm.push(e.replace("ptm:", ""))
                }
            })
        }
        let isActiveTabBrowser = (type && type == "IsActiveTabBrowser")
        batch(() => {
            // index 1
            if (!isActiveTabBrowser) {
                // check isActiveTabBrowser tránh load lại đồ thị index 
                if (list_idx && list_idx.length > 0) {
                    _loadAllMarketInfo(list_idx, list_idx, 'ALL')
                }
            }
            // exchange 2
            if (list_e && list_e.length > 0) {
                _.map(list_e, (e) => {
                    _getInstrumentsByExchange(e)
                })
            }
            if (list_e_ol && list_e_ol.length > 0) {
                _.map(list_e_ol, (e) => {
                    _getOddLotInstrumentsByExchange(e)
                })
            }
            // instrument 2
            if (list_i && list_i.length > 0) {
                _getInstrumentsBySymbols(list_i)
            }
            if (list_i_ol && list_i_ol.length > 0) {
                _getOddLotInstrumentsBySymbols(list_i_ol)
            }
            // orderbook 4
            if (list_o && list_o.length > 0) {
                _.map(list_o, (e) => {
                    _fetchSymbolOrderbook(e)
                })
            }
            if (list_o_ol && list_o_ol.length > 0) {
                _.map(list_o_ol, (e) => {
                    _fetchSymbolOrderbook(e)
                })
            }
            if (list_o10 && list_o10.length > 0) {
                _.map(list_o10, (e) => {
                    _fetchSymbolOrderbook(e)
                })
            }
            if (list_o_ol10 && list_o_ol10.length > 0) {
                _.map(list_o_ol10, (e) => {
                    _fetchSymbolOrderbook(e)
                })
            }
            // trade 2
            if (list_t && list_t.length > 0) {
                _.map(list_t, (e) => {
                    emitter.emit('RECONNECT_RECENT_TRADES' + e, e);
                    emitter.emit('RECONNECT_TRADE' + e, e);
                    // _getTradeData(e)
                })
            }
            if (list_t_ol && list_t_ol.length > 0) {
                _.map(list_t_ol, (e) => {
                    emitter.emit('RECONNECT_RECENT_TRADES' + e, e);
                    emitter.emit('RECONNECT_TRADE' + e, e);
                    // _getTradeData(e)
                })
            }
            //pth + ptm
            // if (list_pth && list_pth.length > 0) {
            //     _.map(list_pth, (e) => {
            //     })
            // }
            // if (list_ptm && list_ptm.length > 0) {
            //     _.map(list_ptm, (e) => {
            //     })
            // }
        })
        // // Dữ liệu stockInfo
        // let redux = reduxStore.getState()
        // // let pathname = redux.router.localtion.pathname || undefined
        // // if (pathname && pathname.indexOf('/trade/')) {
        // // Chỉ khi đang active bảng giá
        // // active các tab Còn lại
        // let stocks = redux.symbol.listSymbolsW || []
        // // Nếu stocks = [] thì load lại All
        // //console.log(TAG_NAME + '' + type + '.:count' + type + '=', count, 'stocks=', stocks);
        // _getInstrumentsBySymbols(stocks)
        // //console.log(TAG_NAME + '' + type + '.:count' + type + '=', count, '_getInstrumentsBySymbols=', '[LOADED]');
        // // }
        // // Dữ liệu marketInfo
        // batch(() => {
        //     let currentListChart = CommonUtils.getListMarketChartCurrent("arr")
        //     dispatch(actions.loadAllMarketInfo(currentListChart, currentListChart, 'ALL'));
        // })
        //console.log(TAG_NAME + '' + type + '.:count' + type + '=', count, 'loadAllMarketInfo()=', '[LOADED]');
    }
    catch (e) {
        //console.log(TAG_NAME + '' + type + '.:count' + type + '=', count, '[ERROR]=', e);
    }
}


export const registerPTMatchAndPutThroughTopics = (exchange, callerId, ex) => {
    registerTopics([socketRoomName.ptmatch + ':' + exchange, socketRoomName.putthrough + ':' + exchange], callerId, ex, true);
};
export const unregisterPTMatchAndPutThroughTopics = (exchange, callerId, callback) => {
    unregisterTopics([socketRoomName.ptmatch + ':' + exchange, socketRoomName.putthrough + ':' + exchange], callerId, callback);
};

// _scheduleToExecuteOperations();