import _ from 'lodash';
import { inquiryService, logService } from "../../services";
import { CommonWidgetUtils, initialNonmalOrderInputState, initialProTradeOrderInputState, initialQuickOrderInputState, initialPutthroughOrderInputState, initialBlbOrderInputState, initialChildBlbOrderInputState, ORDER_TYPE_USE, orderActions, ToastUtil } from "../../utils";

import { SELECTOR_ACCOUNT_SCREEN, TYPE_ACCOUNT } from './../../utils/constants';
import { updatedLayoutPage, updatedScreenModal } from './layoutActions';
import CommonUtils from './../../utils/CommonUtils';
import actionTypes from './actionTypes';
import { Role } from '../../utils';
import { batch } from 'react-redux'
export const addPlaceQuickOrder = (key) => {
    return (dispatch, getState) => {
        const state = getState();
        let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _accountQuickOrderInfo = _currentLayoutPageActive && state.orderNew.placeQuickOrder[_currentLayoutPageActive] && state.orderNew.placeQuickOrder[_currentLayoutPageActive]["accountQuickOrderInfo"]
        let _initialQuickOrderInputState = {
            ...initialQuickOrderInputState,
            accountQuickOrderInfo: {
                ..._accountQuickOrderInfo
            }
        }
        let data = {}
        data[key] = {
            ..._initialQuickOrderInputState
        }
        dispatch({
            type: "ADD_QUICK_ORDER",
            data: data
        });
    };
};

export const deletePlaceQuickOrder = (key) => {
    return (dispatch, getState) => {
        dispatch({
            type: "DELETE_QUICK_ORDER",
            key: key,
        });
    };
};

export const updatePlaceQuickOrder = (data, typeOrder = ORDER_TYPE_USE.QUICK_ORDER, fromUpdate = "FROM_QUICK_ORDER") => {
    // //console.log('laojahackgame======> PLACE ORDER DONE CLEAR EDIT ORDER', { data, fromUpdate });
    return (dispatch, getState) => {
        const state = getState();
        let _allQuotes = state.symbol.allQuotes
        // //console.log("updatePlaceQuickOrder", data, typeOrder, fromUpdate)
        if (typeOrder === ORDER_TYPE_USE.QUICK_ORDER) { // Đặt lệnh nhanh
            let _placeQuickOrder = state.orderNew.placeQuickOrder
            let _currentLayoutPageActive = "C&B" // hardcode mặc định 
            if (_placeQuickOrder) {
                let newData = { ..._placeQuickOrder[_currentLayoutPageActive] }
                let { accountQuickOrderInfo, symbolCurrent } = newData
                let { typeAccount } = accountQuickOrderInfo
                if (fromUpdate == "FROM_PRICEBOARD") {
                    if (data && data["symbolCurrent"]) {
                        if (symbolCurrent && symbolCurrent.id) {
                            if (symbolCurrent.id !== data["symbolCurrent"]) {
                                data.quantity = 0
                            }
                        }
                        const foundSymbol = _.find(_allQuotes, (element) => (element.id === data["symbolCurrent"]))
                        let quote = foundSymbol && state.symbol.quotes[foundSymbol["id"]]
                        if (quote) {
                            if (typeAccount === TYPE_ACCOUNT.FDS) {
                                if ((!CommonUtils.isDerivativeExchange(quote.exchange))) {
                                    return
                                }
                            } else {
                                if ((CommonUtils.isDerivativeExchange(quote.exchange))) {
                                    return
                                }
                            }
                        }
                    }
                }

                Object.keys(data).forEach((item, index) => {
                    if (item === "symbolCurrent") {
                        const foundSymbol = _.find(_allQuotes, (element) => (element.id === data["symbolCurrent"]))
                        foundSymbol && CommonUtils.fetchApiInstrumentsBySymbol([data["symbolCurrent"]], [typeOrder])
                        newData["symbolCurrent"] = foundSymbol
                    } else {
                        newData[item] = data[item]
                    }
                })
                // //console.log('laojahackgame======> PLACE ORDER DONE CLEAR EDIT ORDER 123', { ...newData });

                dispatch({
                    type: "UPDATE_QUICK_ORDER",
                    data: {
                        ..._placeQuickOrder,
                        [_currentLayoutPageActive]: newData
                    }
                });
            }
        } else if (typeOrder === ORDER_TYPE_USE.NORMAL_ORDER) {
            let _placeNormalOrder = _.cloneDeep(state.orderNew.placeNormalOrder)
            let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
            if (_placeNormalOrder) {
                let newData = { ..._placeNormalOrder[_currentLayoutPageActive] }
                Object.keys(data).forEach((item, index) => {
                    // newData[item] = data[item]
                    if (item === "symbolCurrent") {
                        const foundSymbol = _.find(_allQuotes, (element) => (element.id === data["symbolCurrent"]))
                        let foundId = foundSymbol && foundSymbol.id ? foundSymbol.id : '';
                        let currentId = newData['symbolCurrent'] && newData['symbolCurrent'].id ? newData['symbolCurrent'].id : '';
                        if (foundId !== currentId) {
                            foundSymbol && CommonUtils.fetchApiInstrumentsBySymbol([data["symbolCurrent"]], [typeOrder])
                        }
                        newData["symbolCurrent"] = foundSymbol
                    } else {
                        newData[item] = data[item]
                    }
                })
                // //console.log("updatePlaceQuickOrder2", newData)
                dispatch({
                    type: "UPDATE_NORMAL_ORDER",
                    data: {
                        ..._placeNormalOrder,
                        [_currentLayoutPageActive]: newData
                    }
                });
                // if (data["symbolCurrent"]) {
                //     dispatch(actions.updateSymbolPlaceQuickOrder(data["symbolCurrent"], typeOrder))
                // }
            }
        } else if (typeOrder === ORDER_TYPE_USE.PUTTHROUGH_TRADE_ORDER) {
            let _placePutthroughOrder = _.cloneDeep(state.orderNew.placePutthroughOrder)
            let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
            if (_placePutthroughOrder) {
                let newData = { ..._placePutthroughOrder[_currentLayoutPageActive] }
                Object.keys(data).forEach((item, index) => {
                    if (item === "symbolCurrent") {
                        const foundSymbol = _.find(_allQuotes, (element) => (element.id === data["symbolCurrent"]))
                        newData["symbolCurrent"] = foundSymbol
                    } else {
                        newData[item] = data[item]
                    }
                })
                dispatch({
                    type: "UPDATE_PUTTHROUGH_ORDER",
                    data: {
                        ..._placePutthroughOrder,
                        [_currentLayoutPageActive]: newData
                    }
                });
            }
        } else if (typeOrder === ORDER_TYPE_USE.BLB_TRADE_ORDER) {
            let _placeBlbOrder = _.cloneDeep(state.orderNew.placeBlbOrder)
            let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
            if (_placeBlbOrder) {
                let newData = { ..._placeBlbOrder[_currentLayoutPageActive] }
                Object.keys(data).forEach((item, index) => {
                    if (item === "symbolCurrent") {
                        const foundSymbol = _.find(_allQuotes, (element) => (element.id === data["symbolCurrent"]))
                        newData["symbolCurrent"] = foundSymbol
                    } else {
                        newData[item] = data[item]
                    }
                })
                dispatch({
                    type: "UPDATE_BLB_ORDER",
                    data: {
                        ..._placeBlbOrder,
                        [_currentLayoutPageActive]: newData
                    }
                });
            }
        } else if (typeOrder === ORDER_TYPE_USE.BLB_CHILD_TRADE_ORDER) {
            let _placeChildBlbOrder = _.cloneDeep(state.orderNew.placeChildBlbOrder)
            let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
            if (_placeChildBlbOrder) {
                let newData = { ..._placeChildBlbOrder[_currentLayoutPageActive] }
                Object.keys(data).forEach((item, index) => {
                    if (item === "symbolCurrent") {
                        const foundSymbol = _.find(_allQuotes, (element) => (element.id === data["symbolCurrent"]))
                        newData["symbolCurrent"] = foundSymbol
                    } else {
                        newData[item] = data[item]
                    }
                })
                dispatch({
                    type: "UPDATE_CHILD_BLB_ORDER",
                    data: {
                        ..._placeChildBlbOrder,
                        [_currentLayoutPageActive]: newData
                    }
                });
            }
        }
    };
};



export const clearDataPlaceQuickOrder = (typeOrder = ORDER_TYPE_USE.QUICK_ORDER, arrKeep) => {
    // let arrKeepToKeep = arrKeep ? ['focusSymbol'].concat(arrKeep) : ['symbolFocus']; // Event focus vào ô nhập mã nằm ngoài luồng đặt lệnh
    let arrKeepToKeep = arrKeep;
    return (dispatch, getState) => {
        const state = getState();
        try {
            if (typeOrder === ORDER_TYPE_USE.QUICK_ORDER) { // Đặt lệnh nhanh
                let _placeQuickOrder = state.orderNew.placeQuickOrder
                let _currentLayoutPageActive = "C&B" // hardcode mặc định 
                if (_placeQuickOrder) {
                    let dataDefault = {
                        ...initialQuickOrderInputState,
                        accountQuickOrderInfo: _placeQuickOrder[_currentLayoutPageActive]["accountQuickOrderInfo"]
                    }
                    if (arrKeepToKeep && arrKeepToKeep.length > 0) {
                        arrKeepToKeep.map((item, index) => {
                            dataDefault[item] = _placeQuickOrder[_currentLayoutPageActive][item]
                        })
                    }
                    // let dataDefault = {
                    //     ...initialQuickOrderInputState,
                    //     accountQuickOrderInfo: _placeQuickOrder[_currentLayoutPageActive]["accountQuickOrderInfo"],
                    //     action: isKeepAction ? _placeQuickOrder[_currentLayoutPageActive]["action"] : orderActions.BUY
                    //     // action: _placeQuickOrder[_currentLayoutPageActive]["action"],
                    // }
                    dispatch({
                        type: "CLEAR_DATA_QUICK_ORDER",
                        data: {
                            ..._placeQuickOrder,
                            [_currentLayoutPageActive]: dataDefault
                        }
                    });
                }
            } else if (typeOrder === ORDER_TYPE_USE.NORMAL_ORDER) {
                let _placeNormalOrder = state.orderNew.placeNormalOrder
                let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
                if (_placeNormalOrder) {
                    let dataDefault = {
                        ...initialNonmalOrderInputState,
                    }
                    if (arrKeepToKeep && arrKeepToKeep.length > 0) {
                        arrKeepToKeep.map((item, index) => {
                            dataDefault[item] = _placeNormalOrder[_currentLayoutPageActive] && _placeNormalOrder[_currentLayoutPageActive][item]
                        })
                    }

                    // let dataDefault = {
                    //     ...initialNonmalOrderInputState,
                    //     action: isKeepAction ? _placeNormalOrder[_currentLayoutPageActive] && _placeNormalOrder[_currentLayoutPageActive]["action"] : orderActions.BUY
                    // }
                    batch(() => {
                        dispatch({
                            type: "CLEAR_DATA_NORMAL_ORDER",
                            data: {
                                ..._placeNormalOrder,
                                [_currentLayoutPageActive]: dataDefault
                            }
                        });
                    })
                }
            } else if (typeOrder === ORDER_TYPE_USE.PUTTHROUGH_TRADE_ORDER) {
                let _placePutthroughOrder = state.orderNew.placePutthroughOrder
                let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive();
                if (_placePutthroughOrder) {
                    let dataDefault = {
                        ...initialPutthroughOrderInputState,
                        action: orderActions.SELL, // Lệnh thỏa thuận mặc định action bán
                        firm: '002', // Mặc định cho lệnh thỏa thuận cùng thành viên
                        tradeid: '0021', // Mặc định cho lệnh thỏa thuận cùng thành viên
                        clearDay: 2 // Mặc định ngày
                    }
                    dispatch({
                        type: "CLEAR_DATA_PUTTHROUGH_ORDER",
                        data: {
                            ..._placePutthroughOrder,
                            [_currentLayoutPageActive]: dataDefault
                        }
                    });
                }
            } else if (typeOrder === ORDER_TYPE_USE.BLB_TRADE_ORDER) {
                let _placeBlbOrder = _.cloneDeep(state.orderNew.placeBlbOrder)
                let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive();
                if (_placeBlbOrder) {
                    let dataDefault = {
                        ...initialBlbOrderInputState,
                    }
                    dispatch({
                        type: "CLEAR_DATA_BLB_ORDER",
                        data: {
                            ..._placeBlbOrder,
                            [_currentLayoutPageActive]: dataDefault
                        }
                    });
                }
            } else if (typeOrder === ORDER_TYPE_USE.BLB_CHILD_TRADE_ORDER) {
                let _placeChildBlbOrder = _.cloneDeep(state.orderNew.placeChildBlbOrder)
                let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive();
                if (_placeChildBlbOrder) {
                    let dataDefault = {
                        ...initialChildBlbOrderInputState,
                    }
                    dispatch({
                        type: "CLEAR_DATA_CHILD_BLB_ORDER",
                        data: {
                            ..._placeChildBlbOrder,
                            [_currentLayoutPageActive]: dataDefault
                        }
                    });
                }
            }
        } catch (error) {
            logService.error({ typeOn: "orderNewActions.js_clearDataPlaceQuickOrder()", data: { typeOrder, arrKeep }, error: error && error.toString() })
        }
    };
};


export const updateSymbolPlaceQuickOrder = (id, typeOrder = ORDER_TYPE_USE.QUICK_ORDER) => {
    return (dispatch, getState) => {
        const state = getState();
        let _allQuotes = state.symbol.allQuotes
        if (typeOrder === ORDER_TYPE_USE.QUICK_ORDER) {
            let _placeQuickOrder = state.orderNew.placeQuickOrder
            let _currentLayoutPageActive = "C&B"
            if (_placeQuickOrder) {
                const foundSymbol = _.find(_allQuotes, (element) => (element.id === id))
                let newData = { ..._placeQuickOrder[_currentLayoutPageActive] }
                newData["symbolCurrent"] = foundSymbol
                dispatch({
                    type: "UPDATE_QUICK_ORDER",
                    data: {
                        ..._placeQuickOrder,
                        [_currentLayoutPageActive]: newData
                    }
                });
            }
        } else if (ORDER_TYPE_USE.NORMAL_ORDER) {
            let _placeQuickOrder = state.orderNew.placeNormalOrder
            let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
            if (_placeQuickOrder) {
                const foundSymbol = _.find(_allQuotes, (element) => (element.id === id))
                let newData = { ..._placeQuickOrder[_currentLayoutPageActive] }
                newData["symbolCurrent"] = foundSymbol
                dispatch({
                    type: "UPDATE_NORMAL_ORDER",
                    data: {
                        ..._placeQuickOrder,
                        [_currentLayoutPageActive]: newData
                    }
                });
            }
        }

    };
};

export const addPlaceNomalOder = (key) => {
    return (dispatch, getState) => {
        let _initialNonmalOrderInputState = {
            ...initialNonmalOrderInputState,
        }
        let data = {}
        data[key] = {
            ..._initialNonmalOrderInputState
        }
        dispatch({
            type: "ADD_DATA_NORMAL_ORDER",
            data: data
        });
    };
};

export const tranformCustomerAccountList = (accounts) => {
    return _.map(accounts, (account) => {
        return {
            ...account,
            alternateacct: "N",
            cftype: "1",
            // producttypename: account['producttypename'],
            // id: account['account'],
            // name: account['fullName'],
            // accountFull: account['accountFull'],
            // custodycd: account['custodycd'],
            // corebank: account['corebank'],
            // afacctnoext: account['afacctnoext']
        };
    })
};


export const setCurCustomerPlaceQuickOrder = (custodycd) => {  //huy.quang: tìm kiếm customer theo custodycd & gán vào current customer
    return (dispatch, getState) => {
        const state = getState();
        let _currentLayoutPageActive = "C&B"
        let _placeQuickOrder = state.orderNew.placeQuickOrder[_currentLayoutPageActive]
        let { accountQuickOrderInfo } = { ..._placeQuickOrder }

        const currentCustomer = accountQuickOrderInfo.curCustomer;
        // if (!currentCustomer || currentCustomer['custodycd'] !== custodycd) {
        inquiryService.getCustomers(custodycd).then(data => {
            if (data && data.length === 1) {
                inquiryService.getCustomerAccounts(data[0]['custodycd'])
                    .then((res) => {
                        const tranformedData = tranformCustomerAccountList(res);
                        dispatch(updatePlaceQuickOrder({
                            accountQuickOrderInfo: {
                                curCustomer: data[0],
                                accounts: tranformedData,
                                currentAccountId: tranformedData[0].id,
                                typeAccount: TYPE_ACCOUNT.BASE,
                            }
                        }))
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                    })

            };
        }).catch(error => {
            // dispatch(setCurrentCustomer(null));
            ToastUtil.errorApi(error, 'common.fail-to-load-customer-info');
        });

        // };
    };
};

export const setCustodycdCustomer = (typeScreen, curCustomer, tranformedData, callback, accountId) => {  //huy.quang: tìm kiếm customer theo custodycd & gán vào current customer
    return async (dispatch, getState) => {
        let isFillAccountValid = accountId && tranformedData && _.find(tranformedData, item => {
            return accountId === item.id
        });

        if (typeScreen === SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER) {
            await dispatch(updatePlaceQuickOrder({
                accountQuickOrderInfo: {
                    curCustomer: curCustomer[0],
                    accounts: tranformedData,
                    currentAccountId: isFillAccountValid ? accountId : tranformedData[0].id, // Không fill mặc định tiểu khoản đầu tiên khi môi giới đổi tk careby
                    typeAccount: TYPE_ACCOUNT.BASE,
                }
            }))
            callback && callback()
        } else if (typeScreen === SELECTOR_ACCOUNT_SCREEN.LAYOUT) {
            if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER || CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_NM_ORDER) {
                isFillAccountValid = true
                accountId = null
                _.forEach(tranformedData, item => {
                    if (item.typename.includes("TPRL")) {
                        accountId = item.id
                    }
                });
            }
            await dispatch(updatedLayoutPage({
                accountInfo: {
                    curCustomer: curCustomer[0],
                    accounts: tranformedData,
                    currentAccountId: isFillAccountValid ? accountId : tranformedData[0].id,// Không fill mặc định tiểu khoản đầu tiên khi môi giới đổi tk careby
                    typeAccount: TYPE_ACCOUNT.BASE,
                }
            }))
            callback && callback()
        } else if (typeScreen === SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL) {
            await dispatch(updatedScreenModal({
                accountInfo: {
                    curCustomer: curCustomer[0],
                    accounts: tranformedData,
                    currentAccountId: isFillAccountValid ? accountId : tranformedData[0].id,// Không fill mặc định tiểu khoản đầu tiên khi môi giới đổi tk careby
                    typeAccount: TYPE_ACCOUNT.BASE,
                }
            }))
            callback && callback()
        }
    };
};

export const clearCustodycdCustomerScreen = (typeScreen) => {
    return async (dispatch, getState) => {
        let accountInfo = {
            curCustomer: '',
            accounts: [],
            currentAccountId: '',
            typeAccount: TYPE_ACCOUNT.BASE,
        }
        if (typeScreen === SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER) {
            dispatch(updatePlaceQuickOrder({
                accountQuickOrderInfo: { ...accountInfo }
            }))
        } else if (typeScreen === SELECTOR_ACCOUNT_SCREEN.LAYOUT) {
            dispatch(updatedLayoutPage({
                accountInfo
            }))
        } else if (typeScreen === SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL) {
            dispatch(updatedScreenModal({
                accountInfo
            }))
        }
    };
}


export const isCurrentCustomer = (custodycd, typeScreen) => {
    return (dispatch, getState) => {
        let _currentLayoutPageActive = null
        const state = getState();
        let isFlag = false
        if (typeScreen === SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER) {
            _currentLayoutPageActive = "C&B"
            let _placeQuickOrder = _.cloneDeep(state.orderNew.placeQuickOrder)
            _placeQuickOrder = _placeQuickOrder[_currentLayoutPageActive]
            const { curCustomer } = _placeQuickOrder.accountQuickOrderInfo
            if (curCustomer && curCustomer['custodycd'] === custodycd) {
                isFlag = false
            } else {
                isFlag = true
            }
            return isFlag
        }
        else if (typeScreen === SELECTOR_ACCOUNT_SCREEN.LAYOUT) {
            _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
            let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
            const { curCustomer } = { ..._layoutPage.accountInfo }
            if (curCustomer && curCustomer['custodycd'] === custodycd) {
                isFlag = false
            } else {
                isFlag = true
            }
            return isFlag
        } else if (typeScreen === SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL) {
            _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
            let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
            const { curCustomer } = { ..._layoutPage.accountInfo }
            if (curCustomer && curCustomer['custodycd'] === custodycd) {
                isFlag = false
            } else {
                isFlag = true
            }
            isFlag = true
        }
        return isFlag

    };
};


export const setCustodycdCustomerScreen = (custodycd, typeScreen, callback, saveCustodycd, accountIdToFill) => {
    return async (dispatch, getState) => {
        let isFlag = await dispatch(isCurrentCustomer(custodycd, typeScreen))
        if (isFlag) {
            await inquiryService.getCustomers(custodycd).then(async data => {
                if (data && data.length === 1) {
                    await inquiryService.getCustomerAccounts(data[0]['custodycd'])
                        .then(async (res) => {
                            saveCustodycd && dispatch(saveCustomerInfo(custodycd));
                            const tranformedData = tranformCustomerAccountList(res);
                            await dispatch(setCustodycdCustomer(typeScreen, data, tranformedData, callback, accountIdToFill))
                        })
                        .catch((error) => {
                            ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                        })

                };
            }).catch(error => {
                // dispatch(setCurrentCustomer(null));
                // ToastUtil.errorApi(error, 'common.fail-to-load-customer-info');
                ToastUtil.error("CM2", 'CM000002');

            });
        }
        else {
            if (callback) callback();
        }

        // };
    };
};

const saveCustomerInfo = (custodycd) => {
    try {
        return async (dispatch, getState) => {
            let state = getState();
            let currentCustodycd = state.user.userInfo.username;
            let role = state.user.userInfo.role;
            let usersTokens = JSON.parse(localStorage.getItem('token-users'));

            // if (usersTokens[`${currentCustodycd}`]) {
            let savedAccountInfo = usersTokens && usersTokens[`${currentCustodycd}`] && usersTokens[`${currentCustodycd}`]['savedAccountInfo'];
            if (role === Role.BROKER) {
                if (savedAccountInfo) {
                    let foundIndex = _.findIndex(savedAccountInfo, item => { return item['custodycd'] === custodycd });
                    if (foundIndex === -1) {
                        if (savedAccountInfo.length < 5) { // Tham số config ở đây
                            // Nếu tài khoản chưa được lưu  =====> thêm mới
                            usersTokens[`${currentCustodycd}`]['savedAccountInfo'].push({ custodycd, ts: Date.now() });
                        } else {
                            // xóa tài khoản lưu xa nhất và lưu tài khoản lưu sớm nhất
                            let deleteAcocuntInfo = _.minBy(savedAccountInfo, function (item) { return Number(item.ts); });
                            let deleteIndex = _.findIndex(savedAccountInfo, item => { return item['custodycd'] === deleteAcocuntInfo['custodycd'] });
                            savedAccountInfo.splice(deleteIndex, 1);
                            usersTokens[`${currentCustodycd}`]['savedAccountInfo'].push({ custodycd, ts: Date.now() });
                        }
                    } else {
                        // Nếu tài khoản đã được lưu =====> cập nhật lại thông tin ts
                        usersTokens[`${currentCustodycd}`]['savedAccountInfo'][`${foundIndex}`]['ts'] = Date.now();
                    }
                } else {
                    if (usersTokens[`${currentCustodycd}`]) {
                        usersTokens[`${currentCustodycd}`]['savedAccountInfo'] = [].concat({ custodycd, ts: Date.now() });
                    }
                }
            } else {
                usersTokens[`${currentCustodycd}`]['savedAccountInfo'] = [].concat({ custodycd, ts: Date.now() });
            }
            // }

            localStorage.setItem('token-users', JSON.stringify(usersTokens));

            dispatch({
                type: actionTypes.SET_SAVED_CUSTODYCD
            });
        }
    } catch (err) {
        //console.log('Error: Save AccountInfo broker ', err);
    }

}


export const setAccountIdScreen = (accountId, typeScreen) => {
    return (dispatch, getState) => {
        const state = getState();
        if (typeScreen === SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER) {
            let _placeQuickOrder = state.orderNew.placeQuickOrder
            let _currentLayoutPageActive = "C&B" // hardcode mặc định 
            const placeQuickOrderCurrent = _currentLayoutPageActive && _placeQuickOrder[_currentLayoutPageActive]
            const _accountInfo = placeQuickOrderCurrent && placeQuickOrderCurrent["accountQuickOrderInfo"]
            let _typeAccount = TYPE_ACCOUNT.BASE
            let foundAccount = _accountInfo.accounts.find(item => item.id === accountId)
            if (foundAccount && foundAccount.accounttype === "FDS") {
                _typeAccount = TYPE_ACCOUNT.FDS
            }
            let data = {
                // symbolCurrent: null, //VIX: Không clear symbol khi đổi tiểu khoản
                accountQuickOrderInfo: {
                    ..._accountInfo,
                    currentAccountId: accountId,
                    typeAccount: _typeAccount,
                }
            }
            dispatch(updatePlaceQuickOrder({
                ...data
            }))

        } else if (typeScreen === SELECTOR_ACCOUNT_SCREEN.LAYOUT) {
            let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
            let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
            let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
            let _typeAccount = TYPE_ACCOUNT.BASE
            let foundAccount = _accountInfo.accounts.find(item => item.id === accountId)
            if (foundAccount && foundAccount.accounttype === "FDS") {
                _typeAccount = TYPE_ACCOUNT.FDS
            }
            let data = {
                accountInfo: {
                    ..._accountInfo,
                    currentAccountId: accountId,
                    typeAccount: _typeAccount,
                }
            }
            dispatch(updatedLayoutPage(data, _currentLayoutPageActive))
            //VIX: Không clear symbol khi đổi tiểu khoản
            // dispatch(actions.updatedSymbolLayoutPage(null, "search_layout"))
        } else if (typeScreen === SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL) {

        }
    };
};

export const triggerTradeQuickOrderAuthenCallback = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        //console.log("triggerTradeQuickOrderAuthenCallback", data)
        const { onTradeQuickOrderAuthenCallback } = state.layout.listIsOpenModal["C&B"]["QuickOrder"];
        if (onTradeQuickOrderAuthenCallback) {
            onTradeQuickOrderAuthenCallback(data);
        }
    };
};

export const triggerTradePutthroughOrderAuthenCallback = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        //console.log("triggerTradePutthroughOrderAuthenCallback", data)
        const { onPutthrougOrderCallback } = state.layout.listIsOpenModal["C&B"]["Putthrough"];
        if (onPutthrougOrderCallback) {
            onPutthrougOrderCallback(data);
        }
    };
};

export const triggerTradeBlbOrderAuthenCallback = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        //console.log("triggerTradeBlbOrderAuthenCallback", data)
        const { onBlbCallback } = state.layout.listIsOpenModal["C&B"]["BlbOrder"];
        if (onBlbCallback) {
            onBlbCallback(data);
        }
    };
};

export const triggerTradeProTradeAuthenCallback = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        const { onProTradeAuthenCallback } = state.layout.listIsOpenModal["C&B"]["ProTrade"];
        if (onProTradeAuthenCallback) {
            onProTradeAuthenCallback(data);
        }
    };
};


export const addProTradeOder = (key) => {
    return (dispatch, getState) => {
        const state = getState();
        let data = {}
        let _initialProTradeOrderInputState = _.cloneDeep(initialProTradeOrderInputState)
        data[key] = {
            ..._initialProTradeOrderInputState
        }
        dispatch({
            type: "ADD_DATA_PROTRADE_ORDER",
            data: data
        });
    };
};


export const updateProTrade = (data, typeAction) => {
    return (dispatch, getState) => {
        //console.log("updateProTrade", data, typeAction)
        const state = getState();
        let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _placeProTradeOrder = _.cloneDeep(state.orderNew.placeProTradeOrder)
        let _placeProTradeOrderActive = _.cloneDeep(_placeProTradeOrder[_currentLayoutPageActive])
        let newData = _.cloneDeep(_placeProTradeOrderActive)

        let _allQuotes = state.symbol.allQuotes
        if (!_.isEmpty(_placeProTradeOrderActive)) {
            if (typeAction) {
                Object.keys(data).forEach((item, index) => {
                    newData[typeAction][item] = data[item]
                })
                dispatch({
                    type: "UPDATE_PROTRADE_ORDER",
                    data: {
                        ..._placeProTradeOrder,
                        [_currentLayoutPageActive]: newData
                    }
                });
            } else {
                Object.keys(data).forEach((item, index) => {
                    if (item === "symbolCurrent") {
                        const foundSymbol = _.find(_allQuotes, (element) => (element.id === data["symbolCurrent"]))
                        newData["symbolCurrent"] = foundSymbol
                    } else {
                        newData[item] = data[item]
                    }
                })
                dispatch({
                    type: "UPDATE_PROTRADE_ORDER",
                    data: {
                        ..._placeProTradeOrder,
                        [_currentLayoutPageActive]: newData
                    }
                });
            }
        }
    };
};

export const updateSymbolProTrade = (id) => {
    return (dispatch, getState) => {
        const state = getState();
        let _allQuotes = state.symbol.allQuotes

        let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _placeProTradeOrder = { ...state.orderNew.placeProTradeOrder }
        let newData = { ..._placeProTradeOrder[_currentLayoutPageActive] }
        if (_placeProTradeOrder) {
            const foundSymbol = _.find(_allQuotes, (element) => (element.id === id))
            newData["symbolCurrent"] = foundSymbol
            dispatch({
                type: "UPDATE_PROTRADE_ORDER",
                data: {
                    ..._placeProTradeOrder,
                    [_currentLayoutPageActive]: newData
                }
            });
        }
    };
};

export const clearDataPlaceProTrade = () => {
    return (dispatch, getState) => {
        const state = getState();

        let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _placeProTradeOrder = _.cloneDeep(state.orderNew.placeProTradeOrder)

        if (_placeProTradeOrder) {
            // let dataDefault = _.cloneDeep(initialProTradeOrderInputState)
            let dataDefault = {
                ...initialProTradeOrderInputState
            }
            batch(() => {
                dispatch({
                    type: "CLEAR_DATA_PROTRADE_ORDER",
                    data: {
                        ..._placeProTradeOrder,
                        [_currentLayoutPageActive]: dataDefault
                    }
                });
            })
        }
    };
};



export const updatePlaceQuickOrderConditon = (data, typeOrder = ORDER_TYPE_USE.QUICK_ORDER) => {
    //console.log("updatePlaceQuickOrderConditonAAAAAAAAAAAAAAAAAAAAA", data);
    return (dispatch, getState) => {
        const state = getState();
        // //console.log("updatePlaceQuickOrderConditon1", data)

        if (typeOrder === ORDER_TYPE_USE.QUICK_ORDER) { // Đặt lệnh nhanh
            let _placeQuickOrder = state.orderNew.placeQuickOrder
            let _currentLayoutPageActive = "C&B" // hardcode mặc định 
            if (_placeQuickOrder) {
                const { typeOrderCondition } = _placeQuickOrder[_currentLayoutPageActive]
                let newData = { ..._placeQuickOrder[_currentLayoutPageActive][typeOrderCondition] }
                // //console.log("updatePlaceQuickOrderConditon1", typeOrderCondition, newData)
                Object.keys(data).forEach((item, index) => {
                    newData[item] = data[item]
                })
                //console.log("updatePlaceQuickOrderConditon1.1", newData)
                dispatch(updatePlaceQuickOrder({
                    [typeOrderCondition]: newData,
                }, typeOrder))
            }
        } else if (typeOrder === ORDER_TYPE_USE.NORMAL_ORDER) {
            let _placeNormalOrder = state.orderNew.placeNormalOrder
            let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive() // hardcode mặc định 
            if (_placeNormalOrder) {
                const { typeOrderCondition } = _placeNormalOrder[_currentLayoutPageActive]
                let newData = { ..._placeNormalOrder[_currentLayoutPageActive][typeOrderCondition] }
                // //console.log("updatePlaceQuickOrderConditon2", typeOrderCondition, newData)
                Object.keys(data).forEach((item, index) => {
                    newData[item] = data[item]
                })
                // //console.log("updatePlaceQuickOrderConditon2.1", newData)
                dispatch(updatePlaceQuickOrder({
                    [typeOrderCondition]: newData,
                }, typeOrder))

            }
        }
    };
};


export const updatePlaceQuickOrderTPSL = (data, typeOrder = ORDER_TYPE_USE.QUICK_ORDER) => {
    return (dispatch, getState) => {
        const state = getState();
        //console.log("updatePlaceQuickOrderTPSL1", data, typeOrder)
        if (typeOrder === ORDER_TYPE_USE.QUICK_ORDER) { // Đặt lệnh nhanh
            let _placeQuickOrder = state.orderNew.placeQuickOrder
            let _currentLayoutPageActive = "C&B" // hardcode mặc định 
            if (_placeQuickOrder) {
                const { orderTPSL } = _placeQuickOrder[_currentLayoutPageActive]
                let newData = { ..._placeQuickOrder[_currentLayoutPageActive]["orderTPSL"] }
                Object.keys(data).forEach((item, index) => {
                    newData[item] = data[item]
                })
                dispatch(updatePlaceQuickOrder({
                    ["orderTPSL"]: newData,
                }, typeOrder))
            }
        } else if (typeOrder === ORDER_TYPE_USE.NORMAL_ORDER) {
            let _placeNormalOrder = state.orderNew.placeNormalOrder
            let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive() // hardcode mặc định 
            if (_placeNormalOrder) {
                const { orderTPSL } = _placeNormalOrder[_currentLayoutPageActive]
                let newData = { ..._placeNormalOrder[_currentLayoutPageActive]["orderTPSL"] }
                Object.keys(data).forEach((item, index) => {
                    newData[item] = data[item]
                })
                //console.log("updatePlaceQuickOrderTPSL2", data, typeOrder)
                dispatch(updatePlaceQuickOrder({
                    ["orderTPSL"]: newData,
                }, typeOrder))

            }
        }
    };
};


export const updatePlaceProTradeConditon = (data, typeAction) => {
    return (dispatch, getState) => {
        const state = getState();

        let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let _placeProTradeOrder = _.cloneDeep(state.orderNew.placeProTradeOrder)
        let _placeProTradeOrderActive = _.cloneDeep(_placeProTradeOrder[_currentLayoutPageActive])

        if (_placeProTradeOrderActive) {
            const { typeOrderCondition } = _placeProTradeOrderActive
            //console.log("updatePlaceProTradeConditon_1", _placeProTradeOrderActive)
            let newData = { ..._placeProTradeOrderActive[typeOrderCondition] }
            //console.log("updatePlaceProTradeConditon_2", newData)
            if (typeAction) {
                Object.keys(data).forEach((item, index) => {
                    newData[typeAction][item] = data[item]
                })
                //console.log("updatePlaceProTradeConditon_3", newData,)
                dispatch(updateProTrade({
                    [typeOrderCondition]: newData,
                }))
            } else {
                Object.keys(data).forEach((item, index) => {
                    newData[orderActions.BUY][item] = data[item]
                    newData[orderActions.SELL][item] = data[item]
                })
                //console.log("updatePlaceProTradeConditon_3", newData,)
                dispatch(updateProTrade({
                    [typeOrderCondition]: newData,
                }))
            }
        }
    }
};


export const setFocusOnRenderOrder = (focusKey = false) => {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_FOCUS_ON_RENDER",
            focusKey: focusKey
        });
    }
};