export const keyFactory = {
    language: 'language',
    baseid: 'baseid'
};

export const getValueFromLocalStorage = (key) => {
    if (localStorage) {
        try {
            const value = localStorage.getItem(key);
            return value;
        } catch (e) {
            /*Ignore*/
            return null;
        }
    }
    return null;
};

export const setValueToLocalStorage = (key, value) => {
    if (localStorage) {
        return localStorage.setItem(key, value);
    }
    return null;
};

export const genBaseId = () => {
    let baseIDLength = 15; // Độ dài ID
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (var i = 0; i < baseIDLength; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    result += Date.now(); // Thêm 13 ký tự timestamp
    setValueToLocalStorage(keyFactory.baseid, result);
    return result;
};