import _ from "lodash";

import { emitter } from 'utils/EventEmitter';

import _config from '../../combineConfig';
import * as Config from '../../constants/config';
import types from '../actions/actionTypes';

let initialState = {
	marketInfos: {},
	marketHistories: {},
	IS_LOAD_MARKET_INFO_SUCCESS: false,
	IS_LOAD_MARKET_HISTORY_SUCCESS: false,
	IS_LOAD_MARKET_INDEX_SUCCESS: false,
	currentMarketDetail: null,
	isDetailIndex: false
};
function getListIndex() {
	let indexDrawChart = _.map(_config.ALL_INDEXES, (item) => {
		return item.code;
	})
	let allIndexArr = indexDrawChart.concat(Config.ListIndexNotDrawChart)
	return allIndexArr;
}
let check = -1;
function init_marketInfos() {
	let list_floorcode = getListIndex();
	// let list_floorcode = []
	let result = {}
	for (var item of list_floorcode) {
		let _object = {
			[item]: {
				"advance": '', // 6
				"changedIndex": '', //2
				// "dateNo": "3559",
				"decline": '', // 8
				"floorCode": '',
				//"highestIndex": 109.917036,
				//"lowestIndex": 107.152126,
				"marketIndex": '', // 1
				"noChange": '', // 7
				"percentIndex": "-", // 3
				"priorMarketIndex": '',
				"totalShareTraded": '', // 4
				"totalStock": '',
				"totalTrade": '',
				"totalValueTraded": '', // 9
				"tradingDate": '',
				"tradingTime": '',
				"status": '', // 10 ghép tạm
				// "totalNormalTradedQttyRd": 3304990,
				// "totalNormalTradedValueRd": 384.91007,
				// "totalNormalTradedQttyOd": 2297.2,
				// "totalNormalTradedValueOd": 0.5303398,
				// "totalPTTradedQtty": 421867.5,
				// "totalPTTradedValue": 29.755896,
				"openMarketIndex": '',
				"oddLotTotalVolume": '',
				"oddLotTotalValue": '',
				"ptTotalVolumn": '',
				"ptTotalValue": '',
			}
		}
		result = Object.assign(result, _object);
	}
	initialState.marketInfos = result;
	// initialState.IS_LOAD_MARKET_INFO_SUCCESS = false;
}
function init_marketHistories() {
	let list_floorcode = getListIndex();
	// let list_floorcode = []
	let result = {}
	for (var item of list_floorcode) {
		result[item] = {
			"formattedtime": [],
			"volume": [],
			"reference": [],
			"close": [],
		}
	}
	initialState.marketHistories = result;
	// initialState.IS_LOAD_MARKET_HISTORY_SUCCESS = false;
}
function GET_UPDATE_MARKET_INFOS_BY_MC(currentMarketInfos, data) {
	try {
		for (let key in data) {
			if (!data[key]) return currentMarketInfos;
			let dt = data[key]
			if (!dt.MC || !currentMarketInfos) return currentMarketInfos;
			// currentMarketInfos.crrTime = dt.IT;
			currentMarketInfos.advance = dt.ADV ? parseFloat(dt.ADV) : 0;
			currentMarketInfos.changedIndex = dt.ICH ? parseFloat(dt.ICH) : 0;
			let changedIndex = currentMarketInfos.changedIndex
			// currentMarketInfos.dateNo = parseFloat(dt.IT);
			currentMarketInfos.decline = dt.DE ? parseFloat(dt.DE) : 0;
			currentMarketInfos.floorCode = dt.MC;
			// currentMarketInfos.highestIndex = parseFloat(dt.IT);
			// currentMarketInfos.indexColor = dt.IC;
			// Haki.: Thêm logic cập nhật lại IC theo ICH
			currentMarketInfos.indexColor = changedIndex >= 0 ? changedIndex > 0 ? 'up' : 'noChange' : 'down';
			currentMarketInfos.marketIndex = dt.MI ? parseFloat(dt.MI) : 0;
			currentMarketInfos.noChange = dt.NC ? parseFloat(dt.NC) : 0;
			currentMarketInfos.percentIndex = (dt.IPC) ? parseFloat(dt.IPC) : 0;
			currentMarketInfos.priorMarketIndex = dt.PPMI ? parseFloat(dt.PPMI) : 0;
			currentMarketInfos.totalShareTraded = dt.TV ? parseFloat(dt.TV) : 0;
			currentMarketInfos.totalStock = dt.TV ? parseFloat(dt.TV) : 0;
			currentMarketInfos.totalTrade = dt.TVA ? parseFloat(dt.TVA) : 0;
			currentMarketInfos.totalValueTraded = dt.TVA ? parseFloat(dt.TVA) : 0;
			currentMarketInfos.tradingDate = dt.TD;
			currentMarketInfos.tradingTime = dt.IT;
			currentMarketInfos.status = dt.MS;
			currentMarketInfos.numberOfCe = dt.NOC;
			currentMarketInfos.numberOfFl = dt.NOF;
			currentMarketInfos.oddLotTotalVolume = dt.OTV ? parseFloat(dt.OTV) : 0;;
			currentMarketInfos.oddLotTotalValue = dt.OTVA ? parseFloat(dt.OTVA) : 0;;

			currentMarketInfos.openMarketIndex = dt.MI ? parseFloat(dt.MI) : 0;
			currentMarketInfos.ts = dt.ts;
			currentMarketInfos.ptTotalVolumn = dt.PTT ? parseFloat(dt.PTT) : 0;
			currentMarketInfos.ptTotalValue = dt.PTV ? parseFloat(dt.PTV) : 0;

		}
		return currentMarketInfos
	} catch (error) {
		return currentMarketInfos;
	};
}
function reConvertIndexEvent(dt) {
	let index = {}
	if (dt) {
		index.ADV = dt.advance;
		index.ICH = dt.changedIndex;
		index.DE = dt.decline;
		index.MC = dt.floorCode;
		index.IC = dt.indexColor;
		index.MI = dt.marketIndex;
		index.NC = dt.noChange;
		index.IPC = dt.percentIndex;
		index.PPMI = dt.priorMarketIndex;
		index.TV = dt.totalShareTraded;
		index.TV = dt.totalStock;
		index.TVA = dt.totalTrade;
		index.TVA = dt.totalValueTraded;
		index.TD = dt.tradingDate;
		index.IT = dt.tradingTime;
		index.MS = dt.status;
		index.NOC = dt.numberOfCe;
		index.NOF = dt.numberOfFl;
		index.MI = dt.openMarketIndex;
		index.OTV = dt.oddLotTotalVolume;
		index.OTVA = dt.oddLotTotalValue;
		index.PTV = dt.ptTotalValue;
		index.PTT = dt.ptTotalVolumn;
		index.ts = dt.ts;
	}
	return index
}
function GET_UPDATE_MARKET_HISTORY(currentMarketHistories, data) {
	try {
		for (let key in data) {
			if (!data[key]) return;
			let dt = data[key];
			if (!dt.MC || !currentMarketHistories || !currentMarketHistories[dt.MC]) return;
			check = currentMarketHistories ? currentMarketHistories[dt.MC] ? currentMarketHistories[dt.MC].formattedtime ? currentMarketHistories[dt.MC].formattedtime.findIndex(element => {
				return element.substring(0, 5) === dt.IT.substring(0, 5)
			}) : -1 : -1 : -1;
			if (check >= 0) {
				currentMarketHistories[dt.MC].volume[check] = parseFloat(dt.TV);
				currentMarketHistories[dt.MC].reference[check] = parseFloat(dt.MI);
				currentMarketHistories[dt.MC].close[check] = parseFloat(dt.MI);
			}
			else {
				currentMarketHistories[dt.MC].formattedtime.push(dt.IT.substring(0, 5) + ":00");
				currentMarketHistories[dt.MC].volume.push(parseFloat(dt.TV));
				currentMarketHistories[dt.MC].reference.push(parseFloat(dt.MI));
				currentMarketHistories[dt.MC].close.push(parseFloat(dt.MI));
			}
		}
		return currentMarketHistories;
	} catch (error) {
		return currentMarketHistories;
	};
}
const marketinfoReducer = (state = initialState, action) => {
	// export default function (state = initialState, action) {
	if (initialState && initialState.marketInfos && Object.keys(initialState.marketInfos).length == 0) {
		init_marketInfos()
		state = initialState
	}
	if (initialState && initialState.marketHistories && Object.keys(initialState.marketHistories).length == 0) {
		init_marketHistories()
		state = initialState
	}
	check = -1;
	let data = {};
	let currentMarketHistories = { ...state.marketHistories };
	let currentMarketInfos = { ...state.marketInfos }
	switch (action.type) {
		case types.ON_INIT_DAY: {
			// //console.log("Haki8899.:ON_INIT_DAY", action)
			init_marketInfos()
			init_marketHistories()
			state = {
				...initialState
			}
			return state;
		}
		case types.LOAD_MARKET_INFO_SUCCESS:
			// //console.log("Haki8899.:LOAD_MARKET_INFO_SUCCESS", action)
			if (Object.keys(action.marketInfos).length === 0) {
				return {
					...state,
					IS_LOAD_MARKET_INFO_SUCCESS: true
				}
			}
			currentMarketInfos = action.marketInfos;
			return {
				...state,
				// marketInfos: currentMarketInfos,
				marketInfos: { ...state.marketInfos, ...currentMarketInfos },
				IS_LOAD_MARKET_INFO_SUCCESS: true,
			}

		case types.LOAD_MARKET_INDEX_SUCCESS:
			// //console.log("Haki8899.:LOAD_MARKET_INDEX_SUCCESS", action)
			currentMarketHistories = action.marketHistories;
			currentMarketInfos = action.marketInfos;
			if (!currentMarketHistories) {
				currentMarketHistories = {};
			}
			if (!currentMarketInfos) {
				currentMarketInfos = {};
			}
			// //console.log("Haki8899.:LOAD_MARKET_INDEX_SUCCESS 2", state.marketHistories.UPCOM.formattedtime, currentMarketHistories, { ...state.marketHistories, ...currentMarketHistories })

			return {
				...state,
				marketHistories: { ...state.marketHistories, ...currentMarketHistories },
				marketInfos: { ...state.marketInfos, ...currentMarketInfos },
				IS_LOAD_MARKET_INDEX_SUCCESS: true,
			};
		case types.SET_LOAD_INDEX_STATUS:
			return {
				...state,
				IS_LOAD_MARKET_INDEX_SUCCESS: action.isLoaded,
			};
		case types.LOAD_MARKET_HISTORY_SUCCESS:
			// //console.log("Haki8899.:LOAD_MARKET_HISTORY_SUCCESS", action)
			currentMarketHistories = action.marketHistories
			return {
				...state,
				marketHistories: { ...state.marketHistories, ...currentMarketHistories },
				IS_LOAD_MARKET_HISTORY_SUCCESS: true,
			};
		case types.UPDATE_MARKET_INFOS: {
			// //console.log("Haki8899.:UPDATE_MARKET_INFOS", action)
			// //console.log('Haki123.:UPDATE_MARKET_INFOS', state, state.IS_LOAD_MARKET_INFO_SUCCESS, state.IS_LOAD_MARKET_HISTORY_SUCCESS, action.data)
			if (state.IS_LOAD_MARKET_INDEX_SUCCESS == true) {
				// if (state.IS_LOAD_MARKET_INFO_SUCCESS == true && state.IS_LOAD_MARKET_HISTORY_SUCCESS == true) {
				let currentMarketInfos = state.marketInfos; //object
				// const timestamp = action.timestamp
				let newData = []
				if (action.data) {
					for (const index of action.data) {
						let oldIndex = { ...currentMarketInfos[index.MC] };
						// if (!oldIndex || !oldIndex.ts || oldIndex.ts <= timestamp) newData.push({ ...reConvertIndexEvent(oldIndex), ...index, ...{ ts: timestamp } })
						newData.push({ ...reConvertIndexEvent(oldIndex), ...index })
						state.marketInfos[index.MC] = GET_UPDATE_MARKET_INFOS_BY_MC(state.marketInfos[index.MC], newData)
						let marketInfo = { ...state.marketInfos[index.MC] };
						if (marketInfo) {
							let timer = setTimeout(function () {
								emitter.emit(
									Config.Event.UPDATE_LATEST_MARKET_INFO + marketInfo.floorCode,
									marketInfo
								);

								emitter.emit(
									Config.Event.UPDATE_LATEST_MARKET_INFO_MARKET_DETAIL + marketInfo.floorCode,
									marketInfo
								);
								marketInfo = null
								clearTimeout(timer)
							}, 0)
						}
					}
				}
				return {
					...state,
					marketInfos: currentMarketInfos, //object
				}
			}
			else {
			}
			return state;
		}
		// case types.UPDATE_MARKET_HISTORY:
		// 	if (state.IS_LOAD_MARKET_INFO_SUCCESS == true && state.IS_LOAD_MARKET_HISTORY_SUCCESS == true) {
		// 		data = action.data
		// 		if (data) {
		// 			if (Object.keys(data).length === 0) return state;
		// 			currentMarketHistories = GET_UPDATE_MARKET_HISTORY(currentMarketHistories, data)
		// 		}
		// 		return {
		// 			...state,
		// 			marketHistories: currentMarketHistories,
		// 			// marketInfos: { ...state.currentMarketHistories, ...currentMarketHistories }
		// 		}
		// 	}
		// 	else {
		// 		//console.log('marketinfo.reducer.js.:UPDATE_MARKET_HISTORY.:Not update', action)
		// 	}
		// 	return state;
		case types.SET_MARKET_DETAIL_DATA:
			return {
				...state,
				currentMarketDetail: action.marketInfo,
			}
		case types.SET_IS_DETAIL_INDEX:
			return {
				...state,
				isDetailIndex: action.isDetailIndex,
			}
		default:
			return state;
	}
}

export default marketinfoReducer;