import Bowser from "bowser";
import { isMobile, isSafari, isTablet } from "react-device-detect";

import Util from '../../modules/util.js';
import { setValueToLocalStorage } from "../../utils";
import { orderActions, reconfirmTypes } from '../../utils/constants';
import { LoadStatus, WIDGET_WIDTH } from "../../utils/constants";
import { keyFactory } from "../../utils/LocalStorageFactory";
import actionTypes from '../actions/actionTypes';
let availableLanguages = ['en', 'vi', 'kr', 'jp'];

const isLanguageAvailable = (language) => {
    return availableLanguages.includes(language);
};

const browser = {
    chrome: { name: "Chrome", minVersionWeb: 76, minVersionMobile: 76 },
    ie: { name: "Microsoft Edge", minVersionWeb: 80, minVersionMobile: 43 },
    firefox: { name: "Firefox", minVersionWeb: 70, minVersionMobile: 70 },
    safari: { name: "Safari", minVersionWeb: 12.1, minVersionMobile: 12.1 }
}

function checkDesktop() {
    let isDeskTop = window.innerWidth >= WIDGET_WIDTH.DESKTOP.min && window.innerWidth != 0
    return isDeskTop
}

function checkVersion() {
    let bowser = Bowser.getParser(window.navigator.userAgent).getBrowser();
    if (bowser && bowser.name && bowser.version) {
        let listBrowserName = Object.keys(browser);
        for (let i = 0; i < listBrowserName.length; i++) {
            if (browser[listBrowserName[i]].name === bowser.name) {
                let minVersion = (isMobile || isTablet) ? browser[listBrowserName[i]].minVersionMobile : browser[listBrowserName[i]].minVersionWeb;
                let currentVersion = parseInt(bowser.version, 10);
                if (isSafari) {
                    currentVersion = parseFloat(bowser.version).toFixed(2);
                }
                if (currentVersion < minVersion) {
                    return {
                        isDefaultBrowser: true,
                        isisOpenBrowerModal: true
                    };
                }
                else {
                    return {
                        isDefaultBrowser: true,
                        isisOpenBrowerModal: false
                    };
                }
            }
        }
        return {
            isDefaultBrowser: false,
            isisOpenBrowerModal: true
        };
    }
    else
        return {
            isDefaultBrowser: true,
            isisOpenBrowerModal: false
        };
}

const initialOrderInputState = {
    action: orderActions.DEFAULT,
    orderType: 'limit',
    limitPrice: null,
    quotePriceValid: false,
    stopPrice: null,
    stopPriceValid: false,
    stepPriceValid: false,
    quantity: null,
    quantityValid: false,
    subOrderType: '',
    editingOrder: null
};

const initialOrderInputGroup = {
    action: orderActions.DEFAULT,
    orderType: 'limit',
    limitPrice: null,
    stopPrice: null,
    stepPriceValid: false,
    quantity: null,
    quantityValid: false,
    subOrderType: '',
    editingOrder: null
};

const initConfirmModal = {
    isOpen: false,
    messageId: "",
    acceptHandle: null,
    cbProps: {}
}



const initialState = {
    tradeResponseStatus: null,
    started: false,
    language: 'vi',
    orderGroup: 0,
    tradeCurrentSymbol: null,
    tradeCurrentSymbolConditionOrder: null, //symbol lệnh điều kiện
    editTypeTab: 0,
    tradeOrderInput: {
        ...initialOrderInputState
    },
    tradeOrderInputMsg: null,
    tradeOrderInputGroup: {
        ...initialOrderInputGroup
    },
    confirmModalProps: {
        ...initConfirmModal
    },
    tradeAuthenticated: false, // status isVerify in user info. khi lưu xác thực server trả về isVerify = true
    showReconfirmOrderConfig: true, // hiện ẩn nút tích lưu xác thực


    currentReconfirmOrderType: reconfirmTypes.NORMAL_ORDER,
    onTradeAuthenticatedCallback: null,
    isTradeAuthenticateModalOpen: false,
    tradeAuthShowConfirm: true, // dat.nt: false nếu loading trên modal xác nhận lệnh (đợi response của precheck đặt lệnh)
    tradeAdvancedOrderInput: false,
    isTradeLayoutSettingModalOpen: false,
    isChangePinPassModalOpen: false,
    isShowChangeCoreModal: false,
    resetPinPassType: null, // Loại nhập lại khi mới đăng nhập (Nhập lại cả pin pass hoặc pin hoặc pass riêng biệt)
    isSymbolDetailModalOpen: false,
    isSymbolDetailPriceChartShowFull: false,

    isAccountRouteVisible: false,
    isAccountSidebarMobileVisible: false,
    isSettingsRouteVisible: false,
    isSettingsSidebarMobileVisible: false,

    socketConnectFirstTime: true,
    socketMarketConnectFirstTime: true,
    // Posible value is defined in constains.js
    socketStatus: '',
    socketMarketStatus: '',
    socketCIActionCounter: 1,
    socketSEActionCounter: 1,
    socketODActionCounter: 1,
    socketCAActionCounter: 1,
    socketPActionCounter: 1,

    custSocketODActionCounter: 1, //event OD cho theo custid

    accountNotifyList: [],
    agreementOrderNotifyList: [],
    analyticNotifyList: [],
    accountNotifyCounter: 1,

    //huy.quang: du lieu export excel tren man hinh
    excelFilename: null,
    excelData: null,

    //huy.quang: an hien menu side bar tab account & setting
    visibleSidebar: true,

    //huy.quang: luu path cuoi cung cua tab account
    accountMenuPath: '/account/balance',
    //huy.quang: luu path cuoi cung cua tab setting
    settingMenuPath: '/settings/personal-info',
    //huy.quang: luu trang thai popup dat lenh
    showOrderPopup: false,
    isOpenBrowerModal: checkVersion().isisOpenBrowerModal,
    isDefaultBrowser: checkVersion().isDefaultBrowser,
    isDeskTop: checkDesktop(),
    isClearSymbolSuggestion: false,

    //securities
    securities: {},
    securitiesLoadStatus: LoadStatus.NONE,
    //dat.nt: Luu tieu de nhom va tin moi nhat trong nhom de cho vao dong chay
    dataForFooter: [],

    //dat.nt: Luu mã cần lấy thông tin trên modal chi tiết mã chứng khoán (AAA, ACB,...)
    symbolDetail: '',

    //Nhận tín hiệu thay đổi gio dịch thỏa thuận
    socketPTMatchedActionCounter: 1, //khớp lệnh
    socketPUTThroughActionCounter: 1,//chào mua chào bán
    ptmatchs: null,
    putThroughOrders: [],
    //trình chiếu mã CK
    isAutoScroll: false,
    // flag check expired
    loggedOutByExpired: false,
    //tabactive wiget
    tabActive: null,

    //toogle drap and drop sổ lệnh tra cứu môi giới
    isSettingTableDrapDropLookUpOrderNewModalOpen: false,
    currentDate: null,
    contactInfos: null,
    config: null,
    isCheckSymbolDataFeed: false,
    isCheckClickPromotion: false, // Kiểm tra xem trong phiên đăng nhập khách hàng click khuyến mãi chưa. =false chưa click, = true : click và xóa animation
    isActiveTabBrowser: true,

    analyticAction: 0
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.IS_CHECK_SYMBOL_DATA_FEED:
            return {
                ...state,
                isCheckSymbolDataFeed: action.data
            };
        case actionTypes.CHANGE_CLICK_PROMOTION:
            return {
                ...state,
                isCheckClickPromotion: action.data
            };
        case actionTypes.SET_CURRENT_DATE:
            return {
                ...state,
                currentDate: action.currentDate
            };
        case actionTypes.SET_CLIENT_CONFIG:
            return {
                ...state,
                config: action.config
            };
        case actionTypes.APP_STARTUP_COMPLETE:
            return {
                ...state,
                started: true
            };
        case actionTypes.UPDATE_ACCOUNT_NOTIFY_COUNTER:
            return {
                ...state,
                accountNotifyCounter: state.accountNotifyCounter + 1
            };
        case actionTypes.UPDATE_ACCOUNT_NOTIFY_LIST:
            return {
                ...state,
                accountNotifyList: action.accountNotifyList
            };
        case actionTypes.UPDATE_AGREEMENT_ORDER_NOTIFY_LIST:
            return {
                ...state,
                agreementOrderNotifyList: action.agreementOrderNotifyList
            };

        case actionTypes.CONFIRM_ORDERS_INFOR:
            var prev_confirm_orders_infor = state.confirm_orders_infor || {}
            var confirm_orders_infor = prev_confirm_orders_infor
            if (action.confirm_orders_infor && action.confirm_orders_infor.length > 0) {
                confirm_orders_infor[action.accountId] = action.confirm_orders_infor[0]
            } else {
                delete confirm_orders_infor[action.accountId]
            }

            return {
                ...state,
                confirm_orders_infor: { ...confirm_orders_infor }
            };
        case actionTypes.DELETE_CURRENT_CONFIRM_ORDERS_INFO:
            return {
                ...state,
                confirm_orders_infor: {}
            }
        case actionTypes.UPDATE_ANALYTIC_NOTIFY_LIST:
            return {
                ...state,
                analyticNotifyList: action.analyticNotifyList
            };
        case actionTypes.CHANGE_LANGUAGE:
            const language = action.language;

            if (isLanguageAvailable(language)) {
                setValueToLocalStorage(keyFactory.language, language);
                return {
                    ...state,
                    language: language
                };
            };
            //console.log(language + ' is not available');
            return state;
        case actionTypes.LOGOUT_BY_OTHER:
            return {
                ...state,
                loggedOut: true,
                config: state.config, // giữ clientConfig khi logout
                socketMarketStatus: state.socketMarketStatus // giữ trạng thái socket khi logout
            };

        case actionTypes.SET_LOGOUT_BY_OTHER:
            return {
                ...state,
                loggedOut: action.isLogout
            };

        case actionTypes.LOGOUT_BY_SESSION_EXPIRED:
            return {
                ...state,
                loggedOutByExpired: true,
                config: state.config, // giữ clientConfig khi logout
                socketMarketStatus: state.socketMarketStatus // giữ trạng thái socket khi logout
            };

        case actionTypes.SET_SHOW_ORDER_POPUP:
            return {
                ...state,
                showOrderPopup: action.showOrderPopup
            }
        case actionTypes.SET_ACCOUNT_PATH:
            return {
                ...state,
                accountMenuPath: action.path
            };
        case actionTypes.SET_SETTING_PATH:
            return {
                ...state,
                settingMenuPath: action.path
            };
        case actionTypes.TOGGLE_MENU_SIDEBAR:
            return {
                ...state,
                visibleSidebar: !state.visibleSidebar
            };
        case actionTypes.SET_SHOW_AUTO_SCROLL_PRICE_BOARD:
            return {
                ...state,
                isAutoScroll: !state.isAutoScroll,
            };
        case actionTypes.SET_TAB_ACTIVE_WIGET:
            return {
                ...state,
                tabActive: action.tabActive,
            };
        case actionTypes.CHANGE_SHOW_RECONFIRM_ORDER:
            const showReconfirmOrderConfig = action.showReconfirmOrderConfig;
            return {
                ...state,
                showReconfirmOrderConfig: showReconfirmOrderConfig,
            };
        case actionTypes.SET_CURRENT_RECONFIRM_ORDER_TYPE:
            return {
                ...state,
                currentReconfirmOrderType: action.currentReconfirmOrderType
            };
        case actionTypes.SET_PROP_FOR_TradeAuth_COMPONENT:
            var newstate = { ...state };
            newstate.reconfirm_order_component_props = { ...newstate.reconfirm_order_component_props, funcRenderOrderConfirmView: action.funcRenderOrderConfirmView };
            return newstate;
        case actionTypes.FETCH_SAVE_VERIFY_INFO_SUCCESS:
            return {
                ...state,
                tradeAuthenticated: action.data.tradeAuthenticated
            };
        case actionTypes.ON_CHANGE_FUNCTION_NEED_TWOFACTORAUTH_STATUS:
            return {
                ...state,
                tradeResponseStatus: action.data.responseApiStatus
            };
        case actionTypes.SET_TRADE_CURRENT_SYMBOL:
            return {
                ...state,
                editTypeTab: action.editTypeTab,
                tradeCurrentSymbol: action.symbol,
                tradeCurrentSymbolConditionOrder: action.symbolConditionOrder ? action.symbolConditionOrder : null
            };
        case actionTypes.SET_TRADE_CURRENT_ORDER_GROUP:
            return {
                ...state,
                orderGroup: action.orderGroup
            };
        case actionTypes.SET_TRADE_ORDER_INPUT:
            return {
                ...state,
                tradeOrderInput: {
                    ...state.tradeOrderInput,
                    ...action.orderInput,
                },
            };
        case actionTypes.SET_TRADE_ORDER_INPUT_MSG:
            return {
                ...state,
                tradeOrderInputMsg: action.msg
            };
        case actionTypes.SET_TRADE_ORDER_INPUT_GROUP:
            return {
                ...state,
                tradeOrderInputGroup: {
                    ...state.tradeOrderInputGroup,
                    ...action.orderInput
                }
            };
        case actionTypes.CLEAR_TRADE_ORDER_INPUT:
            let { action: orderAction, ...remainInitOrderState } = initialOrderInputState;
            orderAction = action.isClearAction ? orderActions.DEFAULT : state.tradeOrderInput.action;
            return {
                ...state,
                tradeOrderInput: {
                    action: orderAction,
                    ...remainInitOrderState,
                }
            };
        case actionTypes.CLEAR_EDITTING_ORDER_INPUT:
            return {
                ...state,
                tradeOrderInput: {
                    ...state.tradeOrderInput,
                    editingOrder: null
                }
            };
        case actionTypes.CLEAR_TRADE_ORDER_INPUT_GROUP:
            let { action: orderGroupAction, ...remainInitOrderGroupState } = initialOrderInputGroup;
            orderGroupAction = action.isClearAction ? orderActions.DEFAULT : state.tradeOrderInputGroup.action;
            return {
                ...state,
                tradeOrderInputGroup: {
                    action: orderGroupAction,
                    ...remainInitOrderGroupState,
                }
            };
        case actionTypes.SET_TRADE_AUTH_SUCCESS:
            return {
                ...state,
                tradeAuthenticated: true
            };
        case actionTypes.CLEAR_TRADE_AUTH:
            return {
                ...state,
                tradeAuthenticated: false
            };
        case actionTypes.TOOGLE_TRADE_AUTH_MODAL:
            return {
                ...state,
                isTradeAuthenticateModalOpen: !state.isTradeAuthenticateModalOpen,
                onTradeAuthenticatedCallback: action.callback
            };
        case actionTypes.ON_CLOSE_TRADE_AUTH_MODAL:
            return {
                ...state,
                isTradeAuthenticateModalOpen: false,
                onTradeAuthenticatedCallback: action.callback
            };
        case actionTypes.SET_SYMBOL_DETAIL_OPEN_STATE:
            return {
                ...state,
                isSymbolDetailModalOpen: action.isOpen,
                isSymbolDetailPriceChartShowFull: false
            };
        case actionTypes.SET_SYMBOL_DETAIL_PRICE_CHART_SHOW_FULL:
            return {
                ...state,
                isSymbolDetailPriceChartShowFull: action.isOpen
            };
        case actionTypes.TOOGLE_TRADE_ADVANCED_ORDER_INPUT:
            return {
                ...state,
                tradeAdvancedOrderInput: !state.tradeAdvancedOrderInput
            };
        case actionTypes.TOOGLE_TRADE_LAYOUT_SETTING_MODAL:
            return {
                ...state,
                isTradeLayoutSettingModalOpen: !state.isTradeLayoutSettingModalOpen
            };
        case actionTypes.TOOGLE_CHANGE_PIN_PASS_MODAL:
            return {
                ...state,
                isChangePinPassModalOpen: true,
                resetPinPassType: action.resetType
            };
        case actionTypes.SET_STATE_PIN_PASS_MODAL:
            return {
                ...state,
                isChangePinPassModalOpen: action.flag,
            };
        case actionTypes.TOGGLE_MODAL_CHANGE_CORE:
            return {
                ...state,
                isShowChangeCoreModal: !state.isShowChangeCoreModal,
            };
        case actionTypes.SET_IS_ACCOUNT_ROUTE_VISIBLE:
            return {
                ...state,
                isAccountRouteVisible: action.isVisible
            };
        case actionTypes.SET_ACCOUNT_SIDEBAR_MOBILE_VISIBLE:
            return {
                ...state,
                isAccountSidebarMobileVisible: action.visible
            };
        case actionTypes.SET_IS_SETTINGS_ROUTE_VISIBLE:
            return {
                ...state,
                isSettingsRouteVisible: action.isVisible
            };
        case actionTypes.SET_SETTINGS_SIDEBAR_MOBILE_VISIBLE:
            return {
                ...state,
                isSettingsSidebarMobileVisible: action.visible
            };
        // case actionTypes.SET_SOCKET_CONNECT_FIRST_TIME:
        //     return {
        //         ...state,
        //         socketConnectFirstTime: action.isFirstTime,
        //     };
        case actionTypes.SET_SOCKET_CONNECT_STATUS:
            return {
                ...state,
                socketStatus: action.socketStatus
            };
        // case actionTypes.SET_SOCKET_MARKET_CONNECT_FIRST_TIME:
        //     return {
        //         ...state,
        //         socketMarketConnectFirstTime: action.isFirstTimeMarket,
        //     };
        case actionTypes.SET_SOCKET_MARKET_CONNECT_STATUS:
            return {
                ...state,
                socketMarketStatus: action.socketMarketStatus
            };
        case actionTypes.ON_SWITCH_TRADE_LAYOUT:
            return {
                ...state,
                currentTradeLayoutId: action.tradeLayoutId
            };
        case actionTypes.ON_SWITCH_MOBILE_LAYOUT:
            return {
                ...state,
                currentMobileLayoutId: action.tradeLayoutId
            };
        // case actionTypes.ON_SOCKET_CI_ACTION:
        //     return {
        //         ...state,
        //         socketCIActionCounter: state.socketCIActionCounter + 1
        //     };
        // case actionTypes.ON_SOCKET_SE_ACTION:
        //     return {
        //         ...state,
        //         socketSEActionCounter: state.socketSEActionCounter + 1
        //     };
        case actionTypes.ON_SOCKET_OD_ACTION:
            return {
                ...state,
                socketODActionCounter: state.socketODActionCounter + 1
            };
        case actionTypes.ON_SOCKET_CA_ACTION:
            return {
                ...state,
                socketCAActionCounter: state.socketCAActionCounter + 1
            };
        case actionTypes.ON_SOCKET_P_ACTION:
            return {
                ...state,
                socketPActionCounter: state.socketPActionCounter + 1
            };
        case actionTypes.CUST_ON_SOCKET_OD_ACTION:
            return {
                ...state,
                custSocketODActionCounter: state.custSocketODActionCounter + 1
            };
        case actionTypes.SET_SCREEN_EXCEL_EXPORT_DATA:
            return {
                ...state,
                excelFilename: action.data.filename,
                excelData: action.data.data
            };
        case actionTypes.USER_LOGOUT:
            return {
                ...initialState,
                started: true,
                config: state.config, // giữ clientConfig khi logout
                socketMarketStatus: state.socketMarketStatus // giữ trạng thái socket khi logout
            };
        case actionTypes.SINGLE_USER_LOGOUT:
            return {
                ...initialState,
                started: state.started,
                config: state.config, // giữ clientConfig khi logout
                socketMarketStatus: state.socketMarketStatus // giữ trạng thái socket khi logout
            };
        case actionTypes.SET_CONFIRM_MODAL_PROP:
            return {
                ...state,
                confirmModalProps: {
                    ...state.confirmModalProps,
                    ...action.confirmModalProps
                }
            };
        /**
         * dong browser modal
         */
        case actionTypes.CLOSE_BROWSER_MODAL: {
            return {
                ...state,
                isOpenBrowerModal: false
            }
        }
        case actionTypes.CLEAR_SYMBOL_SUGGESTION: {
            return {
                ...state,
                isClearSymbolSuggestion: action.isClearSymbolSuggestion
            }
        }
        case actionTypes.SET_TRADE_AUTH_SHOW_CONFIRM:
            return {
                ...state,
                tradeAuthShowConfirm: action.isShow
            };
        //SECURITIES
        case actionTypes.FETCH_SECURITIES: {
            const account = action.accountId;
            return {
                ...state,
                securitiesLoadStatus: {
                    ...state.securitiesLoadStatus,
                    [account]: LoadStatus.LOADING
                }
            };
        }
        case actionTypes.FETCH_SECURITIES_SUCCESS: {
            const account = action.accountId;
            const data = action.data;
            if (action.msgType) {
                // xu ly khi nhan duoc msg socket
                if (action.msgType == 'CI') {
                    return {
                        ...state,
                        securities: {
                            ...state.securities,
                            [account]: data
                        },
                        securitiesLoadStatus: {
                            ...state.securitiesLoadStatus,
                            [account]: LoadStatus.LOADED
                        },
                        socketCIActionCounter: state.socketCIActionCounter + 1
                    };
                }
                else {
                    if (action.msgType == 'SE') {
                        return {
                            ...state,
                            securities: {
                                ...state.securities,
                                [account]: data
                            },
                            securitiesLoadStatus: {
                                ...state.securitiesLoadStatus,
                                [account]: LoadStatus.LOADED
                            },
                            socketSEActionCounter: state.socketSEActionCounter + 1
                        };
                    }
                }
            }
            return {
                ...state,
                securities: {
                    ...state.securities,
                    [account]: data
                },
                securitiesLoadStatus: {
                    ...state.securitiesLoadStatus,
                    [account]: LoadStatus.LOADED
                }
            };
        }
        case actionTypes.FETCH_DRAFT_ORDERS_FAIL: {
            const account = action.accountId;
            return {
                ...state,
                securitiesLoadStatus: {
                    ...state.securitiesLoadStatus,
                    [account]: LoadStatus.FAIL
                }
            };
        }

        case actionTypes.UPDATE_PUTTHROUGH_DATA: {
            let convertedPutThrough = Util.processJSON_PutThought_Update(action.putThroughs);
            return {
                ...state,
                putThroughs: convertedPutThrough,
                socketPUTThroughActionCounter: state.socketPUTThroughActionCounter + 1
            };
        }

        case actionTypes.UPDATE_PTMATCH_DATA: {
            let convertedPtMatch = Util.processJSON_PtMatch_Update(action.ptMatchs);
            return {
                ...state,
                ptmatchs: convertedPtMatch,
                socketPTMatchedActionCounter: state.socketPTMatchedActionCounter + 1
            };
        }

        case actionTypes.TOOGLE_SETTING_TABLE_DRAP_DROP_LOOK_UP_ORDER_NEW:
            return {
                ...state,
                isSettingTableDrapDropLookUpOrderNewModalOpen: !state.isSettingTableDrapDropLookUpOrderNewModalOpen
            };
        case actionTypes.SET_IS_DESKTOP:
            return {
                ...state,
                isDeskTop: action.isDeskTop
            };
        case actionTypes.SET_CONTACT_INFO:
            return {
                ...state,
                contactInfos: action.data
            };
        case actionTypes.SET_CONTACT_INFO_FALSE:
            return {
                ...state,
                contactInfos: null
            };
        case actionTypes.SET_TAB_ACTIVE_BROWSER:
            return {
                ...state,
                isActiveTabBrowser: action.isActiveTabBrowser
            };
        case actionTypes.TOAST_TO_NOTICE_WHEN_RECEIVE_MESSAGE:      
            return {
                ...state,
                analyticAction: (state.analyticAction || 0) + 1
            };
        default:
            return state;
    }
};

export default appReducer;
