import actionTypes from "store/actions/actionTypes";

/**
 * huy.quang: trigger component's function
 */

const initialState = {
};

const triggerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_WIDGET_GRID_ACTIONS:
            return {
                ...state,
                widgetGrid: action.listActionName
            };
        case actionTypes.SET_TAB_FOR_OPEN_POSSITIONS_ACTION:
            return {
                ...state,
                openPositions: action.listActionName
            };
        case actionTypes.LOAD_CONDITION_ORDER_DATA:
            return {
                ...state,
                conditionOrder: action.listActionName
            };
        default:
            return state;
    }
};

export default triggerReducer;

