const actionTypes = Object.freeze({
    // layout action
    SET_IS_OPEN_MODAL_BY_KEY: "SET_IS_OPEN_MODAL_BY_KEY",
    SET_IS_OPEN_MODAL_HAVE_DATA: "SET_IS_OPEN_MODAL_HAVE_DATA",
    SET_TYPE_SCREEN_SELECTOR_ACCOUNT: "SET_TYPE_SCREEN_SELECTOR_ACCOUNT", // Lưu lại màn hình loại nào đang active selector account
    IS_OPEN_NETWORK: "IS_OPEN_NETWORK",
    IS_OPEN_EXPIRED_TOKEN_ERROR_SCREEN: "IS_OPEN_EXPIRED_TOKEN_ERROR_SCREEN",
    IS_CHECK_SYMBOL_DATA_FEED: 'IS_CHECK_SYMBOL_DATA_FEED',
    CHANGE_CLICK_PROMOTION: 'CHANGE_CLICK_PROMOTION',
    /* App actions */
    SET_CURRENT_DATE: 'SET_CURRENT_DATE',
    APP_STARTUP_COMPLETE: 'APP_STARTUP_COMPLETE',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    CHANGE_SHOW_RECONFIRM_ORDER: 'CHANGE_SHOW_RECONFIRM_ORDER',
    SET_CURRENT_RECONFIRM_ORDER_TYPE: 'SET_CURRENT_RECONFIRM_ORDER_TYPE',
    SET_TRADE_CURRENT_SYMBOL: 'SET_TRADE_CURRENT_SYMBOL',
    SET_TRADE_CURRENT_ORDER_GROUP: 'SET_TRADE_CURRENT_ORDER_GROUP',
    SET_TRADE_ORDER_INPUT: 'SET_TRADE_ORDER_INPUT',
    SET_TRADE_ORDER_INPUT_MSG: 'SET_TRADE_ORDER_INPUT_MSG', //huy.quang: set message canh bao tren trade auth modal
    SET_TRADE_ORDER_INPUT_GROUP: 'SET_TRADE_ORDER_INPUT_GROUP',
    CLEAR_TRADE_ORDER_INPUT_GROUP: 'CLEAR_TRADE_ORDER_INPUT_GROUP',
    CLEAR_TRADE_ORDER_INPUT: 'CLEAR_TRADE_ORDER_INPUT',
    CLEAR_EDITTING_ORDER_INPUT: 'CLEAR_EDITTING_ORDER_INPUT',
    SET_TRADE_AUTH_SUCCESS: 'SET_TRADE_AUTH_SUCCESS',
    CLEAR_TRADE_AUTH: 'CLEAR_TRADE_AUTH',
    TOOGLE_TRADE_AUTH_MODAL: 'TOOGLE_TRADE_AUTH_MODAL',
    SET_SYMBOL_DETAIL_OPEN_STATE: 'SET_SYMBOL_DETAIL_OPEN_STATE',
    SET_SYMBOL_DETAIL_PRICE_CHART_SHOW_FULL: 'SET_SYMBOL_DETAIL_PRICE_CHART_SHOW_FULL',
    ON_CLOSE_TRADE_AUTH_MODAL: 'ON_CLOSE_TRADE_AUTH_MODAL',
    SET_TRADE_AUTH_SHOW_CONFIRM: 'SET_TRADE_AUTH_SHOW_CONFIRM',
    ON_CHANGE_FUNCTION_NEED_TWOFACTORAUTH_STATUS: 'ON_CHANGE_FUNCTION_NEED_TWOFACTORAUTH_STATUS',
    TOOGLE_TRADE_ADVANCED_ORDER_INPUT: 'TOOGLE_TRADE_ADVANCED_ORDER_INPUT',
    TOOGLE_TRADE_LAYOUT_SETTING_MODAL: 'TOOGLE_TRADE_LAYOUT_SETTING_MODAL',
    TOOGLE_CHANGE_PIN_PASS_MODAL: 'TOOGLE_CHANGE_PIN_PASS_MODAL',
    SET_STATE_PIN_PASS_MODAL: 'SET_STATE_PIN_PASS_MODAL',
    TOGGLE_MODAL_CHANGE_CORE: 'TOGGLE_MODAL_CHANGE_CORE',
    SET_IS_ACCOUNT_ROUTE_VISIBLE: 'SET_IS_ACCOUNT_ROUTE_VISIBLE',
    SET_ACCOUNT_SIDEBAR_MOBILE_VISIBLE: 'SET_ACCOUNT_SIDEBAR_MOBILE_VISIBLE',
    SET_IS_SETTINGS_ROUTE_VISIBLE: 'SET_IS_SETTINGS_ROUTE_VISIBLE',
    SET_SETTINGS_SIDEBAR_MOBILE_VISIBLE: 'SET_SETTINGS_SIDEBAR_MOBILE_VISIBLE',
    // SET_SOCKET_CONNECT_FIRST_TIME: 'SET_SOCKET_CONNECT_FIRST_TIME',
    SET_SOCKET_CONNECT_STATUS: 'SET_SOCKET_CONNECT_STATUS',
    // SET_SOCKET_MARKET_CONNECT_FIRST_TIME: 'SET_SOCKET_MARKET_CONNECT_FIRST_TIME',
    SET_SOCKET_MARKET_CONNECT_STATUS: 'SET_SOCKET_MARKET_CONNECT_STATUS',
    ON_SOCKET_CI_ACTION: 'ON_SOCKET_CI_ACTION',
    ON_SOCKET_SE_ACTION: 'ON_SOCKET_SE_ACTION',
    ON_SOCKET_OD_ACTION: 'ON_SOCKET_OD_ACTION',
    ON_SOCKET_CA_ACTION: 'ON_SOCKET_CA_ACTION',
    ON_SOCKET_P_ACTION: 'ON_SOCKET_P_ACTION',
    ON_SOCKET_PP_ACTION: 'ON_SOCKET_PP_ACTION',
    ON_SOCKET_DO_ACTION: 'ON_SOCKET_DO_ACTION',
    ON_SOCKET_DT_ACTION: 'ON_SOCKET_DT_ACTION',
    ON_SOCKET_COD_ACTION: 'ON_SOCKET_COD_ACTION',
    CUST_ON_SOCKET_OD_ACTION: 'CUST_ON_SOCKET_OD_ACTION',
    ON_INIT_DAY: 'ON_INIT_DAY',
    UPDATE_ACCOUNT_NOTIFY_LIST: 'UPDATE_ACCOUNT_NOTIFY_LIST',
    UPDATE_ACCOUNT_NOTIFY_COUNTER: 'UPDATE_ACCOUNT_NOTIFY_COUNTER',
    CONFIRM_ORDERS_INFOR: 'CONFIRM_ORDERS_INFOR',
    UPDATE_ANALYTIC_NOTIFY_LIST: 'UPDATE_ANALYTIC_NOTIFY_LIST',
    TOAST_TO_NOTICE_WHEN_RECEIVE_MESSAGE: 'TOAST_TO_NOTICE_WHEN_RECEIVE_MESSAGE',
    DELETE_CURRENT_CONFIRM_ORDERS_INFO: 'DELETE_CURRENT_CONFIRM_ORDERS_INFO',

    ON_SWITCH_TRADE_LAYOUT: 'ON_SWITCH_TRADE_LAYOUT',
    SET_PROP_FOR_TradeAuth_COMPONENT: 'SET_PROP_FOR_TradeAuth_COMPONENT',
    ON_SWITCH_MOBILE_LAYOUT: 'ON_SWITCH_MOBILE_LAYOUT',

    SET_SHOW_ORDER_POPUP: 'SET_SHOW_ORDER_POPUP',

    SET_CONFIRM_MODAL_PROP: 'SET_CONFIRM_MODAL_PROP',

    SET_CONTACT_INFO: 'SET_CONTACT_INFO',
    SET_CONTACT_INFO_FALSE: 'SET_CONTACT_INFO_FALSE',
    SET_CLIENT_CONFIG: 'SET_CLIENT_CONFIG',

    //huy.quang: set du lieu cho export tren man hinh
    SET_SCREEN_EXCEL_EXPORT_DATA: 'SET_SCREEN_EXPORT_DATA',

    //huy.quang: nhan event dang nhap tu noi khac
    LOGOUT_BY_OTHER: 'LOGOUT_BY_OTHER',
    SET_LOGOUT_BY_OTHER: 'SET_LOGOUT_BY_OTHER',

    // session expired
    LOGOUT_BY_SESSION_EXPIRED: 'LOGOUT_BY_SESSION_EXPIRED',

    //new layout
    LOAD_INIT_LAYOUT_DEFAULT: 'LOAD_INIT_LAYOUT_DEFAULT',
    ADD_LAYOUT_PAGE: 'ADD_LAYOUT_PAGE',
    UPDATED_LAYOUT_PAGE: 'UPDATED_LAYOUT_PAGE',
    DELETE_LAYOUT_PAGE: 'DELETE_LAYOUT_PAGE',
    CHANGE_LAYOUT_PAGE_ACTIVE: 'CHANGE_LAYOUT_PAGE_ACTIVE',
    IS_OPEN_SCREEN_MODAL: 'IS_OPEN_SCREEN_MODAL',
    IS_OPEN_TRADE_MONEY_BIDV: 'IS_OPEN_TRADE_MONEY_BIDV',
    IS_OPEN_PAYMENT: "IS_OPEN_PAYMENT",
    SET_OPENING_KEYNAME: "SET_OPENING_KEYNAME",
    CLEAR_OPENNING_INFO: "CLEAR_OPENNING_INFO",

    //
    ADD_SCREEN_MODAL: 'ADD_SCREEN_MODAL',
    UPDATED_SCREEN_MODAL: 'UPDATED_SCREEN_MODAL',
    DELETE_SCREEN_MODAL: 'DELETE_SCREEN_MODAL',

    // layoutsCustom
    LOAD_LAYOUTS_CUSTOM: 'LOAD_LAYOUTS_CUSTOM',
    UPDATED_LAYOUTS_CUSTOM: 'UPDATED_LAYOUTS_CUSTOM',
    UPDATE_LAYOUTS_CUSTOM_DATA: 'UPDATE_LAYOUTS_CUSTOM_DATA',
    DELETE_LAYOUTS_CUSTOM: 'DELETE_LAYOUTS_CUSTOM',

    // menu
    UPDATE_MENU_ACTIVE: 'UPDATE_MENU_ACTIVE',


    /* User actions */
    USER_LOGIN_START: 'USER_LOGIN_START',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAIL: 'USER_LOGIN_FAIL',
    AUTHORIZATION_SUCCESS: 'AUTHORIZATION_SUCCESS',
    FETCH_USER_INFO_SUCCESS: 'FETCH_USER_INFO_SUCCESS',
    FETCH_SAVE_VERIFY_INFO_SUCCESS: 'FETCH_SAVE_VERIFY_INFO_SUCCESS',
    REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',
    USER_LOGOUT: 'USER_LOGOUT',
    SINGLE_USER_LOGOUT: 'SINGLE_USER_LOGOUT',
    FETCH_USER_DEFAULT_CONFIG: 'FETCH_USER_DEFAULT_CONFIG',
    FETCH_USER_PRICEBOARD_CONFIG: 'FETCH_USER_PRICEBOARD_CONFIG',
    SAVE_USER_PRICEBOARD_CONFIG: 'SAVE_USER_PRICEBOARD_CONFIG',
    CHANGE_DEFAULT_THEME: 'CHANGE_DEFAULT_THEME',
    FETCH_USER_PERMISSION_INFO: 'FETCH_USER_PERMISSION_INFO',
    FETCH_USER_PERMISSION_MODULE_INFO: 'FETCH_USER_PERMISSION_MODULE_INFO',
    UPDATE_TOKEN_STORE: 'UPDATE_TOKEN_STORE',
    SET_CUSTOMER_FULL_NAME: 'SET_CUSTOMER_FULL_NAME',
    SET_ACTION_METHOD_CONDITION: 'SET_ACTION_METHOD_CONDITION',
    SET_LOGIN_POPUP_ON_START: 'SET_LOGIN_POPUP_ON_START',
    SET_SAVED_CUSTODYCD: 'SET_SAVED_CUSTODYCD',

    /* Account actions */
    FETCH_ACCOUNTS_SUCCESS: 'FETCH_ACCOUNTS_SUCCESS',
    SET_CURRENT_ACCOUNT: 'SET_CURRENT_ACCOUNT',
    SET_DEFAULT_ACCOUNT_ID: 'SET_DEFAULT_ACCOUNT_ID',

    /* Symbol actions */
    FETCH_SYMBOL_LIST: 'FETCH_SYMBOL_LIST',
    FETCH_SYMBOL_LIST_SUCCESS: 'FETCH_SYMBOL_LIST_SUCCESS',
    FETCH_SYMBOL_LIST_FAIL: 'FETCH_SYMBOL_LIST_FAIL',
    FETCH_FAV_SYMBOLS: 'FETCH_FAV_SYMBOLS',
    FETCH_FAV_SYMBOLS_SUCCESS: 'FETCH_FAV_SYMBOLS_SUCCESS',
    FETCH_FAV_SYMBOLS_FAIL: 'FETCH_FAV_SYMBOLS_FAIL',
    ADD_FAV_SYMBOL: 'ADD_FAV_SYMBOL',
    ADD_FAV_SYMBOL_FAIL: 'ADD_FAV_SYMBOL_FAIL',
    REMOVE_FAV_SYMBOL: 'REMOVE_FAV_SYMBOL',
    REMOVE_FAV_SYMBOL_FAIL: 'REMOVE_FAV_SYMBOL_FAIL',
    FETCH_WATCH_LIST: 'FETCH_WATCH_LIST',
    FETCH_WATCH_LIST_SUCCESS: 'FETCH_WATCH_LIST_SUCCESS',
    FETCH_WATCH_LIST_FAIL: 'FETCH_WATCH_LIST_FAIL',
    REMOVE_WATCH_LIST: 'REMOVE_WATCH_LIST',
    UPDATE_WATCH_LIST: 'UPDATE_WATCH_LIST',
    FETCH_SYMBOL_QUOTE: 'FETCH_SYMBOL_QUOTE',
    FETCH_SYMBOL_QUOTE_SUCCESS: 'FETCH_SYMBOL_QUOTE_SUCCESS',
    FETCH_SYMBOL_QUOTE_FAIL: 'FETCH_SYMBOL_QUOTE_FAIL',
    FETCH_SYMBOL_ORDERBOOK: 'FETCH_SYMBOL_ORDERBOOK',
    FETCH_SYMBOL_ORDERBOOK_SUCCESS: 'FETCH_SYMBOL_ORDERBOOK_SUCCESS',
    FETCH_SYMBOL_ORDERBOOK_FAIL: 'FETCH_SYMBOL_ORDERBOOK_FAIL',
    //
    UPDATE_INSTRUMENTSEQ: 'UPDATE_INSTRUMENTSEQ',
    UPDATE_INSTRUMENT_DATA: 'UPDATE_INSTRUMENT_DATA',
    //
    UPDATE_ODDLOT_INSTRUMENTSEQ: 'UPDATE_ODDLOT_INSTRUMENTSEQ',
    UPDATE_ODDLOT_INSTRUMENT_DATA: 'UPDATE_ODDLOT_INSTRUMENT_DATA',
    //
    UPDATE_CORPBOND_DATA: 'UPDATE_CORPBOND_DATA',
    SET_ORDERBOOK_DATA: 'SET_ORDERBOOK_DATA',
    UPDATE_ORDERBOOK_DATA: 'UPDATE_ORDERBOOK_DATA',
    INSERT_ORDERBOOK_DATA: 'INSERT_ORDERBOOK_DATA',
    DELETE_ORDERBOOK_DATA: 'DELETE_ORDERBOOK_DATA',
    SET_TRADE_DATA: 'SET_TRADE_DATA',
    INSERT_TRADE_DATA: 'INSERT_TRADE_DATA',
    SET_INDEXS_DATA: 'SET_INDEXS_DATA',
    UPDATE_INDEXS_DATA: 'UPDATE_INDEXS_DATA',
    SET_SYMBOL_DATA_BY_SUGGEST: 'SET_SYMBOL_DATA_BY_SUGGEST',
    FETCH_FIXED_LIST: 'FETCH_FIXED_LIST',
    FETCH_FIXED_LIST_SUCCESS: 'FETCH_FIXED_LIST_SUCCESS',
    FETCH_DERIVATIVE_LIST_SUCCESS: 'FETCH_DERIVATIVE_LIST_SUCCESS',
    FETCH_CORPBOND_LIST_SUCCESS: 'FETCH_CORPBOND_LIST_SUCCESS',
    FETCH_CORPBOND_LIST_FAIL: 'FETCH_CORPBOND_LIST_FAIL',
    FETCHING_ODDLOT_LIST: 'FETCHING_ODDLOT_LIST',
    SET_TOP_INSTRUMENT_LIST: 'SET_TOP_INSTRUMENT_LIST',
    FETCH__TOP_INSTRUMENT_LIST_FAIL: 'FETCH_TOP_INSTRUMENT_LIST_FAIL',
    SET_INDEX_LIST: 'SET_INDEX_LIST',
    SET_RECOMMEND_LIST: 'SET_RECOMMEND_LIST',
    SET_RECOMMEND_LIST_FALSE: 'SET_RECOMMEND_LIST_FALSE',
    SET_INDEX_LIST_FALSE: 'SET_INDEX_LIST_FALSE',
    FETCH_INDUSTRY_LIST: 'SET_INDUSTRY_LIST',
    FETCH_INDUSTRY_LIST_FAIL: 'FETCH_INDUSTRY_LIST_FAIL',
    FETCH_FIXED_LIST_FAIL: 'FETCH_FIXED_LIST_FAIL',
    FETCH_DERIVATIVE_LIST_FAIL: 'FETCH_DERIVATIVE_LIST_FAIL',
    FETCH_SYMBOL_LIST_MAPPING: 'FETCH_SYMBOL_LIST_MAPPING',
    FETCH_SYMBOL_LIST_MAPPING_BEGIN: 'FETCH_SYMBOL_LIST_MAPPING_BEGIN',
    FETCH_SYMBOL_LIST_MAPPING_FAIL: 'FETCH_SYMBOL_LIST_MAPPING_FAIL',
    FETCH_ALL_QUOTE: 'FETCH_ALL_QUOTE',
    FETCH_ALL_QUOTE_BEGIN: 'FETCH_ALL_QUOTE_BEGIN',
    FETCH_ALL_QUOTE_FAIL: 'FETCH_ALL_QUOTE_FAIL',
    UPDATE_LIST_SYMBOLS_W: 'UPDATE_LIST_SYMBOLS_W', // Haki
    /* Customer actions */
    SET_CURRENT_CUSTOMER: 'SET_CURRENT_CUSTOMER',
    SET_CURRENT_CUSTOMER_BY_CUSTODYCD: 'SET_CURRENT_CUSTOMER_BY_CUSTODYCD',
    FETCH_CUSTOMERS_ACCOUNTS_LIST: 'FETCH_CUSTOMERS_ACCOUNTS_LIST',
    FETCH_CUSTOMERS_ACCOUNTS_LIST_SUCCESS: 'FETCH_CUSTOMERS_ACCOUNTS_LIST_SUCCESS',
    CHANGE_CUSTOMER_INFO_EVENTTYPE: 'CHANGE_CUSTOMER_INFO_EVENTTYPE',
    CHANGE_ACCOUNT_INFO_EVENTTYPE: 'CHANGE_ACCOUNT_INFO_EVENTTYPE',
    CHANGE_PASSWORD_INFO_EVENTTYPE: 'CHANGE_PASSWORD_INFO_EVENT_TYPE',

    /* Order actions */
    FETCH_DRAFT_ORDERS: 'FETCH_DRAFT_ORDERS',
    FETCH_DRAFT_ORDERS_SUCCESS: 'FETCH_DRAFT_ORDERS_SUCCESS',
    FETCH_DRAFT_ORDERS_FAIL: 'FETCH_DRAFT_ORDERS_FAIL',
    CLEAR_DRAFT_ORDER: 'CLEAR_DRAFT_ORDER',
    SET_SHOW_RECONFIRM_ORDER: 'SET_SHOW_RECONFIRM_ORDER',
    SET_LIST_RECONFIRM_ORDER: 'SET_LIST_RECONFIRM_ORDER',
    CLEAR_LIST_RECONFIRM_ORDER: 'CLEAR_LIST_RECONFIRM_ORDER',
    CHANGE_ADVANCE_CONDITION_ORDER: 'CHANGE_ADVANCE_CONDITION_ORDER',
    SET_ORDER_TRANSACTION_INFO: 'SET_ORDER_TRANSACTION_INFO',
    SET_LOOKUP_ORDER_NEW: 'SET_LOOKUP_ORDER_NEW',
    SET_ORDER_PUTTHROUGH_INFO: 'SET_ORDER_PUTTHROUGH_INFO',
    CLEAR_ORDER_PUTTHROUGH_INFO: 'CLEAR_ORDER_PUTTHROUGH_INFO',
    /* Inquiry actions */
    FETCH_SECURITIES: 'FETCH_SECURITIES',
    FETCH_SECURITIES_SUCCESS: 'FETCH_SECURITIES_SUCCESS',
    FETCH_SECURITIES_FAIL: 'FETCH_SECURITIES_FAIL',

    /*  Connection Count */
    GET_CONNECT_COUNT: 'GET_CONNECT_COUNT',

    /* huy.quang: trigger 1 so function cua component */
    SET_WIDGET_GRID_ACTIONS: 'SET_WIDGET_GRID_ACTIONS',
    SET_TAB_FOR_OPEN_POSSITIONS_ACTION: 'SET_TAB_FOR_OPEN_POSSITIONS_ACTION',
    LOAD_CONDITION_ORDER_DATA: 'LOAD_CONDITION_ORDER_DATA',

    //huy.quang: toggle an hien sidebar tab account & setting
    TOGGLE_MENU_SIDEBAR: 'TOGGLE_MENU_SIDEBAR',

    //huy.quang: luu path tab account, setting
    SET_ACCOUNT_PATH: 'SET_ACCOUNT_PATH',
    SET_SETTING_PATH: 'SET_SETTING_PATH',

    /* huy.quang: lưu lai state cua 1 so component */
    /**
     * tab trading
     */
    SET_SYMBOL_SELECTOR_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_COLLECTION_ID', // tab dang chon cua bang gia
    SET_SYMBOL_SELECTOR_USER_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_USER_COLLECTION_ID', // tab user dang chon cua bang gia
    SET_SYMBOL_SELECTOR_HNX_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_HNX_COLLECTION_ID', // tab HNX user dang chon cua bang gia
    SET_SYMBOL_SELECTOR_HOSE_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_HOSE_COLLECTION_ID', // tab user HOSE dang chon cua bang gia
    SET_SYMBOL_SELECTOR_UPCOM_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_UPCOM_COLLECTION_ID', // tab user HOSE dang chon cua bang gia
    SET_SYMBOL_SELECTOR_ANALYTIC_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_ANALYTIC_COLLECTION_ID', // tab công cụ phân tích dang chon cua bang gia
    SET_SYMBOL_SELECTOR_INDUSTRY_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_INDUSTRY_COLLECTION_ID', // tab CP ngành dang chon cua bang gia
    SET_SYMBOL_SELECTOR_DERIVATIVE_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_DERIVATIVE_COLLECTION_ID', // tab CP phái sinh
    SET_SYMBOL_SELECTOR_ODDLOT_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_ODDLOT_COLLECTION_ID', // tab Lô lẻ
    SET_SYMBOL_SELECTOR_PUTTHROUGH_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_PUTTHROUGH_COLLECTION_ID', // tab Danh mục thỏa thuận
    SET_SYMBOL_SELECTOR_INDEX_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_INDEX_COLLECTION_ID', // tab Chỉ số trừ các sàn và HNX30 VN30
    SET_SYMBOL_SELECTOR_CW_COLLECTION_ID: 'SET_SYMBOL_SELECTOR_CW_COLLECTION_ID', // Tab chứng quyền (Bao gồm toàn thị trường và VIX phát hành)

    SET_LIST_ACTIVE_SECTION: 'SET_LIST_ACTIVE_SECTION', //cac section dang chon tren widget grid
    /**
     * tab account
     */
    SET_OPEN_POSITION_CURRENT_PAGE: 'SET_OPEN_POSITION_CURRENT_PAGE',
    SET_NPL_EXECUTED_BY_AMOUNT_CURRENT_PAGE: 'SET_NPL_EXECUTED_BY_AMOUNT_CURRENT_PAGE',
    SET_MARGIN_LOANS_CURRENT_PAGE: 'SET_MARGIN_LOANS_CURRENT_PAGE',
    SET_PORTFOLIO_INTEREST_RATE_SUPPORT_PAGE: 'SET_PORTFOLIO_INTEREST_RATE_SUPPORT_PAGE',
    SET_FOOTER_CURRENT_PAGE: 'SET_FOOTER_CURRENT_PAGE',
    DELETE_ALL_NOTIFY: 'DELETE_ALL_NOTIFY',
    CLOSE_BROWSER_MODAL: 'CLOSE_BROWSER_MODAL',
    CLEAR_SYMBOL_SUGGESTION: 'CLEAR_SYMBOL_SUGGESTION',



    ON_CHANGE_ACTIVE_MENU: 'ON_CHANGE_ACTIVE_MENU',
    ON_CHANGE_ACTIVE_MENU_SIDE_BAR: 'ON_CHANGE_ACTIVE_MENU_SIDE_BAR',
    ON_CHANGE_ACTIVE_URL_SIDE_BAR: 'ON_CHANGE_ACTIVE_URL_SIDE_BAR',
    ON_CHANGE_STATUS_MENU_SIDE_BAR: 'ON_CHANGE_STATUS_MENU_SIDE_BAR',
    ON_CHANGE_STATUS_POPUP_FOOTER_PAGE: 'ON_CHANGE_STATUS_POPUP_FOOTER_PAGE',
    ON_CHANGE_OPEN_LIST_MENU_SIDE_BAR: "ON_CHANGE_OPEN_LIST_MENU_SIDE_BAR",
    ON_CHANGE_OPEN_MENU_TOOL: "ON_CHANGE_OPEN_MENU_TOOL",

    /////////////////////////////////////////////////////////////////////////////
    //FR_PB
    AccessToken: 'persist:user',
    LOAD_CUSTOMER_INFO: 'LOAD_CUSTOMER_INFO',
    LOAD_CUSTOMER_INFO_SUCCESS: 'LOAD_CUSTOMER_INFO_SUCCESS',
    LOAD_CUSTOMER_SERVICES: 'LOAD_CUSTOMER_SERVICES',
    LOAD_CUSTOMER_SERVICES_SUCCESS: 'LOAD_CUSTOMER_SERVICES_SUCCESS',
    LOAD_ACCOUNT_INFO: 'LOAD_ACCOUNT_INFO',
    LOAD_ACCOUNT_INFO_SUCCESS: 'LOAD_ACCOUNT_INFO_SUCCESS',
    LOAD_BUY_ACCOUNT_INFO: 'LOAD_BUY_ACCOUNT_INFO',
    LOAD_BUY_ACCOUNT_INFO_SUCCESS: 'LOAD_BUY_ACCOUNT_INFO_SUCCESS',
    CLEAR_BUY_ACCOUNT_INFO: 'CLEAR_BUY_ACCOUNT_INFO',
    LOAD_ALL_ACCOUNTS: 'LOAD_ALL_ACCOUNTS',
    LOAD_ALL_ACCOUNTS_SUCCESS: 'LOAD_ALL_ACCOUNTS_SUCCESS',
    CLEAR_BACK_OFFICER_ACCOUNTS: 'CLEAR_BACK_OFFICER_ACCOUNTS',

    LOAD_SEC_INFO: 'LOAD_SEC_INFO',
    LOAD_SEC_INFO_SUCCESS: 'LOAD_SEC_INFO_SUCCESS',
    LOAD_MORE_SEC_INFOS: 'LOAD_MORE_SEC_INFOS',
    SORT_SEC_INFOS: 'SORT_SEC_INFOS',
    SORT_FUNDAMENT_INFOS: 'SORT_FUNDAMENT_INFOS',
    UPDATE_SECINFOS: 'UPDATE_SECINFOS',

    SORT_COVER_WARRANT_INFOS: 'SORT_COVER_WARRANT_INFOS',
    UPDATE_COVERED_WARRANT_INFOS: 'UPDATE_COVERED_WARRANT_INFOS', // Lỗi tab mã CK

    LOAD_PUT_THROUGH_ORDERS: 'LOAD_PUT_THROUGH_ORDERS',
    LOAD_PUT_THROUGH_ORDERS_SUCCESS:
        'LOAD_PUT_THROUGH_ORDERS_SUCCESS',
    LOAD_AD_ORDERS_SUCCESS: 'LOAD_AD_ORDERS_SUCCESS',
    CHANGE_SORT_DATA: 'CHANGE_SORT_DATA',

    LOAD_MARKET_INFO_SUCCESS: 'LOAD_MARKET_INFO_SUCCESS',
    LOAD_CEIL_FLOOR_COUNT: 'LOAD_CEIL_FLOOR_COUNT',
    UPDATE_MARKET_INFOS: 'UPDATE_MARKET_INFOS',
    LOAD_MARKET_HISTORY_SUCCESS: 'LOAD_MARKET_HISTORY_SUCCESS',
    SET_MARKET_DETAIL_DATA: 'SET_MARKET_DETAIL_DATA',
    SET_IS_DETAIL_INDEX: 'SET_IS_DETAIL_INDEX',
    LOAD_MARKET_INDEX_SUCCESS: 'LOAD_MARKET_INDEX_SUCCESS',
    SET_LOAD_INDEX_STATUS: 'SET_LOAD_INDEX_STATUS',

    LOAD_SEC_INFOS_BY_FLOOR_CODE: 'LOAD_SEC_INFOS_BY_FLOOR_CODE',
    LOAD_SEC_INFOS_BY_FLOOR_CODE_SUCCESS:
        'LOAD_SEC_INFOS_BY_FLOOR_CODE_SUCCESS',
    LOAD_WATCHLIST_SEC_INFOS: 'LOAD_WATCHLIST_SEC_INFOS',
    LOAD_WATCHLIST_SEC_INFOS_SUCCESS:
        'LOAD_WATCHLIST_SEC_INFOS_SUCCESS',
    SET_USER_WATCHLIST_SUCCESS: 'SET_USER_WATCHLIST_SUCCESS',
    SET_USER_WATCHLIST_FALSE: 'SET_USER_WATCHLIST_FALSE',
    SET_USER_WATCHLIST: 'SET_USER_WATCHLIST',
    REMOVE_USER_WATCH_LIST: 'REMOVE_USER_WATCH_LIST',
    UPDATE_USER_WATCH_LIST: 'UPDATE_USER_WATCH_LIST',
    UPDATE_ACTIVE_USER_WATCH_LIST: 'UPDATE_ACTIVE_USER_WATCH_LIST',

    REMOVE_GUEST_WATCHLIST: 'REMOVE_GUEST_WATCHLIST',
    CREATE_GUEST_WATCHLIST: 'CREATE_GUEST_WATCHLIST',
    UPDATE_GUEST_WATCHLIST: 'UPDATE_GUEST_WATCHLIST',
    UPDATE_ACTIVE_GUEST_WATCHLIST: 'UPDATE_ACTIVE_GUEST_WATCHLIST',

    ADD_SYMBOL_TO_BOARD: 'ADD_SYMBOL_TO_BOARD',
    REMOVE_SYMBOL_FROM_WATCHLIST: 'REMOVE_SYMBOL_FROM_WATCHLIST',
    REMOVE_SYMBOL_FROM_BOARD: 'REMOVE_SYMBOL_FROM_BOARD',
    REORDER_SYMBOLS_IN_WATCHLIST_SUCCESS:
        'REORDER_SYMBOLS_IN_WATCHLIST_SUCCESS',

    ADD_SYMBOL_TO_BOARD_SUCCESS: 'ADD_SYMBOL_TO_BOARD_SUCCESS',
    REMOVE_SYMBOL_FROM_BOARD_SUCCESS:
        'REMOVE_SYMBOL_FROM_BOARD_SUCCESS',

    LOAD_ONGOING_STOCK_EVENTS: 'LOAD_ONGOING_STOCK_EVENTS',
    LOAD_ONGOING_STOCK_EVENTS_SUCCESS:
        'LOAD_ONGOING_STOCK_EVENTS_SUCCESS',

    GET_STOCK_TRANSACTIONS: 'GET_STOCK_TRANSACTIONS',
    GET_OLSTOCK_TRANSACTIONS: 'GET_OLSTOCK_TRANSACTIONS',
    GET_ALL_STOCKS: 'GET_ALL_STOCKS',
    GET_ALL_ODDLOT_STOCKS: 'GET_ALL_ODDLOT_STOCKS',
    GET_ALL_STOCKS_SUCCESS: 'GET_ALL_STOCKS_SUCCESS',
    GET_ALL_OLSTOCKS_SUCCESS: 'GET_ALL_OLSTOCKS_SUCCESS',
    ADD_TO_STOCKS_PIN_TOP: 'ADD_TO_STOCKS_PIN_TOP',
    REMOVE_FROM_STOCKS_PIN_TOP: 'REMOVE_FROM_STOCKS_PIN_TOP',
    REMOVE_WATCHLIST_FROM_SYMBOL_PIN_TOP: 'REMOVE_WATCHLIST_FROM_SYMBOL_PIN_TOP',

    GET_STOCK_TRANSACTIONS_SUCCESS: 'GET_STOCK_TRANSACTIONS_SUCCESS',

    GET_ALL_INDUSTRIES: 'GET_ALL_INDUSTRIES',
    SORT_INDUSTRY: 'SORT_INDUSTRY',

    CALCULATE_AVAILABLE_QUANTITY: 'CALCULATE_AVAILABLE_QUANTITY',
    CALCULATE_AVAILABLE_QUANTITY_SUCCESS:
        'CALCULATE_AVAILABLE_QUANTITY_SUCCESS',
    CALCULATE_DERIVATIVE_AVAILABLE_QUANTITY:
        'CALCULATE_DERIVATIVE_AVAILABLE_QUANTITY',
    CALCULATE_DERIVATIVE_AVAILABLE_QUANTITY_SUCCESS:
        'CALCULATE_DERIVATIVE_AVAILABLE_QUANTITY_SUCCESS',

    CLEAR_AVAILABLE_QUANTITY: 'CLEAR_AVAILABLE_QUANTITY',
    LOAD_SEC_INFO_ON_QUICK_PLACE_ORDER:
        'LOAD_SEC_INFO_ON_QUICK_PLACE_ORDER',
    LOAD_FINFO_SEC_INFO_ON_QUICK_PLACE_ORDER:
        'LOAD_FINFO_SEC_INFO_ON_QUICK_PLACE_ORDER',
    LOAD_SEC_INFO_ON_QUICK_PLACE_ORDER_SUCCESS:
        'LOAD_SEC_INFO_ON_QUICK_PLACE_ORDER_SUCCESS',
    LOAD_FINFO_SEC_INFO_ON_QUICK_PLACE_ORDER_SUCCESS:
        'LOAD_FINFO_SEC_INFO_ON_QUICK_PLACE_ORDER_SUCCESS',
    CLEAR_SEC_INFO_ON_QUICK_PLACE_ORDER:
        'CLEAR_SEC_INFO_ON_QUICK_PLACE_ORDER',
    CLEAR_FINFO_SEC_INFO_ON_QUICK_PLACE_ORDER:
        'CLEAR_FINFO_SEC_INFO_ON_QUICK_PLACE_ORDER',
    DO_PLACE_ORDER: 'DO_PLACE_ORDER',
    DO_PLACE_ORDER_SUCCESS: 'DO_PLACE_ORDER_SUCCESS',
    DO_PLACE_ORDER_FAIL: 'DO_PLACE_ORDER_FAIL',

    TOOGLE_RECONFIRM_MODAL: 'TOOGLE_RECONFIRM_MODAL',
    CLOSE_RECONFIRM_MODAL: 'CLOSE_RECONFIRM_MODAL',
    UPDATE_RECONFIRM_LOADING_STATUS: 'UPDATE_RECONFIRM_LOADING_STATUS',

    DO_PLACE_CHANGE_ACTIVE_TAB: 'DO_PLACE_CHANGE_ACTIVE_TAB',
    CHANGE_SHOW_PLACEORDER: 'CHANGE_SHOW_PLACEORDER',
    SET_TRADE_AUTH_SHOW_CONFIRM: 'SET_TRADE_AUTH_SHOW_CONFIRM',

    CHANGE_SHOW_MAKETWATCH: 'CHANGE_SHOW_MAKETWATCH',
    CHANGE_CURRENT_MARKETWATCH_TYPE: 'CHANGE_CURRENT_MARKETWATCH_TYPE',

    SET_RECONFIRM_ORDER_INFOR: 'SET_RECONFIRM_ORDER_INFOR',
    CLEAR_LIST_RECONFIRM_ORDER: 'CLEAR_LIST_RECONFIRM_ORDER',
    SET_TRADE_AUTHENTICATED: 'SET_TRADE_AUTHENTICATED',
    SET_CALLBACK_STATUS: 'SET_CALLBACK_STATUS',
    CLOSE_BROWSER_MODAL: 'CLOSE_BROWSER_MODAL',

    DO_PLACE_MULTIPLE_ORDERS: 'DO_PLACE_MULTIPLE_ORDERS',
    DO_PLACE_MULTIPLE_ORDERS_SUCCESS:
        'DO_PLACE_MULTIPLE_ORDERS_SUCCESS',
    DO_PLACE_MULTIPLE_ORDERS_FAIL: 'DO_PLACE_MULTIPLE_ORDERS_FAIL',

    DO_PLACE_DERIVATIVE_ORDER: 'DO_PLACE_DERIVATIVE_ORDER',
    DO_PLACE_DERIVATIVE_ORDER_SUCCESS:
        'DO_PLACE_DERIVATIVE_ORDER_SUCCESS',
    DO_PLACE_DERIVATIVE_ORDER_FAIL: 'DO_PLACE_DERIVATIVE_ORDER_FAIL',

    LOAD_ASSETS_INFO: 'LOAD_ASSETS_INFO',
    LOAD_DERIVATIVE_ASSETS_INFO: 'LOAD_DERIVATIVE_ASSETS_INFO',
    LOAD_ASSETS_INFO_SUCCESS: 'LOAD_ASSETS_INFO_SUCCESS',
    LOAD_DERIVATIVE_ASSETS_INFO_SUCCESS:
        'LOAD_DERIVATIVE_ASSETS_INFO_SUCCESS',
    LOAD_PORTFOLIO_ON_QUICK_PLACE_ORDER:
        'LOAD_PORTFOLIO_ON_QUICK_PLACE_ORDER',

    LOAD_CONDITIONAL_ORDERBOOK: 'LOAD_CONDITIONAL_ORDERBOOK',
    LOAD_CONDITIONAL_ORDERBOOK_DIAMON:
        'LOAD_CONDITIONAL_ORDERBOOK_DIAMON',
    LOAD_CONDITIONAL_ORDERBOOK_SUCCESS:
        'LOAD_CONDITIONAL_ORDERBOOK_SUCCESS',
    LOAD_CONDITIONAL_ORDERBOOK_ERROR:
        'LOAD_CONDITIONAL_ORDERBOOK_ERROR',

    DO_CANCEL_STOP_ORDER: 'DO_CANCEL_STOP_ORDER',
    DO_CANCEL_STOP_ORDER_SUCCESS: 'DO_CANCEL_STOP_ORDER_SUCCESS',
    DO_CANCEL_STOP_ORDER_FAIL: 'DO_CANCEL_STOP_ORDER_FAIL',

    DO_REPLACE_STOP_ORDER: 'DO_REPLACE_STOP_ORDER',
    DO_REPLACE_STOP_ORDER_SUCCESS: 'DO_REPLACE_STOP_ORDER_SUCCESS',
    DO_REPLACE_STOP_ORDER_FAIL: 'DO_REPLACE_STOP_ORDER_FAIL',

    LOAD_DERIVATIVE_ORDERBOOK_DIAMON:
        'LOAD_DERIVATIVE_ORDERBOOK_DIAMON',
    LOAD_DERIVATIVE_ORDERBOOK: 'LOAD_DERIVATIVE_ORDERBOOK',

    LOAD_DERIVATIVE_BUYSELLSTOP_ORDERBOOK:
        'LOAD_DERIVATIVE_BUYSELLSTOP_ORDERBOOK',
    LOAD_DERIVATIVE_BUYSELLSTOP_ORDERBOOK_SUCCESS:
        'LOAD_DERIVATIVE_BUYSELLSTOP_ORDERBOOK_SUCCESS',
    REPLACE_DERIVATIVE_BUYSELLSTOP_ORDER:
        'REPLACE_DERIVATIVE_BUYSELLSTOP_ORDER',
    REPLACE_DERIVATIVE_BUYSELLSTOP_ORDER_SUCCESS:
        'REPLACE_DERIVATIVE_BUYSELLSTOP_ORDER_SUCCESS',
    REPLACE_DERIVATIVE_BUYSELLSTOP_ORDER_FAILED:
        'REPLACE_DERIVATIVE_BUYSELLSTOP_ORDER_FAILED',
    CANCEL_DERIVATIVE_BUYSELLSTOP_ORDER:
        'CANCEL_DERIVATIVE_BUYSELLSTOP_ORDER',
    CANCEL_DERIVATIVE_BUYSELLSTOP_ORDER_SUCCESS:
        'CANCEL_DERIVATIVE_BUYSELLSTOP_ORDER_SUCCESS',
    CANCEL_DERIVATIVE_BUYSELLSTOP_ORDER_FAILED:
        'CANCEL_DERIVATIVE_BUYSELLSTOP_ORDER_FAILED',

    LOAD_DERIVATIVE_TRAILINGSTOP_ORDERBOOK:
        'LOAD_DERIVATIVE_TRAILINGSTOP_ORDERBOOK',
    LOAD_DERIVATIVE_TRAILINGSTOP_ORDERBOOK_SUCCESS:
        'LOAD_DERIVATIVE_TRAILINGSTOP_ORDERBOOK_SUCCESS',
    CANCEL_DERIVATIVE_TRAILINGSTOP_ORDER:
        'CANCEL_DERIVATIVE_TRAILINGSTOP_ORDER',
    CANCEL_DERIVATIVE_TRAILINGSTOP_ORDER_SUCCESS:
        'CANCEL_DERIVATIVE_TRAILINGSTOP_ORDER_SUCCESS',
    CANCEL_DERIVATIVE_TRAILINGSTOP_ORDER_FAILED:
        'CANCEL_DERIVATIVE_TRAILINGSTOP_ORDER_FAILED',

    LOAD_DERIVATIVE_ORDERBOOK_SUCCESS:
        'LOAD_DERIVATIVE_ORDERBOOK_SUCCESS',
    LOAD_DERIVATIVE_ORDER_HISTORIES:
        'LOAD_DERIVATIVE_ORDER_HISTORIES',
    LOAD_DERIVATIVE_ORDER_HISTORIES_SUCCESS:
        'LOAD_DERIVATIVE_ORDER_HISTORIES_SUCCESS',

    LOAD_ORDERBOOK: 'LOAD_ORDERBOOK',
    LOAD_ORDERBOOK_SUCCESS: 'LOAD_ORDERBOOK_SUCCESS',
    LOAD_ORDERBOOK_ERROR: 'LOAD_ORDERBOOK_ERROR',
    LOAD_SEC_INFO_ON_DERIVATIVE_ORDER_DETAIL:
        'LOAD_SEC_INFO_ON_DERIVATIVE_ORDER_DETAIL',
    LOAD_SEC_INFO_ON_DERIVATIVE_ORDER_DETAIL_SUCCESS:
        'LOAD_SEC_INFO_ON_DERIVATIVE_ORDER_DETAIL_SUCCESS',

    DO_CANCEL_DERIVATIVE_ORDER: 'DO_CANCEL_DERIVATIVE_ORDER',
    DO_CANCEL_DERIVATIVE_ORDER_SUCCESS:
        'DO_CANCEL_DERIVATIVE_ORDER_SUCCESS',
    DO_CANCEL_DERIVATIVE_ORDER_FAIL:
        'DO_CANCEL_DERIVATIVE_ORDER_FAIL',
    DO_CANCEL_ORDER: 'DO_CANCEL_ORDER',
    DO_CANCEL_ORDER_SUCCESS: 'DO_CANCEL_ORDER_SUCCESS',
    DO_CANCEL_ORDER_FAIL: 'DO_CANCEL_ORDER_FAIL',

    DO_REPLACE_DERIVATIVE_ORDER: 'DO_REPLACE_DERIVATIVE_ORDER',
    DO_REPLACE_DERIVATIVE_ORDER_SUCCESS:
        'DO_REPLACE_DERIVATIVE_ORDER_SUCCESS',
    DO_REPLACE_DERIVATIVE_ORDER_FAIL:
        'DO_REPLACE_DERIVATIVE_ORDER_FAIL',
    DO_REPLACE_ORDER: 'DO_REPLACE_ORDER',
    DO_REPLACE_ORDER_SUCCESS: 'DO_REPLACE_ORDER_SUCCESS',
    DO_REPLACE_ORDER_FAIL: 'DO_REPLACE_ORDER_FAIL',

    /*
     * Portfolio Actions
     */
    LOAD_PORTFOLIO_STOCKS: 'LOAD_PORTFOLIO_STOCKS',
    LOAD_PORTFOLIO_STOCKS_SUCCESS: 'LOAD_PORTFOLIO_STOCKS_SUCCESS',
    LOAD_PORTFOLIO_SEC_INFOS: 'LOAD_PORTFOLIO_SEC_INFOS',
    LOAD_PORTFOLIO_SEC_INFOS_SUCCESS:
        'LOAD_PORTFOLIO_SEC_INFOS_SUCCESS',

    FILTER_PORTFOLIO_STOCKS: 'FILTER_PORTFOLIO_STOCKS',
    FILTER_PORTFOLIO_STOCKS_SUCCESS:
        'FILTER_PORTFOLIO_STOCKS_SUCCESS',
    PORTFOLIO_UPDATE_STOCK_PRICE: 'PORTFOLIO_UPDATE_STOCK_PRICE',

    LOAD_FINFO_FILTERS: 'LOAD_FINFO_FILTERS',
    LOAD_FINFO_FILTERS_SUCCESS: 'LOAD_FINFO_FILTERS_SUCCESS',
    LOAD_CONDITION_ITEMS: 'LOAD_CONDITION_ITEMS',
    LOAD_CONDITION_ITEMS_SUCCESS: 'LOAD_CONDITION_ITEMS_SUCCESS',

    /**
     * Assets Actions
     */
    LOAD_MARGIN: 'LOAD_MARGIN',
    LOAD_MARGIN_SUCCESS: 'LOAD_MARGIN_SUCCESS',
    LOAD_AFTYPE: 'LOAD_AFTYPE',
    LOAD_AFTYPE_SUCCESS: 'LOAD_AFTYPE_SUCCESS',
    EXPAND_ASSETS_ENTRY: 'EXPAND_ASSETS_ENTRY',
    SORT_LOAN_DATA: 'SORT_LOAN_DATA',
    PAY_DEBT: 'PAY_DEBT',
    PAY_DEBT_SUCCESS: 'PAY_DEBT_SUCCESS',
    PAY_DEBT_FAIL: 'PAY_DEBT_FAIL',
    CASH_TRANSFER: 'CASH_TRANSFER',
    CASH_MARGIN_TRANSFER: 'CASH_MARGIN_TRANSFER',
    CASH_TRANSFER_SUCCESS: 'CASH_TRANSFER_SUCCESS',
    CASH_TRANSFER_FAIL: 'CASH_TRANSFER_FAIL',
    GET_CASH_TRANSACTIONS: 'GET_CASH_TRANSACTIONS',
    GET_CASH_MARGIN_TRANSACTIONS: 'GET_CASH_MARGIN_TRANSACTIONS',
    GET_CASH_TRANSACTIONS_SUCCESS: 'GET_CASH_TRANSACTIONS_SUCCESS',
    GET_PNL_STATEMENTS: 'GET_PNL_STATEMENTS',
    GET_PNL_STATEMENTS_SUCCESS: 'GET_PNL_STATEMENTS_SUCCESS',

    /**
     * Statement Actions
     */
    LOAD_CASH_STATEMENT: 'LOAD_CASH_STATEMENT',
    LOAD_CASH_STATEMENT_SUCCESS: 'LOAD_CASH_STATEMENT_SUCCESS',
    LOAD_DERIVATIVE_CASH_STATEMENT: 'LOAD_DERIVATIVE_CASH_STATEMENT',
    LOAD_DERIVATIVE_CASH_STATEMENT_SUCCESS:
        'LOAD_DERIVATIVE_CASH_STATEMENT_SUCCESS',
    LOAD_SECURITIES_STATEMENT: 'LOAD_SECURITIES_STATEMENT',
    LOAD_SECURITIES_STATEMENT_SUCCESS:
        'LOAD_SECURITIES_STATEMENT_SUCCESS',
    LOAD_POSITION_STATEMENT: 'LOAD_POSITION_STATEMENT',
    LOAD_POSITION_STATEMENT_SUCCESS:
        'LOAD_POSITION_STATEMENT_SUCCESS',

    /*
     * Auth Actions
     */
    SORT_PORTFOLIO_STOCKS: 'SORT_PORTFOLIO_STOCKS',
    LOG_IN: 'LOG_IN',
    LOG_IN_START: 'LOG_IN_START',
    LOG_IN_SSO: 'LOG_IN_SSO',
    LOG_REQUIRE_DATA: 'LOG_REQUIRE_DATA',
    LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
    LOG_IN_FAILED: 'LOG_IN_FAILED',
    STEP2_AUTHENTICATED: 'STEP2_AUTHENTICATED',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
    SET_ACTIVE_ACCOUNT_TYPE: 'SET_ACTIVE_ACCOUNT_TYPE',
    SET_ACTIVE_ACCOUNT: 'SET_ACTIVE_ACCOUNT',
    AUTHORIZATION_SUCCESS: 'AUTHORIZATION_SUCCESS',
    FETCH_USER_INFO_SUCCESS: 'FETCH_USER_INFO_SUCCESS',
    REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',
    USER_LOGOUT: 'USER_LOGOUT',

    /*
     * Payments Actions
     */

    LOAD_ADVANCE_PAYMENTS: 'LOAD_ADVANCE_PAYMENTS',
    LOAD_ADVANCE_PAYMENTS_SUCCESS: 'LOAD_ADVANCE_PAYMENTS_SUCCESS',
    DO_ADVANCE_PAYMENTS: 'DO_ADVANCE_PAYMENTS',
    DO_ADVANCE_PAYMENTS_SUCCESS: 'DO_ADVANCE_PAYMENTS_SUCCESS',
    REGISTRY_AUTO_ADVANCE_PAYMENTS: 'REGISTRY_AUTO_ADVANCE_PAYMENTS',
    REGISTRY_AUTO_ADVANCE_PAYMENTS_SUCCESS:
        'REGISTRY_AUTO_ADVANCE_PAYMENTS_SUCCESS',
    CANCEL_AUTO_ADVANCE_PAYMENTS: 'CANCEL_AUTO_ADVANCE_PAYMENTS',
    CANCEL_AUTO_ADVANCE_PAYMENTS_SUCCESS:
        'CANCEL_AUTO_ADVANCE_PAYMENTS_SUCCESS',

    LOAD_ORDER_CONFIRMATIONS: 'LOAD_ORDER_CONFIRMATIONS',
    LOAD_ORDER_CONFIRMATIONS_SUCCESS:
        'LOAD_ORDER_CONFIRMATIONS_SUCCESS',
    UPDATE_ORDER_CONFIRMATIONS: 'UPDATE_ORDER_CONFIRMATIONS',
    UPDATE_ORDER_CONFIRMATIONS_SUCCESS:
        'UPDATE_ORDER_CONFIRMATIONS_SUCCESS',
    LOAD_DERIVATIVE_ORDER_CONFIRMATIONS:
        'LOAD_DERIVATIVE_ORDER_CONFIRMATIONS',
    LOAD_DERIVATIVE_ORDER_CONFIRMATIONS_SUCCESS:
        'LOAD_DERIVATIVE_ORDER_CONFIRMATIONS_SUCCESS',
    UPDATE_DERIVATIVE_ORDER_CONFIRMATIONS:
        'UPDATE_DERIVATIVE_ORDER_CONFIRMATIONS',
    UPDATE_DERIVATIVE_ORDER_CONFIRMATIONS_SUCCESS:
        'UPDATE_DERIVATIVE_ORDER_CONFIRMATIONS_SUCCESS',

    LOAD_OPEN_POSITION_FROM_PLACEORDER:
        'LOAD_OPEN_POSITION_FROM_PLACEORDER',
    LOAD_OPEN_POSITION_FROM_PLACEORDER_SUCCESS:
        'LOAD_OPEN_POSITION_FROM_PLACEORDER_SUCCESS',

    // trading overview actions
    GET_ASSET_OVERVIEW: 'GET_ASSET_OVERVIEW',
    GET_ASSET_OVERVIEW_SUCCESS: 'GET_ASSET_OVERVIEW_SUCCESS',
    GET_PORTFOLIO_OVERVIEW: 'GET_PORTFOLIO_OVERVIEW',
    GET_PORTFOLIO_OVERVIEW_SUCCESS: 'GET_PORTFOLIO_OVERVIEW_SUCCESS',
    GET_RIGHTS_OVERVIEW: 'GET_RIGHTS_OVERVIEW',
    GET_RIGHTS_OVERVIEW_SUCCESS: 'GET_RIGHTS_OVERVIEW_SUCCESS',

    // margin management
    GET_MARGIN_INFOS: 'GET_MARGIN_INFOS',
    GET_MARGIN_INFOS_SUCCESS: 'GET_MARGIN_INFOS_SUCCESS',

    LOAD_COVERED_WARRANT: 'LOAD_COVERED_WARRANT',

    UPDATE_SYMBOL_LIST: 'UPDATE_SYMBOL_LIST',

    //trade info
    INSERT_TRADE_DATA: 'INSERT_TRADE_DATA',
    SET_OLTRADE_DATA: 'SET_OLTRADE_DATA',
    INSERT_OLTRADE_DATA: 'INSERT_OLTRADE_DATA',
    REFRESH_CHART_TRADE_DATA: 'REFRESH_CHART_TRADE_DATA',
    LOAD_MORE_TRADE_DATA: 'LOAD_MORE_TRADE_DATA',
    LOAD_MORE_OLTRADE_DATA: 'LOAD_MORE_OLTRADE_DATA',

    //chartInday + chartInfo
    SET_MENUDETAIL_CHART_DATA: 'SET_MENUDETAIL_CHART_DATA', // khớp lệnh trong ngày (chartInday)
    SET_MENUDETAIL_CHART_INFO_DATA: 'SET_MENUDETAIL_CHART_INFO_DATA', // khớp lệnh theo giá (chartInfo)

    FETCH_SYMBOL_LIST_MAPPING_BEGIN: 'FETCH_SYMBOL_LIST_MAPPING_BEGIN',
    FETCH_SYMBOL_LIST_MAPPING: 'FETCH_SYMBOL_LIST_MAPPING',
    LOAD_SYMBOL_LIST_MAPPING: 'LOAD_SYMBOL_LIST_MAPPING',
    FETCH_SYMBOL_LIST_MAPPING_FAIL: 'FETCH_SYMBOL_LIST_MAPPING_FAIL',
    SET_SYMBOL_DATA_BY_SUGGEST: 'SET_SYMBOL_DATA_BY_SUGGEST',
    FETCH_ALL_QUOTE: 'FETCH_ALL_QUOTE',
    FETCH_ALL_QUOTE_BEGIN: 'FETCH_ALL_QUOTE_BEGIN',
    FETCH_ALL_QUOTE_FAIL: 'FETCH_ALL_QUOTE_FAIL',

    //actions liên quan đến notify
    UPDATE_ACCOUNT_NOTIFY_LIST: 'UPDATE_ACCOUNT_NOTIFY_LIST',
    UPDATE_AGREEMENT_ORDER_NOTIFY_LIST: 'UPDATE_AGREEMENT_ORDER_NOTIFY_LIST',
    CONFIRM_ORDERS_INFOR: 'CONFIRM_ORDERS_INFOR',
    UPDATE_ANALYTIC_NOTIFY_LIST: 'UPDATE_ANALYTIC_NOTIFY_LIST',
    UPDATE_ACCOUNT_NOTIFY_COUNTER: 'UPDATE_ACCOUNT_NOTIFY_COUNTER',

    // UPDATE_MARKET_HISTORY: 'UPDATE_MARKET_HISTORY',

    //action liên quan đến giao dịch thỏa thuận
    UPDATE_PTMATCH_DATA: 'UPDATE_PTMATCH_DATA',
    UPDATE_PUTTHROUGH_DATA: 'UPDATE_PUTTHROUGH_DATA',

    //action liên quan đến trình chiếu mã CK
    SET_SHOW_AUTO_SCROLL_PRICE_BOARD: 'SET_SHOW_AUTO_SCROLL_PRICE_BOARD',
    //action liên quan đến tabactive wiget
    SET_TAB_ACTIVE_WIGET: 'SET_TAB_ACTIVE_WIGET',

    //toogle drap and drop sổ lệnh tra cứu môi giới
    TOOGLE_SETTING_TABLE_DRAP_DROP_LOOK_UP_ORDER_NEW: 'TOOGLE_SETTING_TABLE_DRAP_DROP_LOOK_UP_ORDER_NEW',


    SET_IS_DESKTOP: 'SET_IS_DESKTOP',
    SET_BREAKPOINT: 'SET_BREAKPOINT',

    SET_TAB_ACTIVE_BROWSER: 'SET_TAB_ACTIVE_BROWSER',

});

export default actionTypes;
