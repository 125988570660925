import { Random } from "..";

class State {

    constructor({id, data, created} = {}) {
        this._id = id || Random.randomAuthStateId();
        this._data = data;

        if (typeof created === 'number' && created > 0) {
            this._created = created;
        }
        else {
            this._created = parseInt(Date.now() / 1000);
        }
    }

    get id() {
        return this._id;
    }
    get data() {
        return this._data;
    }
    get created() {
        return this._created;
    }

    toStorageString() {
        return JSON.stringify({
            id: this.id,
            data: this.data,
            created: this.created
        });
    }

    static fromStorageString(storageString) {
        return new State(JSON.parse(storageString));
    }
}

export default State;
