
class ListDataMapKeyByLanguage {

    static listDataTitleWidget = (key) => {
        switch (key) {
            case "activeOrders":
                return "trade.sections.titles.activeOrders"
            case "advanceConditionOrder":
                return "trade.sections.titles.advanceConditionOrder"
            case "closePositions":
                return "trade.sections.titles.closePositions"
            case "conditionOrder":
                return "trade.sections.titles.conditionOrder"
            case "confirmOrder":
                return "trade.sections.titles.confirmOrder"
            case "depthChart":
                return "trade.sections.titles.depthChart"
            case "draftOrders":
                return "trade.sections.titles.draftOrders"
            case "fdsActiveOrders":
                return "trade.sections.titles.fdsActiveOrders"
            case "fdsOpenPositions":
                return "trade.sections.titles.fdsOpenPositions"
            case "fdsOrderList":
                return "trade.sections.titles.fdsOrderList"
            case "indexsList":
                return "trade.sections.titles.indexsList"
            case "matchedOrder":
                return "trade.sections.titles.matchedOrder"
            case "chartMatchedInfo":
                return "trade.sections.titles.chartMatchedInfo"
            case "openPositions":
                return "trade.sections.titles.openPositions"
            case "openPositionsSort":
                return "trade.sections.titles.openPositionsSort"
            case "orderbook":
                return "trade.sections.titles.orderbook"
            case "ordersList":
                return "trade.sections.titles.ordersList"
            case "lookupOrders":
                return "trade.sections.titles.lookupOrders"
            case "lookupOrdersNew":
                return "trade.sections.titles.lookupOrdersNew"
            case "ordersListSort":
                return "trade.sections.titles.ordersListSort"
            case "brokerOrders":
                return "trade.sections.titles.brokerOrders"
            case "placeGroupOrder":
                return "trade.sections.titles.placeGroupOrder"
            case "placeOrder":
                return "trade.sections.titles.placeOrder"
            case "placeOrderFast":
                return "trade.sections.titles.placeOrderFast"
            case "priceChart":
                return "trade.sections.titles.priceChart"
            case "infoPortfolioFavorites":
                return "trade.sections.titles.infoPortfolioFavorites"
            case "recentTrades":
                return "trade.sections.titles.recentTrades"
            case "symbolInfo":
                return "trade.sections.titles.symbolInfo"
            case "symbolSelector":
                return "trade.sections.titles.symbolSelector"
            case "putthroughOrder1F":
                return "trade.sections.titles.putthroughOrder1F"
            case "putthroughOrder2F":
                return "trade.sections.titles.putthroughOrder2F"
            case "putthroughOrderNoti":
                return "trade.sections.titles.putthroughOrderNoti"
            case "putthroughConfirmOrder":
                return "trade.sections.titles.putthroughConfirmOrder"
            case "putthroughConfirmCancelOrder":
                return "trade.sections.titles.putthroughConfirmCancelOrder"
            case "advertisingOrder":
                return "trade.sections.titles.advertisingOrder"
            case "orderActive":
                return "trade.sections.titles.orderActive"
            case "normalOrderBook":
                return "trade.sections.titles.normalOrderBook"
            case "conditionOrderBook":
                return "trade.sections.titles.conditionOrderBook"
            case "analysisSymbol":
                return "trade.sections.titles.analysisSymbol"
            case "assetTotalDetail":
                return "trade.sections.titles.assetTotalDetail"
            case "newsSymbol":
                return "trade.sections.titles.newsSymbol"
            case "draftOrderBook":
                return "trade.sections.titles.draftOrderBook"
            case "brokerOrderBook":
                return "trade.sections.titles.brokerOrderBook"
            case "lookUpOrderBook":
                return "trade.sections.titles.lookUpOrderBook"
            case "infoSymbol":
                return "trade.sections.titles.infoSymbol"
            case "placeOrderNormal":
                return "trade.sections.titles.placeOrderNormal"
            case "placeOrderPro":
                return "trade.sections.titles.placeOrderPro"
            case "priceboard":
                return "trade.sections.titles.priceboard"
            case "putthroughtOrder":
                return "trade.sections.titles.putthroughtOrder"
            case "marketBreadth":
                return "trade.sections.titles.marketBreadth"
            case "listStatusMarketIndex":
                return "trade.sections.titles.listStatusMarketIndex"
            case "topStockUp":
                return "trade.sections.titles.topStockUp"
            case "listTranslog":
                return "trade.sections.titles.listTranslog"
            case "topStockDown":
                return "trade.sections.titles.topStockDown"
            case "mutationLiquidity":
                return "trade.sections.titles.mutationLiquidity"
            case "recommendedList":
                return "trade.sections.titles.recommendedList"
            case "liquidityChart":
                return "trade.sections.titles.liquidityChart"
            case "distributionStockCapitalization":
                return "trade.sections.titles.distributionStockCapitalization"
            case "foreignBlockChart":
                return "trade.sections.titles.foreignBlockChart"
            case "newsMarket":
                return "trade.sections.titles.newsMarket"
            case "analysisMarket":
                return "trade.sections.titles.analysisMarket"
            case "marketStatus":
                return "trade.sections.titles.marketStatus"
            case "marketSignal":
                return "trade.sections.titles.marketSignal"
            case "ratingSector":
                return "trade.sections.titles.ratingSector"
            case "ratingStock":
                return "trade.sections.titles.ratingStock"
            case "bondDetailTPRL":
                return "trade.sections.titles.bondDetailTPRL"
            case "transactionDetailTPRL":
                return "trade.sections.titles.transactionDetailTPRL"
            case "orderBookTPRL":
                return "trade.sections.titles.orderBookTPRL"
            default:
                return ""
        }
        return ""
    }

    static listDataFooterStatus = (key) => {
        switch (key) {
            case "CONNECTED":
                return "trade.footer.socket-status.CONNECTED"
            case "CONNECTING":
                return "trade.footer.socket-status.CONNECTING"
            case "DISCONNECTED":
                return "trade.footer.socket-status.CONNECTED"
            default:
                return ""

        }
        return ""
    }
}


export default ListDataMapKeyByLanguage;