import _ from "lodash";

import actionTypes from '../actions/actionTypes';

const initialState = {
    accounts: [],
    currentAccount: null,
    currentAccountId: null,
    defaultAccountId: null
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ACCOUNTS_SUCCESS:
            let _accounts = _.map(action.accounts, (item, index) => {
                let afacctnoext = item['custodycd'] + '.' + item['typename']
                return {
                    ...item,
                    afacctnoext: afacctnoext
                }
            })
            return {
                ...state,
                accounts: _accounts
            };
        case actionTypes.SET_DEFAULT_ACCOUNT_ID:
            return {
                ...state,
                defaultAccountId: action.defaultAccountId
            };
        case actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST:
            return {
                ...state,
                accounts: [],
            };
        case actionTypes.FETCH_CUSTOMERS_ACCOUNTS_LIST_SUCCESS:
            return {
                ...state,
                accounts: action.accounts
            };
        case actionTypes.SET_CURRENT_ACCOUNT: {
            const accountId = action.accountId;
            const account = _.find(state.accounts, (element) => {
                return element.id === accountId;
            });
            if (account != null && action.accountId) {
                return {
                    ...state,
                    currentAccount: account,
                    currentAccountId: action.accountId
                };
                // Truong hop clear thong tin account hien tai (Vi du nhap sai custodycd o man hinh Dat Lenh)
            } else if (action.accountId === null || action.accountId === '') {
                return {
                    ...initialState
                };
            }
            return state;
        }
        case actionTypes.USER_LOGOUT:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default accountReducer;