import { push } from 'connected-react-router';
import $ from 'jquery';
import _ from "lodash";
import moment from 'moment';
import React from 'react';
import { batch } from 'react-redux';

import { symbolService } from 'services';
import { getMarketStatusKey } from 'services/marketinfo.service';
import { emitter, IS_SHOW_POPUP_LOGIN } from 'utils/EventEmitter';

import config from '../combineConfig';
import { getSessionStorage } from '../common/utils';
// import { ListIndexNotDrawChart } from '.././combineConfig';
// import tabs from '../constants/tabs';
import { ListIndexNotDrawChart } from "../constants/config";
import Util from '../modules/util';
import Random from "./auth/Random";
import reduxStore from '../redux';
import { dispatch } from '../redux';
import * as actions from "../store/actions";
import { APP_TITLE, CommonOrderUtils, CommonWidgetUtils, floorCode, getValueFromLocalStorage, PRICEBOARD_THEMES, Role, setValueToLocalStorage, sortDirObj, ToastUtil } from '../utils';
import { formatNumberTypes, ORDER_TYPE_USE, PREFIX_CUSTODYCD, SESSION_STORAGE_LIST_MARKET_CHART, WIDGET_WIDTH } from "../utils/constants";
import { derivativeExchanges, orderActions, subOrderTypes } from "./index";

const Step2Authenticated = 'isStep2Authenticated';
const domain = '';
const storeKeyCurrentCollectionId = "currentCollectionIdByAccount";
/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
function shallowEqual(objA, objB) {
    if (objA === objB) {
        return true;
    }

    if (typeof objA !== 'object' || objA === null ||
        typeof objB !== 'object' || objB === null) {
        return false;
    }

    var keysA = Object.keys(objA);
    var keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
        return false;
    }

    // Test for A's keys different from B.
    var bHasOwnProperty = hasOwnProperty.bind(objB);
    for (var i = 0; i < keysA.length; i++) {
        if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
            return false;
        }
    }

    return true;
};
let LocalConfigWeb = JSON.parse(localStorage.getItem('LocalConfigWeb')) || { isOpenLogSocket: false }
LocalConfigWeb.isOpenLogSocket = true // Hardcode ghi log

// let TabID = Random.randomComponentId()
export const TabID = new Date().getTime() + "_" + Random.randomIdChars(6)
console.debug("TabID=", TabID)
class CommonUtils {
    static getTabID = () => {
        return TabID;
    }

    static logDebug = (from, msg) => {
        if (LocalConfigWeb) {
            if (LocalConfigWeb.isOpenLogSocket) {
                let timeLog = moment(new Date()).format('YYYY-MM-DD hh:mm:ss.SSS');
                console.debug(`${TabID} ${timeLog} ${from}.:= ${JSON.stringify(msg)}`)
            }
        }
        return null;
    }


    static getTokenCurr = (maxLength) => {
        const state = reduxStore.getState();
        let token = state.user.token != null ? state.user.token['access_token'] : null;
        if (token && maxLength) {
            token = token.substr(0, maxLength)
        }
        return token;
    }

    static returnWithoutAccents(text) {
        text = text.normalize('NFD');
        text = text.replace(/[\u0300-\u036f]/g, '');
        text = text.replace(/[đĐ]/g, m => m === 'đ' ? 'd' : 'D');
        text = text.toUpperCase()
        return text
    }

    static checkDesktop() {
        let isDeskTop = window.innerWidth >= WIDGET_WIDTH.DESKTOP.min && window.innerWidth != 0
        return isDeskTop
    }

    static shallowCompare(props, state, nextProps, nextState) {
        return (
            !shallowEqual(props, nextProps) ||
            !shallowEqual(state, nextState)
        );
    }
    static getListAuthType(transModule) {
        const state = reduxStore.getState();

        const userinfo = state.user.userInfo;
        const permissionInfo = userinfo.permissionInfo ? userinfo.permissionInfo : null;
        const currentAccountId = state.account.currentAccountId;

        let listAccPermission = [];

        if (userinfo.role === Role.CUSTOMER && currentAccountId && permissionInfo && permissionInfo.accounts[currentAccountId]) {
            listAccPermission = permissionInfo.accounts[currentAccountId];
        }
        if (userinfo.role === Role.BROKER && userinfo && permissionInfo && permissionInfo.accounts[userinfo.custid]) {
            listAccPermission = permissionInfo.accounts[userinfo.custid];
        }

        return listAccPermission[transModule];
    };

    static getRole() {
        const state = reduxStore.getState();
        const userinfo = state.user.userInfo;
        return userinfo.role
    };

    static convertSide(side) {
        if (side === "Mua") return "Buy"
        return "Sell"
    }

    static convertSideByLanguage(side, language) {
        let text = ""
        if (language === "vi") {
            if (side === "NB") {
                text = "Mua"
            } else {
                text = "Bán"
            }
        } else {
            if (side === "NB") {
                text = "Buy"
            } else {
                text = "Sell"
            }
        }
        return text
    }

    static convertCommandTypeFilterMatch(type) {
        if (type === "Thông thường") return "Normal"
        return "Put through"
    }

    static convertCommandTypeFilterOrder(type) {
        if (type === "Thường") return "Normal"
        return "Put through"
    }

    static convertStatusFilter(type) {
        switch (type) {
            case 'Đang gửi':
                return "Sending";
            case 'Đã hủy':
                return "Canceled";
            case 'Lệnh hủy gửi thành công':
                return "The cancellation order send successful";
            case 'Hủy không thành công':
                return "Cancel fail";
            case 'Hủy thành công':
                return "Cancel successful";
            case 'Lệnh sửa gửi thành công':
                return "Successful send amend order";
            case 'Sửa không thành công':
                return "Amend successful";
            case 'Sửa thành công':
                return "Amend success";
            case 'Giải tỏa lệnh':
                return "Free";
            case 'Đã giải tỏa':
                return "Free";
            case 'Lệnh corebank không đủ tiền':
                return "Not enough cash";
            case 'Chờ gửi':
                return "New";
            case 'Lệnh hết hiệu lực':
                return "Order expire";
            case 'Bên mua từ chối':
                return "Buyer reject";
            case 'Đang hủy':
                return "Deleting";
            case 'Đang đợi sửa':
                return "Editing";
            case 'Sở từ chối':
                return "Rejected";
            case 'Đã gửi':
                return "Sent";
            case 'Khớp 1 phần':
                return "Partially matched orders";
            case 'Khớp hết':
                return "Match";
            case 'Lệnh gốc đang đợi hủy':
                return "Original orders waiting cancel";
            case 'Đang chờ kết quả hủy':
                return "Original orders waiting cancel confirm";
            default:
                return null;
        }
    }

    static convertTitle(title) {
        switch (title) {
            case 'VN-INDEX':
                return "VNINDEX";
            case 'VN30-INDEX':
                return "VN30";
            case 'HNX-INDEX':
                return "HNXINDEX";
            case 'HNX30-INDEX':
                return "HNX30";
            case 'UPCOM':
                return "UPCOM";
            case 'VNMID-INDEX':
                return "VNMID";
            case 'VN100-INDEX':
                return "VN100";
            case 'VNSML-INDEX':
                return "VNSML";
            case 'VNXALL-INDEX':
                return "VNXALL";
            case 'VNALL-INDEX':
                return "VNALL";
            case 'DIAMOND-INDEX':
                return "VNDIAMOND";
            case 'FINLEAD-INDEX':
                return "VNFINLEAD";
            case 'FINSELECT-INDEX':
                return "VNFINSELECT";
            default:
                return null;
        }
    }

    static convetCode(code) {
        switch (code) {
            case 'HOSE':
                return "VN INDEX";
            case '30':
                return "VN30";
            case 'HNX':
                return "HNX INDEX";
            case 'HNX30':
                return "HNX30";
            case 'UPCOM':
                return "UPCOM INDEX";
            case 'MID':
                return "VN MID";
            case '100':
                return "VN100";
            case 'SML':
                return "VN SMALL CAP";
            case 'XALL':
                return "VNX ALL";
            case 'ALL':
                return "VN ALL SHARE";
            case 'DIAMOND':
                return "VN DIAMOND";
            case 'FINLEAD':
                return "VN FINLEAD";
            case 'FINSELECT':
                return "VN FIN SELECT";
            default:
                return null;
        }
    }

    static renderViaDetail(type) {
        switch (type) {
            case 'Algo':
                return "Algo";
            case 'Android':
                return "Android";
            case 'Tại sàn':
                return "Broker";
            case 'Lệnh ĐK':
                return "Condition";
            case 'Home':
                return "Home";
            case 'Horizon':
                return "Horizon";
            case 'Internet':
                return "Internet";
            case 'iPhone':
                return "iPhone";
            case 'Mobile':
                return "Mobile";
            case 'Online':
                return "Online";
            case 'Telephone':
                return "Telephone";
            default:
                return null;
        }
    }

    static getAccountUtils() {
        const state = reduxStore.getState();
        let accounts = state.account.accounts
        let currentAccount = state.account.currentAccount
        let obj = {
            accounts: accounts,
            currentAccount: currentAccount,
        }
        return obj
    };

    static getInstruments(reducers, key) {
        const state = reduxStore.getState();
        let data = _.cloneDeep(state.symbol.instruments);
        return data;
    };

    static getContactInfo(reducers, key) {
        const state = reduxStore.getState();
        let data = state.app.contactInfos ? state.app.contactInfos : {};
        return data;
    };

    static populateMarketInfo(marketInfo) {
        let info = {};
        if (marketInfo) {
            // let chart = this.props.listChart.find(item => item.code === marketInfo.floorCode);
            let index = config.ALL_INDEXES.find(item => item.code === marketInfo.floorCode);
            // info.indexName = chart ? chart.title : '';
            info.marketIndex = Util.formatAccounting(marketInfo.marketIndex, 2);
            info.changeValue = marketInfo.changedIndex;

            // info.changePercent = info.changeValue / marketInfo.priorMarketIndex;
            info.changePercent = marketInfo.percentIndex; // Haki.: Lấy trực tiếp từ data trả về
            info.changeValue = Util.formatAccounting(
                // Math.abs(info.changeValue),
                info.changeValue,
                2
            );

            // Tổng KL hiển thị = Tổng KL + Tổng KL lô lẻ
            // Tổng GT hiển thị = Tổng GT + Tổng GT lô lẻ
            info.totalShareTradedInfo = Util.formatAccounting(
                Number(marketInfo.totalShareTraded) + Number(marketInfo.oddLotTotalVolume),
                0
            );
            info.totalValueTradedInfo = Util.formatNumberShortLargeByDigit(Number(marketInfo.totalValueTraded) + Number(marketInfo.oddLotTotalValue), formatNumberTypes.ONE_DIGITS)

            info.totalShareTraded = Util.formatAccounting(
                marketInfo.totalShareTraded,
                0
            );
            // info.totalValueTraded = Util.formatAccounting(
            // 	marketInfo.totalValueTraded,
            // 	3
            // );
            info.totalValueTraded = Util.formatNumberShortLarge(marketInfo.totalValueTraded)

            info.advance = marketInfo.advance;
            info.noChange = marketInfo.noChange;
            info.decline = marketInfo.decline;
            info.numberOfCe = marketInfo.numberOfCe;
            info.numberOfFl = marketInfo.numberOfFl;
            info.colorClass = Util.getMarketInfoClasses(marketInfo).color;
            info.arrowClass = Util.getMarketInfoClasses(marketInfo).arrow;
            info.statusKey = getMarketStatusKey(marketInfo);
            info.floorCode = marketInfo.floorCode;
            info.priorMarketIndex = marketInfo.priorMarketIndex;
            info.name = index ? index.name : ""
            info.subName = marketInfo.subName;
        }

        return info;
    }

    static checkLogined() {
        const state = reduxStore.getState();
        let isLoggedIn = state.user.isLoggedIn;
        if (!isLoggedIn) {
            emitter.emit(IS_SHOW_POPUP_LOGIN)
        }
        return isLoggedIn;
    };

    static isBroker() {
        const state = reduxStore.getState();
        const userinfo = state.user.userInfo;
        if (userinfo && userinfo.role === Role.BROKER) {
            return true;
        }
        return false;
    };

    static divide(x, y) {
        if (isNaN(x)) return x;
        if (!y || x == null) return null;
        return x / y;
    };

    static roundToTwo(x, y) {
        if (isNaN(x)) return x;
        if (!y || x == null) return null;
        return +((x / y)).toFixed(2)
    }

    static roundToTwoHaveZero(x, y) {
        // nếu = 0 => 0.00
        if (isNaN(x)) return x;
        if (!y || x == null) return null;
        if (((x / y)).toFixed(2) === 0) {
            return ((x / y)).toFixed(2) + ".00"
        } else {
            return ((x / y)).toFixed(2)
        }

    }



    static stringifyInputExcel(str) {
        return `'${str}'`;
    };

    static getBooleanDiplay(b) {
        if (b === "Y" || b === "y") {
            return 'common.yes';
        }

        if (b === "N" || b === "n") {
            return 'common.no';
        }

        return "common.undefined";
    };

    static getGender(g) {
        if (g === "001") {
            return 'common.male';
        }

        if (g === "002") {
            return 'common.female';
        }

        return "common.undefined";
    }

    static getOrderTypeFromPriceType(priceType) {
        return priceType === 'LO' ? 'limit' : (_.includes(subOrderTypes, priceType) ? 'market' : 'stop');
    }

    static getOrderActionFromSideCode(sideCode) {
        return sideCode === 'NB' ? orderActions.BUY : orderActions.SELL;
    }

    static getOrderActionFromExecTypeDescCode(sideCode) {
        return sideCode === 'Buy' ? orderActions.BUY : orderActions.SELL;
    }

    static getCurrentSymbolFromPathName(pathname) {
        const regex = /^\/trade\/([^/?]+)/i;
        const matched = pathname.match(regex);
        return matched ? matched[1] : null;
    }

    static isSameCustomer(customer1, customer2) {
        const custody1 = customer1 != null ? customer1['custodycd'] : null;
        const custody2 = customer2 != null ? customer2['custodycd'] : null;
        return custody1 === custody2;
    }

    static isSameAccount(account1, account2) {
        const accountId1 = account1 != null ? account1['id'] : null;
        const accountId2 = account2 != null ? account2['id'] : null;
        return accountId1 === accountId2;
    }

    static isSameBuyOrder(order1, order2) {
        if (order1 == null && order2 == null) {
            return true;
        }

        return order1 != null && order2 != null
            && order1['BP'] === order2['BP']
            && order1['BQ'] === order2['BQ']
            && order1['CBV'] === order2['CBV'];
    }

    static isSameSellOrder(order1, order2) {
        if (order1 == null && order2 == null) {
            return true;
        }

        return order1 != null && order2 != null
            && order1['SP'] === order2['SP']
            && order1['SQ'] === order2['SQ']
            && order1['CSV'] === order2['CSV'];
    }

    static isDerivativeAccount(account) {
        if (account == null) {
            return false;
        }
        if (typeof account === 'string') {
            return account.startsWith('FDS');
        }
        if (account.id != null) {
            return account.id.startsWith('FDS');
        }
        throw new Error(`Invalid account value: ${JSON.stringify(account)}`);
    }

    static isDerivativeExchange(exchange) {
        return _.includes(derivativeExchanges, exchange);
    }

    static isDerivativeSymbol(symbol) {
        if (symbol == null) {
            return false;
        }
        if (symbol.exchange != null) {
            return this.isDerivativeExchange(symbol.exchange);
        }
        throw new Error(`Invalid symbol value: ${JSON.stringify(symbol)}`);
    }

    static getFDSAccounts(accounts) {
        var fdsAccounts = [];
        accounts.forEach((account) => {
            if (this.isDerivativeAccount(account)) {
                fdsAccounts.push(account);
            }
        });

        return fdsAccounts;
    }

    static convertToNumber(val) {
        if (val == null) {
            return null;
        }
        if (typeof val === 'number') {
            return val;
        }
        return Number(val);
    }

    static formatFDS(text) {
        let check = String(text).search("FDS")
        if (!check) {
            return String(text).replace("FDS", '')
        }
        return text
    }

    static regExp = /[A-Za-z]/;

    static isContainLetter(val) {
        return CommonUtils.regExp.test(val);
    }

    // dat.nt :Bỏ focus khi tab trên các header input 
    static removeHeaderTabIndex() {
        let theads = document.getElementsByTagName("th");
        if (theads.length > 0) {
            for (var i = 0; i < theads.length; i++) {
                if (theads[i].getAttribute('tabindex') != "-1") {
                    theads[i].setAttribute("tabindex", "-1");
                }
            }
        }
    }

    // dat.nt :convert về dạng không dấu
    static removeDiacritics(stringToConvert) {

        var defaultDiacriticsRemovalMap = [
            { 'base': 'A', 'letters': /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g },
            { 'base': 'AA', 'letters': /[\uA732]/g },
            { 'base': 'AE', 'letters': /[\u00C6\u01FC\u01E2]/g },
            { 'base': 'AO', 'letters': /[\uA734]/g },
            { 'base': 'AU', 'letters': /[\uA736]/g },
            { 'base': 'AV', 'letters': /[\uA738\uA73A]/g },
            { 'base': 'AY', 'letters': /[\uA73C]/g },
            { 'base': 'B', 'letters': /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g },
            { 'base': 'C', 'letters': /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g },
            { 'base': 'D', 'letters': /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g },
            { 'base': 'DZ', 'letters': /[\u01F1\u01C4]/g },
            { 'base': 'Dz', 'letters': /[\u01F2\u01C5]/g },
            { 'base': 'E', 'letters': /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g },
            { 'base': 'F', 'letters': /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g },
            { 'base': 'G', 'letters': /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g },
            { 'base': 'H', 'letters': /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g },
            { 'base': 'I', 'letters': /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g },
            { 'base': 'J', 'letters': /[\u004A\u24BF\uFF2A\u0134\u0248]/g },
            { 'base': 'K', 'letters': /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g },
            { 'base': 'L', 'letters': /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g },
            { 'base': 'LJ', 'letters': /[\u01C7]/g },
            { 'base': 'Lj', 'letters': /[\u01C8]/g },
            { 'base': 'M', 'letters': /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g },
            { 'base': 'N', 'letters': /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g },
            { 'base': 'NJ', 'letters': /[\u01CA]/g },
            { 'base': 'Nj', 'letters': /[\u01CB]/g },
            { 'base': 'O', 'letters': /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g },
            { 'base': 'OI', 'letters': /[\u01A2]/g },
            { 'base': 'OO', 'letters': /[\uA74E]/g },
            { 'base': 'OU', 'letters': /[\u0222]/g },
            { 'base': 'P', 'letters': /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g },
            { 'base': 'Q', 'letters': /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g },
            { 'base': 'R', 'letters': /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g },
            { 'base': 'S', 'letters': /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g },
            { 'base': 'T', 'letters': /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g },
            { 'base': 'TZ', 'letters': /[\uA728]/g },
            { 'base': 'U', 'letters': /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g },
            { 'base': 'V', 'letters': /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g },
            { 'base': 'VY', 'letters': /[\uA760]/g },
            { 'base': 'W', 'letters': /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g },
            { 'base': 'X', 'letters': /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g },
            { 'base': 'Y', 'letters': /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g },
            { 'base': 'Z', 'letters': /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g },
            { 'base': 'a', 'letters': /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g },
            { 'base': 'aa', 'letters': /[\uA733]/g },
            { 'base': 'ae', 'letters': /[\u00E6\u01FD\u01E3]/g },
            { 'base': 'ao', 'letters': /[\uA735]/g },
            { 'base': 'au', 'letters': /[\uA737]/g },
            { 'base': 'av', 'letters': /[\uA739\uA73B]/g },
            { 'base': 'ay', 'letters': /[\uA73D]/g },
            { 'base': 'b', 'letters': /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g },
            { 'base': 'c', 'letters': /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g },
            { 'base': 'd', 'letters': /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g },
            { 'base': 'dz', 'letters': /[\u01F3\u01C6]/g },
            { 'base': 'e', 'letters': /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g },
            { 'base': 'f', 'letters': /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g },
            { 'base': 'g', 'letters': /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g },
            { 'base': 'h', 'letters': /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g },
            { 'base': 'hv', 'letters': /[\u0195]/g },
            { 'base': 'i', 'letters': /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g },
            { 'base': 'j', 'letters': /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g },
            { 'base': 'k', 'letters': /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g },
            { 'base': 'l', 'letters': /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g },
            { 'base': 'lj', 'letters': /[\u01C9]/g },
            { 'base': 'm', 'letters': /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g },
            { 'base': 'n', 'letters': /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g },
            { 'base': 'nj', 'letters': /[\u01CC]/g },
            { 'base': 'o', 'letters': /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g },
            { 'base': 'oi', 'letters': /[\u01A3]/g },
            { 'base': 'ou', 'letters': /[\u0223]/g },
            { 'base': 'oo', 'letters': /[\uA74F]/g },
            { 'base': 'p', 'letters': /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g },
            { 'base': 'q', 'letters': /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g },
            { 'base': 'r', 'letters': /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g },
            { 'base': 's', 'letters': /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g },
            { 'base': 't', 'letters': /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g },
            { 'base': 'tz', 'letters': /[\uA729]/g },
            { 'base': 'u', 'letters': /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g },
            { 'base': 'v', 'letters': /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g },
            { 'base': 'vy', 'letters': /[\uA761]/g },
            { 'base': 'w', 'letters': /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g },
            { 'base': 'x', 'letters': /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g },
            { 'base': 'y', 'letters': /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g },
            { 'base': 'z', 'letters': /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g }
        ];

        for (var i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
            stringToConvert = stringToConvert.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
        }

        return stringToConvert;

    }


    //dat.nt : Disable các control select có một lựa chọn (cho vào didmount + didupdate của component có select)
    static disableOneValueSelector() {
        let allSelect = document.getElementsByTagName("SELECT");
        if (allSelect.length > 0) {
            for (var i = 0; i < allSelect.length; i++) {
                allSelect[i].disabled = allSelect[i].disabled ? allSelect[i].disabled : (allSelect[i].length === 1 ? true : false);
            }
        }
    }

    static checkDate = (fromDate, toData) => {
        const state = reduxStore.getState();
        let currentDate = state.app.currentDate ? new Date(state.app.currentDate * 1000) : new Date();
        currentDate.setHours(0, 0, 0, 0);
        const momentCurrentDate = moment(currentDate);
        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toData);

        if (!momentFromDate.isValid() || !momentToDate.isValid()) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return false;
        }


        if (momentToDate.isBefore(momentFromDate)) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return false;
        }

        // Ngày hiện tại < từ ngày và từ ngày < đến ngày ====> tra cứu tương lai

        if (momentCurrentDate.isBefore(momentFromDate) && momentFromDate.isBefore(momentToDate)) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return false;
        }

        return true;
    }

    //dung.le: Sort bảng theo một cột nào đó, chiều tăng/giảm
    static sortRecords(records, sortDirection, sortColumn) {
        if (sortDirection === sortDirObj.ASC) {
            var recordSorted = _.sortBy(records, [sortColumn]);
        }
        else if (sortDirection === sortDirObj.DESC) {
            recordSorted = _.reverse(_.sortBy(records, [sortColumn]));
        }
        else {
            recordSorted = records;
        }
        return recordSorted;
    }

    //dung.le: lấy icon sort tăng/giảm trên header
    static getSortIcon(column, sortDirection, sortColumn) {
        if (sortColumn === column) {
            if (sortDirection === sortDirObj.ASC) {
                return (<i className="fas fa-caret-up" />)
            }
            else if (sortDirection === sortDirObj.DESC) {
                return (<i className="fas fa-caret-down" />)
            }
        }
    }

    // Group Dữ liệu theo trường của phần tử truyền vào
    /**
     * 
     * @param {*} key Trường cần nhóm theo ('name', 'id' ....) 
     * @param {*} dataArr Dữ liệu truyền vào [{...},{...},...]
     * @param {*} sumKeyArr Mảng string chứa tên các trường cần sum ['tradeValue','buyValue',...]
     */
    static groupDataByKeys(key, dataArr, sumKeyArr = []) {
        if (dataArr.length === 0 || !key) return [];
        let groupedData = _.chain(dataArr)
            .groupBy(key)
            //key là giá trị được nhóm theo ,value là các phần tử 1 nhóm
            .map((value, key) => ({ key: key, groupData: value }))
            .value();

        if (sumKeyArr.length > 0) {
            groupedData.forEach(element => {
                let sumLine = {
                    sum: true
                };
                sumKeyArr.forEach(sumkey => {
                    sumLine[sumkey] = 0;
                })
                element.groupData.forEach(subElement => {
                    sumKeyArr.forEach(sumkey => {
                        sumLine[sumkey] = (subElement[sumkey] && parseFloat(subElement[sumkey])) ? sumLine[sumkey] += subElement[sumkey] : sumLine[sumkey];
                    })
                });
                element.groupData.push(sumLine); // Thêm sumline vào từng nhóm
                element['sumLine'] = sumLine; // Tách sumline thành trường riêng mỗi nhóm
            })
        }

        return groupedData;
    }


    static getconditionOrder() {
        let { conditionOrderList } = config;
        let orderList = {};
        if (conditionOrderList) {
            for (let i = 0; i < conditionOrderList.length; i++) {
                if (conditionOrderList[i].isShow === true)
                    orderList[conditionOrderList[i].id] = conditionOrderList[i];
            }
        }
        return orderList;
    }

    static isChrome() {
        // please note, 
        // that IE11 now returns undefined again for window.chrome
        // and new Opera 30 outputs true for window.chrome
        // but needs to check if window.opr is not undefined
        // and new IE Edge outputs to true now for window.chrome
        // and if not iOS Chrome check
        // so use the below updated condition
        var isChromium = window.chrome;
        var winNav = window.navigator;
        var vendorName = winNav.vendor;
        var isOpera = typeof window.opr !== "undefined";
        var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
        var isIOSChrome = winNav.userAgent.match("CriOS");

        if (isIOSChrome) {
            return true;
        } else if (
            isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc." &&
            isOpera === false &&
            isIEedge === false
        ) {
            return true;
        } else {
            return false;
        }
    }


    // dat.nt: dùng để convert dữ liệu từ api instruments do không sử dụng dữ liệu từ socket partial nữa
    // Chỉ dùng 1 lần lúc đầu gọi api instruments
    static processJSONAllStock(proto) {
        var object = {
            Id: proto.id,
            SB: proto.symbol,
            SI: proto.StockId,
            FN: proto.FullName || "",
            TD: proto.tradingdate,
            FC: proto.FloorCode,
            ST: proto.StockType,
            CL: proto.ceiling || "",
            FL: proto.floor,
            RE: proto.reference,
            B3: proto.bidPrice3,
            V3: proto.bidVol3 || "",
            B2: proto.bidPrice2,
            V2: proto.bidVol2 || "",
            B1: proto.bidPrice1,
            V1: proto.bidVol1 || "",
            CP: proto.closePrice,
            // P4A: proto.price4Asset, // Không lấy trường này từ api instrument
            CV: proto.closeVol || "",
            CH: proto.change,
            CHP: Number(proto.changePercent) * 1000 || 0,
            percent: Number(proto.changePercent) * 1000 || 0,
            S1: proto.offerPrice1,
            U1: proto.offerVol1 || "",
            S2: proto.offerPrice2,
            U2: proto.offerVol2 || "",
            S3: proto.offerPrice3,
            U3: proto.offerVol3 || "",
            TT: proto.totalTrading || "",
            TV: proto.totalTradingValue || "",
            AP: parseFloat(Util.formatPrice(proto.averagePrice, 2) * 1000) || "",
            // AP: proto.averagePrice,
            OP: proto.open,
            HI: proto.high,
            LO: proto.low,
            FB: proto.foreignBuy || "",
            FS: proto.foreignSell || "",
            FR: proto.foreignRemain || "",
            SS: proto.Status,
            EX: proto.exchange,
            OI: proto.openInterest,
            LTD: proto.lastTradingDate,
            ULS: proto.underlyingSymbol || "",
            ULP: proto.underlyingPrice,
            IN: proto.IssuerName,
            MD: proto.MaturityDate,
            EP: proto.ExercisePrice,
            ER: proto.ExerciseRatio,
            FO: proto.foreignRoom || "",
            PMP: proto.PT_MATCH_PRICE,
            PMQ: proto.PT_MATCH_QTTY,
            PTQ: proto.PT_TOTAL_TRADED_QTTY,
            PTV: proto.PT_TOTAL_TRADED_VALUE,
            PP: proto.PRIOR_PRICE,
            TO: proto.TOTAL_OFFER_QTTY,
            TB: proto.TOTAL_BID_QTTY,
            // BV4: this.getBV4(proto),
            // SV4: this.getSV4(proto),
            FT: proto.FundType || "",
        };
        object.BEP = this.calBreakEvenPoint(object.EP, object.CP, object.ER);
        //object.ts = Date.now();
        object.s = proto.s || 0; // Haki.: mặc định = 0 nếu undefined
        object.ts = proto.ts || 0; // Haki.: mặc định = 0 nếu undefined
        return object;
    }


    static processJSONCorpBond(proto) {
        var object = {
            SB: proto.symbol,
            FN: proto.FullName,
            TD: proto.tradingdate,
            ST: proto.StockType,
            CE: proto.ceiling,
            FL: proto.floor,
            RE: proto.reference,
            CP: proto.closePrice,
            CV: proto.closeVol,
            CH: proto.change,
            TT: proto.totalTrading,
            TV: proto.totalTradingValue,
            AP: parseFloat(Util.formatPrice(proto.averagePrice, 2) * 1000) || "",
            // AP: proto.averagePrice,
            OP: proto.open,
            HI: proto.high,
            LO: proto.low,
            FR: proto.foreignRemain,
            PMQ: proto.PT_MATCH_QTTY,
            PMP: proto.PT_MATCH_PRICE,
            PTQ: proto.PT_TOTAL_TRADED_QTTY,
            PTV: proto.PT_TOTAL_TRADED_VALUE,
            TSI: proto.tradingSessionID,
            IN: proto.IssuerName,
            MD: proto.MaturityDate,
            TLQ: proto.TotalListingQtty,
            BP: proto.BOND_PERIOD,
            PU: proto.PERIOD_UNIT,
            PRM: proto.PERIOD_REMAIN,
            INT: proto.INTEREST_TYPE,
            IP: proto.INTEREST_PERIOD,
            IU: proto.INTEREST_PERIOD_UNIT,
            ICT: proto.INTEREST_COUPON_TYPE,
            TE: proto.INTERESTRATE_TYPE,
            PT: proto.INTEREST_PAYMENT_TYPE,
            IR: proto.INTEREST_RATE,
            BBQ: proto.PT_BestBidQtty,
            BBP: proto.PT_BestBidPrice,
            OQ: proto.PT_BestOfferQtty,
            BOP: proto.PT_BestOfferPrice,
            TBQ: proto.PT_TotalBidQtty,
            TOQ: proto.PT_TotalOfferQtty,
            MQ: proto.PT_MaxQtty,
            MP: proto.PT_MaxPrice,
            MIQ: proto.PT_MinQtty,
            MIP: proto.PT_MinPrice,
            ISD: proto.IssueDate,
            CR: proto.characteristics,
            STS: proto.securityTradingStatus,
            TSS: proto.tradSesStatus,
            PV: proto.parValue,
            CHP: proto.changePercent,
            TS: proto.ts,
        };
        // if (object.CH && object.RE && object.RE != 0) {
        //     object.percent = ((Number(object.CH) / Number(object.RE)) * 100 * 1000) // tính % thay đổi
        // }

        // object.BEP = this.calBreakEvenPoint(object.EP, object.CP, object.ER);
        // //object.ts = Date.now();
        object.s = proto.s || 0; // Haki.: mặc định = 0 nếu undefined
        object.ts = proto.ts || 0; // Haki.: mặc định = 0 nếu undefined
        return object;
    }


    // static getBV4 = stock => {
    //     var bV4 = 0;
    //     if (
    //         (stock.FloorCode === floorCode.HNX ||
    //             stock.FloorCode === floorCode.UPCOM) &&
    //         stock.TOTAL_BID_QTTY
    //     ) {
    //         bV4 = stock.TOTAL_BID_QTTY
    //     }
    //     return bV4;
    // };

    // static getSV4 = stock => {
    //     var sV4 = 0;
    //     if (
    //         (stock.FloorCode === floorCode.HNX ||
    //             stock.FloorCode === floorCode.UPCOM) &&
    //         stock.TOTAL_OFFER_QTTY
    //     ) {
    //         sV4 = stock.TOTAL_OFFER_QTTY
    //     }
    //     return sV4;
    // };

    //** Logic Tính điểm hòa vốn */
    static calBreakEvenPoint(exercisePrice, closePrice, exerciseRatio) {
        let breakEventPoint = 0;
        if (Number.parseFloat(exercisePrice) && Number.parseFloat(closePrice) && exerciseRatio) {
            breakEventPoint = (exercisePrice ? Number.parseFloat(exercisePrice) : 0) + ((closePrice ? closePrice : 0) * (exerciseRatio ? Number.parseFloat(exerciseRatio.split(":")[0]) : 0));
        }
        return breakEventPoint
    }

    //** checkSeqApi: Check seq theo trường BasicPrice của Api để override instrument */
    static checkSeqApi(seqInstrument, seqApi) {
        // return false
        if (seqApi == "FLEX") { //check s của api trả ra == "FLEX"
            return true
        } else {
            if ((seqInstrument && seqApi) && (Number(seqInstrument) > Number(seqApi))) {
                return false
            }
        }
        return true // Mặc định lấy theo api
    }

    //** Logic lấy giá trong giờ, ngoài giờ giao dịch*/
    static getPriceInOutHourByKey(instrument, key, fallback) {
        // Nếu trong giờ (Nhận được update của instrument) ==> Lấy theo trường instrument
        // Nếu ngoài giờ (Không nhận được update của instrument) ===> Lấy theo giá tham chiếu
        // fallback =  reference || ceiling || floor
        //console.log("getPriceInOutHourByKey_1", instrument, key, fallback)
        if (instrument && Object.keys(instrument).length > 0) {
            const price = instrument[key];
            //console.log("getPriceInOutHourByKey_2", price)
            if (price == "ATC" || price == "ATO" || !price) return fallback // Nếu giá = ATC và giá = ATO thì lấy giá tham chiếu
            if (price && price !== 0) {
                return price;
            }
        }
        return fallback
    }

    static _setCookie(options) {
        var cookie_string = options.name + "=" + options.value;
        if (options.path) {
            cookie_string += "; path=" + escape(options.path);
        }

        if (options.domain) {
            cookie_string += "; domain=" + escape(options.domain);
        }
        //https allow secure
        // if (options.secure) {
        // 	cookie_string += '; secure';
        // }

        if (options.expires) {
            cookie_string += "; expires=" + options.expires;
        }

        document.cookie = cookie_string;
        return null;
    }

    static _getCookie(name) {
        var i,
            x,
            y,
            ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == name) {
                return unescape(y);
            }
        }
        return null;
    }


    static setIsStep2Authenticated = (value, options = {}) => {
        const cookieOptions = {
            name: Step2Authenticated,
            value: value,
            path: '/',
            secure: true,
            domain
        };

        emitter.emit('IS_SAVE_VERIFIED_CHANGE', value);
        sessionStorage.setItem('isTradeAuthenticated', value);
        CommonUtils._setCookie(cookieOptions);
    };

    static setCookie = (name, value, path, secure, domain) => {
        CommonUtils._setCookie({
            name: name,
            value: value,
            path: '/',
            secure: true,
            //expires: new Date(Date.now() + 31536000000),
            domain
        });
    };

    static checkDataBodyNotNull(obj) {
        for (var key in obj) {
            if (obj[key] == null) {
                obj[key] = ''
            }
        }
        return obj;
    }

    static processJSON_OrderBook_Snapshot_Api(orderbookSnapshots) {
        let returnSnapshot = [];
        try {
            if (orderbookSnapshots.length > 0) {
                orderbookSnapshots.forEach(element => {
                    returnSnapshot.push({
                        id: element.topnpriceid ? element.topnpriceid : '',
                        SB: element.Symbol ? element.Symbol : '',
                        TOP: element.Top ? element.Top : '',
                        BP: element.BuyPrice ? element.BuyPrice : 0,
                        BQ: element.BuyQuantity ? element.BuyQuantity : 0,
                        CBV: element.CumulativeBuyVolume ? element.CumulativeBuyVolume : 0,
                        SP: element.SellPrice ? element.SellPrice : 0,
                        SQ: element.SellQuantity ? element.SellQuantity : 0,
                        CSV: element.CumulativeSellVolume ? element.CumulativeSellVolume : 0,
                        ACT: element.Action ? element.Action : '',
                    });
                })
            }
            return returnSnapshot
        } catch (error) {
            //console.log('Error parsing TopnPrice Snapshot', error);
            return returnSnapshot
        }
    }

    static createPopupWin(pageURL, pageTitle, popupWinWidth, popupWinHeight) {
        var left = (window.screen.width - popupWinWidth) / 2;
        var top = (window.screen.height - popupWinHeight) / 3;
        var myWindow = window.open(pageURL, pageTitle, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + popupWinWidth + ', height=' + popupWinHeight + ', top=' + top + ', left=' + left);
        // myWindow.moveTo(left, top);
        // myWindow.focus();
    }

    static processJSON_MathchedInfos(trade) {
        // let object = {
        // 	time: trade.FT,
        // 	qtty: Util.formatVol(trade.FV),
        // 	amt: Util.formatPrice(trade.FMP, 2),
        // 	change: Util.formatPrice(trade.FCV * 10, 2),
        // };
        let object = {
            time: trade.FT,
            qtty: trade.FV,
            amt: trade.FMP,
            change: trade.FCV,
            totalQtty: trade.AVO,
            sellBuy: trade.LC,
            symbol: trade.SB
        };
        return object;
    }

    static formatPrice(value, digits) {
        if (value !== "ATC" && value !== "ATO" && value !== "PLO") {
            return value && value != 0
                ? (Number(value) / 1000).toFixed(digits)
                : "";
        }
        return value;
    }

    static formatAccounting(number, fixed) {
        if (number && number != "") {
            let result =
                fixed > 0
                    ? Number(number)
                        .toFixed(fixed)
                        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                    : CommonUtils.formatNumberWithCommas(number.toFixed(0));

            if (Number(result) == 0) {
                return "";
            }

            return result;
        } else {
            return "";
        }
    }

    // 1000 -> 1,000
    // 23456789 -> 23,456,789
    static formatNumberWithCommas(nStr) {
        nStr += "";
        let x = nStr.split(".");
        let x1 = x[0];
        let x2 = x.length > 1 ? "." + x[1] : "";
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, "$1,$2");
        }
        return x1 + x2;
    }

    static processJSON_ChartMathchedInfos(trades) {
        let object = [];
        try {
            // //console.log("Haki.:<util>.:processJSON_ChartMathchedInfos().: trades= start")
            if (trades && trades.length > 0) {
                let grouped = _(trades)
                    .groupBy("FMP")
                    .map((trade, id) => ({
                        FMP: id,
                        FV: _.sumBy(trade, (x) => Number(x.FV)),
                    }))
                    .value();
                object = _.sortBy(grouped, (item) => Number(item.FMP));
            }
            // //console.log("Haki.:<util>.:processJSON_ChartMathchedInfos().: trades= end")
        } catch (error) {
            //console.log("Haki.:<util>.:processJSON_ChartMathchedInfos().: error=",error);
            return object;
        }
        return object;
    }

    static processJSON_ChartMathchedInfosForApi(trades) {
        let object = [];
        try {
            // //console.log("Haki.:<util>.:processJSON_ChartMathchedInfos().: trades= start")
            if (trades && trades.length > 0) {
                let grouped = _(trades)
                    .groupBy("formattedMatchPrice")
                    .map((trade, id) => ({
                        FMP: id,
                        FV: _.sumBy(trade, (x) => Number(x.formattedVol)),
                    }))
                    .value();
                object = _.sortBy(grouped, (item) => Number(item.FMP));
            }
            // //console.log("Haki.:<util>.:processJSON_ChartMathchedInfos().: trades= end")
        } catch (error) {
            //console.log("Haki.:<util>.:processJSON_ChartMathchedInfos().: error=",error);
            return object;
        }
        return object;
    }

    static convertJSON_DTChartMathched(trades, stockData, notSortForm = false) {
        let object = {
            xDT: [],
            yDT: [],
        };
        let { RE: basicPrice, CL: ceilingPrice, FL: floorPrice, EX: exchange } =
            stockData ? stockData : {};

        let isDerivativeSymbol = exchange && exchange === 'XHNF';
        if (!isDerivativeSymbol) {
            ceilingPrice = parseFloat(CommonUtils.formatPrice(ceilingPrice, 2));
            basicPrice = parseFloat(CommonUtils.formatPrice(basicPrice, 2));
            floorPrice = parseFloat(CommonUtils.formatPrice(floorPrice, 2));
        }
        for (let key in trades) {
            if (trades[key].FMP > 0 && trades[key].FV > 0) {
                if (!notSortForm) {
                    object.xDT.push(CommonUtils.formatPrice(trades[key].FMP, 2))
                } else {
                    object.xDT.push(Number(trades[key].FMP).toFixed(2));
                }

                let pv_color = "";
                let amt = !isDerivativeSymbol ? parseFloat(CommonUtils.formatPrice(trades[key].FMP, 2)) : Number(trades[key].FMP);
                // if (amt == floorPrice) pv_color = "#0abde3";
                // if (amt == basicPrice) pv_color = "#f7ff31";
                // if (amt == ceilingPrice) pv_color = "#f0f";
                // if (floorPrice < amt && amt < basicPrice) pv_color = "#f00";
                // if (basicPrice < amt && amt < ceilingPrice) pv_color = "#0f0";
                if (amt == floorPrice) pv_color = "#0abde3";
                // if (amt == basicPrice) pv_color = "#F1C238";
                if (amt == basicPrice) pv_color = "#F9B525";
                if (amt == ceilingPrice) pv_color = "#FF00FF";
                if (floorPrice < amt && amt < basicPrice) pv_color = "#F93E3E";
                // if (basicPrice < amt && amt < ceilingPrice) pv_color = "#12CC68";
                if (basicPrice < amt && amt < ceilingPrice) pv_color = "#21DB77";
                object.yDT.push({
                    y: Number(trades[key].FV),
                    color: pv_color,
                });
                // object.yDT.push({ x: Number(trades[key].totalqtty), color: pv_color })
            }
        }
        return object;
    }

    /**
     * Convert dữ liệu cho đồ thị chi tiết mã
     * @param {*} chartData 
     * @returns 
     */
    static processJSON_chartindayApi(chartData) {
        let dataLength = chartData.formattedtime.length;
        let result = {
            chartIndayData: [],
            chartInfoData: []
        };
        if (dataLength > 0) {
            // Dữ liệu khớp theo ngày
            for (var i = 0; i < dataLength; i++) {
                let dataObj = {
                    time: chartData.formattedtime[i],
                    qtty: chartData.volume[i].toString(),
                    amt: parseFloat(chartData.close[i] * 1000).toString()
                };
                result.chartIndayData.push(dataObj);
            }

        }

        return result;
    }


    static getListIndex(code) {
        let indexDrawChart
        if (code) {
            indexDrawChart = config.ALL_INDEXES.find(item => item.code == code);
            return indexDrawChart && indexDrawChart.code;
        }
        else {
            indexDrawChart = config.ALL_INDEXES.map(item => {
                return item.code;
            })
        }
        let allIndexArr = indexDrawChart.concat(ListIndexNotDrawChart)
        return allIndexArr;
    }

    // Convert giá trị giá đặt lệnh để validate đảm bảo giữ rule validate như cũ không bị sửa nhiều (VD: 15.5 ---> 15500)
    static convertPriceValueForValidate = (value, exchange) => {
        if (this.isDerivativeExchange(exchange)) {
            // return parseFloat(value) ? Math.round(value * 1) : value;
            return value;
        } else {
            return parseFloat(value) ? Math.round(value * 1000) : (value || 0);
        }
    }

    // Convert giá trị giá đặt lệnh để điền vào ô nhập giá (VD: 15500 --> 15.5)
    static convertPriceValueForPriceInput = (value, exchange) => {
        if (this.isDerivativeExchange(exchange)) {
            // return parseFloat(value) ? (parseInt(value) / 1) : value;
            return value;
        } else {
            return parseFloat(value) ? (parseInt(value) / 1000) : value;
        }
    }

    /**
     * Lấy giây theo string từ thời gian
     * @param {*} timeStr 
     * @returns 
     */
    static getSecondFromTimeString = (timeStr) => {
        // example: 09:03:13 -> 9*3600 + 3*60 + 13 = 32593 seconds
        let arr = timeStr.split(":");
        return arr[0] * 3600 + arr[1] * 60 + arr[2];
    };

    /**
     * Sắp xếp dữ liệu thỏa thuận theo trường Time
     * @param {*} orders 
     * @returns 
     */
    static sortPutthrough = orders => {
        // only sort by time
        return orders.sort((order1, order2) => {
            return (
                this.getSecondFromTimeString(order2.Time) -
                this.getSecondFromTimeString(order1.Time)
            );
        });
    };
    /**
     * Lấy class màu cho putthrough
     * @param {*} stock // ptmatch
     * @returns 
     */
    static getClazzPutThrough = (stock) => {
        if (!stock || !stock.Price) return '';
        let price = stock.Price;
        let fmValue = price * 1;

        if (isNaN(fmValue)) {
            if (typeof price === 'string' && price.length > 0) {
                return '';
            }
            return 'text-ref-price';
        }

        if (fmValue == 0) return 'text-ref-price';
        if (fmValue == stock.ceiling * 1) return 'text-ceil-price';
        if (fmValue == stock.floor * 1) return 'text-floor-price';

        if (fmValue > stock.reference * 1) return 'text-priceboard-green';
        if (fmValue < stock.reference * 1) return 'text-priceboard-red';

        return 'text-ref-price';
    };

    static biggestToSmallestUnixTime(a, b) {
        return b.UT - a.UT;
    }

    static comparePermissionModule = (permission) => {
        const state = reduxStore.getState();
        let _permissionModuleInfo = state.user.userInfo.permissionModuleInfo
        return _permissionModuleInfo.some((item, index) => {
            return item.otmncode.includes(permission)
        })
    }

    static openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    static getObjIsVal = (obj, listKey) => {
        // Haki.: Validate dữ liệu property của obj
        try {
            if (listKey.endsWith("|")) {
                //repalce '|' cuối nếu bị thừa spec
                listKey = listKey.substring(0, listKey.length - 1) + '';
            }
            let arrKey = _.split(listKey, '|');
            let isCheck = true
            if (arrKey.length > 0) {
                let _obj = _.cloneDeep(obj)
                for (let i = 0; i < arrKey.length; i++) {
                    if (arrKey[i] === undefined || arrKey[i] === null || arrKey[i] === '') {
                        isCheck = false;
                        break;
                    }
                    else {
                        if (typeof (_obj[arrKey[i]]) == 'undefined') {
                            isCheck = false;
                            break;
                        }
                    }
                    _obj = _obj[arrKey[i]]
                }
                if (isCheck) {
                    return _obj
                } else {
                    return undefined
                }
            }
            else {
                return undefined
            }
        }
        catch (e) {
            //console.log('getObjIsVal.:Err=', e)
            return undefined;
        }
    };

    static getClassCheckValue = (value, isColor = false) => {
        let _value = Number(value)
        let _class = ""
        if (!value && isColor) {
            _class = "text-yellow"
        } else {
            if (_value > 0) {
                _class = "text-green"
            } else if (_value < 0) {
                // _class = "text-red text-font-600"
                _class = "text-red"
            } else if (_value == 0) {
                _class = "text-yellow"
            } else {
                _class = ""
            }
        }

        return _class
    }

    populateMarketInfo(marketInfo) {
        let info = {};
        if (marketInfo) {
            let chart = this.props.listChart.find(item => item.code === marketInfo.floorCode);
            info.indexName = chart ? chart.title : '';
            info.marketIndex = Util.formatAccounting(marketInfo.marketIndex, 2);
            info.changeValue = marketInfo.changedIndex;

            // info.changePercent = info.changeValue / marketInfo.priorMarketIndex;
            info.changePercent = marketInfo.percentIndex; // Haki.: Lấy trực tiếp từ data trả về
            info.changeValue = Util.formatAccounting(
                // Math.abs(info.changeValue),
                info.changeValue,
                2
            );

            // Tổng KL hiển thị = Tổng KL + Tổng KL lô lẻ
            // Tổng GT hiển thị = Tổng GT + Tổng GT lô lẻ
            info.totalShareTradedInfo = Util.formatAccounting(
                Number(marketInfo.totalShareTraded) + Number(marketInfo.oddLotTotalVolume),
                0
            );
            info.totalValueTradedInfo = Util.formatNumberShortLargeByDigit(Number(marketInfo.totalValueTraded) + Number(marketInfo.oddLotTotalValue), formatNumberTypes.ONE_DIGITS)

            info.totalShareTraded = Util.formatAccounting(
                marketInfo.totalShareTraded,
                0
            );
            // info.totalValueTraded = Util.formatAccounting(
            // 	marketInfo.totalValueTraded,
            // 	3
            // );
            info.totalValueTraded = Util.formatNumberShortLarge(marketInfo.totalValueTraded)

            info.advance = marketInfo.advance;
            info.noChange = marketInfo.noChange;
            info.decline = marketInfo.decline;
            info.numberOfCe = marketInfo.numberOfCe;
            info.numberOfFl = marketInfo.numberOfFl;
            info.colorClass = Util.getMarketInfoClasses(marketInfo).color;
            info.arrowClass = Util.getMarketInfoClasses(marketInfo).arrow;
            info.statusKey = getMarketStatusKey(marketInfo);
            info.floorCode = marketInfo.floorCode;
            info.priorMarketIndex = marketInfo.priorMarketIndex;
        }

        return info;
    }

    static middlewareOrder = () => {
        let isSaveTradeAuthenticatedFromSession = sessionStorage.getItem('isTradeAuthenticated');
        const isSaveTradeAuthenticated = isSaveTradeAuthenticatedFromSession && isSaveTradeAuthenticatedFromSession === 'true' ? true : false;
        if (isSaveTradeAuthenticated === false) {
            sessionStorage.setItem('isTradeAuthenticated', "true")
        }
        dispatch(actions.clearInfoCheckAuth())
    }

    static clearTradeAuthInfo = () => {
        // VIX: Không clear xác thực trên server khi thực hiện đặt lệnh bị lỗi
        // emitter.emit('IS_SAVE_VERIFIED_CHANGE', false);
        // tradeApiService.disableTwoFactorCode().then().catch(err => {
        //     //console.log('Error: Disable verify ', err); // Có thể bắn ra ở case gọi đặt lệnh bị hết phiên
        // })
        // sessionStorage.setItem('isTradeAuthenticated', "false");  // set giá trị lưu xác thực vào session
    }

    static convertDateToTime(inputDate) { // 30/04/2021
        const [day, month, year] = inputDate.split('/');
        const date = new Date(+year, month - 1, +day);
        const dueDate = date.getTime();
        return dueDate
    }

    static convertDateByType(date, type, character = "/") {
        if (date) {
            if (type == "date_time") { //"2023-01-14T10:44:19.000Z"
                let result1 = date.split(".")[0];
                let result2 = result1.split("T")[1];
                return result2
            } else if (type == "date") { //"2023-01-13T00:00:00.000Z"
                let result1 = date.split("T")[0];
                let result2 = result1.split("-");
                let year = result2[0]
                let month = result2[1]
                let day = result2[2]
                return day + character + month + character + year
            }
            return "-"
        }
        return "-"
    }

    static convertLastTradingDate(LTD) { // 20221229
        if (!LTD) return LTD;
        let year = LTD.substring(0, 4);
        let month = LTD.substring(4, 6);
        let date = LTD.substring(6, 8);
        let convertedDate = `${date}/${month}/${year}`;
        return convertedDate;
    }

    static openLayoutByKey(keyLayout,) { // 20221229
        const state = reduxStore.getState();
        const layoutPage = state.layout.layoutPage
        let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        let curLayoutPage = layoutPage[_currentLayoutPageActive]
        let _accountInfo = curLayoutPage && curLayoutPage["accountInfo"]
        // Lọc ra tiểu khoản TPRL
        let accountIdTPRL = null
        let flag = true
        _.forEach(_accountInfo.accounts, item => {
            if (item.typename.includes("TPRL")) {
                accountIdTPRL = item.id
            }
        });
        // case mở layout mới từ tab  khác TPRL: nêu có tiểu khoản TPRL thì set = tiểu khoản TPRL ko thì set = null
        if (CommonUtils.getOrderTypeUseLayout(keyLayout) == ORDER_TYPE_USE.TPRL_OR_ORDER || CommonUtils.getOrderTypeUseLayout(keyLayout) == ORDER_TYPE_USE.TPRL_NM_ORDER && _accountInfo) {
            if (accountIdTPRL) {
                _accountInfo.currentAccountId = accountIdTPRL
            } else {
                _accountInfo.currentAccountId = null
            }
            flag = false
            // console.log("openLayoutByKey_1", _accountInfo)
        }

        //  case mở layout mới từ tab TPRL: nếu accountid hiện = null và ko có tiểu khoản tprl thì set mặc định là thằng đầu tiên
        if (_accountInfo && !_accountInfo.currentAccountId && flag) {
            // console.log("openLayoutByKey_2", _accountInfo)
            if (!accountIdTPRL) {
                _accountInfo.currentAccountId = _accountInfo.accounts[0] && _accountInfo.accounts[0].id
            }
        }
        // console.log("openLayoutByKey", _accountInfo)

        dispatch(actions.setIsOpenModalHaveData("QuickOrder", {
            isOpenQuickOrder: false
        }))

        //console.log("binh_openLayoutByKey", layoutPage, keyLayout, layoutPage[keyLayout])
        Object.keys(layoutPage).map((item, i) => {
            if (layoutPage[keyLayout] && layoutPage[keyLayout].showMenu) {
                dispatch(actions.changeLayoutPageActive(keyLayout));
                dispatch(push(layoutPage[keyLayout].activeURL));
                return
            } else {
                batch(() => {
                    dispatch(actions.updatedLayoutPage({
                        "showMenu": true,
                        "accountInfo": _accountInfo
                    }, keyLayout))
                    dispatch(push(layoutPage[keyLayout].activeURL));
                    dispatch(actions.changeLayoutPageActive(keyLayout))
                    dispatch(actions.updatedSymbolLayoutPage(null, "search_layout"))
                })
            }
        })
    }

    static onChangeTitleApp = (title, username) => {
        document.title = title ? `${APP_TITLE} - ` + username + ' - ' + title : `${APP_TITLE}`;
    }

    static getWidthId = (id) => {
        let width = $(`#${id}`).width()
        return width || 0
    }

    static getLanguageAvailable = (language) => {
        if (language === "kr" || language === "jp") {
            return "en"
        }
        return language
    };


    static convertRound = (value) => { //0.9146111111 => 0.92
        let a = value
        let b = a.toFixed(6)
        let c = Math.round(b * 100000)
        c = (c / 100000)
        return c
    }


    static callBackFillPriceOrder = async (symbolId, arrKey) => { // arrKey = [ ORDER_TYPE_USE.QUICK_ORDER, ORDER_TYPE_USE.NORMAL_ORDER, ORDER_TYPE_USE.PRO_TRADE_ORDER, ]

        if (symbolId && arrKey && arrKey.length > 0) {

            arrKey.map(async (item, i) => {
                if (item == ORDER_TYPE_USE.QUICK_ORDER) {
                    await dispatch(actions.updatePlaceQuickOrder(
                        {
                            symbolTempCheck: symbolId,
                            isCheckFillPrice: true,
                        }, item
                    ))
                }

                if (item == ORDER_TYPE_USE.NORMAL_ORDER || item == ORDER_TYPE_USE.PRO_TRADE_ORDER) {
                    let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
                    const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent

                    if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
                        if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                            await dispatch(actions.updatePlaceQuickOrder(
                                {
                                    symbolTempCheck: symbolId,
                                    isCheckFillPrice: true,
                                }, item
                            ))
                        }
                        if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                            await dispatch(actions.updateProTrade({
                                symbolTempCheck: symbolId,
                                isCheckFillPrice: true,
                            }))
                        }
                    }
                }
            })
        }
    }



    static fetchApiInstrumentsBySymbol = async (symbols, arrKey) => {
        // //console.log("fetchApiInstrumentsBySymbol", symbols)
        if (symbols && symbols.length > 0) {
            let uniqueSymbols = symbols.filter((element) => !_.isEmpty(element))
            uniqueSymbols = [...new Set(uniqueSymbols)]; // Remove duplicates using Set
            let _symbols = uniqueSymbols.join(',')
            // //console.log("fetchApiInstrumentsBySymbol_2", { uniqueSymbols, symbols, _symbols })
            if (_symbols && _symbols.length > 0) {
                await symbolService.getInstrument(_symbols)
                    .then(async data => {
                        if (data && data.length > 0) {
                            await dispatch(actions.updateInstrumentByData(data))
                            if (arrKey && arrKey.length > 0) {
                                await CommonUtils.callBackFillPriceOrder(_symbols, arrKey)
                            }
                            return true
                        }
                    })
                    .catch(err => {
                        return true
                    })
            }
        }
    }

    static transformSymbolsDataInstruments = (data) => {
        const exchanges = [];
        const symbols = [];
        const symbolWithIndex = {};
        const symbolInfoForInstrument = {};
        const symbolInfos = data;
        _.map(symbolInfos, (event, index) => {
            const symbolInfo = event;
            const { symbol, FullName, exchange, StockType } = symbolInfo;
            // if (exchange === '' || exchange === undefined) {
            //     continue;
            // }
            if (!exchanges.includes(exchange)) {
                exchanges.push(exchange);
            }

            const convertedInfo = {
                id: symbol,
                exchange: exchange,
                desc: FullName,
                stockType: StockType,
                ...symbolInfo
            };
            symbolInfoForInstrument[symbol] = CommonUtils.processJSONAllStock(convertedInfo);
            symbolWithIndex[symbol] = convertedInfo;
            symbols.push(convertedInfo);
        })
        // for (let i = 0; i < symbolInfos.length; i++) {
        //     const symbolInfo = symbolInfos[i];
        //     const { symbol, FullName, exchange, StockType } = symbolInfo;
        //     if (exchange === '' || exchange === undefined) {
        //         continue;
        //     }

        //     if (!exchanges.includes(exchange)) {
        //         exchanges.push(exchange);
        //     }

        //     const convertedInfo = {
        //         id: symbol,
        //         exchange: exchange,
        //         desc: FullName,
        //         stockType: StockType,
        //         ...symbolInfo
        //     };
        //     symbolInfoForInstrument[symbol] = CommonUtils.processJSONAllStock(convertedInfo);
        //     symbolWithIndex[symbol] = convertedInfo;
        //     symbols.push(convertedInfo);
        // }
        return { exchanges: exchanges, symbols: symbols, symbolWithIndex, symbolInfoForInstrument };
    };

    static transformCorpbondSymbolsData = (data) => {
        const exchanges = [];
        const symbols = [];
        const symbolWithIndex = {};
        const symbolInfoForInstrument = {};
        const symbolInfos = data;
        const quotes = {};
        for (let i = 0; i < symbolInfos.length; i++) {
            const symbolInfo = symbolInfos[i];
            const { symbol, FullName, StockType } = symbolInfo;

            //tam thoi fix them du lieu vao mang quotes, xoa mang all quotes sau
            const { ceiling, floor, reference, PT_BestBidPrice: bidPrice1, PT_BestOfferPrice: offerPrice1, ExercisePrice, ExerciseRatio, closePrice } = symbolInfo;

            // Tính điểm hòa vốn
            // let breakEvenPoint = CommonUtils.calBreakEvenPoint(ExercisePrice, closePrice, ExerciseRatio);


            const convertedInfo = {
                id: symbol,
                // exchange: exchange,
                desc: FullName,
                stockType: StockType,
                ...symbolInfo,
                // breakEvenPoint: breakEvenPoint
            };
            symbolInfoForInstrument[symbol] = CommonUtils.processJSONCorpBond(convertedInfo);
            symbolWithIndex[symbol] = convertedInfo;
            symbols.push(convertedInfo);

            //tam thoi fix them du lieu vao mang quotes, xoa mang all quotes sau
            quotes[symbol] = { symbol, ceiling, floor, reference, FullName, StockType, exchange: '', bidPrice1, offerPrice1 };
        }
        return { symbols: symbols, symbolWithIndex, symbolInfoForInstrument, quotes };
    };

    /**
     * True nếu thiết bị sử dụng touchscreen (Trừ các thiết bị laptop hoặc màn hình có touchscreen như surface)
     */
    static isTouchDevice = () => {
        if ("ontouchstart" in document.documentElement) {
            return true;
        }
        return false;
    };


    static getClassbyAction = (action) => {
        let _class = ""
        if (action === orderActions.BUY) {
            _class = "bg-buy"
        } else if (action === orderActions.SELL) {
            _class = "bg-sell"
        } else {
            _class = "text-not-active"
        }
        return _class
    }

    static getSaveVerifiedSession = () => { // Lấy lưu xác thực theo session
        let isTradeAuthenticated = sessionStorage.getItem('isTradeAuthenticated')

        if (isTradeAuthenticated) {
            if (JSON.parse(isTradeAuthenticated) === true) {
                return true
            } else {
                return false
            }
        }
        return false
    }

    static getNewRowLanguage = (language) => {
        let obj = {}
        if (language === 'en') {
            obj = {
                id: "All",
                entypename: "Basis",
                accounttype: "ALL"
            }
        } else if (language === 'jp') {
            obj = {
                id: "全て",
                entypename: "株式市場",
                accounttype: "ALL"
            }
        } else if (language === 'kr') {
            obj = {
                id: "전체",
                entypename: "주식",
                accounttype: "ALL"
            }
        } else {
            obj = {
                id: "Tất cả",
                entypename: "Cơ sở",
                accounttype: "ALL"
            }
        }
        return obj
    }

    static getPlaceHolderInput = (language) => {
        if (language === 'en') {
            return "Import"
        } else if (language === 'jp') {
            return '株数をご入力してください。'
        } else if (language === 'kr') {
            return '수입'
        } else {
            return 'Nhập'
        }
    }

    static renderIconTheme = (dark, light, darkblue) => {
        const state = reduxStore.getState();
        const defaultTheme = state.user.userInfo.defaultTheme
        switch (defaultTheme) {
            case 'dark':
                return dark
            case 'light':
                return light
            case 'dark-blue':
                return darkblue
            default:
                return ''
                break;
        }
    }

    static setPlaceQuickOrder = (cb) => { // set quick order

        dispatch(actions.setIsOpenModalHaveData("QuickOrder", {
            isOpenQuickOrder: true
        }))
        cb && cb()
    }


    static getValidValue = (val) => {
        if (!val) {
            return 0;
        }
        return val;
    }

    static getValueConfigPriceBoard = (currentCollectionId, isLoggedIn, priceBoardConfig) => {
        // const state = reduxStore.getState();
        // let isLoggedIn = state.user.isLoggedIn;
        // let currentCollectionId = state.componentState.symbolSelector.currentCollectionId;
        if (isLoggedIn) {
            const keyGet = "pinned_" + currentCollectionId
            // const priceBoardConfig = state.user.priceBoardConfig;
            if (priceBoardConfig && priceBoardConfig[keyGet]) return JSON.stringify(priceBoardConfig[keyGet]);
            return;
        }
        else {
            const keyGet = "pinned_" + currentCollectionId;
            const settingUtilities = getValueFromLocalStorage(keyGet);
            if (settingUtilities) {
                return settingUtilities;
            } else {
                return;
            }
        }
    }

    static setValueConfigPriceBoard = (data) => {
        const state = reduxStore.getState();
        let isLoggedIn = state.user.isLoggedIn;
        let currentCollectionId = state.componentState.symbolSelector.currentCollectionId;
        if (isLoggedIn) {
            const keySave = "pinned_" + currentCollectionId
            let clonePriceBoardConfig = state.user.priceBoardConfig;
            //let dataUpdate = priceBoardConfig[keySave];
            if (clonePriceBoardConfig) {
                clonePriceBoardConfig[keySave] = [...data]
            }
            else {
                clonePriceBoardConfig = {
                    [keySave]: [...data]
                }
            }
            const dataSave = {
                ...clonePriceBoardConfig
            }
            dispatch(actions.setConfigPriceBoard(dataSave))
        }
        else {
            const keySave = "pinned_" + currentCollectionId;
            setValueToLocalStorage(keySave, JSON.stringify(data));
        }
    }
    // get list đồ thị index (bảng giá)
    static getListMarketChartCurrent = (type) => {
        let listIndexs = []
        let listMarketFromSessionStorage = getSessionStorage(SESSION_STORAGE_LIST_MARKET_CHART)
        if (listMarketFromSessionStorage && listMarketFromSessionStorage.length > 0) {
            listIndexs = listMarketFromSessionStorage
            // return listMarketFromSessionStorage
        } else {
            listIndexs = _.filter(config.ALL_INDEXES, (e) => e.isDefault == true)
            // Mặc định khai báo theo isDefault ở config
            // return _.filter(config.ALL_INDEXES, (e) => e.isDefault == true)
        }
        if (type == "arr") {
            let _listIndexs = listIndexs
            listIndexs = []
            _.map(_listIndexs, (e) => {
                listIndexs.push(e.code)
            })
        }
        return listIndexs
    }

    //Lưu tab active bảng giá theo user
    static saveCurrentCollectionIdByAccount = (username, value) => {
        //const storeKey = "lastLayoutActive-" + username;
        let arrCurrentCollectionId = [];
        const arrCurrentCollectionIdLocal = this.getCurrentCollectionIdByLocal();
        arrCurrentCollectionId = JSON.parse(arrCurrentCollectionIdLocal) ? JSON.parse(arrCurrentCollectionIdLocal) : [];
        //Tim kiem username da duoc luu tab active neu co roi thi update object neu chua co thi add them vao arr roi luu local
        var index = _.findIndex(arrCurrentCollectionId, { username: username });
        if (index > -1) {
            // Replace item at index using native splice
            arrCurrentCollectionId.splice(index, 1, { username: username, currentCollectionId: value });
        }
        else {
            const obj = { username: username, currentCollectionId: value }
            arrCurrentCollectionId.push(obj)
        }
        //localStorage.setItem(storeKeyUsername,username);
        localStorage.setItem(storeKeyCurrentCollectionId, JSON.stringify(arrCurrentCollectionId));
    }

    static getCurrentCollectionIdByAccount = (username) => {
        const arrCurrentCollectionIdLocal = this.getCurrentCollectionIdByLocal();
        let arrCurrentCollectionId = JSON.parse(arrCurrentCollectionIdLocal) ? JSON.parse(arrCurrentCollectionIdLocal) : [];
        const objCurrentCollectionId = _.find(arrCurrentCollectionId, { username: username });
        if (objCurrentCollectionId) return objCurrentCollectionId.currentCollectionId;
        else return null;
        //return objLayoutCurrentActive && objLayoutCurrentActive.layoutActive;
    }

    static getCurrentCollectionIdByLocal = () => {
        //const storeKeyUsername = "usernameLastSaveLayoutActive";
        const result = localStorage.getItem(storeKeyCurrentCollectionId);
        if (result != 'null' && result != null && result != undefined && result != 'undefined' && result != '') {
            return result;
        } else {
            return '[]';
        }
        //return localStorage.getItem(storeKeyLayout);
    }

    static onFirstRefFocusAccountSelector = (selectAccountCustomerRef) => {
        const state = reduxStore.getState();
        let isOpenScreenConfirm = state.layout.listIsOpenModal["C&B"]["ScreenConfirm"].isOpenScreenConfirm;
        if (isOpenScreenConfirm === true) return;
        let inputRef = selectAccountCustomerRef && selectAccountCustomerRef.current && selectAccountCustomerRef.current.getInputRef();
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
            // inputRef.current.setSelectionRange(0, 6);
            // let value = inputRef.current;
            let timer = setTimeout(() => {
                let value = inputRef.current ? inputRef.current.value : '';
                if (value) {
                    inputRef.current.setSelectionRange(PREFIX_CUSTODYCD.length, value.length);
                }
                inputRef = null
                clearTimeout(timer)
            }, 100);
        }
    }

    static onCopyText = (text) => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(text);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = text; document.body.appendChild(textArea);
            textArea.focus(); textArea.select();
            try { document.execCommand('copy') }
            catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea)
        }
    }

    /**
     * Set theme cho bảng giá (không yêu cầu theo tài khoản tại thời điểm yêu cầu, lưu theo trình duyệt và k bị clear khi tắt trình duyệt )
     * @param {*} theme 
     */
    static setPriceBoardTheme = (theme) => {
        const keySave = 'PriceboardTheme'
        setValueToLocalStorage(keySave, theme);
    }

    /**
     * Lấy theme bảng giá hiện tại từ localStorage
     */
    static getPriceBoardTheme = () => {
        const keySave = 'PriceboardTheme'
        let priceboardTheme = getValueFromLocalStorage(keySave);
        if (priceboardTheme) {
            return priceboardTheme;
        } else {
            // Nếu không có trong storage set lại mặc định
            this.setPriceBoardTheme(PRICEBOARD_THEMES.CLASSIC);
            return PRICEBOARD_THEMES.CLASSIC;
        }
    }

    /**
     * Lây tên hiển thị nếu quá dài // tránh các case css tên dài tự tính bị lỗi khi thay đổi liên tục tên hiển thị
     * @param {*} name 
     */
    static getDisplayName = (name) => {
        const DISPLAY_LENGTH = 17 // Số ký tự cần lấy của tên
        let resultName = name;
        if (name && name.length > DISPLAY_LENGTH) {
            resultName = '... ' + name.substring(name.length - DISPLAY_LENGTH, name.length);
        }
        return resultName;
    }


    static convertSignalDataDatx = (proto) => {
        var object = {
            "id": proto.signal_id,
            "openPrice": proto.open_price,
            "closePrice": proto.closed_price,
            "eventType": proto.event_type,
            "takeProfitPrice": proto.take_profit_price,
            "stopLossPrice": proto.stop_loss_price,
            "openDate": proto.open_date,
            "closedDate": proto.closed_date,
            "reason": "",
            "symbol": proto.symbol,
            "lastChange": null,
            "sectorKey": proto.sector_key,
            "status": proto.status,
            "allocation": proto.allocation,
        };
        return object;
    }

    static getOrderTypeUseLayout = (keyLayout) => {
        let key = ORDER_TYPE_USE.ORDER
        if (keyLayout) { } else {
            keyLayout = CommonWidgetUtils.getReplaceLayoutPageActive()
        }
        if (keyLayout.includes("CS#TRADE#TPRL_NM")) {
            key = ORDER_TYPE_USE.TPRL_NM_ORDER
        } else if (keyLayout.includes("CS#TRADE#TPRL_OR")) {
            key = ORDER_TYPE_USE.TPRL_OR_ORDER
        } else if (keyLayout.includes("CS#TRADE#TPRL_RP")) {
            key = ORDER_TYPE_USE.TPRL_RP_ORDER
        }
        return key
    }
}

export default CommonUtils;
