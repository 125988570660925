import { batch } from 'react-redux';

import Util from '../../modules/util.js';
import symbolService from '../../services/symbolService';
import actionTypes from '../../store/actions/actionTypes';

export const loadAllMarketInfo = (code, floorCode, loadType = 'ALL') => {
	//console.log('loadAllMarketInfo---: 11111111111', { code, floorCode, loadType });
	// //console.log('loadAllMarketInfo---: 22222222222', code.toString());
	return (dispatch) => {
		// const state = getState();
		try {

			//chia 2 api do phân biệt thời gian 2 sở HNX, HOSE
			if (loadType === 'LOAD_MARKET_INFO') {
				Promise.all(
					[
						symbolService.getMarketInfoSnap(floorCode),
					]
				).then(([data]) => {
					let convertedMarketInfo = Util.processJSON_MarketInfos(data);
					// Trạng thái HNX30 ăn theo trạng thái HNX nếu không trả ra từ nguồn
					if (convertedMarketInfo['HNX30'] && convertedMarketInfo['HNX'] && convertedMarketInfo['HNX']['status'] && !convertedMarketInfo['HNX30']['status']) {
						convertedMarketInfo['HNX30']['status'] = convertedMarketInfo['HNX']['status'];
					}
					dispatch({
						from: "1",
						type: actionTypes.LOAD_MARKET_INDEX_SUCCESS,
						marketHistories: null, // history hiện tại
						marketInfos: convertedMarketInfo
					})
				});
			} else if (loadType === 'LOAD_MARKET_HISTORY') {
				// set biến load thành công index để đợi api cập nhật về mới vẽ tiếp tục vẽ
				dispatch({
					type: actionTypes.SET_LOAD_INDEX_STATUS,
					isLoaded: false
				});
				Promise.all(
					[
						(code && code.length > 0) && symbolService.getMarketHistory(code.toString()),
					]
				).then(([dataHistory]) => {
					dispatch({
						from: "2",
						type: actionTypes.LOAD_MARKET_INDEX_SUCCESS,
						marketHistories: dataHistory,
						marketInfos: null // info hiện tại
					});
				});


			} else if (loadType === 'ALL') {
				// set biến load thành công index để đợi api cập nhật về mới vẽ tiếp tục vẽ
				dispatch({
					type: actionTypes.SET_LOAD_INDEX_STATUS,
					isLoaded: false
				});
				Promise.all(
					[
						symbolService.getMarketInfoSnap(floorCode),
						(code && code.length > 0) && symbolService.getMarketHistory(code.toString()),
					]
				).then(([responseMarketInfo, dataHistory]) => {
					//console.log("LOAD_MARKET_INDEX_SUCCESS.: 3, ", code, floorCode, responseMarketInfo, dataHistory)
					let convertedMarketInfo = Util.processJSON_MarketInfos(responseMarketInfo);
					// Trạng thái HNX30 ăn theo trạng thái HNX nếu không trả ra từ nguồn
					if (convertedMarketInfo['HNX30'] && convertedMarketInfo['HNX'] && convertedMarketInfo['HNX']['status'] && !convertedMarketInfo['HNX30']['status']) {
						convertedMarketInfo['HNX30']['status'] = convertedMarketInfo['HNX']['status'];
					}


					batch(() => {
						dispatch({
							from: "3",
							type: actionTypes.LOAD_MARKET_INDEX_SUCCESS,
							marketHistories: dataHistory,
							marketInfos: convertedMarketInfo
						});
					})


				});
			}
		} catch (e) {
			batch(() => {
				dispatch({
					from: "4",
					type: actionTypes.LOAD_MARKET_INDEX_SUCCESS,
					marketHistories: {},
					marketInfos: {}
				});
			})
			return;
		}
	};
}

export const updateMarketInfos = (data, timestamp) => ({
	type: actionTypes.UPDATE_MARKET_INFOS,
	timestamp,
	data
});

export const onInitDay = () => ({
	type: actionTypes.ON_INIT_DAY
});

export const setMarketInfoDetailData = (marketInfo) => ({
	type: actionTypes.SET_MARKET_DETAIL_DATA,
	marketInfo
});

export const setIsDetailIndex = (isDetailIndex) => ({
	type: actionTypes.SET_IS_DETAIL_INDEX,
	isDetailIndex
});

