import _ from "lodash";

import * as LayoutUtils from '../../src/containers/LayoutPage/LayoutUtils.js';
import reduxStore from '../redux';
import { dispatch } from '../redux';
import * as actions from "../store/actions";
import { ACCOUNT_TYPE } from '.';
import { LAYOUT_STATUS } from './constants';
import { batch } from 'react-redux';
import { push } from 'connected-react-router';
import $ from 'jquery';
import * as LayoutDesktop from "../containers/LayoutPage/LayoutDesktop";
const OPEN_TAB_SHOW_SAVE_LAYOUT = false
class CommonWidgetUtils {
    static getAccountType(account) {
        let AccountType = ACCOUNT_TYPE.CS;
        return AccountType;
        throw new Error(`Invalid account value: ${JSON.stringify(account)}`);
    }
    static getGridByWidgetChange(grid) {
        let _grid = _.cloneDeep(grid);
        let objGrid = {
            lg: _grid,
            md: _grid,
            sm: _grid,
        }
        return objGrid;
    }
    static getLayoutsCustomCurrent(OBJLAYOUT) {
        const state = reduxStore.getState();
        const isDeskTop = state.app.isDeskTop
        const layoutPage = state.layout.layoutPage;
        let currentLayoutPageActive = OBJLAYOUT || state.layout.currentLayoutPageActive;

        // let currentLayoutPageActive = state.layout.currentLayoutPageActiveTemp || OBJLAYOUT || state.layout.currentLayoutPageActive;
        // let currentLayoutPageActiveTemp = state.layout.currentLayoutPageActiveTemp;
        //console.log('getLayoutsCustomCurrent.:LayoutPage=', layoutPage, currentLayoutPageActive, layoutPage && layoutPage[currentLayoutPageActive])
        const LayoutPageCurrent = layoutPage[currentLayoutPageActive] || undefined;
        let layoutCurrent = undefined
        //console.log('getLayoutsCustomCurrent.:layoutCurrent=', layoutCurrent, currentLayoutPageActive)
        // let _isLayoutDefautl = LayoutUtils.isLayoutDefautl(currentLayoutPageActive)
        // if (_isLayoutDefautl) {

        //     let _layoutCurrent = isDeskTop ? LayoutUtils.getDesktopLayouts(currentLayoutPageActive) : LayoutUtils.getMobileLayouts(currentLayoutPageActive);
        //     //console.log('getLayoutsCustomCurrent.:Mặc định=1', LayoutUtils.getDesktopLayouts(currentLayoutPageActive))
        //     layoutCurrent = (_layoutCurrent && _layoutCurrent.length > 0) ? { ..._layoutCurrent[0] } : undefined
        //     //console.log('getLayoutsCustomCurrent.:Mặc định=2', currentLayoutPageActive, layoutCurrent)
        // } else {
        const layoutsCustom = state.layout.layoutsCustom && [...state.layout.layoutsCustom];
        //console.log('getLayoutsCustomCurrent.:3=', isDeskTop, currentLayoutPageActive, layoutCurrent)
        // Nếu là layoutCustom thì tìm kiếm trong list layoutCustom
        let index = layoutsCustom && layoutsCustom.findIndex((e) => e.id == currentLayoutPageActive)
        layoutCurrent = (index > -1) ? { ...layoutsCustom[index] } : undefined
        //console.log('getLayoutPageCurrent.:3=', index, layoutCurrent)

        // }
        // let _isLayoutCustom = LayoutUtils.isLayoutCustom(currentLayoutPageActive)

        //console.log('getLayoutPageCurrent.:4=', isDeskTop, currentLayoutPageActive, layoutCurrent)
        return {
            // currentLayoutPageActiveTemp,
            currentLayoutPageActive,
            LayoutPageCurrent, // lấy thông tin như tài khoản, mã... đang active
            layoutCurrent,
        }

    }


    static getLayoutPageCurrent(isCheckLayoutCustom = false, OBJLAYOUT) {
        const state = reduxStore.getState();
        const isDeskTop = state.app.isDeskTop
        const layoutPage = state.layout.layoutPage;
        const layoutsCustom = state.layout.layoutsCustom;
        const breakpoint = state.layout.breakpoint
        let currentLayoutPageActive = OBJLAYOUT || state.layout.currentLayoutPageActive;

        // let currentLayoutPageActive = OBJLAYOUT || state.layout.currentLayoutPageActiveTemp || state.layout.currentLayoutPageActive;
        //console.log('getLayoutPageCurrent.:LayoutPage=', layoutPage, currentLayoutPageActive, layoutPage && layoutPage[currentLayoutPageActive])
        const LayoutPageCurrent = layoutPage[currentLayoutPageActive] || undefined;
        let layoutCurrent = undefined
        // let layoutCurrent = (layoutCurrent && { ...LayoutPageCurrent.layoutCurrent }) || undefined;
        //console.log('getLayoutPageCurrent.:layoutCurrent=', layoutsCustom, layoutCurrent, currentLayoutPageActive, isCheckLayoutCustom)

        let _isLayoutDefautl = LayoutUtils.isLayoutDefautl(currentLayoutPageActive)
        if (_isLayoutDefautl && isCheckLayoutCustom == false) {
            // Load layout mặc định
            // check layout Mặc định đã từng được save trên server chưa /userdata/config
            let index = _.findIndex(layoutsCustom, (e) => e.id == currentLayoutPageActive)
            //console.log('getLayoutPageCurrent.:layoutCurrent=index=', index, layoutsCustom)
            let _layoutCurrent = []
            if (index > -1) {
                _layoutCurrent = isDeskTop ? [layoutsCustom[index]] : LayoutUtils.getMobileLayouts(currentLayoutPageActive);
            }
            else {
                _layoutCurrent = isDeskTop ? LayoutUtils.getDesktopLayouts(currentLayoutPageActive) : LayoutUtils.getMobileLayouts(currentLayoutPageActive);
            }
            // //console.log('getLayoutPageCurrent.:Mặc định=1', layoutsCustom, LayoutUtils.getDesktopLayouts(currentLayoutPageActive))
            layoutCurrent = (_layoutCurrent && _layoutCurrent.length > 0) ? { ..._layoutCurrent[0] } : undefined
            // //console.log('getLayoutPageCurrent.:Mặc định=2', layoutsCustom, currentLayoutPageActive, layoutCurrent)
        } else {
            const layoutsCustom = state.layout.layoutsCustom && [...state.layout.layoutsCustom];
            //console.log('getLayoutPageCurrent.:3=', isDeskTop, _isLayoutDefautl, currentLayoutPageActive, layoutCurrent)
            // Nếu là layoutCustom thì tìm kiếm trong list layoutCustom
            let index = layoutsCustom && layoutsCustom.findIndex((e) => e.id == currentLayoutPageActive)
            // layoutCurrent = (index > -1) ? _.cloneDeep(layoutsCustom[index]) : un  defined
            layoutCurrent = (index > -1) ? { ...layoutsCustom[index] } : undefined
            //console.log('getLayoutPageCurrent.:3=', index, layoutCurrent)

        }
        // let _isLayoutCustom = LayoutUtils.isLayoutCustom(currentLayoutPageActive)

        //console.log('getLayoutPageCurrent.:4=', isDeskTop, _isLayoutDefautl, currentLayoutPageActive, LayoutPageCurrent, layoutCurrent)
        let _layoutCurrent = _.cloneDeep(layoutCurrent)
        let _LayoutPageCurrent = _.cloneDeep(LayoutPageCurrent)
        return {
            currentLayoutPageActive,
            LayoutPageCurrent: _LayoutPageCurrent, // lấy thông tin như tài khoản, mã... đang active
            layoutCurrent: _layoutCurrent,
            breakpoint
        }
    };

    static genNewObjLayoutCustom(OBJLAYOUT) {
        if (!OBJLAYOUT) return undefined;
        const state = reduxStore.getState();
        let layoutsCustom = state.layout.layoutsCustom;
        let indexIds = []
        let newOBJLAYOUT = undefined
        let layoutsCustomID = LayoutUtils.layoutsCustomID

        if (layoutsCustom && layoutsCustom.length > 0) {
            layoutsCustomID.map(function (event) {
                // Thử khởi tạo lần lượt theo thứ tự
                let index = _.findIndex(layoutsCustom, (e) => (e.id.indexOf(event) > -1))
                if (index < 0) {
                    // Không có thì khởi tạo
                    newOBJLAYOUT = OBJLAYOUT + event
                }
            });
            if (!newOBJLAYOUT) {
                return undefined
            }
            return newOBJLAYOUT
        }
        newOBJLAYOUT = OBJLAYOUT + layoutsCustomID[layoutsCustomID.length - 1]
        return newOBJLAYOUT
    }

    static getPathLayoutCustom(OBJLAYOUT) {
        let path = OBJLAYOUT && OBJLAYOUT.split('$')
        path = path && path.length > 0 && path[1]
        return path
    }
    // static getKeyLayout(role, isDerivativeAccount, daily) {
    //     let account = isDerivativeAccount ? ACCOUNT_TYPE.PS : ACCOUNT_TYPE.CS;
    //     if (daily) {
    //         account = ACCOUNT_TYPE.DL
    //     }
    //     return `${role}.${account}`;
    // };

    // static getNameSelectedLayoutId(STRPROP) {
    //     if (STRPROP.indexOf('@PRICEBOARD') > -1) {
    //         return 'PriceBoardLayoutId'
    //     }

    //     if (STRPROP.indexOf('@TRADE') > -1) {
    //         return 'currentTradeLayoutId'
    //     }
    //     return false
    // };

    // static getActivePriceboard(currentLayoutId, layouts, customLayout) {
    //     let currentLayout = {};
    //     currentLayout = layouts.find(item => {
    //         return item && item.id === currentLayoutId;
    //     })

    //     //case customLayout
    //     if (!currentLayout) {
    //         currentLayout = customLayout;
    //     }

    //     let visibleSectionIds = currentLayout.visibleSectionIds;
    //     let mergeSections = currentLayout.mergeSections;

    //     let allBoards = []; // all priceboard widget in the current layout
    //     let activeBoards = []; // only have active pricboard widget
    //     let childMergeSections = [];
    //     let parentMergeSections = Object.keys(mergeSections);
    //     let childs = Object.values(mergeSections);

    //     childs.map(child => {
    //         child.map(item => {
    //             childMergeSections.push(item)
    //         })
    //     })

    //     visibleSectionIds.map(section => {
    //         if (LIST_PRICEBOARD.includes(section)) {
    //             allBoards.push(section)
    //         }
    //         if (!parentMergeSections.includes(section) && !childMergeSections.includes(section)) {
    //             activeBoards.push(section)
    //         }
    //     })

    //     let state = reduxStore.getState();
    //     let listActiveSection = state.componentState.widgetGrid.listActiveSection;

    //     if (listActiveSection.length === 0) {
    //         parentMergeSections.map(item => {
    //             activeBoards.push(item)
    //         })
    //     }

    //     listActiveSection.map(section => {
    //         if (LIST_PRICEBOARD.includes(section)) {
    //             activeBoards.push(section)
    //         }
    //     })

    //     activeBoards = activeBoards.filter(item => {
    //         return item && allBoards.includes(item)
    //     })
    //     activeBoards = _.union(activeBoards);
    //     return activeBoards;
    // }
    static getReplaceLayoutPageActive() {
        const state = reduxStore.getState();
        const layoutAcive = state.layout.currentLayoutPageActive
        // if (layoutAcive.indexOf('$TC_TEMP') > -1) {
        //     return layoutAcive.replace('$TC_TEMP', '')
        // }
        return layoutAcive
    }

    static getReplaceTempLayoutPageActive(currentLayoutPageActive) {
        const layoutAcive = currentLayoutPageActive
        if (layoutAcive.indexOf('$TC_TEMP') > -1) {
            return layoutAcive.replace('$TC_TEMP', '')
        }
        return layoutAcive
    }

    static getTypeLayoutAcive() {
        const state = reduxStore.getState();
        let layoutAcive = state.layout.currentLayoutPageActive
        layoutAcive = layoutAcive.replace('$TC_TEMP', '')
        const layoutPage = state.layout.layoutPage
        //console.log('getTypeLayoutAcive.:1===', layoutAcive, layoutPage)

        let index = Object.keys(layoutPage).findIndex((e) => e == layoutAcive)
        if (index > -1) {
            let _layoutPage = layoutPage[Object.keys(layoutPage)[index]]
            //console.log('getTypeLayoutAcive.:2===', index, _layoutPage)
            return _layoutPage ? _layoutPage.typeLayout : "D"
        }
        //console.log('getTypeLayoutAcive.:3===')
        // if (layoutAcive.indexOf('$TC_TEMP') > -1) {
        //     return layoutAcive.replace('$TC_TEMP', '')
        // }
        return "D"
    }

    static onCheckPermistionKeyLayout = (keyName) => { // check quyền theo keyName khi mở layout không phải đăng nhập 
        //console.log("binh_onCheckPermistionKeyLayout", keyName)
        if (keyName.includes("PRICEBOARD#CB") || keyName.includes("SYMBOL_DETAIL") || keyName.includes("MARKET_WATCH") || keyName.includes("AI_TRADING") || keyName.includes("C#CS#TRADE#NC$TC")) {
            return false
        }
        return true
        // return false: k cần đăng nhập, true: phải đăng nhập
    };

    static onCheckLayoutToChangActive = (keyLayout) => { // lấy có temp trong layoutsCustom ưu tiên chuyển tab lấy temp
        const state = reduxStore.getState();
        let currentLayoutPageActive = keyLayout
        let layoutsCustom = state.layout.layoutsCustom

        if (layoutsCustom && layoutsCustom.length > 0) {
            layoutsCustom.forEach((item, index) => {
                //console.log("binh_onCheckLayoutToChangActive_1", currentLayoutPageActive + "$TC_TEMP", item)
                if (currentLayoutPageActive + "$TC_TEMP" === item["id"]) {
                    currentLayoutPageActive = item["id"]
                    return
                }
            })
        }
        //console.log("binh_onCheckLayoutToChangActive_2", { layoutsCustom, keyLayout, currentLayoutPageActive })
        return currentLayoutPageActive
    }

    static onCheckLayoutChange = (layoutsCustom, layoutsConfig, currentLayoutPageActive) => { // check layout change
        //console.log("binh_onCheckLayoutChange_1", { layoutsCustom, layoutsConfig, currentLayoutPageActive })
        let _layoutsCustom = _.cloneDeep(layoutsCustom)
        let _layoutsConfig = _.cloneDeep(layoutsConfig)

        if (layoutsCustom && layoutsConfig && currentLayoutPageActive) {
            let _layoutCustom = _layoutsCustom.find(el => el.id == currentLayoutPageActive);
            let _layoutConfig = _layoutsConfig.find(el => el.id == currentLayoutPageActive);
            //console.log("binh_onCheckLayoutChange_2", { _layoutCustom, _layoutConfig, currentLayoutPageActive })
            if (_layoutCustom && (!_layoutConfig || (_layoutConfig && _layoutConfig.length == 0))) {
                // //console.log("binh_onCheckLayoutChange_3", { _layoutCustom, _layoutConfig, currentLayoutPageActive })
                // //console.log("binh_onCheckLayoutChange_result_1", _layoutCustom, _layoutConfig)
                // gán _layoutConfig bằng defaultConffig của App theo layout currentLayoutPageActive
                _layoutConfig = LayoutUtils.getDesktopLayouts(currentLayoutPageActive.replace("$TC_TEMP", ""))
                // //console.log("binh_onCheckLayoutChange_result_1.1", _layoutConfig)

                _layoutConfig = (_layoutConfig && _layoutConfig.length > 0) && _layoutConfig[0]
                // //console.log("binh_onCheckLayoutChange_result_1.2", _layoutConfig)
                if (!_layoutConfig) {
                    return true
                }
            }
            // Xóa currDomPosition để so sánh
            if (_layoutCustom && _layoutConfig) {
                _layoutCustom["visibleSectionIds"] = _layoutCustom["visibleSectionIds"].filter((e) => e.indexOf("currDomPosition") < 0)
                _layoutConfig["visibleSectionIds"] = _layoutConfig["visibleSectionIds"].filter((e) => e.indexOf("currDomPosition") < 0)
                if (_layoutCustom["mergeSections"] && Object.keys(_layoutCustom["mergeSections"]).length > 0) {
                    let findCurrDomPosition = Object.keys(_layoutCustom["mergeSections"]).filter((e) => e.indexOf("currDomPosition") > -1)
                    if (findCurrDomPosition && findCurrDomPosition.length > 0) {
                        delete _layoutCustom["mergeSections"][findCurrDomPosition[0]]
                    }
                }
                if (_layoutConfig["mergeSections"] && Object.keys(_layoutConfig["mergeSections"]).length > 0) {
                    let findCurrDomPosition = Object.keys(_layoutConfig["mergeSections"]).filter((e) => e.indexOf("currDomPosition") > -1)
                    if (findCurrDomPosition && findCurrDomPosition.length > 0) {
                        delete _layoutConfig["mergeSections"][findCurrDomPosition[0]]
                    }
                }
            }

            // Xóa currDomPosition để so sánh

            if (_layoutCustom && _layoutConfig) {
                //console.log("binh_onCheckLayoutChange_3", { a: _layoutCustom["grid"], b: _layoutConfig["grid"] }, { a: JSON.stringify(_layoutCustom["grid"]), b: JSON.stringify(_layoutConfig["grid"]) })
                if (!(_.isEqual(JSON.stringify(_layoutCustom["grid"]), JSON.stringify(_layoutConfig["grid"])))) {
                    // if (!(_.isEqual(_layoutCustom["grid"]), _layoutConfig["grid"])) {
                    let objKey = Object.keys(_layoutCustom["grid"])
                    if (objKey && objKey.length > 0) {
                        let flag = false
                        objKey.forEach((item, index) => {
                            //console.log("binh_onCheckLayoutChange_result_item", _layoutCustom["grid"][item])
                            if (!(_.isEqual(_layoutCustom["grid"][item], _layoutConfig["grid"][item]))) {
                                flag = true
                            }
                        })
                        return flag
                    }
                    //console.log("binh_onCheckLayoutChange_result_2", { objkey: Object.keys(_layoutCustom["grid"]) }, true)
                    return true
                } else if (!(_.isEqual(_layoutCustom["visibleSectionIds"].sort(), _layoutConfig["visibleSectionIds"].sort()))) {
                    //console.log("binh_onCheckLayoutChange_result_3", true)
                    return true
                } else if (!(_.isEqual(_layoutCustom["mergeSections"], _layoutConfig["mergeSections"]))) {
                    //console.log("binh_onCheckLayoutChange_result_4", true)
                    return true
                }
            }
        }
        //console.log("binh_onCheckLayoutChange_result_5", false)
        return false
    }

    static onConvertLayoutsCustom = (objData) => { // convert layoutout =>update
        const { layoutsCustom, currentLayoutPageActive, isLayoutChange, statusUpdate, from } = objData
        //console.log("onConvertLayoutsCustom.:", from, isLayoutChange, statusUpdate)
        let _statusUpdate = statusUpdate ? statusUpdate : LAYOUT_STATUS.UPDATE
        let _layoutsCustom = _.cloneDeep(layoutsCustom)
        if (layoutsCustom && currentLayoutPageActive) {
            if (from && from.includes("btnsave")) {
                _layoutsCustom = _layoutsCustom.map((item, index) => {
                    if (item["id"] === currentLayoutPageActive) {
                        return {
                            ...item,
                            ["layoutStatus"]: LAYOUT_STATUS.NONE
                        }
                    } else {
                        return item
                    }
                })
                return _layoutsCustom
            }

            if (isLayoutChange) {
                _layoutsCustom = _layoutsCustom.map((item, index) => {
                    if (item["id"] === currentLayoutPageActive && isLayoutChange) {
                        //console.log('onConvertLayoutsCustom====', isLayoutChange, layoutsCustom)
                        return {
                            ...item,
                            ["layoutStatus"]: _statusUpdate
                        }
                    } else {
                        return item
                    }
                })
            }
        }
        return _layoutsCustom
    }


    static onCheckSaveLayout = () => { // check layout to save
        let isCheckExistLayoutSave = false

        if (OPEN_TAB_SHOW_SAVE_LAYOUT) {
            const state = reduxStore.getState();
            let layoutsCustom = state.layout.layoutsCustom
            let layoutsConfig = state.layout.layoutsConfig
            const layoutPage = state.layout.layoutPage
            let layoutActive = null
            let _layoutsCustom = layoutsCustom.filter((item, index) => item.layoutStatus == LAYOUT_STATUS.UPDATE)
            let from = "CreatLayout_btn_logout"

            //console.log("binh_onCheckSaveLayout", { layoutsCustom, _layoutsCustom })

            if (_layoutsCustom && _layoutsCustom.length > 0) {
                _layoutsCustom.forEach((item, index) => {
                    if (item && item.id && item.layoutStatus == LAYOUT_STATUS.UPDATE) {
                        layoutActive = item.id
                        isCheckExistLayoutSave = true
                    }
                })
            }

            const onCallbackUpdateLayoutCustom = (currentLayoutPageActive) => {
                let layoutConfig = layoutsConfig.find(item => (item.id == currentLayoutPageActive))
                let _layoutsCustom = layoutsCustom

                if (layoutConfig) {
                    _layoutsCustom = layoutsCustom.map((item, index) => {
                        if (item.id == currentLayoutPageActive) {
                            item.layoutStatus = LAYOUT_STATUS.NONE
                            return layoutConfig
                        } else {
                            return item
                        }
                    })
                } else {
                    _layoutsCustom = layoutsCustom.filter((item, index) => {
                        if (item.id.includes("$TC_TEMP") && (item.id == currentLayoutPageActive + "$TC_TEMP" || item.id == currentLayoutPageActive)) {
                            return false
                        } else {
                            return true
                        }
                    })
                }

                //console.log("binh_onCheckSaveLayout3", { layoutsConfig, layoutConfig, layoutsCustom, _layoutsCustom, currentLayoutPageActive })
                batch(() => {
                    dispatch(actions.updateLayoutCustomData(_layoutsCustom))
                });
            }

            //console.log("binh_onCheckSaveLayout2", { layoutActive })
            let callBackSaveLayout = null
            if (layoutActive && isCheckExistLayoutSave) {
                callBackSaveLayout = () => {
                    if (layoutPage[layoutActive] && layoutPage[layoutActive].activeURL) {
                        const isLayoutDefault = (layoutActive.indexOf('$TC_TEMP') > -1) || (layoutActive.indexOf('$TC') < 0)
                        batch(() => {
                            dispatch(actions.changeLayoutPageActive(layoutActive))
                            dispatch(push(layoutPage[layoutActive].activeURL));
                            dispatch(actions.setIsOpenModalHaveData("CreatLayout", {
                                isOpenModalCreatLayout: true,
                                data: {
                                    from,
                                    onCallbackUpdateLayoutCustom: () => onCallbackUpdateLayoutCustom(layoutActive),
                                    onCallbackHideLayoutPage: () => {
                                        // dispatch(actions.hideLayoutPage(layoutActive))
                                        if (isLayoutDefault) {
                                            batch(() => {
                                                dispatch(actions.hideLayoutPage(layoutActive))
                                            });
                                        } else {
                                            batch(() => {
                                                dispatch(actions.deleteLayoutPage(layoutActive))
                                            });
                                        }
                                        return
                                    }
                                }
                            }))
                        })
                    }
                }
            }

            //console.log("binh_onCheckSaveLayout_return", isCheckExistLayoutSave)
            return {
                isCheckExistLayoutSave,
                callBackSaveLayout,
            }
        }

        return {
            isCheckExistLayoutSave
        }
    }


    static sortByZIndex = (a, b) => {
        return a.style.zIndex - b.style.zIndex;
    }

    static changeIndexVisibleSectionIdsByCSS = (from, lastSectionIdActive, visibleSectionIds) => {
        if (!lastSectionIdActive) {
            return false
        }
        //console.log('changeIndexVisibleSectionIdsByCSS=====1=', from, lastSectionIdActive, visibleSectionIds)
        let _visibleSectionIds = _.cloneDeep(visibleSectionIds)
        let _currDomPosition = _visibleSectionIds.find(e => e.indexOf("currDomPosition"))
        // let indexLastActive = $(`#section_${lastSectionIdActive}`).css("z-index");
        // let sesionHtml = $('id^="section_"]')
        let maxZIndex = -1
        let sesionIds = $("div[id^='section_']")
        let currDomPosition = "currDomPosition" // Lưu lại vị trí sections theo DOM
        sesionIds.each(function (i, el) {
            let id = this.id.replaceAll('section_', '')
            currDomPosition = currDomPosition + "||" + id
        });
        //console.log('changeIndexVisibleSectionIdsByCSS=====2=', _currDomPosition, currDomPosition)

        sesionIds = sesionIds.sort(this.sortByZIndex)
        let crrLastSesionIds;
        sesionIds.each(function (i, el) {
            //It'll be an array of elements
            let zIndex = parseInt($(`#${this.id}`).css("z-index"));
            if (zIndex > maxZIndex) {
                maxZIndex = zIndex
                crrLastSesionIds = this.id.replaceAll('section_', '')
            }
        });
        if (lastSectionIdActive == crrLastSesionIds && _currDomPosition == currDomPosition) {
            //console.log('changeIndexVisibleSectionIdsByCSS=====2=NOT CHANGE Z-INDEX===', from, lastSectionIdActive, crrLastSesionIds)
            return false
        }
        else {
            // if (indexLastActive) {
            //     // $(`#section_${crrLastSesionIds}`).css("z-index", indexLastActive)
            //     $(`#section_${lastSectionIdActive}`).css("z-index", maxZIndex)
            // }
            // else {

            // }
            // $(`#section_${lastSectionIdActive}`).css("z-index", maxZIndex + 1)
            // //console.log('changeIndexVisibleSectionIdsByCSS=====4==CHANGE Z-INDEX===', visibleSectionIds)
            _visibleSectionIds = _visibleSectionIds.filter(e => e !== undefined && e !== null && e !== lastSectionIdActive)
            // //console.log('changeIndexVisibleSectionIdsByCSS=====5==CHANGE Z-INDEX===', _visibleSectionIds)
            _visibleSectionIds.push(lastSectionIdActive)
            // //console.log('changeIndexVisibleSectionIdsByCSS=====6==CHANGE Z-INDEX===', _visibleSectionIds)
            // let _sesionIds = $("div[id^='section_']").sort(this.sortByZIndex)
            // _sesionIds = $.map(_sesionIds, function (e) { return e.id.replaceAll('section_', ''); })
            // _visibleSectionIds.map((section, index) => {
            //     //console.log('Haki_lt123.: section=', section, index + 190)
            //     $(`#section_${section}`).css("z-index", index + 190)
            // })
            // let _sesionIdsMerge = _.merge(visibleSectionIds, _sesionIds);
            // //console.log('sesionHtml===res====', lastSectionIdActive, indexLastActive, crrLastSesionIds, maxZIndex, sesionIds, _sesionIds)
            _visibleSectionIds = _visibleSectionIds.filter(e => e !== undefined && e !== null && e.indexOf("currDomPosition") < 0)
            _visibleSectionIds.push(currDomPosition); // Lưu lại vị trí sections theo DOM
            //console.log('changeIndexVisibleSectionIdsByCSS=====3==CHANGE Z-INDEX===', from, lastSectionIdActive, crrLastSesionIds, _visibleSectionIds)
            return _visibleSectionIds
        }
    }

    static overrideConfigLayoutServer = (configLayout) => {
        //console.log('overrideConfigLayoutServer', { configLayout })
        if (configLayout && configLayout.length > 0) {
            //console.log('overrideConfigLayoutServer1', { configLayout })
            let _configLayout = _.cloneDeep(configLayout);
            // //console.log('overrideConfigLayoutServer1', _configLayout)
            _configLayout = _configLayout.map((item, index) => {
                // //console.log('overrideConfigLayoutServer2', item)
                let _grid = _.cloneDeep(item.grid);
                let listBreakpoint = Object.keys(item.grid)
                if (_grid && listBreakpoint && listBreakpoint.length > 0) {
                    listBreakpoint.map((elementBreakpoint) => {
                        let _listGrid = _grid[elementBreakpoint]
                        if (_listGrid && _listGrid.length > 0) {
                            _listGrid.map((elementGrid, index) => {
                                // let size = { x: 0, y: 0, w: 10, h: 6, minW: 2, minH: 2 };
                                let _minW = 2
                                let _minH = 2
                                //console.log('overrideConfigLayoutServer2')
                                let _DefaultSession = LayoutDesktop.DefaultSession && LayoutDesktop.DefaultSession[elementBreakpoint] &&
                                    LayoutDesktop.DefaultSession[elementBreakpoint].find((e) => e.i === elementGrid.i)
                                //console.log('overrideConfigLayoutServer3', { _DefaultSession })
                                if (_DefaultSession) {
                                    _minW = _DefaultSession.minW || _minW
                                    _minH = _DefaultSession.minH || _minH
                                }
                                elementGrid.minW = _minW
                                elementGrid.minH = _minH
                            })
                            // //console.log('overrideConfigLayoutServer4', { _listGrid, listGrid: _grid[_breakpoint] })
                        }
                    })
                    return {
                        ...item,
                        grid: _grid
                    }
                }
                return item
            })
            // //console.log('overrideConfigLayoutServer_re', _configLayout)
            return _configLayout
        }

        return configLayout
    }


    static toggleEnableEditLayout = (status, from) => {
        const state = reduxStore.getState();
        let _currentLayoutPageActive = null
        _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
        const layoutPage = state.layout.layoutPage[_currentLayoutPageActive];
        const { isEditLayout } = layoutPage
        let _status = status ? status : !isEditLayout
        if (from && _currentLayoutPageActive && _currentLayoutPageActive.includes("$TC_TEMP")) {
            if (from.includes("addNewLayoutCustom") || from.includes("resetLayoutCustom")) {
                dispatch(actions.updatedLayoutPage({
                    isEditLayout: _status,

                }, _currentLayoutPageActive.replace('$TC_TEMP', '')))
            }
        }
        //console.log("toggleEnableEditLayout", { layoutPage, _currentLayoutPageActive, _status })
        dispatch(actions.updatedLayoutPage({
            isEditLayout: _status
        }))
    }

};

export default CommonWidgetUtils;
