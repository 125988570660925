/**
 * limit decimal numbers to given scale
 * Not used .fixedTo because that will break with big numbers
 */
function limitToScale(numStr, scale, fixedDecimalScale) {
    let str = '';
    const filler = fixedDecimalScale ? '0' : '';
    for (let i = 0; i <= scale - 1; i++) {
        str += numStr[i] || filler;
    }
    return str;
}

/**
 * This method is required to round prop value to given scale.
 * Not used .round or .fixedTo because that will break with big numbers
 */
function roundToPrecision(numStr, scale, fixedDecimalScale) {
    //if number is empty don't do anything return empty string
    if (['', '-'].indexOf(numStr) !== -1) return numStr;

    const shoudHaveDecimalSeparator = numStr.indexOf('.') !== -1 && scale;
    const { beforeDecimal, afterDecimal, hasNagation } = splitDecimal(numStr);
    const roundedDecimalParts = parseFloat(`0.${afterDecimal || '0'}`).toFixed(scale).split('.');
    const intPart = beforeDecimal.split('').reverse().reduce((roundedStr, current, idx) => {
        if (roundedStr.length > idx) {
            return (Number(roundedStr[0]) + Number(current)).toString() + roundedStr.substring(1, roundedStr.length);
        }
        return current + roundedStr;
    }, roundedDecimalParts[0]);

    const decimalPart = limitToScale(roundedDecimalParts[1] || '', Math.min(scale, afterDecimal.length), fixedDecimalScale);
    const negation = hasNagation ? '-' : '';
    const decimalSeparator = shoudHaveDecimalSeparator ? '.' : '';
    return `${negation}${intPart}${decimalSeparator}${decimalPart}`;
}

function getThousandsGroupRegex(thousandsGroupStyle) {
    switch (thousandsGroupStyle) {
        case 'lakh':
            return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;
        case 'wan':
            return /(\d)(?=(\d{4})+(?!\d))/g;
        case 'thousand':
        default:
            return /(\d)(?=(\d{3})+(?!\d))/g;
    }
}

function applyThousandSeparator(str, thousandSeparator, thousandsGroupStyle) {
    const thousandsGroupRegex = getThousandsGroupRegex(thousandsGroupStyle);
    const index = str.search(/[1-9]/);
    return str.substring(0, index) + str.substring(index, str.length).replace(thousandsGroupRegex, '$1' + thousandSeparator);
}

//spilt a float number into different parts beforeDecimal, afterDecimal, and negation
function splitDecimal(numStr, allowNegative = true) {
    const hasNagation = numStr[0] === '-';
    const addNegation = hasNagation && allowNegative;
    numStr = numStr.replace('-', '');

    const parts = numStr.split('.');
    const beforeDecimal = parts[0];
    const afterDecimal = parts[1] || '';

    return {
        beforeDecimal,
        afterDecimal,
        hasNagation,
        addNegation
    };
}

// Returned regex assumes decimalSeparator is as per prop
function getSeparators(options) {
    const { decimalSeparator } = options;
    let { thousandSeparator } = options;

    if (thousandSeparator === true) {
        thousandSeparator = ',';
    }

    return {
        decimalSeparator,
        thousandSeparator
    }
}

function getMaskAtIndex(index, options) {
    const { mask = ' ' } = options;
    if (typeof mask === 'string') {
        return mask;
    }

    return mask[index] || ' ';
}

function validateOptions(options) {
    const { mask } = options;

    // Validate decimalSeparator and thousandSeparator
    const { decimalSeparator, thousandSeparator } = getSeparators(options);

    if (decimalSeparator === thousandSeparator) {
        throw new Error(`
              Decimal separator can't be same as thousand separator.
              thousandSeparator: ${thousandSeparator} (thousandSeparator = {true} is same as thousandSeparator = ",")
              decimalSeparator: ${decimalSeparator} (default value for decimalSeparator is .)
           `);
    }

    // Validate mask
    if (mask) {
        const maskAsStr = mask === 'string' ? mask : mask.toString();
        if (maskAsStr.match(/\d/g)) {
            throw new Error(`
              Mask ${mask} should not contain numeric character;
            `)
        }
    }

}

/*** format specific methods start ***/
/**
 * Format when # based string is provided
 * @param {string} numStr Numeric String
 * @param options options
 * @return {string}        formatted Value
 */
function formatWithPattern(numStr, options) {
    const { format } = options;
    let hashCount = 0;
    const formattedNumberAry = format.split('');
    for (let i = 0, ln = format.length; i < ln; i++) {
        if (format[i] === '#') {
            formattedNumberAry[i] = numStr[hashCount] || getMaskAtIndex(hashCount, options);
            hashCount += 1;
        }
    }
    return formattedNumberAry.join('');
}

/**
 * @param  {string} numStr Numeric string/floatString] It always have decimalSeparator as .
 * @param options options
 * @return {string} formatted Value
 */
function formatAsNumber(numStr, options) {
    const { decimalScale, fixedDecimalScale, prefix, suffix, allowNegative, thousandsGroupStyle } = options;
    const { thousandSeparator, decimalSeparator } = getSeparators(options);

    const hasDecimalSeparator = numStr.indexOf('.') !== -1 || (decimalScale && fixedDecimalScale);
    let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(numStr, allowNegative); // eslint-disable-line prefer-const

    //apply decimal precision if its defined
    if (decimalScale !== undefined) afterDecimal = limitToScale(afterDecimal, decimalScale, fixedDecimalScale);

    if (thousandSeparator) {
        beforeDecimal = applyThousandSeparator(beforeDecimal, thousandSeparator, thousandsGroupStyle);
    }

    //add prefix and suffix
    if (prefix) beforeDecimal = prefix + beforeDecimal;
    if (suffix) afterDecimal = afterDecimal + suffix;

    //restore negation sign
    if (addNegation) beforeDecimal = '-' + beforeDecimal;

    // eslint-disable-next-line
    numStr = beforeDecimal + (hasDecimalSeparator && decimalSeparator || '') + afterDecimal;

    return numStr;
}

function formatNumString(numStr = '', options) {
    const { format, allowEmptyFormatting } = options;
    let formattedValue = numStr;

    if (numStr === '' && !allowEmptyFormatting) {
        formattedValue = ''
    } else if (numStr === '-' && !format) {
        formattedValue = '-';
    } else if (typeof format === 'string') {
        formattedValue = formatWithPattern(formattedValue, options);
    } else if (typeof format === 'function') {
        formattedValue = format(formattedValue);
    } else {
        formattedValue = formatAsNumber(formattedValue, options)
    }

    return formattedValue;
}

function doFormatValue(value, options) {
    const { defaultValue, format, decimalScale, fixedDecimalScale, allowEmptyFormatting } = options;
    if (value == null) {
        value = defaultValue;
    }

    const isNonNumericFalsy = !value && value !== 0;

    if (isNonNumericFalsy && allowEmptyFormatting) {
        value = '';
    }

    // if value is not defined return empty string
    if (isNonNumericFalsy && !allowEmptyFormatting) return '';

    if (typeof value === 'number') {
        value = value.toString();
    }

    //change infinity value to empty string
    if (value === 'Infinity') {
        value = '';
    }

    //round the number based on decimalScale
    //format only if non formatted value is provided
    if (!format && typeof decimalScale === 'number') {
        value = roundToPrecision(value, decimalScale, fixedDecimalScale)
    }

    return formatNumString(value, options);
}

const defaultOptions = {
    defaultValue: null,
    mask: null,
    decimalSeparator: '.',
    thousandsGroupStyle: 'thousand',
    fixedDecimalScale: false,
    prefix: '',
    suffix: '',
    allowNegative: true,
    allowEmptyFormatting: false
};

class NumberFormatUtils {

    static format(value, options) {
        const opts = {
            ...defaultOptions,
            ...options
        };
        validateOptions(opts);

        return doFormatValue(value, options);
    }

    static formatVolume(value, options = {}) {
        const opts = {
            decimalScale: 0,
            fixedDecimalScale: true,
            thousandSeparator: ',',
            decimalSeparator: '.',
            ...options
        };
        return this.format(value, opts);
    }

    static formatPrice(value, options = {}) {
        const opts = {
            decimalScale: 0,
            fixedDecimalScale: false,
            thousandSeparator: ',',
            decimalSeparator: '.',
            ...options
        };
        return this.format(value, opts);
    }

    static formatNumber(value, options = {}) {
        const opts = {
            decimalScale: 0,
            fixedDecimalScale: false,
            thousandSeparator: ',',
            decimalSeparator: '.',
            ...options
        };
        return Number(this.format(value, opts));
    }

}

export default NumberFormatUtils;