import EventEmitter2 from 'eventemitter2';
// import EventEmitterBasic from 'events';
export const FOCUS_QUANTITY_INPUT = 'FOCUS_QUANTITY_INPUT';
export const FOCUS_PRICE_INPUT = 'FOCUS_PRICE_INPUT';
export const FOCUS_PRICE_GROUP_INPUT = 'FOCUS_PRICE_GROUP_INPUT';
export const FOCUS_SYMBOL_PLACE_ORDER = 'FOCUS_SYMBOL_PLACE_ORDER';
export const OPEN_LOGIN_POP_UP = 'OPEN_LOGIN_POP_UP';
const _emitter = new EventEmitter2();
_emitter.onAny(function (event, value) {
    switch (event) {
        case FOCUS_QUANTITY_INPUT:
        case FOCUS_PRICE_INPUT:
        case FOCUS_PRICE_GROUP_INPUT:
            // const fakeInput = document.createElement('input')
            // fakeInput.setAttribute('type', 'text')
            // fakeInput.style.position = 'absolute'
            // fakeInput.style.opacity = 1
            // fakeInput.style.height = 100
            // fakeInput.style.fontSize = '16px' // disable auto zoom

            // // you may need to append to another element depending on the browser's auto 
            // // zoom/scroll behavior
            // document.body.prepend(fakeInput)

            // // focus so that subsequent async focus will work
            // fakeInput.focus();
            // setTimeout(function () {
            //     fakeInput.remove();
            // }, 1000);
            break;
    }
});


// Haki.: thêm emitter.: basic
export const IS_SHOW_POPUP_LOGIN = 'IS_SHOW_POPUP_LOGIN';
export const emitter = _emitter;
emitter.setMaxListeners(50); // unlimit listener
// const _emitter2 = new EventEmitterBasic();
// _emitter2.setMaxListeners(50); // unlimit listener

// export const emitter2 = _emitter2;
