import React from 'react';
import reduxStore from '../redux';
import { EXCHANGE, oddLotByExchange, STOCKTYPE, CommonWidgetUtils, ORDER_TYPE_USE, CommonUtils, orderActions, ToastUtil } from "../utils";
import { FormattedPrice } from 'components/Formating';
import { symbolService } from 'services';
import { dispatch } from '../redux';
import { batch } from "react-redux";
import * as actions from "../store/actions";
import actionTypes from 'store/actions/actionTypes';
class CommonOrderUtils {
    static validateQuantityOrder(value, props) {
        const { quote } = props
        if (value == null) {
            return false;
        }

        if ("string" !== typeof value) {
            value = value.toString();
        }
        const numberValue = parseFloat(value);
        let lot = parseInt(value || 0);

        const valueInvalid = !value.length || value.split(".").length > 2
            || (!props.allowZero && numberValue === 0)
            || (!props.allowDecimal && numberValue !== parseInt(value, 10));
        if (props.instrument) {
            let instrument = props.instrument;
            if (instrument.EX == EXCHANGE.HOSE) {
                if (props.orderType && props.orderType === 'market') {
                    return ((lot >= 100) && (lot % oddLotByExchange.HOSE == 0)) || (lot === 0);
                } else {
                    if (props.isNotOddLot) {
                        return (lot % oddLotByExchange.HOSE == 0)
                    } else {
                        return (lot < 100) || (lot % oddLotByExchange.HOSE == 0)
                    }
                }
                // return (parseInt(value) % oddLotByExchange.HOSE == 0)
            } else if (instrument.EX == EXCHANGE.HNX) {
                if (instrument.ST == STOCKTYPE.BOND) return (lot % oddLotByExchange.HNX == 0);
                else {
                    if (props.orderType && props.orderType === 'market') {
                        return ((lot >= 100) && (lot % oddLotByExchange.HNX == 0)) || (lot === 0);
                    } else {
                        if (props.isNotOddLot) {
                            return (lot % oddLotByExchange.HNX == 0)
                        } else {
                            return (lot < 100) || (lot % oddLotByExchange.HNX == 0)
                        }
                    }
                }
            } else if (instrument.EX == EXCHANGE.UPCOM) {
                return (lot < 100) || (lot % oddLotByExchange.UPCOM == 0)
            } else if (instrument.EX === EXCHANGE.TPRL) {
                // *Khối lượng giao dịch tối thiểu:
                // Trái phiếu mệnh giá dưới 100 triệu đồng: 100 trái phiếu
                // Trái phiếu mệnh giá từ 100 triệu đồng trở lên: 1 trái phiếu
                // *Lô giao dịch
                // Trái phiếu mệnh giá dưới 100 triệu đồng: lô giao dịch 10 trái phiếu
                // Trái phiếu mệnh giá từ 100 triệu đồng trở lên: lô giao dịch 01 trái phiếu

                if (quote) {
                    // tradelot = 1 tương đương mệnh giá >= 100 tr
                    // tradelot = 10 tương đương mệnh giá < 100 tr
                    if (quote.tradelot == 10) {
                        return numberValue >= 100 && (lot % 10 == 0)
                    } else if (quote.tradelot == 1) {
                        return numberValue >= 1 && (lot % 1 == 0)
                    }
                }
            }
        }
        return !valueInvalid;
    };

    static getInfoOrderLayoutCurrent() {
        let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
        let layoutCurrent = getLayoutPageCurrent.layoutCurrent;
        const visibleSectionIds = layoutCurrent && layoutCurrent.visibleSectionIds;
        let arr = []
        if (visibleSectionIds) {
            if (visibleSectionIds.includes('placeOrderNormal')) {
                arr.push(ORDER_TYPE_USE.NORMAL_ORDER)
            }
            if (visibleSectionIds.includes('placeOrderPro')) {
                arr.push(ORDER_TYPE_USE.PRO_TRADE_ORDER)
            }
        } else {
            arr = []
        }

        //console.log("getInfoOrderLayoutCurrent", arr)
        return {
            listOrderLayoutCurrent: arr
        }
    }

    static getSymbolOfSymbolSpecial(symbolSpecial) {
        const config = '_WFT'
        if (symbolSpecial && symbolSpecial.endsWith(config)) {
            return symbolSpecial.replace(config, "")
        }
        return symbolSpecial
    }


    static returnPriceByExchange = (instrument, value, isColor) => {
        if ((!CommonUtils.isDerivativeExchange(instrument["EX"]))) {
            return (
                <FormattedPrice isFormatZero={true} value={Number(value) || 0} shortForm={true} />
            )
        } else if ((CommonUtils.isDerivativeExchange(instrument["EX"]))) {
            return (
                <FormattedPrice isFormatZero={true} value={Number(value) || 0} shortForm={true} isNotThousand={true} />
            )
        }
    }

    static checkPriceStepTrailingStop = (symbol, side, delta, priceStep) => {
        const state = reduxStore.getState();
        const quotes = state.symbol.quotes
        const quote = quotes[symbol]
        //console.log("binh_checkPriceStepTrailingStop_input", { symbol, side, delta, priceStep, quote })
        if (quote) {
            let closePrice = 0
            let _delta = Number(delta * 1000)
            let _priceStep = Number(priceStep * 1000)
            //console.log("binh_checkPriceStepTrailingStop_1", { symbol, closePrice, _delta, _priceStep })
            if (quote.exchange == "HOSE") {
                if (quote.StockType == STOCKTYPE.ETF || quote.StockType == STOCKTYPE.CW) {
                    if (_priceStep % 10 != 0) {
                        return false;
                    }
                }
                let instruments = CommonUtils.getInstruments();
                let instrument = instruments[symbol]
                //console.log("binh_checkPriceStepTrailingStop_2", { quote, instruments, instrument })
                if (instrument && instrument["CP"]) {
                    closePrice = instrument["CP"]
                } else {
                    closePrice = quote.reference
                }
                //console.log("binh_checkPriceStepTrailingStop_3", { quote, instruments, instrument, closePrice })
                if (side == orderActions.BUY) {
                    // Lệnh Mua: Mua theo luật của Cột Mua giống SGD, Vì khi giá tăng vượt biên độ nó kích hoạt ngay rồi. Công thức check của mình đã có cộng Biên độ
                    if ((closePrice + _delta) < 10000) {
                        // Trường hợp (Giá TT + Biên độ) < 10000
                        // Tham số bước giá tối thiểu: 10
                        if (_priceStep % 10 != 0) {
                            return false;
                        }
                    } else if ((closePrice + _delta) < 50000) {
                        // Trường hợp (Giá TT + Biên độ) < 50000
                        // Tham số bước giá tối thiểu: 50
                        if (_priceStep % 50 != 0) {
                            return false;
                        }
                    } else {
                        // Trường hợp (Giá TT + Biên độ) > 50000
                        // Tham số bước giá tối thiểu: 100
                        if (_priceStep % 100 != 0) {
                            return false;
                        }
                    }
                } else if (side == orderActions.SELL) {
                    // Lệnh Bán: Lệnh Trailing Stop Bán không có giá kích hoạt lớn nhất vì chứng khoán có thể tăng không giới hạn.
                    // Tuy nhiên giả định trường hợp tăng giá gấp đôi là rất hiếm trong thời gian hiệu lực của lệnh (nếu vào case tăng gấp 3 thì phải chấp nhận tình huống sai bước giá).
                    // Là mình luôn quy định bước giá chặt hơn của Sở để nếu chứng khoán tăng giá (không quá 2 lần) thì bước giá vẫn phù hợp.
                    // thì tăng thì bước giá vẫn chùm vào nhau để chùm vào các khoảng giá
                    if ((closePrice - _delta) < 5000) {
                        // Trường hợp (Giá TT - Biên độ) < 5000
                        // Tham số bước giá tối thiểu: 10
                        if (_priceStep % 10 != 0) {
                            return false;
                        }
                    } else if ((closePrice - _delta) < 25000) {
                        // Trường hợp (Giá TT - Biên độ) < 25000
                        // Tham số bước giá tối thiểu: 50
                        if (_priceStep % 50 != 0) {
                            return false;
                        }
                    } else {
                        // Trường hợp (Giá TT - Biên độ) > 25000
                        // Tham số bước giá tối thiểu: 100
                        if (_priceStep % 100 != 0) {
                            return false;
                        }
                    }
                }
            } else if (quote.exchange == "HNX" || quote.exchange == "UPCOM") {
                if (quote.StockType != STOCKTYPE.BOND && quote.StockType != STOCKTYPE.TPDN && (_priceStep % 100 != 0)) {
                    return false;
                }
            }
            return true
        }
        return false
    }


    static checkCompareCeilFloorReference = (obj) => {
        const { quote, instrument, quoteNew, from } = obj
        if (from) {
            // //console.log("binh_checkCompareCeilFloorReference", { quote, instrument, quoteNew, from }, ceil, CL, instrument.CL)
            // //console.log("binh_checkCompareCeilFloorReference", ceil != CL, floor != FL, reference != RE)
        }
        let isFlag = true
        // ceiling: CL,floor: FL, reference:RE

        if (quote) {
            const { ceiling, floor, reference } = quote
            if (instrument) {
                const { CL, FL, RE } = instrument

                if (ceiling != CL || floor != FL || reference != RE) {
                    isFlag = false
                }
            }

            if (quoteNew) {
                const { ceiling: ceilingNew, floor: floorNew, reference: referenceNew } = quoteNew
                if (ceiling != ceilingNew || floor != floorNew || reference != referenceNew) {
                    isFlag = false
                }
            }
        }
        // //console.log("binh_checkCompareCeilFloorReference_re", { isFlag })
        return isFlag
    }


    static checkInfoQuote = async (symbolId, quote) => {
        let flag = true
        if (symbolId && quote) {
            await symbolService.getQuote(symbolId)
                .then((data) => {
                    if (data && data.length > 0) {
                        let quoteNew = data[0]
                        // if (0 == 0) {
                        //     quoteNew.reference = 180000
                        // }
                        let isCheck = CommonOrderUtils.checkCompareCeilFloorReference({ quote, quoteNew, from: "compare_checkInfoQuote" })
                        if (!isCheck) {
                            batch(() => {
                                dispatch(actions.setIsOpenModalHaveData("WaringQuoteChange", {
                                    isOpenWaringQuoteChange: true,
                                }))

                                dispatch({
                                    type: actionTypes.FETCH_SYMBOL_QUOTE_SUCCESS,
                                    symbol: symbolId,
                                    // data: { symbol, ceiling, floor, reference, FullName, StockType, exchange, bidPrice1, offerPrice1 }
                                    data: quoteNew
                                })
                            });
                            flag = false
                        }
                    }
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-symbol-quote');
                    flag = false
                });
        }
        return flag
    }

}

export default CommonOrderUtils;
