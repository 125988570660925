import { CommonUtils } from "utils";


export const updateInfoCheckAuth = (data, key = "tradeAuth") => {
    return (dispatch, getState) => {
        let verified = data['verified'] ? data['verified'] : false;
        let isVerifiedLocal = CommonUtils.getSaveVerifiedSession();
        if (verified !== isVerifiedLocal) {
            CommonUtils.setIsStep2Authenticated(JSON.stringify(verified), '/', true, '');
        }
        dispatch({
            type: "UPDATE_INFO_CHECK_AUTH",
            data: data,
            key: key,
        });
    };
};


export const updateConfirmCheckAuth = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: "UPDATE_INFO_COMFIRM_AUTH",
            typeInfo: data.typeInfo,
            dataInfo: data.dataInfo,
        });
    };
};

export const clearInfoCheckAuth = (authKey) => {
    return (dispatch, getState) => {
        dispatch({
            type: "CLEAR_INFO_CHECK_AUTH",
            key: authKey,
        });
    };
};