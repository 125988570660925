import { fdsInquiryService, inquiryService } from "../../services";
import { LoadStatus, Role } from "../../utils";
import actionTypes from './actionTypes';

export const fetchSecurities = (forceReload, msgType) => {
    return async (dispatch, getState) => {
        const state = getState();
        // const currentAccount = state.account.currentAccount;
        //hard code key currentAccount lấy danh sách đang nắm giữ (Lấy tất cả gồm cơ sở + phái sinh)
        let currentAccount = { id: "currentAccount" }
        // //console.log("currentAccount sehoding---: ", currentAccount)
        // //console.log("currentAccount sehoding state---: ", state)
        if (currentAccount != null) {
            const loadStatus = state.app.securitiesLoadStatus[currentAccount.id];
            const notLoaded = loadStatus !== LoadStatus.LOADED;
            const notLoading = loadStatus !== LoadStatus.LOADING;
            const { userInfo, isForBroker } = state.user;
            const { currentCustomer } = state.customer;
            let isBroker = (userInfo.role === Role.BROKER);
            let custid = null;
            if (!isBroker || isForBroker) {
                custid = userInfo ? userInfo.custid : null;
            } else if (isBroker) {
                custid = currentCustomer ? currentCustomer.custid : null;
            };
            if (notLoading && (notLoaded || forceReload)) {
                dispatch({
                    type: actionTypes.FETCH_SECURITIES,
                    accountId: currentAccount.id
                });
                let datainq = []
                let datainqfds = []

                await inquiryService.getSecuritiesHolding(custid)
                    .then(data => {
                        datainq = data
                        //console.log("responses--- api 1: ", data)
                    }).catch(error => {
                        datainq = []
                        //console.log("error getSecuritiesHolding sec: ", error)
                    })
                await fdsInquiryService.getSecuritiesHolding(custid)
                    .then(data => {
                        datainqfds = data
                        //console.log("responses--- api 2: ", datainqfds)
                    }).catch(error => {
                        datainqfds = []
                        //console.log("error getSecuritiesHolding fds: ", error)
                    })
                let data = [...datainq, ...datainqfds]
                //console.log("responses--- api 3: ", data)
                dispatch({
                    type: actionTypes.FETCH_SECURITIES_SUCCESS,
                    accountId: currentAccount.id,
                    data: data,
                    msgType: msgType
                });
                // Promise.all([
                //     inquiryService.getSecuritiesHolding(custid).catch(e => { return [] }),
                //     fdsInquiryService.getSecuritiesHolding(custid).catch(e => { return [] }),
                // ]).then((responses) => {
                //     let [datainq, datainqfds] = responses
                //     //console.log("responses--- api 1: ", datainq, datainqfds)
                //     let data = [...datainq, ...datainqfds]
                //     //console.log("responses--- api 2: ", data)
                //     dispatch({
                //         type: actionTypes.FETCH_SECURITIES_SUCCESS,
                //         accountId: currentAccount.id,
                //         data: data,
                //         msgType: msgType
                //     });
                // })
                //     .catch((error) => {
                //         //console.log("responses--- api error: ", error)
                //         dispatch({
                //             type: actionTypes.FETCH_SECURITIES_FAIL
                //         });
                //         if (custid) {
                //             ToastUtil.errorApi(error, 'common.fail-to-load-security-info');
                //         }
                //     });
            }
        }
    };
};
