import { serverService } from '../../services'

import actionTypes from './actionTypes';
// import { ToastUtil } from "../../utils";

export const fetchConnectionCount = () => {
    return (dispatch) => {
        serverService.getConnectionCount().then((data) => {
            dispatch({
                type: actionTypes.GET_CONNECT_COUNT,
                connectionCount: data,
            });
        }).catch((e) => {
            // ToastUtil.errorApi(e, 'common.fail-to-load-connection');
        });
    }
};