export const APP_TITLE = 'VIX';

export const PREFIX_CUSTODYCD = '072C';

export const path = {
    LOGIN: '/login',
    LOG_OUT: '/logout',
    SSO: '/websso',
    CONFIG: '/config',
    PRICEBOARD: '/priceboard',
    TRADE: '/webtrade',
    PROTRADE: '/protrade',
    TPRLTRADE_NM: '/tprltrade-nm',
    TPRLTRADE_OR: '/tprltrade-or',
    TPRLTRADE_RP: '/tprltrade-rp',
    PUTTHROUGHTRADE: '/putthroughtrade',
    MARKET_WATCH: '/market-watch',
    AI_TRADING: '/ai-trading',
    CUSTOMETRADE: '/customtrade',
    ACCOUNT: '/account',
    SETTINGS: '/settings',
    FDS_ACCOUNT: '/fds/account',
    HOME: '/',
    ACCOUNT_REGISTER: '/account-register',
    ERROR: '/error',
    REGISTER_SERVICE: '/setting/register-service',
    RECOMMEND_CUSTOMER: '/recommend-customer',
    VERSION: '/version',
    ASSETS: '/asset-total',
    CUSTOMER_ORGANZITION: '/customer-organization',
    IBROKER: '/i-broker',
    SYMBOL_DETAIL: '/symbol-detail',
    SENTRY_TEST: '/sentry-test',
};

//rptId của các màn hình xuất báo cáo
export const RPT_ID = {
    //Sao kê tiền
    GET_CASH_STATEMENT: 'GET_CASH_STATEMENT',
    // Sao kê chứng khoán
    GET_SECURITIES_STATEMENT: 'SE1000',
    // Lịch sử đặt lệnh (cơ sở)
    ORDERHIST: 'ORDERHIST',
    // Lịch sử khớp lệnh (cơ sở)
    ORDERMATCH: 'ORDERMATCH',
    //Lãi/lỗ đã thực hiện (cơ sở) 
    GET_PNL_EXECUTED: 'GET_PNL_EXECUTED',
    // Báo cáo tổng hợp lãi lỗ
    GET_SUMMARY_PNL: 'GET_SUMMARY_PNL',
    //Tra cứu tài khoản khách hàng
    GET_CAREBY_CUSTOMER: 'GET_CAREBY_CUSTOMER',
    //Tra cứu tài sản khách hàng
    SUMMARY_ACCOUNT_BY_DAY: 'SUMMARY_ACCOUNT_BY_DAY',
    //Quản trị khách hàng: Vi phạm tỉ lệ (phái sinh)
    GET_ACCOUNT_VIOLATED_RATE: 'GET_ACCOUNT_VIOLATED_RATE',
    //Quản trị khách hàng: Theo hạn nợ
    GET_MRLIST_BY_TLID: 'GET_MRLIST_BY_TLID',
    //Quản trị khách hàng: Thực hiện quyền
    GET_RIGHT_OFF_INFOR: 'GET_RIGHT_OFF_INFOR',
    //Tra cứu doanh số 
    GET_BROKER_SALES: 'GET_BROKER_SALES',

};

export const OUTPUT_REPORT = {
    OFFSET: 1,
    LIMIT: 999999999999
}

// Haki_lt.: Layout Widget [START]
export const ACCOUNT_TYPE = {
    CS: 'CS', // Cơ sở
    PS: 'PS', // Phái sinh
    DL: 'DL', // Daily
    FCB: 'FCB', // FCBond
}

export const SCREEN = {
    lg: 'lg',
    md: 'md',
    sm: 'sm'
}

// Quy định các layout theo chức năng
export const WIDGET_LAYOUT = {
    PRICEBOARD: 'PRICEBOARD',
    TRADE: 'TRADE',
}

// Quy định màn hình widget
export const WIDGET_WIDTH = {
    DESKTOP: {
        min: 769,
        max: 3000
    },
    MOBILE: {
        min: 0,
        max: 769
    }
}

export const TYPE_CONFIG = {
    TRADE: 'TRADE',
    PRICEBOARD: 'PRICEBOARD',
    SYMBOLDETAIL: 'SYMBOLDETAIL',
    // MARKETWATCH: 'MARKETWATCH',
}


export const FOCUS_KEYS = {
    SYMBOL: 'SYMBOL',
    CUSTOMER_SELECT: 'CUSTOMER_SELECT',
    PRICE_INPUT: 'PRICE_INPUT'
}

// export const LIST_PRICEBOARD = ['PRICEBOARD', 'derivativeBoard', 'coveredWarrantBoard', 'bondBoard', 'etfBoard']
export const LIST_PRICEBOARD = ['PRICEBOARD']
// Haki_lt.: Layout Widget [End]
export const mDate = 2 * 365; //date from now

//huy.quang: cac tab trong danh muc dau tu o tab tai khoan
export const openPositionPage = { ALL_PORTFOLIO: 0, OPEN_POSITION: 1, ODD_PORTFOLIO: 2, RETAIL_PORTFOLIO: 3, SOLD_PORTFOLIO: 4 };
//huy.quang: cac tab trong lai lo theo dong tien o tab tai khoan
export const nplExecutedByAmountPage = { REPORT_TABLE: 0, AMOUNT_CHANGE_TABLE: 1, SECURITIES_CHANGE_TABLE: 2 };
//tabs in cac khoan no
export const marginLoanPage = { MARGINLOANS: 0, REPAYMENT_HIST: 1 };
// export const orderPage = { MARGINLOANS: 0, REPAYMENT_HIST: 1, OPENPOSITIONS: 2, DRAFTORDERS: 3, ASSET: 4,GROUPORDER: 5, BROKERORDER: 6, PRICEBOARD: 7 };
//tabs in hỗ trợ lãi suất
export const portfolioInterestRateSupportPage = { PORTFOLIO_INTEREST_RATE_SUPPORT: 0, PORTFOLIO_INTEREST_RATE_SUPPORT_HIST: 1 };

export const stepValues = { PRICE: 100000, QUANTITY: 10, QUANTITY_TO: 100000 };
export const reconfirmTypes = { NORMAL_ORDER: 'ORDER', CONDITION_ORDER: 'CONDITION_ORDER', GROUP_ORDER: 'GROUP_ORDER' };
export const orderActions = { BUY: 'buy', SELL: 'sell', DEFAULT: 'default' };
export const orderTypesKey = { limit: 'limit', market: 'market', stop: 'stop' };
export const orderTypes = Object.keys(orderTypesKey).sort();
export const orderTypesShortKey = { limit: 'LO' };

export const execTypes = ['ALL', 'NB', 'NS', 'MS'];
export const execTypeSearch = ['ALL', 'B', 'S'];
export const execTypesSeach1 = ['ALL', 'NB', 'NS'];

export const execTypesObj = { ALL: 'ALL', BUY: 'NB', SELL: 'NS', MS: 'MS' }
//bỏ bán khống, mua cover exectypedesc
export const execTypesRemoveSSBC = ['ALL', 'NB', 'NS', 'MS'];
// export const confirmOrderExecTypes = ['ALL', 'NB', 'NS', 'MS', 'CB', 'CS', 'AB', 'AS'];
export const confirmOrderExecTypes = ['ALL', 'NB', 'NS', 'CB', 'CS', 'AB', 'AS']; // VIX: Bỏ bán cầm cố
export const confirmOrderStatusReportExecTypes = ['ALL', 'Y', 'N'];
export const activeOrderExecType = { BUY: 'NB', SELL: 'NS' };
export const activeOrderExecTypeDesc = { BUY: 'Buy', SELL: 'Sell' };
export const activeOrderExecTypes = ['NB', 'NS'];
export const sideLookupCommand = ['NS', 'NB']

export const agreementOrderExecTypes = ['NB', 'NS'];

export const settlementStatusList = ['ALL', '001', '002'];
export const dueStatusList = ['ALL', '001', '002', '003'];

export const fdsExecTypes = ['ALL', 'NB', 'NS'];
export const orderMethods = ['ALL', 'F', 'O'];
export const subOrderTypes = ['ATO', 'ATC', 'MAK', 'MOK', 'MTL', 'PLO', 'MP', 'MAS'];
export const fullOrderTypes = ['ATO', 'ATC', 'MAK', 'MOK', 'MTL', 'PLO', 'MP', 'LO', 'MAS'];
export const fullSubOrderTypes = { ATO: 'ATO', ATC: 'ATC', MAK: 'MAK', MOK: 'MOK', MTL: 'MTL', PLO: 'PLO', MP: 'MP', LO: 'LO', MAS: 'MAS' };
export const portFolioType = { ALL: '003', SOLD: '002', HOLDING: '001' };
export const orderStatus = ["ALL", "8", "6", "2", "3", "10", "4", "12"];

export const LoadStatus = { NONE: 'NONE', LOADING: 'LOADING', LOADED: 'LOADED', FAIL: 'FAIL' };

export const SocketStatus = { CONNECTING: 'CONNECTING', CONNECTED: 'CONNECTED', DISCONNECTED: 'DISCONNECTED', ERROR: 'ERROR', WARNING: "WARNING" };

export const TransferType = {
    INTERNAL: '001',
    EXTERNAL: '002',
};

// 4 translate
export const AuthenMethod = {
    SMSOTP: "5",
    PIN: "1",
    SMARTOTP: "6"
};

export const TransferTypes = [TransferType.INTERNAL, TransferType.EXTERNAL];

export const AuthenTypes = [AuthenMethod.SMARTOTP, AuthenMethod.SMSOTP, AuthenMethod.PIN];

export const IFlexType = {
    UN_AUTO: '001',
    AUTO: '002',
};

export const IFlexTypes = [IFlexType.UNAUTO, IFlexType.AUTO];

export const TransferStockType = {
    INTERNAL: '001',
};

export const TransferStockTypes = [TransferType.INTERNAL];

export const TradingCCPType = {
    PAY: '001',
    WITHDRAW: '002',
};

export const CashAdvanceType = {
    ADVANCE: '001',
};

export const CashAdvanceTypes = [CashAdvanceType.ADVANCE];

export const ModalConfirmType = {
    STOCK_TRANSFER_INTERNAL: 'STOCK_TRANSFER_INTERNAL',
    CASH_TRANSFER_INTERNAL: 'CASH_TRANSFER_INTERNAL',
    CASH_TRANSFER_EXTERNAL: 'CASH_TRANSFER_EXTERNAL',
    UNHOLD_COREBANK: 'UNHOLD_COREBANK',
    PAY_CCP: 'PAY_CCP',
    WITHDRAWAL_CCP: 'WITHDRAWAL_CCP',
    CASH_IN_ADVANCE: 'CASH_IN_ADVANCE',
    HOLD_MONEY_BIDV: 'HOLD_MONEY_BIDV',
    UNHOLD_MONEY_BIDV: 'UNHOLD_MONEY_BIDV',
    IFLEX_UN_AUTO: "IFLEX_UN_AUTO",
    IFLEX_AUTO: "IFLEX_AUTO",
    TOGGLE_IFLEX: 'TOGGLE_IFLEX',
    DEPOSIT_WITHDRAW: 'DEPOSIT_WITHDRAW',
    REGISTER_VOUCHER: 'REGISTER_VOUCHER',
    IPO_REGISTER: 'IPO_REGISTER',
    CHANGE_INFO: 'CHANGE_INFO',
    BANK_ADDITION: 'BANK_ADDITION',
    BANK_REMOVE: 'BANK_REMOVE',
    REGISTER_AUTO_CASH_TRANS_CORE_BANK: 'REGISTER_AUTO_CASH_TRANS_CORE_BANK',
    REGISTER_MARGIN_SUB_ACCOUNT: 'REGISTER_MARGIN_SUB_ACCOUNT',
    REGISTER_SMS: 'REGISTER_SMS',
    REGISTER_PRODUCT: 'REGISTER_PRODUCT',

    BLB_ACK_CONFIRM: 'BLB_ACK_CONFIRM',
    BLB_ACK_CONFIRM_SELL_CONDITION: 'BLB_ACK_CONFIRM_SELL_CONDITION',
    BLB_ACK_EDIT_CANCEL_ORDER: 'BLB_ACK_EDIT_CANCEL_ORDER', // Xác nhận với case ack lệnh sửa và lệnh hủy
    BLB_DONE_4_DAY: 'BLB_DONE_4_DAY',
    BLB_REJECT: 'BLB_REJECT',
    BLB_CANCEL_FROM_FIXNET: 'BLB_CANCEL_FROM_FIXNET',
    BLB_MAIN_ORDER_CANCEL_WARNNING: 'BLB_MAIN_ORDER_CANCEL_WARNNING',
    BLB_CHILD_ORDER_WARNING: 'BLB_CHILD_ORDER_WARNING', // warning cho precheck lệnh con bloomberg


    PAY_DERIVATIVE: 'PAY_DERIVATIVE', // Nộp rút ký quỹ
    WITHDRAWAL_DERIVATIVE: 'WITHDRAWAL_DERIVATIVE', // Nộp rút ký quỹ

    CONFIRM_NOTI: 'CONFIRM_NOTI',

    RIGHT_OFF: 'RIGHT_OFF',
    RIGHT_OFF_CANCEL: 'RIGHT_OFF_CANCEL',
    DEBT_MANAGEMENT_PAY: 'DEBT_MANAGEMENT_PAY',
    DEBT_MANAGEMENT_EXTEND: 'DEBT_MANAGEMENT_EXTEND',
    CONFIRM_ORDER_ONLINE: 'CONFIRM_ORDER_ONLINE',
    SIGN_UP_2_FACTOR_AUTH: 'SIGN_UP_2_FACTOR_AUTH',
    FORGET_PASSWORD: 'FORGET_PASSWORD',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    TOKEN_LIFETIME: 'TOKEN_LIFETIME',

    SAVE_2_FACTOR_AUTH: 'SAVE_2_FACTOR_AUTH',

    SPLIT_ORDER_WARNNING: 'SPLIT_ORDER_WARNNING',
    SPLIT_ORDER_BY_EXCHANGE_WARNNING: 'SPLIT_ORDER_BY_EXCHANGE_WARNNING',

    SELL_ALL_OPENPOSITION_NOTE: 'SELL_ALL_OPENPOSITION_NOTE'
};

export const TradingCCPTypes = [TradingCCPType.PAY, TradingCCPType.WITHDRAW];

export const FeeType = {
    IN_FEE: 'inFee',
    OUT_FEE: 'outFee'
};

export const FeeTypes = [FeeType.IN_FEE, FeeType.OUT_FEE];

export const TradingDerivativeType = {
    PAY: '001',
    WITHDRAW: '002',
};

export const TradingDerivativeTypes = [TradingDerivativeType.PAY, TradingDerivativeType.WITHDRAW];

export const TradingMoneyBIDVType = {
    HOLD: '001',
    UNHOLD: '002',
};

export const TradingMoneyBIDVTypes = [TradingMoneyBIDVType.HOLD, TradingMoneyBIDVType.UNHOLD];

// export const TradeAuthType = {
//     PIN: "0",
//     OTP: "1",
//     CTS: "4",
//     SMARTOTP: "6"
// };
export const TradeAuthType = {
    OTP: "5",
    PIN: "1",
    TOKEN: "2",
    MATRIX: "3",
    //binhuun: Hiện tại đang chỉ có 4 xác thực ( 10/8/20222 )
    CTS: "4",
    SMARTOTP: "6"
};

export const PRICEBOARD_THEMES = { CLASSIC: 'C', OLED: 'O' };

export const TradeAuthTypes = [TradeAuthType.PIN, TradeAuthType.OTP];

export const Role = { CUSTOMER: 'C', BROKER: 'B' };

export const EXCHANGE = { HOSE: 'HOSE', HNX: 'HNX', UPCOM: 'UPCOM', DERIVATIVE: 'XHNF', TPRL: "TPRL" };
export const STOCKTYPE = { BOND: '1', ETF: '3', CW: '4', TPDN: '12' };

export const screenMode = { DESKTOP: 'desktop', MOBILE: 'mobile', MEDIUM: 'medium' };

export const DragTypes = {
    WIDGET: 'WIDGET'
};

export const derivativeExchanges = ['XHNF'];

export const isComs = ['ALL', 'Y', 'N'];

export const oddLotByExchange = {
    HOSE: 100,
    HNX: 100,
    UPCOM: 100
};

export const sides = ['B', 'S'];

export const conditionSides = ['buy', 'sell'];

export const conditionSide = { BUY: 'buy', SELL: 'sell' };

export const indexes = ['VNINDEX', 'VN30', 'HNXINDEX', 'HNX30', 'UPCOM'];

export const operators = ['>', '≥', '=', '<', '≤'];

export const formatExcelFiles = ['xls', 'xlsx', 'csv'];

export const reportTypes = {
    XLSX: 'XLSX',
    PDF: 'PDF'
};

export const reportParams = {
    ALL: 'ALL',
    OUT: '?',
    NULL: '?',
    OPT: 'A',
    BRID: '0001'
}
export const modules = {
    ORDINPUT: 'ORDINPUT', COND_ORDER: 'COND_ORDER', GROUP_ORDER: 'GROUP_ORDER',
    CASHTRANS: 'CASHTRANS', REPURCHARSE: 'REPURCHARSE', ADWINPUT: 'ADWINPUT',
    ISSUEINPUT: 'ISSUEINPUT', MORTGAGE: 'MORTGAGE', BONDSTOSHARES: 'BONDSTOSHARES',
    TERMDEPOSIT: 'TERMDEPOSIT', STOCKTRANS: 'STOCKTRANS', REGISTER: 'REGISTER',
    REGISTER_SMSOTP: 'REGISTER_SMSOTP', SELL_ODDLOT_STOCK: 'SELL_ODDLOT_STOCK',
    RESETPASS: 'RESETPASS', CASHTRANSINT: 'CASHTRANSINT', EXTENDMARGIN: 'EXTENDMARGIN'
};

export const templateAccMess = {
    "401A": { path: "/account/confirm-order" },
};

export const SymbolCollectionTypes = {
    WATCH_LIST: 'WATCH_LIST',
    EXCHANGE: 'EXCHANGE',
    PORTFOLIO: 'PORTFOLIO',
    FIXED_LIST: 'FIXED_LIST',
    TOP_INSTRUMENT: 'TOP_INSTRUMENT',
    INDUSTRY: 'INDUSTRY',
    CW: 'CW',
    BONDS: 'BONDS',
    PUTTHROUGH: 'PUTTHROUGH',
    INDEX: 'INDEX',
    IBROKER: 'IBROKER',
    DERIVATIVE: 'DERIVATIVE',
    RECOMMEND: 'RECOMMEND',
    ETF: 'ETF',
    ODDLOT: 'ODDLOT',
    CORPBOND: 'CORPBOND'
};

export const resetPinPassTypes = {
    ALL: 1,
    PIN: 2,
    PASS: 3
}

export const WidgetDisplayModes = {
    MINIMAL: 'MINIMAL',
    COMPACT: 'COMPACT'
};

export const ShortcutMenuIconTypes = {
    SVG: 'SVG',
    FONT: 'FONT'
};

export const ShortcutMenuTypes = {
    LINK: 'LINK',
    ACTION: 'ACTION',
    EVENT: 'EVENT'
};

export const CommonObject = {
    emptyObject: Object.freeze({}),
    emptyArray: Object.freeze([])
};

export const sortDirObj = {
    ASC: "ASC",
    DESC: "DESC",
};

export const YesNoObj = {
    YES: 'yes',
    NO: 'no'
};

export const termDeposit = [
    { title: 'yes', value: 'Y' },
    { title: 'no', value: 'N' }
];

export const textPrices = ['ATO', 'ATC'];

export const FixVarObject = {
    FIX: 'Fixed',
    VAR: 'Tier'
};

export const ibondMethods = {
    T: 'VAR',
    F: 'FIX'
}

export const bondStransferHistStatus = {
    A: {
        vi: 'Hoàn Thành',
        en: 'Completed'
    },
    P: {
        vi: 'Đã gửi',
        en: 'Sent'
    }
}
export const subsideTypeSTO = {
    profitSell: 'P',
    lossSell: 'L',
};
export const subsideTypeSEO = {
    sell: 'NS',
    buy: 'NB'
};

export const floorCode = {
    HNX: '02',
    HOSE: '10',
    UPCOM: '04',
    VN30: '30',
    DERIVATIVE_INDEX: '03',
    HNX30: 'HNX30',
    VNXALLSHARE: '13',
    DERIVATIVE_VN30: 'DER01',
    DERIVATIVE: 'XHNF',
}

export const SEOVERTIME_TYPE = {
    SUM: 'A',
    DETAIL: 'B'
}

export const PRECHECK_ORDER_STATUS = {
    CHECKED: 'S',
    ERROR: 'E',
    CHECKING: 'C',
    NONE: 'N'
}


export const TABS_ON_FOOTER = {
    ASSET: "ASSET",
    BASEORDER: "BASEORDER",
    GROUPORDER: "GROUPORDER",
    ORDERLIST: "ORDERLIST",
    OPENPOSITIONS: "OPENPOSITIONS",
    DRAFTORDERS: "DRAFTORDERS",
    PRICEBOARD: "PRICEBOARD",
    BROKERORDER: "BROKERORDER"
}

export const FILTER_SYMBOL_ID = {
    INDUSTRIES_EVENT: "INDUSTRIES_EVENT", // sự kiện quyền

    topChangeHNX: "TOP_GAIN_OF_HNX", // Top mã tăng giá
    topKeepHNX: "TOP_LOSS_OF_HNX", // Top mã giảm giá

    topChangeHOSE: "TOP_GAIN_OF_HOSE",
    topKeepHOSE: "TOP_LOSS_OF_HOSE",

    topChangeUPCOM: "TOP_GAIN_OF_UPCOM",
    topKeepUPCOM: "TOP_LOSS_OF_UPCOM"
}

export const formatNumberTypes = {
    ONE_DIGITS: '0,0.[0]',
    TWO_DIGITS: '0,0.[00]',       //get 2 digits after common
    THREE_DIGITS: '0,0.[000]',    //get 3 digits after common
};

export const COUNT_DOWN_RELOAD = 10

export const SESSION_STORAGE_LIST_MARKET_CHART = 'SESSION_STORAGE_LIST_MARKET_CHART';

export const CURRENT_LOGIN_TOKEN = 'CURRENT_LOGIN_TOKEN';

export const FOOTER_INDEXES = [
    { title: 'VN-INDEX', code: 'HOSE', department: 'HOSE' },
    { title: 'HNX-INDEX', code: 'HNX', department: 'HNX' },
    { title: 'UPCOM', code: 'UPCOM', department: 'HNX' },
]

export const PERMISSION_MODULE_INFO = {
    "TERMDEPOSIT": "TERMDEPOSIT" // quyền hỗ trợ lãi suất
}

export const listPercentOrder = [
    {
        key: 1,
        title: "25%",
        value: 0.25,
    },
    {
        key: 2,
        title: "50%",
        value: 0.5,
    },
    {
        key: 3,
        title: "75%",
        value: 0.75,
    },
    {
        key: 4,
        title: "100%",
        value: 1,
    }
]

export const LIST_IS_OPEN_MODAL = {
    IS_OPEN_QUICK_ORDER: "isOpenQuickOrder",
}

export const TYPE_DAY = {
    DURING_DAY: 'T', // Trong ngày
    MUCH_DAY: 'G' // Nhiều ngày
}

export const TYPE_SUBMIT_ORDER = {
    ORDER: 'ORDER',
    DRAP_ORDER: 'DRAP_ORDER',
    TPSL_ORDER: 'TPSL_ORDER',
    CONDITION_ORDER: 'CONDITION_ORDER',
    TPRL_NM_ORDER: 'TPRL_NM_ORDER', // TPRL lệnh thường
    TPRL_NM_ORDER: 'TPRL_NM_ORDER', // TPRL lệnh outright
    TPRL_NM_ORDER: 'TPRL_NM_ORDER', // TPRL lệnh repo
}

export const ORDER_TYPE_USE = {
    QUICK_ORDER: 'QUICK_ORDER', // Trong ngày
    NORMAL_ORDER: 'NORMAL_ORDER', // Trong ngày
    TPRL_NM_ORDER: 'TPRL_NM_ORDER', // TPRL lệnh thường
    TPRL_OR_ORDER: 'TPRL_OR_ORDER', // TPRL lệnh outright
    TPRL_RP_ORDER: 'TPRL_RP_ORDER', // TPRL lệnh repo
    PRO_TRADE_ORDER: 'PRO_TRADE_ORDER',
    PUTTHROUGH_TRADE_ORDER: 'PUTTHROUGH_TRADE_ORDER', // Thỏa thuận
    BLB_TRADE_ORDER: 'BLB_TRADE_ORDER', // Lệnh tổng BloomBerg
    BLB_CHILD_TRADE_ORDER: 'BLB_CHILD_TRADE_ORDER' // Lệnh con BloomBerg
}

export const ACTION_USER_ORDER = {
    PLACE_ORDER: 'PLACE_ORDER', // Đặt lệnh
    CANCEL_ORDER: 'CANCEL_ORDER', // Hủy lệnh
    EDIT_ORDER: 'EDIT_ORDER' // Sửa lệnh
}

// User actions của lệnh tổng bloomberg (ACK ,reject, hủy từ fixnet,...) để case màn giao diện xác nhận
export const BLB_ORDER_ACTION = {
    BLB_ACK_CONFIRM: 'BLB_ACK_CONFIRM',
    BLB_ACK_CONFIRM_SELL_CONDITION: 'BLB_ACK_CONFIRM_SELL_CONDITION',
    BLB_ACK_EDIT_CANCEL_ORDER: 'BLB_ACK_EDIT_CANCEL_ORDER', // Xác nhận với case ack lệnh sửa và lệnh hủy
    BLB_REJECT: 'BLB_REJECT',
    BLB_CANCEL_FROM_FIXNET: 'BLB_CANCEL_FROM_FIXNET',
    BLB_DONE_4_DAY: 'BLB_DONE_4_DAY',
}

export const SELECTOR_ACCOUNT_SCREEN = {
    QUICK_ORDER: 'QUICK_ORDER',
    LAYOUT: 'LAYOUT',
    SCREEN_MODAL: 'SCREEN_MODAL'
}

export const TYPE_ORDER_CONDITION = {
    STOP_LIMIT: 'orderConditionLimit',
    TRAILING_STOP: 'orderConditionTrailing',
    OCO: 'orderConditionOCO'
}

export const ACTION_METHOD_CONDITION = {
    FINAL_MATCH_PRICE: 'C',
    TOP_WAIT_MATCH: 'T'
}

export const TYPE_ACCOUNT = {
    BASE: 1,
    FDS: 2,
    BOND: 3,
}

export const LIST_TYPE_DEFAULT_TPRL_OR = {
    TRANSACTION_REPORTS: "bcgd",
    AGREEMENT: "ttdt",
    PAYMENT_NOW: 1,
    PAYMENT_ENDDAY: 2,
}

export const initialQuickOrderInputState = {
    isCheckFillPrice: false,
    isCheckClearOrder: false,  //Trạng thái clear dữ liệu đặt lệnh
    symbolTempCheck: null, //BSCWEBEXT-392
    actionUserOrder: null, // action thực hiện
    symbolCurrent: null, // Thông tin mã chứng khoán bảo gồm trần sàn tham chiếu...
    action: orderActions.BUY, // Đặt lệnh mua or bán
    orderType: 'limit', // loại lệnh limt - market - condition
    isCheckSplitOrder: false,
    limitPrice: 0, // giá mua
    isValidMarketPrice: true,
    quotePriceValid: false,
    stepPriceValid: false,
    quantity: 0, // khối lương
    quantityValid: false,
    subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)
    subOrderTypes: [], // Danh sách sàn (dùng khi chọn sàn trong input nhập mã)
    editingOrder: null, // Danh sách sửa lệnh
    cancelingOrder: null, // Danh sách hủy lệnh
    validUntilOrder: TYPE_DAY.DURING_DAY,
    fromDate: null,
    toDate: null,
    toDateConst: null,
    typeSubmitOrder: TYPE_SUBMIT_ORDER.ORDER,
    accountQuickOrderInfo: {
        "curCustomer": "",                //thông tin khách hàng dành cho MG
        "accounts": [],                  //danh sách tiểu khoản (accountid) của tài khoản
        "currentAccountId": "",          //tiểu khoản đang active
        "typeAccount": TYPE_ACCOUNT.BASE,
    },
    typeOrderCondition: TYPE_ORDER_CONDITION.STOP_LIMIT,
    orderConditionLimit: {
        stopPrice: 0, // Giá kích hoạt
        stepStopPriceValid: false,
        quoteStopPriceValid: false,

        quotePrice: 0, // giá đặt
        quotePriceValid: false,
        stepPriceValid: false,

        quantity: 0, // khối lượng
        quantityValid: false,
        subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

        validUntilOrder: TYPE_DAY.MUCH_DAY,
        fromDate: null,
        toDate: null,
        toDateConst: null,
    },
    orderConditionTrailing: {
        delta: 0, // Biên độ
        deltaValid: false,

        priceStep: 0, // Bước giá
        priceStepValid: false,

        quantity: 0, // khối lượng
        quantityValid: false,
        subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

        validUntilOrder: TYPE_DAY.MUCH_DAY,
        fromDate: null,
        toDate: null,
        toDateConst: null,
    },
    orderConditionOCO: {
        stopPriceLo: 0, // Giá kích hoạt
        stepStopPriceLoValid: false,
        quoteStopPriceLoValid: false,


        stopPrice: 0, // Giá kích hoạt
        stepStopPriceValid: false,
        quoteStopPriceValid: false,

        quotePrice: 0, // giá đặt
        quotePriceValid: false,
        stepPriceValid: false,

        quantity: 0, // khối lượng
        quantityValid: false,
        subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

        validUntilOrder: TYPE_DAY.MUCH_DAY,
        fromDate: null,
        toDate: null,
        toDateConst: null,
    },
    typeOrderSelectdTPRLOR: LIST_TYPE_DEFAULT_TPRL_OR.TRANSACTION_REPORTS,
    custodycdPartnerTPRLOR: null,
    partyIdTPRLOR: null,
    typePaymentTPRLOR: LIST_TYPE_DEFAULT_TPRL_OR.PAYMENT_NOW,
};



export const initialNonmalOrderInputState = {
    editOnly: false, // Trạng thái của màn đặt lệnh nhanh (true nếu chỉ để sửa lệnh và không cho đặt cũng như xem sổ lệnh) 
    isCheckFillPrice: false,
    isCheckClearOrder: false,  //Trạng thái clear dữ liệu đặt lệnh
    symbolTempCheck: null, //BSCWEBEXT-392
    actionUserOrder: null, // action thực hiện
    symbolCurrent: null, // Thông tin mã chứng khoán bảo gồm trần sàn tham chiếu...
    action: orderActions.BUY, // Đặt lệnh mua or bán
    orderType: 'limit', // loại lệnh limt - market - condition
    limitPrice: 0, // giá mua
    isValidMarketPrice: true,
    quotePriceValid: false,
    stepPriceValid: false,
    quantity: 0, // khối lương
    isCheckSplitOrder: false, // Có chia lệnh hay không 
    splitQuantity: 0, // Khối lượng chia (Chỉ dùng cho môi giới)
    splitQuantityValid: 0, // Khối lượng chia (Chỉ dùng cho môi giới)
    quantityValid: false,
    subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)
    editingOrder: null, // Danh sách sửa lệnh
    cancelingOrder: null, // Danh sách hủy lệnh
    validUntilOrder: TYPE_DAY.DURING_DAY,
    fromDate: null,
    toDate: null,
    toDateConst: null,
    typeSubmitOrder: TYPE_SUBMIT_ORDER.ORDER,
    typeOrderCondition: TYPE_ORDER_CONDITION.STOP_LIMIT,
    focusSymbol: false,
    orderConditionLimit: {
        stopPrice: 0, // Giá kích hoạt
        stepStopPriceValid: false,
        quoteStopPriceValid: false,

        quotePrice: 0, // giá đặt
        quotePriceValid: false,
        stepPriceValid: false,

        quantity: 0, // khối lượng
        quantityValid: false,
        subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

        validUntilOrder: TYPE_DAY.MUCH_DAY,
        fromDate: null,
        toDate: null,
        toDateConst: null,
    },
    orderConditionTrailing: {
        delta: 0, // Biên độ
        deltaValid: false,

        priceStep: 0, // Bước giá
        priceStepValid: false,

        quantity: 0, // khối lượng
        quantityValid: false,
        subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

        validUntilOrder: TYPE_DAY.MUCH_DAY,
        fromDate: null,
        toDate: null,
        toDateConst: null,
    },
    orderConditionOCO: {
        stopPriceLo: 0, // Giá kích hoạt
        stepStopPriceLoValid: false,
        quoteStopPriceLoValid: false,


        stopPrice: 0, // Giá kích hoạt
        stepStopPriceValid: false,
        quoteStopPriceValid: false,

        quotePrice: 0, // giá đặt
        quotePriceValid: false,
        stepPriceValid: false,

        quantity: 0, // khối lượng
        quantityValid: false,
        subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

        validUntilOrder: TYPE_DAY.MUCH_DAY,
        fromDate: null,
        toDate: null,
        toDateConst: null,
    },
    typeOrderSelectdTPRLOR: LIST_TYPE_DEFAULT_TPRL_OR.TRANSACTION_REPORTS,
    custodycdPartnerTPRLOR: null,
    partyIdTPRLOR: null,
    typePaymentTPRLOR: LIST_TYPE_DEFAULT_TPRL_OR.PAYMENT_NOW,
};

export const initialProTradeOrderInputState = {
    isCheckFillPrice: false,
    symbolTempCheck: null, //BSCWEBEXT-392
    typeAction: orderActions.BUY, // Dùng để xác thực
    symbolCurrent: null, // Thông tin mã chứng khoán bảo gồm trần sàn tham chiếu...
    orderType: 'limit', // loại lệnh limt - market - condition
    typeOrderCondition: TYPE_ORDER_CONDITION.STOP_LIMIT,
    typeSubmitOrder: TYPE_SUBMIT_ORDER.ORDER,
    actionUserOrder: null, // action thực hiện

    editingOrder: null, // Danh sách sửa lệnh
    cancelingOrder: null, // Danh sách hủy lệnh
    'buy': {
        actionUserOrder: null, // action thực hiện
        action: orderActions.BUY, // Đặt lệnh mua or bán
        limitPrice: 0, // giá mua
        quotePriceValid: false,
        stepPriceValid: false,
        quantity: 0, // khối lương
        quantityValid: false,
        subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)
        subOrderTypes: [], // Danh sách sàn (dùng khi chọn sàn trong input nhập mã)
        editingOrder: null, // Danh sách sửa lệnh
        cancelingOrder: null, // Danh sách hủy lệnh
        validUntilOrder: TYPE_DAY.DURING_DAY,
        fromDate: null,
        toDate: null,
        toDateConst: null,
    },
    'sell': {
        actionUserOrder: null, // action thực hiện
        action: orderActions.SELL, // Đặt lệnh mua or bán
        limitPrice: 0, // giá mua
        quotePriceValid: false,
        stepPriceValid: false,
        quantity: 0, // khối lương
        quantityValid: false,
        subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)
        subOrderTypes: [], // Danh sách sàn (dùng khi chọn sàn trong input nhập mã)
        editingOrder: null, // Danh sách sửa lệnh
        cancelingOrder: null, // Danh sách hủy lệnh
        validUntilOrder: TYPE_DAY.DURING_DAY,
        fromDate: null,
        toDate: null,
        toDateConst: null,
    },
    orderConditionLimit: {
        'buy': {
            stopPrice: 0, // Giá kích hoạt
            stepStopPriceValid: false,
            quoteStopPriceValid: false,

            quotePrice: 0, // giá đặt
            quotePriceValid: false,
            stepPriceValid: false,

            quantity: 0, // khối lượng
            quantityValid: false,
            subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

            validUntilOrder: TYPE_DAY.MUCH_DAY,
            fromDate: null,
            toDate: null,
            toDateConst: null,
        },
        'sell': {
            stopPrice: 0, // Giá kích hoạt
            stepStopPriceValid: false,
            quoteStopPriceValid: false,

            quotePrice: 0, // giá đặt
            quotePriceValid: false,
            stepPriceValid: false,

            quantity: 0, // khối lượng
            quantityValid: false,
            subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

            validUntilOrder: TYPE_DAY.MUCH_DAY,
            fromDate: null,
            toDate: null,
            toDateConst: null,
        }
    },
    orderConditionTrailing: {
        'buy': {
            delta: 0, // Biên độ
            deltaValid: false,

            priceStep: 0, // Bước giá
            priceStepValid: false,

            quantity: 0, // khối lượng
            quantityValid: false,
            subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

            validUntilOrder: TYPE_DAY.MUCH_DAY,
            fromDate: null,
            toDate: null,
            toDateConst: null,
        },
        'sell': {
            delta: 0, // Biên độ
            deltaValid: false,

            priceStep: 0, // Bước giá
            priceStepValid: false,

            quantity: 0, // khối lượng
            quantityValid: false,
            subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

            validUntilOrder: TYPE_DAY.MUCH_DAY,
            fromDate: null,
            toDate: null,
            toDateConst: null,
        }
    },
    orderConditionOCO: {
        'buy': {
            stopPriceLo: 0, // Giá kích hoạt
            stepStopPriceLoValid: false,
            quoteStopPriceLoValid: false,


            stopPrice: 0, // Giá kích hoạt
            stepStopPriceValid: false,
            quoteStopPriceValid: false,

            quotePrice: 0, // giá đặt
            quotePriceValid: false,
            stepPriceValid: false,

            quantity: 0, // khối lượng
            quantityValid: false,
            subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

            validUntilOrder: TYPE_DAY.MUCH_DAY,
            fromDate: null,
            toDate: null,
            toDateConst: null,
        },
        'sell': {
            stopPriceLo: 0, // Giá kích hoạt
            stepStopPriceLoValid: false,
            quoteStopPriceLoValid: false,


            stopPrice: 0, // Giá kích hoạt
            stepStopPriceValid: false,
            quoteStopPriceValid: false,

            quotePrice: 0, // giá đặt
            quotePriceValid: false,
            stepPriceValid: false,

            quantity: 0, // khối lượng
            quantityValid: false,
            subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)

            validUntilOrder: TYPE_DAY.MUCH_DAY,
            fromDate: null,
            toDate: null,
            toDateConst: null,
        },
    }
}

export const initialPutthroughOrderInputState = {
    actionUserOrder: null, // action thực hiện
    symbolCurrent: null, // Thông tin mã chứng khoán bảo gồm trần sàn tham chiếu...
    action: orderActions.SELL, // Đặt lệnh mua or bán (Lệnh thỏa thuận mặc định bán)
    orderType: 'limit', // loại lệnh limt - market - condition
    limitPrice: 0, // giá mua
    quotePriceValid: false,
    stepPriceValid: false,
    quantity: 0, // khối lương
    quantityValid: false,
    subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)
    editingOrder: null, // Danh sách sửa lệnh
    cancelingOrder: null, // Danh sách hủy lệnh
    typeSubmit: 1,
    crossType: '1F', // Loại đặt lệnh thỏa thuận (1F: Cùng thành viên, 2F: Khác thành viên)
    firm: '', // TVLK đối ứng của lệnh thỏa thuận
    tradeid: '', // Mã TVLK đối ứng của lệnh thỏa thuận
    ioild: '', // Mặc định truyền rỗng trường này
    clearDay: '', // Chu kỳ thanh toán lệnh thỏa thuận
    typeOrderCondition: TYPE_ORDER_CONDITION.STOP_LIMIT,
};

export const initialBlbOrderInputState = {
    actionUserOrder: null, // action thực hiện
    symbolCurrent: null, // Thông tin mã chứng khoán bảo gồm trần sàn tham chiếu...
    action: orderActions.BUY, // Đặt lệnh mua or bán (Lệnh thỏa thuận mặc định bán)
    orderType: 'limit', // loại lệnh limt - market - condition
    limitPrice: 0, // giá mua
    quotePriceValid: false,
    stepPriceValid: false,
    quantity: 0, // khối lương
    quantityValid: false,
    editingOrder: null, // Danh sách sửa lệnh
    cancelingOrder: null, // Danh sách hủy lệnh
    typeSubmit: 1,
    note: '', // Ghi chú của lệnh tổng BloomBerg
    typeOrderCondition: TYPE_ORDER_CONDITION.STOP_LIMIT,
    subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)
    subOrderTypes: [], // Danh sách sàn (dùng khi chọn sàn trong input nhập mã)
    // Riêng Lệnh tổng
    confirmData: {}, // dữ liệu confirm dùng cho các chức năng liên quan (ACK, reject , Hủy từ Fixnet, ...)
    cancelOrderConfirm: {}, // Dữ liệu confirm cho hủy lệnh
    backStep: null, // Backstep phục vụ chức năng ACK lệnh
    handleClose: null, // Nút đóng cho tradeauth modal
    useBtnClose: false, // Dùng nút đóng tự xử lý
};

export const initialChildBlbOrderInputState = {
    actionUserOrder: null, // action thực hiện
    symbolCurrent: null, // Thông tin mã chứng khoán bảo gồm trần sàn tham chiếu...
    action: orderActions.BUY, // Đặt lệnh mua or bán (Lệnh thỏa thuận mặc định bán)
    orderType: 'limit', // loại lệnh limt - market - condition
    limitPrice: 0, // giá mua
    quotePriceValid: false,
    stepPriceValid: false,
    quantity: 0, // khối lương
    quantityValid: false,
    editingOrder: null, // Danh sách sửa lệnh
    cancelingOrder: null, // Danh sách hủy lệnh
    typeSubmit: 1,
    note: '', // Ghi chú của lệnh tổng BloomBerg
    typeOrderCondition: TYPE_ORDER_CONDITION.STOP_LIMIT,
    subOrderType: '', // Loại sàn (dùng khi chọn sàn trong input nhập mã)
    subOrderTypes: [], // Danh sách sàn (dùng khi chọn sàn trong input nhập mã)
};

export const FLDNAMES_VALID = {
    DATEOFBIRTH: "DATEOFBIRTH",  //ngày sinh
    MOBILESMS: "MOBILESMS",  //số điện thoại di động
    MOBILE: "MOBILE",  //số điện thoại cố định  
    EMAIL: "EMAIL",  //email
    SEX: "SEX",  //giới tính
    ADDRESS: "ADDRESS",  //địa chỉ
};

export const accountNotifyType = {
    TRANSACTION: 1,
    MARKET_ALERT: 2
}

export const OPENNING_SCREEN_TYPE = {
    LAYOUT: 'LAYOUT',
    SCREEN_MODAL: 'SCREEN_MODAL',
    MODAL: 'MODAL',
    ORDER: 'ORDER'
}

export const BROKER_VIEW_RULE = {
    OPEN_ALLOW: 'OPEN_ALLOW', // Cho phép mở màn bao gồm cả mở màn disable chức năng (code chặn trên từng màn)
    NO_ACCESS: 'NO_ACCESS', // Không cho phép môi giới mở màn (ẩn)
    DISPLAY_NOT_ALOW_WARNNING: 'DISPLAY_NOT_ALOW_WARNNING', // Cho phép hiển thị và tím kiếm nhưng khi nhấn vào bắn ra thông báo warnning không có quyền truy cập
    HIDE: "HIDE" // Không hiển thị với tk môi giới
}

export const PUTTHROUGH_ORDER_TYPE = {
    ONE_FIRM: "1F", // Cùng thành viên
    TWO_FIRM: "2F" // Khác thành viên
}

export const PUTTHROUGH_ORDER_ACTION = {
    PLACE_ORDER: 'PLACE_ORDER', // Đặt lệnh (confirrm cho đặt lệnh thỏa thuận)
    CONFIRM_PLACE_PUTTHROUGH_NOTI: 'CONFIRM_PLACE_PUTTHROUGH_NOTI', // confirm cho thông báo xác nhận lệnh thỏa thuận
    CONFIRM_PLACE_PUTTHROUGH_ORDER: 'CONFIRM_PLACE_PUTTHROUGH_ORDER', // confirm cho xác nhận lệnh thỏa thuận
    CONFIRM_CANCEL_PUTTHROUGH_ORDER: 'CONFIRM_CANCEL_PUTTHROUGH_ORDER' // confirm cho hủy xác nhận lệnh thỏa thuận
}

export const CONFIRM_CANCEL_PUTTHROUGH_CDTYPE = {
    CONFIRM: "A",
    CANCEL: "R"
}

export const BLB_ACK_OPTIONS = {
    CHILD_ORD_PRICE: "CHILD_ORD_PRICE",
    AVG_PRICE: "AVG_PRICE",
    NOT_CHILD_ORD_PRICE: "NOT_CHILD_ORD_PRICE",
    BLANK_PRICE_TYPE: "BLANK_PRICE_TYPE"
}

export const BLB_ORDER_STATUS_FOR_ACK = {
    NEW_ORDER: 'N',
    UPDATED_ORDER: 'A',
    CANCELED_ORDER: 'C'
}

export const BLB_CONFIRM_TYPE_FOR_ACK = {
    NEW_ORDER: 'NEW_ORDER',
    AMEND_ORDER: 'AMEND_ORDER',
    CANCEL_ORDER: 'CANCEL_ORDER'
}


export const LAYOUT_STATUS = {
    NONE: 'N',
    UPDATE: 'U',
}
