

const initialAuth = {
    transactionId: null,
    tokenid: null,
    listAuthType: [],
    challengeQuestion: null,
    authtype: null,
    verified: false,
}

const initialState = {
    infoAuth: {
        "C&B": {
            authKey: "tradeAuth",
            tranAuth: {
                ...initialAuth
            },
            tradeAuth: {
                ...initialAuth
            },
            infoConfirmAuth: {
                typeInfo: null,
                dataInfo: null,
            }
        }
    }

};

const checkAuthReducer = (state = initialState, action) => {

    switch (action.type) {
        case "UPDATE_INFO_CHECK_AUTH":
            let _updateinfocheckauth = {
                ...state.infoAuth["C&B"],
                authKey: action.key,
                [action.key]: { ...action.data }
            }
            return {
                ...state,
                infoAuth: {
                    ...state.infoAuth,
                    "C&B": _updateinfocheckauth
                }
            };
        case "UPDATE_INFO_COMFIRM_AUTH":
            let _updateconfirmauth = {
                ...state.infoAuth["C&B"],
                infoConfirmAuth: {
                    typeInfo: action.typeInfo,
                    dataInfo: action.dataInfo
                }
            }
            return {
                ...state,
                infoAuth: {
                    ...state.infoAuth,
                    "C&B": _updateconfirmauth
                }

            };
        case "CLEAR_INFO_CHECK_AUTH":
            let authKeyNew = state.infoAuth["C&B"].authKey
            if (action.key) {
                authKeyNew = action.key
            }
            return {
                ...state,
                infoAuth: {
                    ...state.infoAuth,
                    "C&B": {
                        ...state.infoAuth["C&B"],
                        [authKeyNew]: { ...initialAuth }
                    }
                }
            };
        default:
            return state;
    }
};

export default checkAuthReducer;