import { push } from 'connected-react-router';
import { Role } from 'constants/config';
import { batch } from 'react-redux';

import { inquiryService } from "../../services";
import userService from '../../services/userService';
import * as actions from "../../store/actions";
import { getValueFromLocalStorage, resetPinPassTypes } from '../../utils';
import { ToastUtil } from "../../utils";
import CommonUtils from '../../utils/CommonUtils';

import { fetchAccountsSuccess } from './accountActions';
import actionTypes from './actionTypes';
import { changeLanguage, setCurrentDate, setCurrentReconfirmOrder, setClientConfig } from './appActions';
import { fetchSecurities } from './inquiryActions';
import { getLayoutConfig, loadIntiDataLayout } from './layoutActions';
import { setSymbolSelectorCollectionId, setSymbolSelectorUserCollectionId } from './componentStateAction'
import { emitter } from 'utils/EventEmitter';
const loginStart = () => ({
    type: actionTypes.USER_LOGIN_START
});

const loginSucess = () => ({
    type: actionTypes.USER_LOGIN_SUCCESS
});

export const loginFail = (error) => ({
    type: actionTypes.USER_LOGIN_FAIL,
    error: error
});

export const authorizationSuccess = (data) => {
    return {
        type: actionTypes.AUTHORIZATION_SUCCESS,
        data: data
    }
};

export const refetchPermissionList = (cb) => {
    return (dispatch, getState) => {
        userService.getPermissionList()
            .then(data => {
                dispatch({
                    type: actionTypes.FETCH_USER_PERMISSION_INFO,
                    permissionInfo: data,
                });
                if (cb) cb();
            }).catch(err => {
                ToastUtil.errorApi(err, 'fails-to-get-permission-list');
            })
    }
}

export const fetchPermissionModuleList = () => {
    return (dispatch, getState) => {
        inquiryService.getPermissionModuleList()
            .then(data => {
                dispatch({
                    type: actionTypes.FETCH_USER_PERMISSION_MODULE_INFO,
                    permissionModuleInfo: data,
                });
            })
            .catch(err => {
                ToastUtil.errorApi(err, 'fails-to-get-permission-module-list');
            })
    }
}

export const fetchUserInfoSuccess = (info, authinfo, defaultConfig, isFirstLogin = true) => {
    let _config = {}
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.FETCH_USER_INFO_SUCCESS,
            config: { ..._config, marketOrder: defaultConfig.marketOrder },
            info: info,
            authinfo: authinfo,
            defaultSymbol: defaultConfig['symbol'],
            defaultSymbolFds: defaultConfig['symbolFDS'],
            defaultAccount: defaultConfig['accountid'],
            defaultBenefitAccount: defaultConfig['benefitaccount'],
            theme: defaultConfig['theme'],
            isFirstLogin: isFirstLogin,
            actionMethodConditon: defaultConfig["activeType"],
        });

        //huy.quang: dong bo language fs2pro voi sso cho cac man hinh da dang nhap
        // if (isFirstLogin) {
        //     const defaultLanguage = defaultConfig['language'] ? defaultConfig['language'] : info.language;
        //     dispatch(changeLanguage(defaultLanguage));
        // }
        const showReconfirmOrder = defaultConfig['showReconfirmOrder'];
        const currentShowReconfirmOrder = getState().app.showReconfirmOrderConfig;
        if (showReconfirmOrder !== currentShowReconfirmOrder) {
            dispatch(setCurrentReconfirmOrder(showReconfirmOrder));
        }
    };
};

export const changeDefaultTheme = (theme) => ({
    type: actionTypes.CHANGE_DEFAULT_THEME,
    theme: theme
});
export const fetchSaveVerifyInfoSuccess = (tradeAuthenticated) => {
    return {
        type: actionTypes.FETCH_SAVE_VERIFY_INFO_SUCCESS,
        data: {
            tradeAuthenticated,
        }
    }
};

export const fetchUpdateUserDefaultConfig = () => {
    return (dispatch, getState) => {
        userService.getDefaultConfig()
            .then(defaultConfig => {
                dispatch({
                    type: actionTypes.FETCH_USER_DEFAULT_CONFIG,
                    defaultSymbol: defaultConfig['symbol'],
                    defaultSymbolFds: defaultConfig['symbolFDS'],
                    defaultAccount: defaultConfig['accountid'],
                    defaultBenefitAccount: defaultConfig['benefitaccount']
                });
            })
            .catch((error) => {
                dispatch(loginFail(error));
            });
    };
};

export const fetchUserInfoFromSavedSession = (callback) => {
    return (dispatch, getState) => {
        const language = getValueFromLocalStorage('language');
        userService.getRequiredData(language)
            .then((responses) => {
                const [info, defaultConfig, accounts, config, currentDate] = responses;

                batch(() => {
                    dispatch(setCurrentDate(currentDate.currdate))
                    dispatch(setClientConfig(config))
                    dispatch(fetchSaveVerifyInfoSuccess(info.userinfo.isVerified));
                    // check Iscorebank với tài khoản khách hàng
                    let isCorebank = false;
                    if (info.userinfo['role'] === Role.CUSTOMER && accounts && accounts.length > 0) {
                        for (let i = 0; i < accounts.length; i++) {
                            if (accounts[i].corebank === 'Y') {
                                isCorebank = true;
                                break;
                            }
                        }
                    }
                    //lsy ra userInfo
                    dispatch(fetchUserInfoSuccess({ ...info.userinfo, isCorebank: isCorebank }, info.authinfo, defaultConfig, false));
                    // lay ra currentAcount
                    dispatch(fetchAccountsSuccess(accounts, info.userinfo));
                    // dispatch(loadIntiDataLayout(info.userinfo));
                    //dispatch(refetchPermissionList()); // Lấy lại thông tin quyền mới nhất (Không lấy theo cache trên sso cho trường hợp quyền có thay đổi)
                    // dispatch(fetchPermissionModuleList()); // Lấy lại thông tin quyền sử dụng phân hệ mới nhất 
                    dispatch(loginSucess());
                    dispatch(fetchSecurities(true));
                    dispatch(getConfigPriceBoard());
                    // load config from server
                    // dispatch(getLayoutConfig());

                    // Tạm thời lấy name cho tài khoản (môi giới và khách hàng)
                    if (info.userinfo.role === Role.BROKER) {
                        inquiryService.getBrokerInfo()
                            .then((data) => {
                                let currentUsersToken = JSON.parse(localStorage.getItem('token-users'));
                                let custodycd = defaultConfig.username;
                                let name = data.brokerName ? data.brokerName : '';
                                dispatch(setCustomerFullName(name));
                                if (currentUsersToken && currentUsersToken[`${custodycd}`]) currentUsersToken[`${custodycd}`]['name'] = name;
                                localStorage.setItem(`token-users`, JSON.stringify(currentUsersToken));
                                emitter.emit("UPDATE_TOKEN_STORE");
                            })
                            .catch((error) => {
                                // ToastUtil.errorApi(error, 'common.fail-to-load-account-info');
                            });

                    }
                    else if (info.userinfo.role === Role.CUSTOMER) {
                        // Lấy tên cho khách hàng
                        let currentUsersToken = JSON.parse(localStorage.getItem('token-users'));
                        let custodycd = accounts[0] ? accounts[0]['custodycd'] : '';
                        let name = accounts[0] ? accounts[0]['name'] : '';
                        // dispatch(setCustomerFullName(name + "." + custodycd));
                        dispatch(setCustomerFullName(name));
                        if (currentUsersToken && currentUsersToken[`${custodycd}`]) currentUsersToken[`${custodycd}`]['name'] = name;
                        localStorage.setItem(`token-users`, JSON.stringify(currentUsersToken));
                        emitter.emit("UPDATE_TOKEN_STORE");
                    }

                    if (info.userinfo.isfirstlogin === 'Y') {
                        dispatch(actions.toggleModalChangeCore());
                    }

                    if (info.userinfo.isresetpin === 'Y' && info.userinfo.isresetpass === 'Y') { // Change pin pass
                        dispatch(actions.toogleChangePinPassModal(null));
                    } else if (info.userinfo.isresetpin === 'N' && info.userinfo.isresetpass === 'Y') { // Change pass
                        dispatch(actions.toogleChangePinPassModal(resetPinPassTypes.PASS));
                    } else if (info.userinfo.isresetpin === 'Y' && info.userinfo.isresetpass === 'N') { // Change pin
                        dispatch(actions.toogleChangePinPassModal(resetPinPassTypes.PIN));
                    }

                    // Case bắt buộc đổi pass của broker khi F5 lại cũng phải mở lại màn đổi mk
                    if (info.userinfo.isChangePass === 'Y' && info.userinfo.isresetpass !== 'Y') { //W: Mở popup đổi MK nhưng không bắt buộc đổi ;  Y: Mở popup đổi MK và bắt buộc đổi
                        dispatch(actions.toogleChangePinPassModal(resetPinPassTypes.PASS));
                    }

                    let { username, custid, custodycd, tlid, role, ...rest } = info.userinfo || {};
                    let authInfo = { username, custid, custodycd, tlid, role };
                    CommonUtils.setCookie("authInfo", JSON.stringify(authInfo), '/', true, '');
                    CommonUtils.setIsStep2Authenticated(JSON.stringify(info.userinfo.isVerified), '/', true, '');
                })
                callback();
            })
            .catch((error) => {
                dispatch(loginFail(error));
                CommonUtils.setCookie("authInfo", null, '/', true, '');
                CommonUtils.setIsStep2Authenticated(null, '/', true, '');
                callback();
            });
    };
};

export const login = (code) => {
    return (dispatch, getState) => {
        const state = getState();
        const language = state.app.language;
        dispatch(loginStart());
        userService.getToken(code)
            .then((response) => {
                dispatch(authorizationSuccess(response));
                userService.getRequiredData(language)
                    .then((responses) => {
                        const [info, defaultConfig, accounts, config, currentDate] = responses;
                        batch(() => {
                            dispatch(setCurrentDate(currentDate.currdate))
                            dispatch(setClientConfig(config))
                            dispatch(fetchSaveVerifyInfoSuccess(info.userinfo.isVerified));
                            // check Iscorebank với tài khoản khách hàng
                            let isCorebank = false;
                            if (info.userinfo['role'] === Role.CUSTOMER && accounts && accounts.length > 0) {
                                for (let i = 0; i < accounts.length; i++) {
                                    if (accounts[i].corebank === 'Y') {
                                        isCorebank = true;
                                        break;
                                    }
                                }
                            }
                            dispatch(fetchUserInfoSuccess({ ...info.userinfo, isCorebank: isCorebank }, info.authinfo, defaultConfig, true));
                            dispatch(fetchAccountsSuccess(accounts, info.userinfo));
                            dispatch(loadIntiDataLayout(info.userinfo));
                            // dispatch(fetchPermissionModuleList()); // Lấy lại thông tin quyền sử dụng phân hệ mới nhất 
                            //huy.quang: push url / vào history để ngăn event back lại sso
                            dispatch(push('/'));
                            dispatch(loginSucess());
                            dispatch(fetchSecurities(true));
                            dispatch(actions.fetchWatchList(true, true))
                            // load config from server
                            dispatch(getLayoutConfig());
                            dispatch(getConfigPriceBoard());
                            if (info.userinfo.isreset === 'Y') {
                                dispatch(actions.toogleChangePinPassModal());
                            }
                            let { username, custid, custodycd, tlid, role, ...rest } = info.userinfo || {};
                            let authInfo = { username, custid, custodycd, tlid, role };
                            CommonUtils.setCookie("authInfo", JSON.stringify(authInfo), '/', true, '');
                            CommonUtils.setIsStep2Authenticated(JSON.stringify(info.userinfo.isVerified), '/', true, '');
                        })
                    })
                    .catch((error) => {
                        dispatch(loginFail(error));
                        CommonUtils.setCookie("authInfo", null, '/', true, '');
                        CommonUtils.setIsStep2Authenticated(null, '/', true, '');
                    });
            })
            .catch((error) => {
                dispatch(loginFail(error));
            });
    }
};

export const loginByTokenData = (tokenData, callback) => {
    return (dispatch, getState) => {
        const state = getState();
        const language = state.app.language;
        dispatch(authorizationSuccess(tokenData));
        userService.getRequiredData(language)
            .then((responses) => {
                const [info, defaultConfig, accounts, config, currentDate] = responses;
                dispatch(setCurrentDate(currentDate.currdate))
                dispatch(setClientConfig(config))
                dispatch(fetchSaveVerifyInfoSuccess(info.userinfo.isVerified));
                // check Iscorebank với tài khoản khách hàng
                let isCorebank = false;
                if (info.userinfo['role'] === Role.CUSTOMER && accounts && accounts.length > 0) {
                    for (let i = 0; i < accounts.length; i++) {
                        if (accounts[i].corebank === 'Y') {
                            isCorebank = true;
                            break;
                        }
                    }
                }

                dispatch(fetchUserInfoSuccess({ ...info.userinfo, isCorebank: isCorebank }, info.authinfo, defaultConfig, true));
                dispatch(fetchAccountsSuccess(accounts, info.userinfo));
                dispatch(loadIntiDataLayout(info.userinfo));
                // dispatch(fetchPermissionModuleList()); // Lấy lại thông tin quyền sử dụng phân hệ mới nhất 
                //huy.quang: push url / vào history để ngăn event back lại sso
                dispatch(push('/'));
                dispatch(loginSucess());
                dispatch(fetchSecurities(true));
                dispatch(getLayoutConfig());
                dispatch(getConfigPriceBoard());
                dispatch(setSymbolSelectorCollectionId('portfolio'))
                dispatch(setSymbolSelectorUserCollectionId('portfolio'))
                dispatch(actions.fetchWatchList(true, true))
                // Tạm thời lấy name cho tài khoản (môi giới và khách hàng)
                if (info.userinfo.role === Role.BROKER) {
                    inquiryService.getBrokerInfo()
                        .then((data) => {
                            let currentUsersToken = JSON.parse(localStorage.getItem('token-users'));
                            let custodycd = defaultConfig.username;
                            let name = data.brokerName ? data.brokerName : '';
                            dispatch(setCustomerFullName(name));
                            if (currentUsersToken && currentUsersToken[`${custodycd}`]) currentUsersToken[`${custodycd}`]['name'] = name;
                            localStorage.setItem(`token-users`, JSON.stringify(currentUsersToken));
                            emitter.emit("UPDATE_TOKEN_STORE");
                        })
                        .catch((error) => {
                            // ToastUtil.errorApi(error, 'common.fail-to-load-account-info');
                        });

                }
                else if (info.userinfo.role === Role.CUSTOMER) {
                    // Lấy tên cho khách hàng
                    let currentUsersToken = JSON.parse(localStorage.getItem('token-users'));
                    let custodycd = accounts[0] ? accounts[0]['custodycd'] : '';
                    let name = accounts[0] ? accounts[0]['name'] : '';
                    // dispatch(setCustomerFullName(name + "." + custodycd));
                    dispatch(setCustomerFullName(name));
                    if (currentUsersToken && currentUsersToken[`${custodycd}`]) currentUsersToken[`${custodycd}`]['name'] = name;
                    localStorage.setItem(`token-users`, JSON.stringify(currentUsersToken));
                    emitter.emit("UPDATE_TOKEN_STORE");
                }

                // Đồng bộ language với sso theo luồng đăng nhập token
                if (defaultConfig.language !== tokenData.ui_locales) {
                    userService.changeLanguage(tokenData.ui_locales)
                        .catch((error) => {
                            // ToastUtil.errorApi(error);
                            //console.log('Error: switch language loginByTokenData');
                        });
                    dispatch(changeLanguage(tokenData.ui_locales));
                }

                // Đồng bộ theme với sso theo luồng đăng nhập token
                if (defaultConfig.theme !== tokenData.ui_theme) {
                    userService.changeTheme(tokenData.ui_theme)
                        .catch((error) => {
                            ToastUtil.errorApi(error);
                        });
                    dispatch(changeDefaultTheme(tokenData.ui_theme));
                }

                if (info.userinfo.isfirstlogin === 'Y') {
                    dispatch(actions.toggleModalChangeCore());
                }

                if (info.userinfo.isresetpin === 'Y' && info.userinfo.isresetpass === 'Y') { // Change pin pass
                    dispatch(actions.toogleChangePinPassModal(null));
                } else if (info.userinfo.isresetpin === 'N' && info.userinfo.isresetpass === 'Y') { // Change pass
                    dispatch(actions.toogleChangePinPassModal(resetPinPassTypes.PASS));
                } else if (info.userinfo.isresetpin === 'Y' && info.userinfo.isresetpass === 'N') { // Change pin
                    dispatch(actions.toogleChangePinPassModal(resetPinPassTypes.PIN));
                }

                if ((info.userinfo.isChangePass === 'Y' || (info.userinfo.role === Role.CUSTOMER && info.userinfo.isChangePass === 'W' )) //Y: Mở popup đổi MK và bắt buộc đổi W: Không bắt buộc đổi (Khách hàng)
                    && info.userinfo.isresetpass !== 'Y' // Phải ưu tiên quên mk trước
                ) { 
                    dispatch(actions.toogleChangePinPassModal(resetPinPassTypes.PASS));
                }

                let { username, custid, custodycd, tlid, role, ...rest } = info.userinfo || {};
                let authInfo = { username, custid, custodycd, tlid, role };
                CommonUtils.setCookie("authInfo", JSON.stringify(authInfo), '/', true, '');
                CommonUtils.setIsStep2Authenticated(JSON.stringify(info.userinfo.isVerified), '/', true, '');
                localStorage.setItem("statusCurrentUserCollectionId", "noneActive"); //set tab danh mục quan tâm chưa active
                callback && callback();
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-account-info');
                dispatch(loginFail(error));
                CommonUtils.setCookie("authInfo", null, '/', true, '');
                CommonUtils.setIsStep2Authenticated(null, '/', true, '');
                callback && callback();
            });
    }
};
export const getConfigPriceBoard = () => {
    return (dispatch, getState) => {
        userService.getPriceBoardConfig()
            .then(data => {
                dispatch({
                    type: actionTypes.FETCH_USER_PRICEBOARD_CONFIG,
                    priceBoardConfig: data.configPriceboard
                });
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-config-priceboard');
            });
    };
}
export const setConfigPriceBoard = (data) => {
    return (dispatch, getState) => {
        const config = {
            config: data
        }
        //Vẫn để lưu redux bất kể có gọi được lưu trên server hay không
        dispatch({
            type: actionTypes.SAVE_USER_PRICEBOARD_CONFIG,
            priceBoardConfig: data
        })
        userService.savePriceBoardConfig(config)
            .then(dataResponse => {

            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-save-config-priceboard');
            });
    };
}

export const setRefreshTokenSuccess = (token) => ({
    type: actionTypes.REFRESH_TOKEN_SUCCESS,
    token: token
});

export const setRefreshTokenFail = () => ({
    type: actionTypes.REFRESH_TOKEN_FAIL
});


export const logoutExpiredToken = () => ({
    type: actionTypes.SINGLE_USER_LOGOUT,
    started: false
});

export const logout = () => ({
    type: actionTypes.USER_LOGOUT
});
/**
 * Logout tài khoản trên tab hiện tại
 * @param {*} custodycd 
 * @param {*} logoutWithStore // true: có xóa trong localstorage (hạn chế nhiều tab cùng truy cập localStore một lúc trong trường hợp logout All hoặc 1 tài khoản đăng nhập trên nhiều tab)
 * @returns 
 */
export const logoutSingleUser = (custodycd, logoutWithStore) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: actionTypes.SINGLE_USER_LOGOUT,
            custodycd,
            logoutWithStore
        })
        dispatch(actions.getLayoutConfigLocalStorage());
        dispatch(actions.changeLayoutPageActive(`C#CS#PRICEBOARD#CB`))
        dispatch(actions.fetchWatchList(true, false))
        dispatch(push('/'));
    }
};

export const updateTokenStore = (usersToken) => ({
    type: actionTypes.UPDATE_TOKEN_STORE,
    usersToken: usersToken
});


export const setCustomerFullName = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_CUSTOMER_FULL_NAME,
            customerFullName: data
        });
    }
};

export const setActionMethodCondition = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_ACTION_METHOD_CONDITION,
            actionMethodConditon: data
        });
    }
};

export const setLoginPopupOnstart = (isOpen = false, custodycd = '') => ({
    type: actionTypes.SET_LOGIN_POPUP_ON_START,
    isOpen,
    custodycd
});

export const onChangeMenuActive = (keyName) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.ON_CHANGE_ACTIVE_MENU,
            keyName
        });

    }
};

export const onChangeMenuActiveSideBar = (keyName) => {
    return (dispatch, getState) => {


        dispatch({
            type: actionTypes.ON_CHANGE_ACTIVE_MENU_SIDE_BAR,
            keyName
        });
    }
};

export const onChangeActiveURLSideBar = (newUrl) => {
    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.ON_CHANGE_ACTIVE_URL_SIDE_BAR,
            newUrl
        });
    }
};

export const onChangeOpenListMenuSideBar = (isOpenList) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.ON_CHANGE_OPEN_LIST_MENU_SIDE_BAR,
            isOpenList
        });
    }
}

export const onChangeStatusMenuSideBar = (statusMenuSideBar) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.ON_CHANGE_STATUS_MENU_SIDE_BAR,
            statusMenuSideBar
        });
    }
};

export const onchangeStatusPopupFooterPage = (status, typeStatus, tabActivated) => ({
    type: actionTypes.ON_CHANGE_STATUS_POPUP_FOOTER_PAGE,
    status,
    typeStatus,
    tabActivated
});

export const loadUserInfo = () => {
    return async (dispatch, getState) => {
        const state = getState();
        await Promise.all([
            userService.getUserInfo(),
            userService.getAccounts(),
            userService.getDefaultConfig(),
        ]).then((responses) => {
            const [info, accounts, defaultConfig] = responses
            batch(() => {

                dispatch(fetchSaveVerifyInfoSuccess(info.userinfo.isVerified));
                // check Iscorebank với tài khoản khách hàng
                let isCorebank = false;
                if (info.userinfo['role'] === Role.CUSTOMER && accounts && accounts.length > 0) {
                    for (let i = 0; i < accounts.length; i++) {
                        if (accounts[i].corebank === 'Y') {
                            isCorebank = true;
                            break;
                        }
                    }
                }
                //lsy ra userInfo
                dispatch(fetchUserInfoSuccess({ ...info.userinfo, isCorebank: isCorebank }, info.authinfo, defaultConfig, false));
                // lay ra currentAcount
                dispatch(fetchAccountsSuccess(accounts, info.userinfo));

                if (info.userinfo.isresetpin === 'Y' && info.userinfo.isresetpass === 'Y') { // Change pin pass
                    dispatch(actions.toogleChangePinPassModal(null));
                } else if (info.userinfo.isresetpin === 'N' && info.userinfo.isresetpass === 'Y') { // Change pass
                    dispatch(actions.toogleChangePinPassModal(resetPinPassTypes.PASS));
                } else if (info.userinfo.isresetpin === 'Y' && info.userinfo.isresetpass === 'N') { // Change pin
                    dispatch(actions.toogleChangePinPassModal(resetPinPassTypes.PIN));
                }

                let { username, custid, custodycd, tlid, role, ...rest } = info.userinfo || {};
                let authInfo = { username, custid, custodycd, tlid, role };
                CommonUtils.setCookie("authInfo", JSON.stringify(authInfo), '/', true, '');
                CommonUtils.setIsStep2Authenticated(JSON.stringify(info.userinfo.isVerified), '/', true, '');
            })
        })
            .catch((error) => {
                CommonUtils.setCookie("authInfo", null, '/', true, '');
                CommonUtils.setIsStep2Authenticated(null, '/', true, '');
            });
    };
};


export const setIsOpenMenuTool = (isOpen) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.ON_CHANGE_OPEN_MENU_TOOL,
            isOpen: isOpen
        });
    }
};
