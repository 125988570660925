import _ from "lodash";

import { initialProTradeOrderInputState } from "../../utils/constants";
import actionTypes from '../actions/actionTypes';

let _defaultProTradeOrder = _.cloneDeep(initialProTradeOrderInputState)
const initialState = {
    // Posible value is defined in constains.js
    socketStatus: '',
    socketMarketStatus: '',

    socketCIAction: {},
    socketSEAction: {},
    socketODAction: {},
    socketCAAction: {},
    socketPAction: {},
    socketPPAction: {}, // Tín hiệu load lại sức mua danh cho đặt lệnh thường
    socketDOAction: {}, // Tín hiệu load lại sức mua danh cho đặt lệnh phái sinh
    socketDTAction: {}, // Tín hiệu load lại sức mua danh cho đặt lệnh phái sinh
    socketCODAction: {}, // Sổ lệnh điều kiện cơ sở

    custSocketODActionCounter: 1,
};

const socketRealtimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ON_SOCKET_CI_ACTION:
            return {
                ...state,
                socketCIAction: {
                    ...state.socketCIAction,
                    [action.accountId]: (state.socketCIAction[action.accountId] ? state.socketCIAction[action.accountId] : 0) + 1
                },
            };
        case actionTypes.ON_SOCKET_SE_ACTION:
            return {
                ...state,
                socketSEAction: {
                    ...state.socketSEAction,
                    [action.accountId]: (state.socketSEAction[action.accountId] ? state.socketSEAction[action.accountId] : 0) + 1
                },
            };
        case actionTypes.ON_SOCKET_OD_ACTION:
            return {
                ...state,
                socketODAction: {
                    ...state.socketODAction,
                    [action.accountId]: (state.socketODAction[action.accountId] ? state.socketODAction[action.accountId] : 0) + 1
                },
            };
        case actionTypes.ON_SOCKET_CA_ACTION:
            return {
                ...state,
                socketCAAction: {
                    ...state.socketCAAction,
                    [action.accountId]: (state.socketCAAction[action.accountId] ? state.socketCAAction[action.accountId] : 0) + 1
                },
            };
        case actionTypes.ON_SOCKET_P_ACTION:
            return {
                ...state,
                socketPAction: {
                    ...state.socketPAction,
                    [action.accountId]: (state.socketPAction[action.accountId] ? state.socketPAction[action.accountId] : 0) + 1
                },
            };
        case actionTypes.ON_SOCKET_PP_ACTION:
            return {
                ...state,
                socketPPAction: {
                    ...state.socketPPAction,
                    [action.accountId]: (state.socketPPAction[action.accountId] ? state.socketPPAction[action.accountId] : 0) + 1
                },
            };
        case actionTypes.ON_SOCKET_DO_ACTION:
            return {
                ...state,
                socketDOAction: {
                    ...state.socketDOAction,
                    [`FDS${action.accountId}`]: (state.socketDOAction[`FDS${action.accountId}`] ? state.socketDOAction[`FDS${action.accountId}`] : 0) + 1
                },
            };
        case actionTypes.ON_SOCKET_DT_ACTION:
            return {
                ...state,
                socketDTAction: {
                    ...state.socketDTAction,
                    [`FDS${action.accountId}`]: (state.socketDTAction[`FDS${action.accountId}`] ? state.socketDTAction[`FDS${action.accountId}`] : 0) + 1
                },
            };
        case actionTypes.ON_SOCKET_COD_ACTION:
            return {
                ...state,
                socketCODAction: {
                    ...state.socketCODAction,
                    [action.accountId]: (state.socketCODAction[action.accountId] ? state.socketCODAction[action.accountId] : 0) + 1
                },
                // socketCODAction: state.socketCODAction + 1
            };
        case actionTypes.CUST_ON_SOCKET_OD_ACTION:
            return {
                ...state,
                custSocketODActionCounter: state.custSocketODActionCounter + 1
            };
        default:
            return state;
    }
};

export default socketRealtimeReducer;