import _ from 'lodash';

import { CommonUtils } from 'utils';
import { LoadStatus } from "../../utils/constants";
import actionTypes from '../actions/actionTypes';
// CommonUtils.logDebug("symbolReducer.:logDebug")

function getSeqByListProperty(listProperty, seq) {
    let instrumentsSeq = {}
    _.map(listProperty, e => {
        instrumentsSeq[e] = seq
    })
    return instrumentsSeq;
}

const initialState = {
    initDaySeq: undefined,
    symbols: [],
    exchanges: [],
    symbolLoadStatus: LoadStatus.NONE,

    favoriteSymbols: [],
    favoriteSymbolsLoadStatus: LoadStatus.NONE,

    watchLists: [],
    watchListsLoadStatus: LoadStatus.NONE,

    fixedList: {},
    fixedListLoadStatus: LoadStatus.NONE,

    derivativeList: {},
    oddlotList: {},
    oddlotSymbols: [],
    oddlotListLoadStatus: LoadStatus.NONE,


    quotes: {},
    quoteLoadStatus: {},
    //
    instruments: {},
    instrumentsSeq: {},
    //
    oddLotInstruments: {},
    oddLotInstrumentsSeq: {},
    //
    orderbooks: {},
    orderbookLoadStatus: {},

    trades: {},

    symboldatabysuggest: null,

    symbolWithIndex: {},

    symbolListMapping: [],

    symbolListMappingWithIndex: {},

    symbolMappingLoadStatus: LoadStatus.NONE,

    allQuotes: [],
    allQuotesWithIndex: {},
    allQuotesLoadStatus: LoadStatus.NONE,

    tradeInfoNextIndex: -1, // index để lấy thêm lịch sử khớp lệnh

    topInstrumentList: {
        topChangeHNX: [],
        topKeepHNX: [],

        topChangeHOSE: [],
        topKeepHOSE: [],

        topChangeUPCOM: [],
        topKeepUPCOM: [],

    }, // Danh sách các mã top giảm giá và lên giá theo các sàn
    industryList: [], // danh sách thÔng tin cổ phiếu ngành
    industryKeysInclude: [], // danh sách keys include của cổ phiếu ngành
    DTChartMatched: [],
    tradeInfoChart: [],
    indexInstrumentList: {},
    recommendList: [],
    recommendWithIndex: {},
    corpbornList: [],
    corpbondInstrument: {},
    corpbondWithIndex: {},
    corpbondLoadStatus: LoadStatus.NONE
};

const symbolReducer = (state = initialState, action) => {
    let currentTopInstrumentList = state.topInstrumentList;
    let currentIndexInstrumentList = state.indexInstrumentList;
    switch (action.type) {
        case actionTypes.FETCH_SYMBOL_LIST:
            return {
                ...state,
                symbols: [],
                symbolLoadStatus: LoadStatus.LOADING
            };
        case actionTypes.FETCH_SYMBOL_LIST_SUCCESS:
            return {
                ...state,
                symbols: action.symbols,
                exchanges: action.exchanges,
                symbolWithIndex: action.symbolWithIndex,
                symbolLoadStatus: LoadStatus.LOADED
            };
        case actionTypes.FETCH_SYMBOL_LIST_FAIL:
            return {
                ...state,
                symbolLoadStatus: LoadStatus.FAIL
            };
        case actionTypes.FETCH_FAV_SYMBOLS:
            return {
                ...state,
                favoriteSymbolsLoadStatus: LoadStatus.LOADING
            };
        case actionTypes.FETCH_FAV_SYMBOLS_SUCCESS:
            return {
                ...state,
                favoriteSymbols: action.symbols,
                favoriteSymbolsLoadStatus: LoadStatus.LOADED
            };
        case actionTypes.FETCH_FAV_SYMBOLS_FAIL:
            return {
                ...state,
                favoriteSymbolsLoadStatus: LoadStatus.FAIL
            };
        case actionTypes.ADD_FAV_SYMBOL: {
            const symbol = action.symbol;
            const newFavorites = [...state.favoriteSymbols];
            const notExist = _.findIndex(newFavorites, (element) => {
                return element === symbol;
            }) < 0;
            if (notExist) {
                newFavorites.push(symbol);
            }
            return {
                ...state,
                favoriteSymbols: newFavorites
            };
        }
        case actionTypes.ADD_FAV_SYMBOL_FAIL: {
            const symbol = action.symbol;
            const newFavorites = [...state.favoriteSymbols];
            _.remove(newFavorites, (element) => {
                return symbol === element;
            });
            return {
                ...state,
                favoriteSymbols: newFavorites
            };
        }
        case actionTypes.REMOVE_FAV_SYMBOL: {
            const symbol = action.symbol;
            const newFavorites = [...state.favoriteSymbols];
            _.remove(newFavorites, (element) => {
                return symbol === element;
            });
            return {
                ...state,
                favoriteSymbols: newFavorites
            };
        }
        case actionTypes.REMOVE_FAV_SYMBOL_FAIL: {
            const symbol = action.symbol;
            const newFavorites = [...state.favoriteSymbols];
            const notExist = _.findIndex(newFavorites, (element) => {
                return element === symbol;
            }) < 0;
            if (notExist) {
                newFavorites.push(symbol);
            }
            return {
                ...state,
                favoriteSymbols: newFavorites
            };
        }
        case actionTypes.FETCH_WATCH_LIST:
            return {
                ...state,
                watchListsLoadStatus: LoadStatus.LOADING
            };
        case actionTypes.FETCH_WATCH_LIST_SUCCESS:
            return {
                ...state,
                watchLists: action.watchLists,
                watchListsLoadStatus: LoadStatus.LOADED
            };
        case actionTypes.FETCH_WATCH_LIST_FAIL:
            return {
                ...state,
                watchListsLoadStatus: LoadStatus.FAIL
            };
        case actionTypes.UPDATE_WATCH_LIST: {
            const id = action.id;
            const newWatchLists = [...state.watchLists];
            const index = _.findIndex(newWatchLists, (watchList) => {
                return watchList.id === id;
            });
            if (index > -1) {
                newWatchLists.splice(index, 1, {
                    ...newWatchLists[index],
                    name: action.name,
                    symbols: action.symbols,
                    collumnsetting: action.collumnsetting
                });
            }
            return {
                ...state,
                watchLists: newWatchLists
            };
        }
        case actionTypes.REMOVE_WATCH_LIST: {
            const id = action.id;
            const newWatchLists = [...state.watchLists];
            const index = _.findIndex(newWatchLists, (watchList) => {
                return watchList.id === id;
            });
            if (index > -1) {
                newWatchLists.splice(index, 1);
            }
            return {
                ...state,
                watchLists: newWatchLists
            };
        }
        case actionTypes.SINGLE_USER_LOGOUT:
            return {
                ...state,
                // watchLists: []
            };
        case actionTypes.FETCH_FIXED_LIST:
            return {
                ...state,
                fixedListLoadStatus: LoadStatus.LOADING
            };
        case actionTypes.FETCH_DERIVATIVE_LIST_SUCCESS:
            return {
                ...state,
                derivativeList: action.derivativeList,
            };
        case actionTypes.FETCH_CORPBOND_LIST_SUCCESS:
            if (action.corpbondWithIndex) {
                state.corpbondWithIndex = action.corpbondWithIndex
            }
            return {
                ...state,
                corpbornList: action.corpbornList,
                corpbondInstrument: action.corpbondInstrument,
                // corpbondWithIndex: action.corpbondWithIndex,
                corpbondLoadStatus: LoadStatus.LOADED
            };
        case actionTypes.FETCH_CORPBOND_LIST_FAIL:
            return {
                ...state,
                corpbondLoadStatus: LoadStatus.FAIL
            };
        case actionTypes.FETCHING_ODDLOT_LIST:
            return {
                ...state,
                oddlotListLoadStatus: LoadStatus.LOADING
            };
        case actionTypes.FETCH_FIXED_LIST_SUCCESS:
            return {
                ...state,
                fixedList: action.fixedList,
                fixedListLoadStatus: LoadStatus.LOADED
            };
        case actionTypes.SET_TOP_INSTRUMENT_LIST:
            currentTopInstrumentList[action.id] = action.symbolList;
            return {
                ...state,
                topInstrumentList: { ...currentTopInstrumentList },
            };
        case actionTypes.FETCH__TOP_INSTRUMENT_LIST_FAIL:
            currentTopInstrumentList[action.id] = [];
            return {
                ...state,
                topInstrumentList: { ...currentTopInstrumentList },
            };
        case actionTypes.SET_INDEX_LIST:
            currentIndexInstrumentList[action.id] = action.symbolList;
            return {
                ...state,
                indexInstrumentList: { ...currentIndexInstrumentList },
            };
        case actionTypes.SET_INDEX_LIST_FALSE:
            currentIndexInstrumentList[action.id] = [];
            return {
                ...state,
                indexInstrumentList: { ...currentIndexInstrumentList },
            };
        case actionTypes.SET_RECOMMEND_LIST:
            return {
                ...state,
                recommendList: action.data,
                recommendWithIndex: action.recommendWithIndex
            };
        case actionTypes.SET_RECOMMEND_LIST_FALSE:
            return {
                ...state,
                recommendList: [],
            };
        case actionTypes.FETCH_INDUSTRY_LIST:
            return {
                ...state,
                industryList: action.industryList,
                industryKeysInclude: action.industryKeysInclude
            };
        case actionTypes.FETCH_INDUSTRY_LIST_FAIL:
            return {
                ...state,
                industryKeysInclude: false // FIX
            };
        case actionTypes.FETCH_FIXED_LIST_FAIL:
            return {
                ...state,
                fixedListLoadStatus: LoadStatus.FAIL
            };
        case actionTypes.SET_SYMBOL_DATA_BY_SUGGEST: {
            return {
                ...state,
                symboldatabysuggest: action.data
            }
        }
        case actionTypes.UPDATE_INSTRUMENTSEQ:
            if (action.isClear) {
                state.instrumentsSeq = {}
            }
            return {
                ...state,
                instrumentsSeq: {
                    ...state.instrumentsSeq,
                    ...action.instrumentsSeq
                },
            };
        case actionTypes.UPDATE_INSTRUMENT_DATA:
            let rowKeys = action.rowKey
            let isCheckSeq = (action.isCheckSeq == true) ? true : false
            if (isCheckSeq) {
                // console.debug("UPDATE_INSTRUMENT_DATA.:START=", rowKeys, action.instruments)
                if (rowKeys && rowKeys.length > 0) {
                    _.map(rowKeys, rowKey => {
                        if (rowKey) {
                            // state.instruments[rowKey] = {
                            //     ...action.instruments[rowKey]
                            // }
                            let checkSeq = (state.instruments[rowKey] && state.instruments[rowKey].SB && state.instrumentsSeq[rowKey] && state.instrumentsSeq[rowKey].SB)
                            if ((action.typeFrom === "update") || checkSeq) {
                                let keysActions = action.instruments[rowKey] && Object.keys(action.instruments[rowKey])
                                // Co du lieu instrument cua ma CK rowKey
                                // Can check seq theo tung Property roi moi update gia tri cho tung Property
                                let newInstruments = {}
                                let newInstrumentsSeq = {}
                                _.map(keysActions, e => {
                                    // if (rowKey == "SSI" && e == "SB") {
                                    //     console.debug("Socket_MissedSequence---symbol, lastSeqInstrument, socketSeq, data =  SSI===> ins 2.0=", action.instruments[rowKey].s, state.instrumentsSeq[rowKey] && state.instrumentsSeq[rowKey][e])
                                    // }
                                    if (checkSeq) {
                                        // ưu tiên xử lý điều kiện checkSeq trước khi xử lý auto "update"
                                        if (action.instruments[rowKey].s == undefined || state.instrumentsSeq[rowKey][e] == undefined ||
                                            (action.instruments[rowKey].s && state.instrumentsSeq[rowKey] && action.instruments[rowKey].s > state.instrumentsSeq[rowKey][e]) // check seq
                                            || ((action.instruments[rowKey].TD && state.instrumentsSeq[rowKey].TD) && (action.instruments[rowKey].TD !== state.instrumentsSeq[rowKey].TD))) {  // check Tradingdate
                                            // Tạm thời check thêm !action.instruments[rowKey].s do API hiện tại chưa trả ra s
                                            newInstruments[e] = action.instruments[rowKey][e]
                                            // let _tryHardSeq = action.instruments[rowKey].s ? action.instruments[rowKey].s : (state.instrumentsSeq[rowKey].s || undefined)
                                            // newInstrumentsSeq[e] = _tryHardSeq
                                            newInstrumentsSeq[e] = action.instruments[rowKey].s
                                        } else {
                                            newInstruments[e] = state.instruments[rowKey][e]
                                            // let _tryHardSeq = state.instrumentsSeq[rowKey].s || undefined
                                            // newInstrumentsSeq[e] = _tryHardSeq
                                            newInstrumentsSeq[e] = state.instrumentsSeq[rowKey].s
                                        }
                                    } else {
                                        newInstruments[e] = action.instruments[rowKey][e]
                                        newInstrumentsSeq[e] = action.instruments[rowKey].s
                                    }
                                })
                                state.instruments[rowKey] = { ...state.instruments[rowKey], ...newInstruments };
                                state.instrumentsSeq[rowKey] = { ...state.instrumentsSeq[rowKey], ...newInstrumentsSeq };
                            } else {
                                // Chua co du lieu instrument cua ma CK rowKey => tao moi instruments và instrumentsSeq
                                state.instruments[rowKey] = { ...action.instruments[rowKey] }
                                let listProperty = Object.keys(state.instruments[rowKey])
                                let firstSeq = state.instruments[rowKey].s || 0
                                state.instrumentsSeq[rowKey] = getSeqByListProperty(listProperty, firstSeq)
                                // if (state.instruments[rowKey].SB == "AAA") console.debug("UPDATE_INSTRUMENT_DATA.:START=2", state.instruments[rowKey], state.instrumentsSeq[rowKey])
                            }
                        }
                    })
                }
                if (action.oddLotInstruments) {
                    let oddlotSymbols = []
                    Object.keys(action.oddlotList).forEach(key => {
                        oddlotSymbols = oddlotSymbols.concat(action.oddlotList[`${key}`]['symbolArr']);
                    })
                    return {
                        ...state,
                        instruments: { ...state.instruments },
                        instrumentsSeq: { ...state.instrumentsSeq },
                        oddLotInstruments: { ...action.oddLotInstruments },
                        oddLotInstrumentsSeq: {},
                        oddlotList: action.oddlotList,
                        oddlotSymbols: oddlotSymbols,
                    };
                }
                return {
                    ...state,
                    instruments: state.instruments,
                    instrumentsSeq: state.instrumentsSeq,
                };
            }
            else {
                if (action.typeFrom === "update") {
                    if (rowKeys && rowKeys.length > 0) {
                        _.map(rowKeys, rowKey => {
                            if (rowKey) {
                                state.instruments[rowKey] = { ...state.instruments[rowKey], ...action.instruments[rowKey] }
                                state.instruments[rowKey].bySocketUpdate = true
                                // CommonUtils.logDebug(`symbolReducer.:msg.:i_1.:Redux.:UPDATE_INSTRUMENT_DATA`, { action })
                            }
                        })
                    }
                } else {
                    state.instruments = { ...state.instruments, ...action.instruments }
                    // CommonUtils.logDebug(`symbolReducer.:msg.:i_1.:Redux.:SET_INSTRUMENT_DATA`, { instruments: action.instruments })
                }
                if (action.oddLotInstruments) {
                    let oddlotSymbols = []
                    Object.keys(action.oddlotList).forEach(key => {
                        oddlotSymbols = oddlotSymbols.concat(action.oddlotList[`${key}`]['symbolArr']);
                    })
                    return {
                        ...state,
                        instruments: { ...state.instruments },
                        oddLotInstruments: { ...action.oddLotInstruments },
                        oddlotList: action.oddlotList,
                        oddlotSymbols: oddlotSymbols,
                    };
                }
                return { ...state }
                // state
                // return {
                //     ...state,
                //     instruments: state.instruments,
                //     // instruments: { ...state.instruments }, // https://redux.js.org/usage/structuring-reducers/immutable-update-patterns
                // };
                // return {
                //     ...state,
                //     instruments: state.instruments,
                //     // instruments: { ...state.instruments }, // https://redux.js.org/usage/structuring-reducers/immutable-update-patterns
                // };
            }
        // return {
        //     ...state,
        //     instruments: state.instruments,
        //     instrumentsSeq: state.instrumentsSeq,
        // };
        case actionTypes.UPDATE_CORPBOND_DATA:
            let rowKeysCorpbond = action.rowKey
            // console.debug("UPDATE_INSTRUMENT_DATA.:START=", rowKeys, action.instruments)

            if (rowKeysCorpbond && rowKeysCorpbond.length > 0) {
                _.map(rowKeysCorpbond, rowKey => {
                    if (rowKey) {
                        let keysActions = action.corpbondInstrument[rowKey] && Object.keys(action.corpbondInstrument[rowKey])
                        // Co du lieu instrument cua ma CK rowKey
                        // Can check seq theo tung Property roi moi update gia tri cho tung Property
                        if (state.corpbondInstrument[rowKey]) {
                            state.corpbondInstrument[rowKey] = { ...state.corpbondInstrument[rowKey], ...action.corpbondInstrument[rowKey] };
                        } else {
                            state.corpbondInstrument[rowKey] = action.corpbondInstrument[rowKey];
                        }
                    }
                })
            }

            return {
                ...state,
                corpbondInstrument: state.corpbondInstrument,
            };
        case actionTypes.UPDATE_ODDLOT_INSTRUMENTSEQ:
            if (action.isClear) {
                state.oddLotInstrumentsSeq = {}
            }
            return {
                ...state,
                oddLotInstrumentsSeq: {
                    ...state.oddLotInstrumentsSeq,
                    ...action.oddLotInstrumentsSeq
                },
            };
        case actionTypes.UPDATE_ODDLOT_INSTRUMENT_DATA:
            let rowKeysOddLot = action.rowKey
            let isCheckSeqOddLot = (action.isCheckSeqOddLot == true) ? true : false
            if (isCheckSeqOddLot) {
                // console.debug("Haki12341234=====UPDATE_ODDLOT_INSTRUMENT_DATA.:START=", rowKeysOddLot, action.oddLotInstruments)
                if (rowKeysOddLot && rowKeysOddLot.length > 0) {
                    _.map(rowKeysOddLot, rowKey => {
                        if (rowKey) {
                            // state.instruments[rowKey] = {
                            //     ...action.instruments[rowKey]
                            // }
                            let checkSeq = (state.oddLotInstruments[rowKey] && state.oddLotInstruments[rowKey].SB && state.oddLotInstrumentsSeq[rowKey])
                            if ((action.typeFrom === "update") || checkSeq) {
                                let keysActions = action.oddLotInstruments[rowKey] && Object.keys(action.oddLotInstruments[rowKey])
                                // Co du lieu instrument cua ma CK rowKey
                                // Can check seq theo tung Property roi moi update gia tri cho tung Property
                                let newOddLotInstruments = {}
                                let newOddLotInstrumentsSeq = {}
                                _.map(keysActions, e => {
                                    // if (rowKey == "SSI" && e == "SB") {
                                    //     console.debug("Socket_MissedSequence---symbol, lastSeqInstrument, socketSeq, data =  SSI===> ins 2.0=", action.instruments[rowKey].s, state.instrumentsSeq[rowKey] && state.instrumentsSeq[rowKey][e])
                                    // }
                                    if (checkSeq) {
                                        // ưu tiên xử lý điều kiện checkSeq trước khi xử lý auto "update"
                                        if (action.oddLotInstruments[rowKey].s == undefined || state.oddLotInstrumentsSeq[rowKey][e] == undefined ||
                                            (action.oddLotInstruments[rowKey].s && state.oddLotInstrumentsSeq[rowKey] && action.oddLotInstruments[rowKey].s > state.oddLotInstrumentsSeq[rowKey][e]) // check seq  
                                            || ((action.oddLotInstruments[rowKey].TD && state.oddLotInstrumentsSeq[rowKey].TD) && (action.oddLotInstruments[rowKey].TD !== state.oddLotInstrumentsSeq[rowKey].TD))) {  // check Tradingdate                                     
                                            // Tạm thời check thêm !action.instruments[rowKey].s do API hiện tại chưa trả ra s
                                            newOddLotInstruments[e] = action.oddLotInstruments[rowKey][e]
                                            // let _tryHardSeq = action.oddLotInstruments[rowKey].s ? action.oddLotInstruments[rowKey].s : (state.oddLotInstrumentsSeq[rowKey].s || undefined)
                                            // newOddLotInstrumentsSeq[e] = _tryHardSeq
                                            newOddLotInstrumentsSeq[e] = action.oddLotInstruments[rowKey].s
                                        } else {
                                            newOddLotInstruments[e] = state.oddLotInstruments[rowKey][e]
                                            // let _tryHardSeq = state.oddLotInstrumentsSeq[rowKey].s || undefined
                                            // newOddLotInstrumentsSeq[e] = _tryHardSeq
                                            newOddLotInstrumentsSeq[e] = state.oddLotInstrumentsSeq[rowKey].s
                                        }
                                    } else {
                                        newOddLotInstruments[e] = action.oddLotInstruments[rowKey][e]
                                        newOddLotInstrumentsSeq[e] = action.oddLotInstruments[rowKey].s
                                    }
                                })
                                state.oddLotInstruments[rowKey] = { ...state.oddLotInstruments[rowKey], ...newOddLotInstruments };
                                state.oddLotInstrumentsSeq[rowKey] = { ...state.oddLotInstrumentsSeq[rowKey], ...newOddLotInstrumentsSeq };
                            } else {
                                // Chua co du lieu instrument cua ma CK rowKey => tao moi oddLotInstruments và oddLotInstrumentsSeq
                                state.oddLotInstruments[rowKey] = { ...action.oddLotInstruments[rowKey] }
                                let listProperty = Object.keys(state.oddLotInstruments[rowKey])
                                let firstSeq = state.oddLotInstruments[rowKey].s || 0
                                state.oddLotInstrumentsSeq[rowKey] = getSeqByListProperty(listProperty, firstSeq)
                                // if (state.instruments[rowKey].SB == "AAA") console.debug("UPDATE_INSTRUMENT_DATA.:START=2", state.oddLotInstruments[rowKey], state.oddLotInstrumentsSeq[rowKey])
                            }
                        }
                    })
                }
                // console.debug("Haki12341234=====UPDATE_ODDLOT_INSTRUMENT_DATA.:END=", state.oddLotInstruments)
                // let oddlotSymbols = []
                // Object.keys(action.oddlotList).forEach(key => {
                //     oddlotSymbols = oddlotSymbols.concat(action.oddlotList[`${key}`]['symbolArr']);
                // })
                return {
                    ...state,
                    oddLotInstruments: { ...state.oddLotInstruments },
                    oddLotInstrumentsSeq: { ...state.oddLotInstrumentsSeq },
                    // oddlotList: action.oddlotList, 
                    // oddlotSymbols: oddlotSymbols,
                };
            }
            else {
                if (action.typeFrom === "update") {
                    if (rowKeysOddLot && rowKeysOddLot.length > 0) {
                        _.map(rowKeysOddLot, rowKey => {
                            if (rowKey) {
                                state.oddLotInstruments[rowKey] = { ...state.oddLotInstruments[rowKey], ...action.oddLotInstruments[rowKey] }
                                state.oddLotInstruments[rowKey].bySocketUpdate = true
                                // CommonUtils.logDebug(`symbolReducer.:msg.:i_1.:Redux.:UPDATE_ODDLOT_INSTRUMENT_DATA`, { action })
                            }
                        })
                    }
                } else {
                    state.oddLotInstruments = { ...state.oddLotInstruments, ...action.oddLotInstruments }
                    // CommonUtils.logDebug(`symbolReducer.:msg.:i_1.:Redux.:UPDATE_ODDLOT_INSTRUMENT_DATA`, { instruments: action.instruments })
                }
                return {
                    ...state,
                    oddLotInstruments: { ...state.oddLotInstruments },
                };
            }
        case actionTypes.SET_ORDERBOOK_DATA: {
            const data = action.data;
            const symbol = _.first(data)['SB'];
            const orderbook = _.sortBy(data, (element) => (parseInt(element['TOP'])));
            return {
                ...state,
                orderbooks: {
                    ...state.orderbooks,
                    [symbol]: orderbook
                },
                orderbookLoadStatus: {
                    ...state.orderbookLoadStatus,
                    [symbol]: LoadStatus.LOADED
                }
            };
        }
        case actionTypes.UPDATE_ORDERBOOK_DATA: {
            const updates = action.data;
            const symbol = _.first(updates)['SB'];
            const currentOrderbook = state.orderbooks[symbol] || [];
            let newOrderbook = _.filter(currentOrderbook, (row) => {
                return _.findIndex(updates, (update) => {
                    return parseInt(update['TOP']) === parseInt(row['TOP'])
                }) < 0;
            });
            newOrderbook = _.concat(newOrderbook, updates);
            newOrderbook = _.sortBy(newOrderbook, (element) => (parseInt(element['TOP'])));
            return {
                ...state,
                orderbooks: {
                    ...state.orderbooks,
                    [symbol]: newOrderbook
                }
            };
        }
        case actionTypes.INSERT_ORDERBOOK_DATA:
        case actionTypes.DELETE_ORDERBOOK_DATA: {
            const deletes = action.data;
            const symbol = _.first(deletes)['SB'];
            const currentOrderbook = state.orderbooks[symbol] || [];
            let newOrderbook = _.filter(currentOrderbook, (row) => {
                return _.findIndex(deletes, (update) => {
                    return update.id === row.id
                }) < 0;
            });
            newOrderbook = _.sortBy(newOrderbook, (element) => (element['TOP']));
            return {
                ...state,
                orderbooks: {
                    ...state.orderbooks,
                    [symbol]: newOrderbook
                }
            };
        }
        case actionTypes.SET_TRADE_DATA: {
            const data = action.data;
            const symbol = _.first(data)['SB'];
            // Since data missing id, we need to generate one to use as react's key
            // We use AAGID (app auto generated id)
            const dataLength = data.length;
            _.forEach(data, (element, index) => {
                element['AAGID'] = dataLength - index;
            });


            return {
                ...state,
                trades: {
                    ...state.trades,
                    [symbol]: data
                },
                tradeInfoNextIndex: action.tradeInfoNextIndex,
            };
        }
        case actionTypes.SET_MENUDETAIL_CHART_INFO_DATA: {
            let DTChartMatchedToSet = [];
            if (action.data) {
                DTChartMatchedToSet = CommonUtils.processJSON_ChartMathchedInfosForApi(action.data)
                return {
                    ...state,
                    DTChartMatched: DTChartMatchedToSet
                }
            } else {
                return {
                    ...state,
                    DTChartMatched: DTChartMatchedToSet
                }
            }
        }
        case actionTypes.SET_MENUDETAIL_CHART_DATA: {
            let tradeInfoChartToSet = [];
            if (action.data) {
                let convertedData = CommonUtils.processJSON_chartindayApi(action.data)
                tradeInfoChartToSet = convertedData.chartIndayData;
                return {
                    ...state,
                    tradeInfoChart: tradeInfoChartToSet,
                }
            } else {
                return {
                    ...state,
                    tradeInfoChart: tradeInfoChartToSet,
                }
            }
        }
        case actionTypes.INSERT_TRADE_DATA: {
            const inserts = action.data;
            const symbol = _.first(inserts)['SB'];
            const currentTrades = state.trades[symbol] || [];

            // Since data missing id, we need to generate one to use as react's key
            // We use AAGID (app auto generated id)
            const firstCurrent = _.first(currentTrades);
            const currentAagid = firstCurrent ? firstCurrent['AAGID'] : 1;
            const insertsLength = inserts.length;
            _.forEach(inserts, (element, index) => {
                element['AAGID'] = currentAagid + (insertsLength - index);
            });

            const newTrades = _.concat(inserts, currentTrades);

            return {
                ...state,
                trades: {
                    ...state.trades,
                    [symbol]: newTrades
                },
            };
        }

        case actionTypes.ON_INIT_DAY: {
            // const currentIndexs = state.indexs || {};
            const currentInstrument = state.instruments || {};
            const currentOddLotInstruments = state.oddLotInstruments || {};
            const newInstrument = {}
            const newOddLotInstruments = {}
            for (const key in currentInstrument) {
                const element = currentInstrument[key]
                const { Id, SB, SI, FN, FC, ST, CL, FL, RE, EX, ts } = element;
                const newElement = { Id, SB, SI, FN, FC, ST, CL, FL, RE, EX, ts };
                newInstrument[key] = newElement;
            }
            for (const key in currentOddLotInstruments) {
                const element = currentOddLotInstruments[key]
                const { Id, SB, SI, FN, FC, ST, CL, FL, RE, EX, ts } = element;
                const newElement = { Id, SB, SI, FN, FC, ST, CL, FL, RE, EX, ts };
                newOddLotInstruments[key] = newElement;
            }
            return {
                ...state,
                orderbooks: {},
                trades: {},
                instruments: newInstrument,
                instrumentsSeq: {},
                oddLotInstruments: newOddLotInstruments,
                oddLotInstrumentsSeq: {},
                initDaySeq: new Date().getTime()
            }
        }
        case actionTypes.FETCH_SYMBOL_LIST_MAPPING: {
            return {
                ...state,
                symbolListMapping: action.symbollist,
                symbolListMappingWithIndex: action.symbolListMappingWithIndex,
                symbolMappingLoadStatus: LoadStatus.LOADED
            }
        }
        case actionTypes.FETCH_SYMBOL_LIST_MAPPING_BEGIN: {
            return {
                ...state,
                symbolListMapping: [],
                symbolListMappingWithIndex: {},
                symbolMappingLoadStatus: LoadStatus.LOADING
            }
        }
        case actionTypes.FETCH_SYMBOL_LIST_MAPPING_FAIL: {
            return {
                ...state,
                symbolListMapping: [],
                symbolListMappingWithIndex: {},
                symbolMappingLoadStatus: LoadStatus.FAIL
            }
        }
        case actionTypes.FETCH_SYMBOL_QUOTE: {
            const symbol = action.symbol;
            return {
                ...state,
                quoteLoadStatus: {
                    ...state.quoteLoadStatus,
                    [symbol]: LoadStatus.LOADING
                }
            };
        }
        case actionTypes.FETCH_SYMBOL_QUOTE_SUCCESS: {
            const symbol = action.symbol;
            const data = action.data;
            return {
                ...state,
                quotes: {
                    ...state.quotes,
                    [symbol]: { ...data }
                },
                quoteLoadStatus: {
                    ...state.quoteLoadStatus,
                    [symbol]: LoadStatus.LOADED
                }
            };
        }
        case actionTypes.FETCH_SYMBOL_QUOTE_FAIL: {
            const symbol = action.symbol;
            return {
                ...state,
                quoteLoadStatus: {
                    ...state.quoteLoadStatus,
                    [symbol]: LoadStatus.FAIL
                }
            };
        }
        case actionTypes.FETCH_SYMBOL_ORDERBOOK: {
            const symbol = action.symbol;
            return {
                ...state,
                orderbookLoadStatus: {
                    ...state.orderbookLoadStatus,
                    [symbol]: LoadStatus.LOADING
                }
            };
        }
        case actionTypes.FETCH_SYMBOL_ORDERBOOK_FAIL: {
            const symbol = action.symbol;
            return {
                ...state,
                orderbooks: {
                    ...state.orderbooks,
                    [symbol]: []
                },
                orderbookLoadStatus: {
                    ...state.orderbookLoadStatus,
                    [symbol]: LoadStatus.FAIL
                }
            };
        }
        case actionTypes.FETCH_ALL_QUOTE: {
            return {
                ...state,
                quotes: action.quotes,
                allQuotes: action.allQuotes,
                allQuotesWithIndex: action.allQuotesWithIndex,
                allQuotesLoadStatus: LoadStatus.LOADED
            }
        }
        case actionTypes.FETCH_ALL_QUOTE_BEGIN: {
            return {
                ...state,
                quotes: {},
                allQuotes: [],
                allQuotesWithIndex: {},
                allQuotesLoadStatus: LoadStatus.LOADING
            }
        }
        case actionTypes.FETCH_ALL_QUOTE_FAIL: {
            return {
                ...state,
                quotes: {},
                allQuotes: [],
                allQuotesWithIndex: {},
                allQuotesLoadStatus: LoadStatus.FAIL
            }
        }
        // case actionTypes.UPDATE_LIST_SYMBOLS_W:
        //     return {
        //         ...state,
        //         listSymbolsW: action.listSymbolsW || []
        //     }
        default:
            return state;
    }
};

export default symbolReducer;
