import _ from 'lodash';

import { symbolService, userService } from "../../services";
import { CommonUtils, EXCHANGE, FILTER_SYMBOL_ID, LoadStatus, ToastUtil } from "../../utils";
import * as actions from '../actions';
import actionTypes from './actionTypes';

// Lấy dữ liệu cho bảng giá
export const fetchSymbolsList = (obj = {}) => {
    // test case isForce
    // Lỗi: Khi chuyển tab trên trình duyệt, ẩn hiện trình duyệt,  gọi lại fetchSymbolsList() thì load lại api getSymbolInfo() nên bị ghi đè data instruments => data instruments bị thiếu thông tin
    // TH lỗi: load bảng giá ( nháy giao diện ), danh mục đầu tư ( giá thị trường bị NAN )
    // Sửa tạm: Đối với isForce = true. Gọi api getSymbolInfoAll thay vì gọi api getSymbolInfo

    // obj = { isForce,callBackInstrumentsSymbol }
    // callBackInstrumentsSymbol = gọi brief xong mới gọi instrusments(symbol)

    return async (dispatch, getState) => {
        const state = getState();
        let { isForce, callBackInstrumentsSymbol } = obj
        // Only load first time
        const symbolNotLoaded = state.symbol.symbolLoadStatus !== LoadStatus.LOADED;
        const symbolNotLoading = state.symbol.symbolLoadStatus !== LoadStatus.LOADING;
        // //console.log("fetchSymbolsList", obj)
        let funcApi = symbolService.getSymbolInfo
        // let funcApi = symbolService.getSymbolInfoAll //Để all để lấy đủ dữ liệu bảng giá cần push vào store ag-grid (sửa tạm để đúng, nghĩ sau)
        if (isForce) {
            funcApi = symbolService.getSymbolInfoAll
        }
        if (symbolNotLoaded || isForce) {
            if (symbolNotLoading || isForce) {
                // if (!isForce) {
                dispatch({
                    type: actionTypes.FETCH_SYMBOL_LIST
                });
                // }
                await funcApi()
                    .then(async (data) => {
                        // Transform data
                        if (data) {
                            // lọc dữ liệu lô lẻ oddLotInstruments [START]
                            let isOddlot = true
                            let dataHOSE = transformSymbolsData(_.filter(data, (e) => e.exchange == EXCHANGE.HOSE), isOddlot)
                            let dataHNX = transformSymbolsData(_.filter(data, (e) => e.exchange == EXCHANGE.HNX), isOddlot)
                            let dataUPCOM = transformSymbolsData(_.filter(data, (e) => e.exchange == EXCHANGE.UPCOM), isOddlot)
                            let oddlotList = {
                                OddlotHOSE: { oddLotInstrument: dataHOSE.symbolInfoForInstrument, symbolArr: dataHOSE.symbolsOddlot, exchange: EXCHANGE.HOSE },
                                OddlotHNX: { oddLotInstrument: dataHNX.symbolInfoForInstrument, symbolArr: dataHNX.symbolsOddlot, exchange: EXCHANGE.HNX },
                                OddlotUPCOM: { oddLotInstrument: dataUPCOM.symbolInfoForInstrument, symbolArr: dataUPCOM.symbolsOddlot, exchange: EXCHANGE.UPCOM }
                            }
                            // console.debug("FETCH_SYMBOL_LIST.:", dataHOSE.symbolInfoForInstrument, dataHOSE.symbols, dataHOSE.symbolsOddlot, dataHNX.symbolsOddlot, dataUPCOM.dataHNX.symbolsOddlot)
                            // lọc dữ liệu lô lẻ oddLotInstruments [END]
                            const tranformedData = transformSymbolsData(data, isOddlot);
                            await dispatch({
                                type: actionTypes.FETCH_SYMBOL_LIST_SUCCESS,
                                exchanges: tranformedData.exchanges,
                                symbols: tranformedData.symbols,
                                symbolWithIndex: tranformedData.symbolWithIndex, //huy.quang: mang symbol danh index by symbol de phuc vu tim kiem
                                instruments: tranformedData.symbolInfoForInstrument
                            });
                            let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
                            await dispatch({
                                type: actionTypes.UPDATE_INSTRUMENT_DATA,
                                rowKey,
                                instruments: tranformedData.symbolInfoForInstrument,
                                // oddLotInstruments: !isForce ? tranformedData.symbolInfoForOddlotInstrument : undefined, // update oddLotInstruments khi gọi api getSymbolInfo(brief=true)
                                oddLotInstruments: tranformedData.symbolInfoForOddlotInstrument, // Tạm thời để update khi gọi vào luồng lấy dữ liệu
                                oddlotList: oddlotList, // update oddLotInstruments khi gọi api getSymbolInfo(brief=true)
                            });
                            callBackInstrumentsSymbol && await callBackInstrumentsSymbol()
                        }
                    })
                    .catch((error) => {
                        dispatch({
                            type: actionTypes.FETCH_SYMBOL_LIST_FAIL
                        });
                        // ToastUtil.errorApi(error, 'common.fail-to-load-symbols-list');
                    });
            }
        }
    };
};


// Lấy dữ liệu trái phiếu riêng lẻ
export const fetchCorpbondList = () => {
    return (dispatch, getState) => {
        symbolService.getCorpbondList()
            .then((responses) => {
                let convertData = transformCorpbondSymbolsData(responses);
                dispatch({
                    type: actionTypes.FETCH_CORPBOND_LIST_SUCCESS,
                    corpbornList: convertData.symbols,
                    corpbondInstrument: convertData.symbolInfoForInstrument,
                    corpbondWithIndex: convertData.symbolWithIndex
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.FETCH_CORPBOND_LIST_FAIL
                });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    };
};



// Lấy dữ liệu cho bảng giá
export const updateInstrumentByData = (dataApi, keys = ["SB"]) => {
    return async (dispatch, getState) => {
        let tranformedData = CommonUtils.transformSymbolsDataInstruments(dataApi);
        if (tranformedData && tranformedData.symbolInfoForInstrument) {
            let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
            await dispatch({ type: actionTypes.UPDATE_INSTRUMENT_DATA, rowKey, instruments: tranformedData.symbolInfoForInstrument, typeFrom: "update" });
        }
    };
};


// Lấy dữ liệu cho đặt lệnh 
export const fetchAllQuotes = () => {
    return (dispatch, getState) => {
        const state = getState();
        const loadStatus = state.symbol.allQuotesLoadStatus;
        const notLoading = !loadStatus || loadStatus !== LoadStatus.LOADING;
        if (notLoading) {
            // dispatch({
            //     type: actionTypes.FETCH_ALL_QUOTE_BEGIN
            // });
            symbolService.getQuote("ALL")
                .then((data) => {
                    // Transform data
                    const tranformedData = transformSymbolsData(data);
                    dispatch({
                        type: actionTypes.FETCH_ALL_QUOTE,
                        allQuotes: tranformedData.symbols,
                        allQuotesWithIndex: tranformedData.symbolWithIndex,
                        quotes: tranformedData.quotes,
                    });
                    dispatch({
                        type: actionTypes.FETCH_SYMBOL_LIST_MAPPING,
                        symbollist: tranformedData.symbols,
                        symbolListMappingWithIndex: tranformedData.symbolWithIndex
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.FETCH_ALL_QUOTE_FAIL
                    });
                    dispatch({
                        type: actionTypes.FETCH_SYMBOL_LIST_MAPPING_FAIL
                    });
                    // ToastUtil.errorApi(error, 'common.fail-to-load-symbols-list');
                });
        }

    };
};


export const fetchSymbolQuote = (symbolId) => {
    return (dispatch, getState) => {
        const state = getState();
        const loadStatus = state.symbol.quoteLoadStatus[symbolId];
        const symbolNotLoading = !loadStatus || loadStatus !== LoadStatus.LOADING;
        if (symbolNotLoading) {
            dispatch({
                type: actionTypes.FETCH_SYMBOL_QUOTE,
                symbol: symbolId
            });

            symbolService.getQuote(symbolId)
                .then((data) => {
                    if (!data || data.length == 0) {
                        dispatch({
                            type: actionTypes.FETCH_SYMBOL_QUOTE_FAIL,
                            symbol: symbolId
                        });
                    } else {
                        var { symbol, ceiling, floor, reference, FullName, StockType, exchange, bidPrice1, offerPrice1, tradelot } = data[0]
                        dispatch({
                            type: actionTypes.FETCH_SYMBOL_QUOTE_SUCCESS,
                            symbol: symbolId,
                            data: { symbol, ceiling, floor, reference, FullName, StockType, exchange, bidPrice1, offerPrice1, tradelot }
                        });
                        // dispatch({
                        //     type: actionTypes.FETCH_SYMBOL_LIST_MAPPING,
                        //     symbollist: tranformedData.symbols,
                        //     symbolListMappingWithIndex: tranformedData.symbolWithIndex
                        // });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.FETCH_SYMBOL_QUOTE_FAIL,
                        symbol: symbolId
                    });
                    ToastUtil.errorApi(error, 'common.fail-to-load-symbol-quote');
                });
        }
    };
};

export const fetchSymbolOrderbook = (symbolId) => {
    return async (dispatch, getState) => {
        const state = getState();
        const loadStatus = state.symbol.orderbookLoadStatus[symbolId];
        const symbolNotLoading = !loadStatus || loadStatus !== LoadStatus.LOADING;
        if (symbolNotLoading) {
            dispatch({
                type: actionTypes.FETCH_SYMBOL_ORDERBOOK,
                symbol: symbolId
            });

            await symbolService.getOrderBookSnap(symbolId)
                .then((data) => {
                    if (!data || data.length == 0) {
                        dispatch({
                            type: actionTypes.FETCH_SYMBOL_ORDERBOOK_FAIL,
                            symbol: symbolId
                        });
                    } else {
                        let orderbookToSet = CommonUtils.processJSON_OrderBook_Snapshot_Api(data);
                        dispatch(setOrderbookData(orderbookToSet, ['SB']));
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.FETCH_SYMBOL_ORDERBOOK_FAIL,
                        symbol: symbolId
                    });
                    ToastUtil.errorApi(error, 'common.fail-to-load-symbol-orderbook');
                });
        }
    };
};

// Lấy dữ liệu cho ô tìm kiếm trên bảng giá
export const fetchSymbolsListMapping = () => {
    return (dispatch, getState) => {

        dispatch({
            type: actionTypes.FETCH_SYMBOL_LIST_MAPPING_BEGIN
        });
        symbolService.getSymbolInfoMapping()
            .then((data) => {
                // Transform data
                const tranformedData = transformSymbolsData(data);
                dispatch({
                    type: actionTypes.FETCH_SYMBOL_LIST_MAPPING,
                    symbollist: tranformedData.symbols,
                    symbolListMappingWithIndex: tranformedData.symbolWithIndex
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.FETCH_SYMBOL_LIST_MAPPING_FAIL
                });
                // ToastUtil.errorApi(error, 'common.fail-to-load-symbols-list');
            });

    };
};

/*
export const fetchFavoriteSymbols = (forceReload) => {
    return (dispatch, getState) => {
        const state = getState();
        // Only load first time
        const notLoaded = state.symbol.favoriteSymbolsLoadStatus !== LoadStatus.LOADED;
        const notLoading = state.symbol.favoriteSymbolsLoadStatus !== LoadStatus.LOADING;
        if ((notLoaded || forceReload) && notLoading) {
            dispatch({
                type: actionTypes.FETCH_FAV_SYMBOLS
            });
            userService.getFavoriteSymbols()
                .then((data) => {
                    if (data && data['priceBoardSymbols']) {
                        // Transform data
                        const symbols = _.split(data['priceBoardSymbols'], ',') || [];

                        dispatch({
                            type: actionTypes.FETCH_FAV_SYMBOLS_SUCCESS,
                            symbols: symbols
                        });
                    } else {
                        dispatch({
                            type: actionTypes.FETCH_FAV_SYMBOLS_SUCCESS,
                            symbols: []
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.FETCH_FAV_SYMBOLS_FAIL
                    });
                    ToastUtil.errorApi(error, 'common.fail-to-load-favorites-list');
                });
        }
    };
};

export const addFavoriteSymbol = (symbolId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.ADD_FAV_SYMBOL,
            symbol: symbolId
        });
        userService.addFavoriteSymbols([symbolId])
            .then(() => {
                ToastUtil.success('favorite-edit.add-symbol-success');
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.ADD_FAV_SYMBOL_FAIL,
                    symbol: symbolId
                });
                ToastUtil.errorApi(error, 'favorite-edit.add-symbol-fail');
            });
    };
};

export const removeFavoriteSymbol = (symbolId) => {
    return dispatch => {
        dispatch({
            type: actionTypes.REMOVE_FAV_SYMBOL,
            symbol: symbolId
        });
        userService.deleteFavoriteSymbols([symbolId])
            .then(() => {
                ToastUtil.success('favorite-edit.remove-symbol-success');
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.REMOVE_FAV_SYMBOL_FAIL,
                    symbol: symbolId
                });
                ToastUtil.errorApi(error, 'favorite-edit.remove-symbol-fail');
            });
    };
};
 */

export const fetchWatchList = (forceReload, isLoggedIn, callback) => {
    return (dispatch, getState) => {
        const state = getState();
        // Only load first time
        const notLoaded = state.symbol.watchListsLoadStatus !== LoadStatus.LOADED;
        const notLoading = state.symbol.watchListsLoadStatus !== LoadStatus.LOADING;
        if ((notLoaded || forceReload) && notLoading) {
            dispatch({
                type: actionTypes.FETCH_WATCH_LIST
            });
            const array = localStorage.getItem("watchlists");
            let parsedArray = array ? JSON.parse(array) : [];

            if (isLoggedIn) {
                userService.getWatchLists()
                    .then((data) => {
                        if (data && data.length > 0) {
                            //parsedArray = [...parsedArray, ...data]
                            dispatch({
                                type: actionTypes.FETCH_WATCH_LIST_SUCCESS,
                                watchLists: data
                            });
                        } else {
                            dispatch({
                                type: actionTypes.FETCH_WATCH_LIST_SUCCESS,
                                watchLists: data
                            });
                        }
                        callback != null && callback(true);
                    })
                    .catch((error) => {
                        dispatch({
                            type: actionTypes.FETCH_WATCH_LIST_FAIL
                        });
                        ToastUtil.errorApi(error, 'common.fail-to-load-favorites-list');
                        callback != null && callback(false);
                    });
            } else {
                // const array = localStorage.getItem("watchlists");
                // const parsedArray = array ? JSON.parse(array) : [];
                dispatch({
                    type: actionTypes.FETCH_WATCH_LIST_SUCCESS,
                    watchLists: parsedArray
                });
            }
        }
    };
};

export const addWatchList = (name, symbols, isLoggedIn) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (isLoggedIn) {
                userService.addWatchList(name, symbols)
                    .then(() => {
                        dispatch(fetchWatchList(true, true, () => {
                            const state = getState();
                            const watchLists = state.symbol.watchLists;
                            const addedRecord = _.find(watchLists, (elem) => {
                                return elem.name === name;
                            });
                            resolve(addedRecord);
                        }));
                        ToastUtil.success('watch-lists-edit.add-success');
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'watch-lists-edit.add-fail');
                        reject();
                    });
            } else {
                let item = {
                    name: name,
                    symbols: [],
                    id: Date.now().toString(36) + Math.random().toString(36).substr(2),
                }
                const array = localStorage.getItem("watchlists");
                const parsedArray = array ? JSON.parse(array) : [];
                const newArray = [...parsedArray, item];
                localStorage.setItem("watchlists", JSON.stringify(newArray));
                dispatch(fetchWatchList(true, isLoggedIn));
                resolve(item);
                ToastUtil.success('watch-lists-edit.add-success');
            }
        });
    };
};

export const updateWatchList = (watchlist, isLogin) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            if (watchlist && watchlist.username) {
                userService.updateWatchList(watchlist.id, watchlist.name, watchlist.symbols, watchlist.collumnSetting)
                    .then(() => {
                        dispatch({
                            type: actionTypes.UPDATE_WATCH_LIST,
                            id: watchlist.id,
                            name: watchlist.name,
                            symbols: watchlist.symbols,
                            collumnsetting: watchlist.collumnSetting
                        });
                        ToastUtil.success('watch-lists-edit.update-success');
                        resolve();
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'watch-lists-edit.update-fail');
                        reject();
                    });
            } else {
                dispatch({
                    type: actionTypes.UPDATE_WATCH_LIST,
                    id: watchlist.id,
                    name: watchlist.name,
                    symbols: watchlist.symbols,
                    isLogin: watchlist.isLogin,
                    collumnsetting: watchlist.collumnSetting
                });
                const array = localStorage.getItem("watchlists");
                const parsedArray = array ? JSON.parse(array) : [];
                let index = parsedArray.findIndex(item => item.id === watchlist.id)
                parsedArray[index].name = watchlist.name;
                parsedArray[index].symbols = watchlist.symbols;
                parsedArray[index].collumnSetting = watchlist.collumnSetting;
                let newArray = [...parsedArray]
                localStorage.setItem("watchlists", JSON.stringify(newArray));
                ToastUtil.success('watch-lists-edit.update-success');
                resolve();
            }
        });
    };
};


export const updateWatchListColumnSetting = (collection) => {
    return (dispatch, getState) => {
        const state = getState();
        let isLogin = state.user.isLoggedIn;
        return new Promise((resolve, reject) => {
            if ((collection && collection.username)) {
                userService.updateWatchList(collection.id, collection.name, collection.symbols, collection.collumnSetting)
                    .then(() => {
                        dispatch({
                            type: actionTypes.UPDATE_WATCH_LIST,
                            id: collection.id,
                            name: collection.name,
                            symbols: collection.symbols,
                            collumnsetting: collection.collumnSetting
                        });
                        ToastUtil.success('watch-lists-edit.update-success');
                        resolve();
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'watch-lists-edit.update-fail');
                        reject();
                    });
            } else {
                dispatch({
                    type: actionTypes.UPDATE_WATCH_LIST,
                    id: collection.id,
                    name: collection.name,
                    symbols: collection.symbols,
                    collumnsetting: collection.collumnSetting
                });
                const array = localStorage.getItem("watchlists");
                const parsedArray = array ? JSON.parse(array) : [];
                let index = parsedArray.findIndex(item => item.id === collection.id)
                parsedArray[index].name = collection.name;
                parsedArray[index].symbols = collection.symbols;
                let newArray = [...parsedArray]
                localStorage.setItem("watchlists", JSON.stringify(newArray));
                ToastUtil.success('watch-lists-edit.update-success');
            }
        });
    };
};

export const deleteWatchList = (watchlist, isLoggedIn) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            if (watchlist && watchlist.username) {
                userService.deleteWatchList(watchlist.id)
                    .then(() => {
                        dispatch({
                            type: actionTypes.REMOVE_WATCH_LIST,
                            id: watchlist.id
                        });
                        ToastUtil.success('watch-lists-edit.remove-success');
                        resolve();
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'watch-lists-edit.remove-fail');
                        reject();
                    });
            } else {
                const array = localStorage.getItem("watchlists");
                const parsedArray = array ? JSON.parse(array) : [];
                // const newArray = [...parsedArray];
                let newArray = parsedArray.filter(item => item.id !== watchlist.id)
                localStorage.setItem("watchlists", JSON.stringify(newArray));
                dispatch({
                    type: actionTypes.REMOVE_WATCH_LIST,
                    id: watchlist.id
                });
                ToastUtil.success('watch-lists-edit.remove-success');
            }
        });
    };
};

export const fetchFixedList = (forceReload) => {
    return (dispatch, getState) => {
        const state = getState();
        // Only load first time
        const notLoaded = state.symbol.fixedListLoadStatus !== LoadStatus.LOADED;
        const notLoading = state.symbol.fixedListLoadStatus !== LoadStatus.LOADING;
        if ((notLoaded || forceReload) && notLoading) {
            dispatch({
                type: actionTypes.FETCH_FIXED_LIST
            });

            Promise.all([
                symbolService.getFixedSymbolList('30'),
                // symbolService.getFixedSymbolList('HNX30'),
                symbolService.getFilteredSymbolList(FILTER_SYMBOL_ID.INDUSTRIES_EVENT)
            ])
                .then((responses) => {
                    // const [vn30Symbol, hnx30Symbol, industryEvent] = responses;
                    const [vn30Symbol, industryEvent] = responses;
                    dispatch({
                        type: actionTypes.FETCH_FIXED_LIST_SUCCESS,
                        fixedList: {
                            VN30: vn30Symbol,
                            // HNX30: hnx30Symbol,
                            industryEvent
                        }
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.FETCH_FIXED_LIST_FAIL
                    });
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });
        }
    };
};

// Lấy all thông tin lô lẻ theo sàn (snapshot)
export const fetchOddlotListByExchange = (exchange) => {
    return (dispatch, getState) => {
        // const state = getState();
        // const notLoading = state.symbol.oddlotListLoadStatus !== LoadStatus.LOADING;
        // if (exchange && notLoading) {
        if (exchange) {
            symbolService.getOddlotByExchange(exchange)
                .then((data) => {
                    const tranformedData = transformSymbolsData(data);
                    // const instruments = tranformedData.symbolInfoForInstrument;
                    // const symbolList = tranformedData.symbols;
                    let rowKey = Object.keys(tranformedData.symbolInfoForInstrument)
                    let oddlotList = {}
                    //console.log("fetchOddlotListByExchange.:1", oddlotList)
                    oddlotList[`Oddlot${exchange}`] = { oddLotInstrument: tranformedData.symbolInfoForInstrument, symbolArr: tranformedData.symbols, exchange: exchange }
                    //console.log("fetchOddlotListByExchange.:2", oddlotList)
                    dispatch({
                        type: actionTypes.UPDATE_ODDLOT_INSTRUMENT_DATA,
                        rowKey,
                        oddLotInstruments: tranformedData.symbolInfoForInstrument,
                        oddlotList: oddlotList
                    });
                }).catch(error => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                })
        }
    };
};

export const fetchDerivativeList = (forceReload) => {
    return (dispatch, getState) => {
        symbolService.getSymbolListByExchange('XHNF')
            .then((responses) => {
                const indexFutures = responses.filter(p => p.underlyingSymbol == "VN30");
                const bondFutures = responses.filter(function (event) {
                    if (event.underlyingSymbol) return event.underlyingSymbol.indexOf("VGB") > -1
                });
                const derivatives = indexFutures.concat(bondFutures);
                dispatch({
                    type: actionTypes.FETCH_DERIVATIVE_LIST_SUCCESS,
                    derivativeList: {
                        derivatives: derivatives,
                        indexFutures: indexFutures,
                        bondFutures: bondFutures
                    }
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.FETCH_DERIVATIVE_LIST_FAIL
                });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    };
};


const processIndustryList = (industryArr) => {
    let industryList = [];
    let industryKeysList = [];
    let allIndustryList = {
        codeList: "",
        key: "all",
        industryName: "Tất cả",
        industryNameEn: "All",
    }

    if (industryArr.length > 0) {
        industryArr.forEach(item => {
            allIndustryList.codeList += item.codeList ? item.codeList + ',' : '';
            let key = item.industryNameEn ? item.industryNameEn.replaceAll(' ', '') : item.id;
            industryKeysList.push(key)
            industryList.push({ ...item, key: key })
        });
    }
    if (allIndustryList.codeList) {
        let allCodeArr = [];
        // 1 mã xuất hiện 1 lần
        allCodeArr = _.uniq(allIndustryList.codeList.split(','));
        allIndustryList.codeList = allCodeArr.join(',');
    }
    // add danh sách all
    industryList.unshift(allIndustryList);
    industryKeysList.unshift(allIndustryList.key);

    return { industryList, industryKeysList };
}

export const fetchIndustryList = () => {
    return (dispatch, getState) => {
        userService.getIndustryList()
            .then((responses) => {
                if (responses.length > 0) {
                    let processedList = processIndustryList(responses);
                    dispatch({
                        type: actionTypes.FETCH_INDUSTRY_LIST,
                        industryList: processedList.industryList,
                        industryKeysInclude: processedList.industryKeysList
                    });
                } else {
                    dispatch({
                        type: actionTypes.FETCH_INDUSTRY_LIST_FAIL
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.FETCH_INDUSTRY_LIST_FAIL
                });
            });
    };
};


export const fetchTopInstrumentList = (topID) => {
    return (dispatch, getState) => {
        let filterInput = FILTER_SYMBOL_ID[topID] ? FILTER_SYMBOL_ID[topID] : '';

        if (filterInput) {
            symbolService.getFilteredSymbolList(filterInput)
                .then((responses) => {
                    dispatch({
                        type: actionTypes.SET_TOP_INSTRUMENT_LIST,
                        id: topID,
                        symbolList: responses[filterInput]
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.FETCH__TOP_INSTRUMENT_LIST_FAIL,
                        id: topID
                    });
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });
        }
    };
};

export const fetchIndexSymbolList = (indexCode) => {
    return (dispatch, getState) => {
        symbolService.getFixedSymbolList(indexCode)
            .then((responses) => {
                dispatch({
                    type: actionTypes.SET_INDEX_LIST,
                    id: indexCode,
                    symbolList: responses
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.SET_INDEX_LIST_FALSE,
                    id: indexCode
                });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    };
};

// Lấy thông tin bảng giá khuyến nghị
export const fetchRecommendList = () => {
    return (dispatch, getState) => {
        // let state = getState();
        // let instruments = state.symbol.instruments;
        symbolService.getRecommendSymbolList()
            .then((responses) => {
                let convertData = {};
                if (responses.length > 0) {
                    responses.forEach(item => {
                        convertData[`${item['machungkhoan']}`] = item;
                    })
                }
                dispatch({
                    type: actionTypes.SET_RECOMMEND_LIST,
                    data: responses,
                    recommendWithIndex: convertData
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.SET_RECOMMEND_LIST_FALSE
                });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    };
};

export const setSymbolInfoBySelectSuggest = (symbolId) => {
    return (dispatch, getState) => {
        if (symbolId && symbolId != "") {
            dispatch(actions.setTradeCurrentSymbolById(symbolId, false));
        } else {
            dispatch({
                type: actionTypes.SET_SYMBOL_DATA_BY_SUGGEST,
                data: null
            });
        }
    }
};

export const updateInstrumentSeq = (isClear) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_INSTRUMENTSEQ,
            isClear,
        });
    };
};



export const updateInstrumentData = (data, typeFrom, isCheckSeq) => {
    return (dispatch, getState) => {
        // const state = getState();
        // let newData = []
        if (data) {
            for (const item of data) {
                // let olditem = state.symbol.instruments[item.SB];
                // Nếu có cập nhật close Price của instrument thì thêm trường P4A 
                // Để cập nhật lấy giá closePrice mới nhất theo instrument còn lại lấy giá theo API
                // newData = [{ ...item }] // Do instrument api trả ra full trường, lúc nào cũng có CH
                if (!item.SB) {
                    return null;
                }
                // if (item.length > 0) {
                // let instruments = {};
                // let row = newData[0];
                // let rowKey = buildRowKey(row, keys);
                let rowKey = item.SB;
                if (item.CHP != undefined) item.percent = Number(item.CHP) * 1000
                // row.ts = timestamp;
                // instruments[rowKey] = row;
                dispatch({
                    type: actionTypes.UPDATE_INSTRUMENT_DATA,
                    rowKey: [rowKey],
                    instruments: { [rowKey]: item },
                    typeFrom: typeFrom,
                    isCheckSeq
                });
                // }
            }
        }
    };
};


export const updateCorpbondData = (data) => {
    return (dispatch, getState) => {
        let newData = []
        if (data) {
            for (const item of data) {
                // let olditem = state.symbol.corpbondInstrument[item.SB];
                newData = [{ ...item }] // Do instrument api trả ra full trường, lúc nào cũng có CH
                if (!item.SB) {
                    return null;
                }
                if (newData.length > 0) {
                    let corpbonds = {};
                    let row = newData[0];
                    row['ts'] = newData[0]['t'] ? newData[0]['t'] : row['ts'];
                    // let rowKey = buildRowKey(row, keys);
                    let rowKey = item.SB;
                    // row.ts = timestamp;
                    corpbonds[rowKey] = row;
                    dispatch({
                        type: actionTypes.UPDATE_CORPBOND_DATA,
                        rowKey: [rowKey],
                        corpbondInstrument: corpbonds,
                    });
                }
            }
        }
    };
};

export const updateOddLotInstrumentSeq = (isClear) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_ODDLOT_INSTRUMENTSEQ,
            isClear,
        });
    };
};
export const updateOddLotInstrumentData = (data, typeFrom, isCheckSeqOddLot) => {
    return (dispatch, getState) => {
        const state = getState();
        let newData = []
        if (data) {
            for (const item of data) {
                let olditem = state.symbol.oddLotInstruments[item.SB];
                // Nếu có cập nhật close Price của instrument thì thêm trường P4A 
                // Để cập nhật lấy giá closePrice mới nhất theo instrument còn lại lấy giá theo API
                newData = [{ ...olditem, ...item }] // Do oddlot api không trả ra full trường
                if (!item.SB) {
                    return null;
                }
                if (newData.length > 0) {
                    let oddLotInstruments = {};
                    let row = newData[0];
                    // let rowKey = buildRowKey(row, keys);
                    let rowKey = item.SB;
                    // //console.log("updateOddLotInstrumentData====0.0")
                    if (item.CHP != undefined) row.percent = Number(row.CHP) * 1000 // check theo CHP của tín hiệu mới tính lại CHP
                    // row.ts = timestamp;
                    oddLotInstruments[rowKey] = row;
                    dispatch({
                        type: actionTypes.UPDATE_ODDLOT_INSTRUMENT_DATA,
                        rowKey: [rowKey],
                        oddLotInstruments: oddLotInstruments,
                        typeFrom: typeFrom,
                        isCheckSeqOddLot
                    });
                }
            }
        }
    };
};

export const setOrderbookData = (data, keys) => {
    return (dispatch) => {
        if (!data || data.length === 0) {
            return;
        }

        dispatch({
            type: actionTypes.SET_ORDERBOOK_DATA,
            data: data,
            keys: keys
        });
    };
};

export const updateOrderbookData = (data) => {
    return (dispatch) => {
        if (!data || data.length === 0) {
            return;
        }

        dispatch({
            type: actionTypes.UPDATE_ORDERBOOK_DATA,
            data: data
        });
    };
};

export const insertOrderbookData = (data) => {
    return (dispatch) => {
        if (!data || data.length === 0) {
            return;
        }

        dispatch({
            type: actionTypes.INSERT_ORDERBOOK_DATA,
            data: data
        });
    };
};

export const deleteOrderbookData = (data) => {
    return (dispatch) => {
        if (!data || data.length === 0) {
            return;
        }

        dispatch({
            type: actionTypes.DELETE_ORDERBOOK_DATA,
            data: data
        });
    };
};

export const setTradeData = (data, nextIndex) => {
    return (dispatch) => {
        if (!data || data.length === 0) {
            return;
        }
        let tradeInfoNextIndex = nextIndex && nextIndex > 0 ? nextIndex : -1;
        dispatch({
            type: actionTypes.SET_TRADE_DATA,
            data: data,
            tradeInfoNextIndex: tradeInfoNextIndex
        });
    };
};

export const insertTradeData = (data) => {
    return (dispatch) => {
        if (!data || data.length === 0) {
            return;
        }

        dispatch({
            type: actionTypes.INSERT_TRADE_DATA,
            data: data
        });
    };
};

export const onInitDay = () => ({
    type: actionTypes.ON_INIT_DAY
});

const transformSymbolsData = (data, isOddlot) => {
    const exchanges = [];
    const symbols = [];
    const symbolsOddlot = []
    const symbolWithIndex = {};
    const symbolInfoForInstrument = {};
    const symbolInfoForOddlotInstrument = {}
    const symbolInfos = data;
    const quotes = {};
    for (let i = 0; i < symbolInfos.length; i++) {
        const symbolInfo = symbolInfos[i];
        const { symbol, FullName, exchange, StockType } = symbolInfo;

        //tam thoi fix them du lieu vao mang quotes, xoa mang all quotes sau
        const { ceiling, floor, reference, bidPrice1, offerPrice1, ExercisePrice, ExerciseRatio, closePrice, tradelot } = symbolInfo;

        if (exchange === '' || exchange === undefined) {
            continue;
        }

        if (!exchanges.includes(exchange)) {
            exchanges.push(exchange);
        }

        // Tính điểm hòa vốn
        let breakEvenPoint = CommonUtils.calBreakEvenPoint(ExercisePrice, closePrice, ExerciseRatio);


        const convertedInfo = {
            id: symbol,
            exchange: exchange,
            desc: FullName,
            stockType: StockType,
            ...symbolInfo,
            breakEvenPoint: breakEvenPoint
        };
        symbolInfoForInstrument[symbol] = CommonUtils.processJSONAllStock(convertedInfo);
        symbolWithIndex[symbol] = convertedInfo;
        symbols.push(convertedInfo);

        //tam thoi fix them du lieu vao mang quotes, xoa mang all quotes sau
        quotes[symbol] = { symbol, ceiling, floor, reference, FullName, StockType, exchange, bidPrice1, offerPrice1, tradelot };
        if (isOddlot) {
            const convertedInfoOddlot = {
                id: symbol,
                desc: FullName,
                breakEvenPoint: breakEvenPoint,
                //
                symbol: symbol,
                stockType: StockType,
                ceiling: symbolInfo.ceiling || "",
                floor: symbolInfo.floor || "",
                reference: symbolInfo.reference || "",
                exchange: symbolInfo.exchange || "",
                FullName: symbolInfo.FullName || "",
                Status: symbolInfo.Status || "",
                FloorCode: symbolInfo.FloorCode || "",
                FundType: symbolInfo.FundType || "",
            };
            symbolsOddlot.push(convertedInfoOddlot)
            symbolInfoForOddlotInstrument[symbol] = CommonUtils.processJSONAllStock(convertedInfoOddlot);
        }
    }
    return {
        exchanges: exchanges,
        symbols: symbols,
        symbolWithIndex,
        symbolInfoForInstrument,
        quotes,
        symbolInfoForOddlotInstrument: symbolInfoForOddlotInstrument,
        symbolsOddlot
    };
};


const transformCorpbondSymbolsData = (data) => {
    const exchanges = [];
    const symbols = [];
    const symbolWithIndex = {};
    const symbolInfoForInstrument = {};
    const symbolInfos = data;
    const quotes = {};
    for (let i = 0; i < symbolInfos.length; i++) {
        if (!symbolInfos[i]['PERIOD_REMAIN'] || parseFloat(symbolInfos[i]['PERIOD_REMAIN']) <= 0) continue; // Loại các mã không có hoặc hết kỳ hạn
        const symbolInfo = symbolInfos[i];
        const { symbol, FullName, StockType } = symbolInfo;

        //tam thoi fix them du lieu vao mang quotes, xoa mang all quotes sau
        const { ceiling, floor, reference, PT_BestBidPrice: bidPrice1, PT_BestOfferPrice: offerPrice1, ExercisePrice, ExerciseRatio, closePrice } = symbolInfo;

        // Tính điểm hòa vốn
        // let breakEvenPoint = CommonUtils.calBreakEvenPoint(ExercisePrice, closePrice, ExerciseRatio);


        const convertedInfo = {
            id: symbol,
            // exchange: exchange,
            desc: FullName,
            stockType: StockType,
            ...symbolInfo,
            // breakEvenPoint: breakEvenPoint
        };
        symbolInfoForInstrument[symbol] = CommonUtils.processJSONCorpBond(convertedInfo);
        symbolWithIndex[symbol] = convertedInfo;
        symbols.push(convertedInfo);

        //tam thoi fix them du lieu vao mang quotes, xoa mang all quotes sau
        quotes[symbol] = { symbol, ceiling, floor, reference, FullName, StockType, exchange: '', bidPrice1, offerPrice1 };
    }
    return { symbols: symbols, symbolWithIndex, symbolInfoForInstrument, quotes };
};

export const getTradeData = (symbol) => {
    return (dispatch,) => {

        symbolService.getAllTranslogSnap(symbol)
            .then((data) => {
                if (data && data.length > 0) {
                    let transLog = transformTradeInfo(data);
                    dispatch(setTradeData(transLog, data.nextIndex));
                }
            })
            .catch((error) => {
                // ToastUtil.errorApi(error, 'common.fail-to-load-symbol-quote');
            });

    };
}

export const getAllTranslogBySymbol = (symbol) => {
    return (dispatch,) => {

        symbolService.getAllTranslogBySymbol(symbol)
            .then((data) => {
                if (data && data.length > 0) {
                    dispatch({
                        type: actionTypes.SET_MENUDETAIL_CHART_INFO_DATA,
                        data: data,
                    });
                }
            })
            .catch((error) => {
                // ToastUtil.errorApi(error, 'common.fail-to-load-symbol-quote');
            });

    };
};

export const getMoreTradeData = (symbol, index, limitRow) => {
    return (dispatch, getState) => {
        const state = getState();
        // symbolService.getAllTranslogSnap(symbol, index)
        symbolService.getTranslogSnap(symbol, index, limitRow)
            .then((data) => {

                if (data) {
                    let transLog = transformTradeInfo(data.translog || []);
                    let transLogToSet = state.symbol.trades[symbol] ? state.symbol.trades[symbol].concat(transLog) : transLog;
                    dispatch(setTradeData(transLogToSet, data.nextIndex));
                }
            })
            .catch((error) => {
                // ToastUtil.errorApi(error, 'common.fail-to-load-symbol-quote');
            });
    }
}

export const getMenuDetailChartInfo = symbol => {
    return async (dispatch, getState) => {
        await symbolService.getMarketHistory(symbol)
            .then(res => {
                dispatch({
                    type: actionTypes.SET_MENUDETAIL_CHART_DATA,
                    data: res[symbol],
                });
            }).catch(err => {
                //console.log('Fail to fetch MenuDetailChartInfo ChartMatchedInDay', err)
            });
    }
}

// convert dữ liệu lấy từ api snapshot
export const transformTradeInfo = (data) => {
    let transLog = [];

    transLog = data.map(element => {
        const transDate = element.tradingdate && element.tradingdate.split('/');
        const transTime = element.formattedTime && element.formattedTime.split(':');
        const transUnix = new Date(parseInt(transDate[2]), parseInt(transDate[1]) - 1, parseInt(transDate[0]),
            parseInt(transTime[0]), parseInt(transTime[1]), parseInt(transTime[2])).getTime() / 1000;
        return {
            id: element.id ? element.id : '',
            IID: element.Id ? element.Id : '',
            SM: element.sequenceMsg ? element.sequenceMsg : '',
            TD: element.tradingdate ? element.tradingdate : '',
            SB: element.symbol ? element.symbol : '',
            FT: element.formattedTime ? element.formattedTime : '',
            LC: element.lastColor ? element.lastColor : '',
            FMP: element.formattedMatchPrice ? element.formattedMatchPrice : '',
            CC: element.changeColor ? element.changeColor : '',
            FCV: element.formattedChangeValue ? element.formattedChangeValue : '',
            FV: element.formattedVol ? element.formattedVol : '',
            AVO: element.formattedAccVol ? element.formattedAccVol : '',
            AVA: element.formattedAccVal ? element.formattedAccVal : '',
            UT: transUnix ? transUnix : '',
        }
    })

    // Chuyển đoạn sort lên OpenAPi
    // transLog = _.orderBy(transLog, ['UT'], ['desc']);

    return transLog;

}

// // Haki
// export const updateListSymbolsW = (listSymbolsW) => {
//     return (dispatch) => {
//         setTimeout(() => {
//             dispatch({
//                 type: actionTypes.UPDATE_LIST_SYMBOLS_W,
//                 listSymbolsW: listSymbolsW,
//             });
//         }, 300)
//     };
// };
