import { push } from 'connected-react-router';
import _ from 'lodash';
import { batch } from 'react-redux';

import { inquiryService, notifyService, serverService } from "../../services";
import userService from '../../services/userService';
import { CommonUtils, CommonWidgetUtils, ToastUtil } from "../../utils";
import { reconfirmTypes } from '../../utils/constants';

import actionTypes from './actionTypes';
import { changeLayoutPageActive } from './layoutActions'
import { authorizationSuccess, fetchUserInfoFromSavedSession, loginByTokenData, logout, setLoginPopupOnstart } from './userActions';
import * as actions from "../../store/actions";
const globalVar = window._env_

const appStartupComplete = () => ({
    type: actionTypes.APP_STARTUP_COMPLETE
});

const setAuthorize = (custodycd, dispatch) => {
    let usersTokens = JSON.parse(localStorage.getItem('token-users'));
    if (usersTokens && usersTokens[`${custodycd}`]) {
        let tokenData = usersTokens[`${custodycd}`];
        if (tokenData.access_token) {
            dispatch(loginByTokenData(tokenData, () => {
                dispatch(appStartupComplete());
            }));
        } else {
            // Tài khoản đã bị logout = access_token rỗng => mở màn login
            // emitter.emit(
            //     OPEN_LOGIN_POP_UP,
            //     custodycd
            // );
            dispatch(setLoginPopupOnstart(true, custodycd));
            dispatch(appStartupComplete());
        }
    }
}

export const initializeApp = (custodycd) => {
    return (dispatch, getState) => {
        const state = getState();


        let isOpenBrowser = CommonUtils._getCookie('isOpenBrowser');
        let isCheckLogout = localStorage.getItem('isCheckLogout');
        const haveSavedSession = state.user.token != null;

        // Lưu lại thông tin token nếu localStorage rỗng
        let usersTokens = JSON.parse(localStorage.getItem('token-users'));
        if (haveSavedSession && !usersTokens) {
            dispatch(authorizationSuccess(state.user.token));
        }

        if ((isOpenBrowser === null) && haveSavedSession) {
            // Case xảy ra khi thoát browser
            // Khi lấy được token thì isOpenBrowser = true
            // Thoát trình duyệt isOpenBrowser = null, 
            // check init app để ko cho login khi đã thoát khỏi browser
            dispatch(logout());
            return
        }

        // Check cờ check trạng thái đã từng logout trên Browser này || Từng tắt Browser rồi mở lại
        if ((isCheckLogout === true && isOpenBrowser === null) && haveSavedSession) {
            // Case xảy ra khi người dùng tự logout, xóa hết thông tin thừa lưu trên localstorage
            // Tránh lưu lại 1 số thông tin bị cache lại do redux persist
            dispatch(logout());
            return
        }
        //console.log('initializeApp.:token', state.user.token)
        batch(() => {
            dispatch(actions.getLayoutConfigLocalStorage());
            if (custodycd && !haveSavedSession && globalVar.switch.enableSaveLoginAccount) {
                sessionStorage.setItem('activeCustID', custodycd);
                setAuthorize(custodycd, dispatch);
                return;
            }
            if (haveSavedSession) {
                dispatch(fetchUserInfoFromSavedSession(() => {
                    dispatch(appStartupComplete());
                }));
            } else {
                dispatch(appStartupComplete());
            }
            // Set lại currentLayoutPageActive và xóa layoutCustom TEMP
            dispatch({
                type: actionTypes.UPDATED_LAYOUTS_CUSTOM,
                layoutCustom: undefined,
                isSaveByBtn: true,
                from: 'initializeApp'
            });
            let currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
            dispatch(changeLayoutPageActive(currentLayoutPageActive))
        })
    };
};

export const changeLanguage = (language) => ({
    type: actionTypes.CHANGE_LANGUAGE,
    language: language
});

export const logoutByOther = () => ({
    type: actionTypes.LOGOUT_BY_OTHER
});

export const setIsLogout = (isLogout) => ({
    type: actionTypes.SET_LOGOUT_BY_OTHER,
    isLogout: isLogout
});

export const setCurrentDate = (currentDate) => ({
    type: actionTypes.SET_CURRENT_DATE,
    currentDate: currentDate
});

export const setClientConfig = (config) => ({
    type: actionTypes.SET_CLIENT_CONFIG,
    config: config
});

export const logoutBySessionExpired = () => ({
    type: actionTypes.LOGOUT_BY_SESSION_EXPIRED
});

export const setCurrentReconfirmOrder = (showReconfirmOrderConfig) => ({
    type: actionTypes.CHANGE_SHOW_RECONFIRM_ORDER,
    showReconfirmOrderConfig: showReconfirmOrderConfig
});

export const setCurrentConfirmOrderType = (currentReconfirmOrderType) => ({
    type: actionTypes.SET_CURRENT_RECONFIRM_ORDER_TYPE,
    currentReconfirmOrderType: currentReconfirmOrderType
});

export const setFuncRenderOrderConfirmView = (func) => ({
    type: actionTypes.SET_PROP_FOR_TradeAuth_COMPONENT,
    funcRenderOrderConfirmView: func
});

export const changeReconfirmOrder = (status) => {
    return (dispatch, getState) => {
        userService.changeReconfirmOrder(status).then(changeResult => {
            dispatch({
                type: actionTypes.CHANGE_SHOW_RECONFIRM_ORDER,
                showReconfirmOrderConfig: status
            });
        }).catch(error => {
        })
    };
};

export const setShowOrderPopup = (showOrderPopup) => ({
    type: actionTypes.SET_SHOW_ORDER_POPUP,
    showOrderPopup: showOrderPopup
});

export const setTradeCurrentSymbol = (symbol) => {
    return dispatch => {
        dispatch(setTradeCurrentSymbolById(symbol.id));
    };
};

export const setTradeCurrentSymbolById = (symbolId, editTypeTab, isRedirect, symbolConditionOrderId) => {
    return (dispatch, getState) => {
        const state = getState();
        const currentSymbolInUrl = CommonUtils.getCurrentSymbolFromPathName(state.router.location.pathname);
        if (isRedirect) {
            if (currentSymbolInUrl !== symbolId) {
                dispatch(push(`/webtrade/${symbolId}`));
            }
        } else {
            // const symbols = state.symbol.allQuotes; // mã được phép đặt lệnh
            const symbols = state.symbol.symbols; // toàn bộ mã trên bảng giá
            const foundSymbol = (symbolConditionOrderId && symbolId === null) ? _.find(symbols, (element) => (element.id === symbolConditionOrderId)) : _.find(symbols, (element) => (element.id === symbolId));
            if (!foundSymbol) {
                dispatch({
                    type: actionTypes.CLEAR_SYMBOL_SUGGESTION,
                    isClearSymbolSuggestion: true
                })
            }
            if (symbolConditionOrderId) {
                // Dành riêng cho lệnh điều kiện
                dispatch({
                    type: actionTypes.SET_TRADE_CURRENT_SYMBOL,
                    symbolConditionOrder: foundSymbol
                })
            } else {
                dispatch({
                    type: actionTypes.SET_TRADE_CURRENT_SYMBOL,
                    symbol: foundSymbol,
                    editTypeTab: editTypeTab
                })
            }
        }
    };
};

export const setTradeCurrentSymbolActual = (symbol) => ({
    type: actionTypes.SET_TRADE_CURRENT_SYMBOL,
    symbol: symbol
});

export const setTradeCurrentOrderGroup = (orderGroup) => ({
    type: actionTypes.SET_TRADE_CURRENT_ORDER_GROUP,
    orderGroup: orderGroup
});

export const setTradeOrderInputMsg = (msg) => ({
    type: actionTypes.SET_TRADE_ORDER_INPUT_MSG,
    msg: msg
});
export const setTradeOrderInput = (orderInput, symbol) => {
    //console.log("ha--setTradeOrderInput", orderInput)
    return (dispatch, getState) => {
        const state = getState();
        const currentOrderInput = state.app.tradeOrderInput || {};

        let actionAllowed = true;
        let errID = "";

        // If symbol passed, check if symbol exist first
        if (symbol) {
            const symbols = state.symbol.allQuotes;
            const foundSymbol = _.find(symbols, (element) => (element.id === symbol));
            if (!foundSymbol) {
                console.error(`Symbol ${symbol} not found in mapping. OrderInput state will not be modified.`);
                // Error will be displayed to user in Trade's route logic
                return;
            } else {
                dispatch(setTradeCurrentSymbol(foundSymbol));
            }
        }

        // Check if editing an order
        const editingOrder = currentOrderInput.editingOrder;
        if (editingOrder != null) {
            // If symbol is passed and it's not same as symbol of editing order, we will allow the action
            if (symbol && symbol !== currentOrderInput.editingOrder['symbol']) {
                actionAllowed = true;
                orderInput.editingOrder = null;
            } else {
                // If we edit another order, action is allowed
                // Otherwise we need to perform some check
                if (orderInput.editingOrder == null) {
                    // In case we edit order, we does not allow user to change action or orderType
                    if (orderInput.action && orderInput.action !== currentOrderInput.action) {
                        errID = "trade.place-order.only-change-price-quantity-when-edit";
                        actionAllowed = false;
                    } else if (orderInput.orderType && orderInput.orderType !== currentOrderInput.orderType) {
                        errID = "trade.place-order.limit-price-edit-not-alowed";
                        actionAllowed = false
                    }
                }
            }
        }
        if (actionAllowed) {
            dispatch({
                type: actionTypes.SET_TRADE_ORDER_INPUT,
                orderInput: orderInput
            });
        } else {
            ToastUtil.error('common.error', errID ? errID : "trade.place-order.only-change-price-quantity-when-edit");
        }

    };
};


export const setTradeOrderInputGroup = (orderInput, symbol) => {
    return (dispatch, getState) => {
        const state = getState();
        const currentOrderInput = state.app.tradeOrderInputGroup || {};

        let actionAllowed = true;

        // If symbol passed, check if symbol exist first
        if (symbol) {
            const symbols = state.symbol.allQuotes;
            const foundSymbol = _.find(symbols, (element) => (element.id === symbol));
            if (!foundSymbol) {
                console.error(`Symbol ${symbol} not found in mapping. OrderInput state will not be modified.`);
                // Error will be displayed to user in Trade's route logic
                return;
            }
        }

        // Check if editing an order
        if (currentOrderInput.editingOrder != null) {
            // If symbol is passed and it's not same as symbol of editing order, we will allow the action
            if (symbol && symbol !== currentOrderInput.editingOrder['symbol']) {
                actionAllowed = true;
                orderInput.editingOrder = null;
            } else {
                // If we edit another order, action is allowed
                // Otherwise we need to perform some check
                if (orderInput.editingOrder == null) {
                    // In case we edit order, we does not allow user to change action or orderType
                    if (orderInput.action && orderInput.action !== currentOrderInput.action) {
                        actionAllowed = false;
                    } else if (orderInput.orderType && orderInput.orderType !== currentOrderInput.orderType) {
                        actionAllowed = false
                    }
                }
            }
        }

        dispatch({
            type: actionTypes.SET_TRADE_ORDER_INPUT_GROUP,
            orderInput: orderInput
        });

        // if (actionAllowed) {
        //     dispatch({
        //         type: actionTypes.SET_TRADE_ORDER_INPUT_GROUP,
        //         orderInput: orderInput
        //     });
        // } else {
        //     ToastUtil.error('common.error',"trade.place-order.only-change-price-quantity-when-edit");
        // }
    };
};

export const clearTradeOrderInput = (isClearAction) => ({
    type: actionTypes.CLEAR_TRADE_ORDER_INPUT,
    isClearAction
});

export const clearEdittingOrderInput = () => ({
    type: actionTypes.CLEAR_EDITTING_ORDER_INPUT
});

export const clearTradeOrderInputGroup = (isClearAction) => ({
    type: actionTypes.CLEAR_TRADE_ORDER_INPUT_GROUP,
    isClearAction
});


export const setTradeAuthSuccess = () => {
    return (dispatch, getState) => {
        const state = getState();
        const { isSaveTradeAuthenticated } = state.layout.listIsOpenModal["C&B"];
        if (isSaveTradeAuthenticated) {
            sessionStorage.setItem('isTradeAuthenticated', "true")
            // Sửa lỗi lưu xác thực redux => sessionStorage
            // dispatch({
            //     type: actionTypes.SET_TRADE_AUTH_SUCCESS
            // });
        } else {
            sessionStorage.setItem('isTradeAuthenticated', "false")
            // dispatch(clearTradeAuth());
        }
    }
}
export const triggerTradeCb = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        const { onTradeAuthenticatedCallback } = state.app;

        if (onTradeAuthenticatedCallback) {
            onTradeAuthenticatedCallback(data);
        }
    };
};

export const triggerTradeCbtest = (data) => {
    return (dispatch, getState) => {
        const state = getState();

        const { onTradeQuickOrderAuthenCallback } = state.layout.listIsOpenModal["C#CS#TRADE#CB"]["QuickOrder"];

        if (onTradeQuickOrderAuthenCallback) {
            onTradeQuickOrderAuthenCallback(data);
        }
    };
};

export const onChangeFunctionNeedTwoFactorAuthStatus = (responseApiStatus) => { //responseApiStatus nhận 1 trong các giá trị của constants.LoadStatus
    return {
        type: actionTypes.ON_CHANGE_FUNCTION_NEED_TWOFACTORAUTH_STATUS,
        data: {
            responseApiStatus
        }
    }
};
export const clearTradeAuth = () => {
    return (dispatch, getState) => {
        const state = getState();
        const { tradeAuthenticated } = state.app;

        if (tradeAuthenticated) {
            userService.changeSaveVerifyInfo(false).then(changeResult => {
                dispatch({
                    type: actionTypes.CLEAR_TRADE_AUTH
                });
            }).catch(error => {
            });
        }
    };
};

export const toogleTradeAuthModal = (callback) => {
    return (dispatch, getState) => {
        const state = getState();
        const { isTradeAuthenticateModalOpen } = state.app;
        if (isTradeAuthenticateModalOpen) {
            dispatch({
                type: actionTypes.SET_CURRENT_RECONFIRM_ORDER_TYPE,
                currentReconfirmOrderType: reconfirmTypes.NORMAL_ORDER
            });
            dispatch({
                type: actionTypes.SET_PROP_FOR_TradeAuth_COMPONENT,
                funcRenderOrderConfirmView: null
            });
            dispatch({
                type: actionTypes.SET_LIST_RECONFIRM_ORDER,
                currentReconfirmOrderList: []
            })
            dispatch({
                type: actionTypes.SET_TRADE_ORDER_INPUT_MSG,
                msg: null
            })
        }
        dispatch({
            type: actionTypes.TOOGLE_TRADE_AUTH_MODAL,
            callback: callback
        });
    };
};

export const onCloseTradeAuthModal = (callback) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_CURRENT_RECONFIRM_ORDER_TYPE,
            currentReconfirmOrderType: reconfirmTypes.NORMAL_ORDER
        });
        dispatch({
            type: actionTypes.SET_PROP_FOR_TradeAuth_COMPONENT,
            funcRenderOrderConfirmView: null
        });
        dispatch({
            type: actionTypes.SET_LIST_RECONFIRM_ORDER,
            currentReconfirmOrderList: []
        })
        dispatch({
            type: actionTypes.SET_TRADE_ORDER_INPUT_MSG,
            msg: null
        })
        dispatch({
            type: actionTypes.ON_CLOSE_TRADE_AUTH_MODAL,
            callback: callback
        });
    };
};

export const setAccountPath = (path) => ({
    type: actionTypes.SET_ACCOUNT_PATH,
    path: path
});

export const setSettingPath = (path) => ({
    type: actionTypes.SET_SETTING_PATH,
    path: path
})

export const toggleVisibleSidebar = () => ({
    type: actionTypes.TOGGLE_MENU_SIDEBAR
})

export const toogleAdvancedOrderInput = () => ({
    type: actionTypes.TOOGLE_TRADE_ADVANCED_ORDER_INPUT,
});

export const toogleTradeLayoutSettingModal = () => ({
    type: actionTypes.TOOGLE_TRADE_LAYOUT_SETTING_MODAL,
});

export const toogleChangePinPassModal = (type) => ({
    type: actionTypes.TOOGLE_CHANGE_PIN_PASS_MODAL,
    resetType: type
});

export const setStatePinPassModal = (flag) => ({
    type: actionTypes.SET_STATE_PIN_PASS_MODAL,
    flag: flag
});

export const toggleModalChangeCore = (type) => ({
    type: actionTypes.TOGGLE_MODAL_CHANGE_CORE,
});

export const setIsAccountRouteVisible = (isVisible) => ({
    type: actionTypes.SET_IS_ACCOUNT_ROUTE_VISIBLE,
    isVisible: isVisible
});

export const setAccountSidebarMobileVisible = (visible) => ({
    type: actionTypes.SET_ACCOUNT_SIDEBAR_MOBILE_VISIBLE,
    visible: visible
});

export const setIsSettingsRouteVisible = (isVisible) => ({
    type: actionTypes.SET_IS_SETTINGS_ROUTE_VISIBLE,
    isVisible: isVisible
});

export const setSettingsSidebarMobileVisible = (visible) => ({
    type: actionTypes.SET_SETTINGS_SIDEBAR_MOBILE_VISIBLE,
    visible: visible
});

// export const setSocketConnectFirstTime = (isFirstTime) => ({
//     type: actionTypes.SET_SOCKET_CONNECT_FIRST_TIME,
//     isFirstTime: isFirstTime
// });

export const setSocketConnectStatus = (socketStatus) => ({
    type: actionTypes.SET_SOCKET_CONNECT_STATUS,
    socketStatus: socketStatus
});


// export const setSocketMarketConnectFirstTime = (isFirstMarketTime) => ({
//     type: actionTypes.SET_SOCKET_MARKET_CONNECT_FIRST_TIME,
//     isFirstMarketTime: isFirstMarketTime
// });

export const setSocketMarketConnectStatus = (socketMarketStatus) => ({
    type: actionTypes.SET_SOCKET_MARKET_CONNECT_STATUS,
    socketMarketStatus: socketMarketStatus
});

export const setSelectedTradeLayout = (tradeLayoutId) => ({
    type: actionTypes.ON_SWITCH_TRADE_LAYOUT,
    tradeLayoutId
});

export const setSelectedMobileLayout = (tradeLayoutId) => ({
    type: actionTypes.ON_SWITCH_MOBILE_LAYOUT,
    tradeLayoutId
});

export const onSocketAccountAction = (accountId, action) => {
    return (dispatch, getState) => {
        let reduxAction = null;
        if (action === 'OD') {
            reduxAction = actionTypes.ON_SOCKET_OD_ACTION;
        } else if (action === 'CFMOD') {
            dispatch(getConfirmOrdersInfor(accountId))
        } else if (action === 'CI') {
            reduxAction = actionTypes.ON_SOCKET_CI_ACTION;
        } else if (action === 'SE') {
            // dispatch(fetchSecurities(true, msgType))
            reduxAction = actionTypes.ON_SOCKET_SE_ACTION;
        } else if (action === 'CA') {
            reduxAction = actionTypes.ON_SOCKET_CA_ACTION;
        } else if (action === 'P') {
            reduxAction = actionTypes.ON_SOCKET_P_ACTION;
        } else if (action === 'PP') {
            reduxAction = actionTypes.ON_SOCKET_PP_ACTION;
        } else if (action === 'DO') {
            reduxAction = actionTypes.ON_SOCKET_DO_ACTION;
        } else if (action === 'DT') {
            reduxAction = actionTypes.ON_SOCKET_DT_ACTION;
        } else if (action === 'COD') {
            reduxAction = actionTypes.ON_SOCKET_COD_ACTION;
        }

        if (action != null) {
            if (accountId) {
                dispatch({
                    type: reduxAction,
                    accountId: accountId
                });
            } else {
                dispatch({
                    type: reduxAction,
                });
            }
        }
    };
};

// export const onSocketAccountAction = (accountId, action) => {
//     return (dispatch, getState) => {
//         const state = getState();

//         const currentAccount = state.layout.accountIdActiveScreenModal;
//         //console.log("loc1--- accountId: ", accountId)
//         //console.log("loc1--- currentAccount.id: ", currentAccount)
//         //console.log("loc1--- action: ", action)

//         //console.log("loc1--- 1")
//         let reduxAction = null;
//         if (action === 'OD') {  // giang.ngo trường hợp có tín hiệu lệnh thay đổi thì không phân biệt tiểu khoản vì sổ lệnh đang hiển thị all
//             reduxAction = actionTypes.ON_SOCKET_OD_ACTION;
//             //console.log("loc1--- OD")
//         } else if (action === 'CFMOD') {  // giang.ngo trường hợp có tín hiệu lệnh thay đổi thì không phân biệt tiểu khoản vì sổ lệnh đang hiển thị all
//             dispatch(getConfirmOrdersInfor(accountId))
//             //console.log("loc1--- CFMOD")
//         } else if (currentAccount != null && currentAccount === accountId) {
//             //console.log("loc1--- come")
//             if (action === 'CI' || action === 'SE') {
//                 //console.log("loc1--- CI - SE")
//                 let msgType = action; // truyền msgType để update count Socket theo action CI/SE
//                 dispatch(fetchSecurities(true, msgType))
//             }
//             else {
//                 if (action === 'CA') {
//                     //console.log("loc1--- CA")
//                     reduxAction = actionTypes.ON_SOCKET_CA_ACTION;
//                 } else if (action === 'P') {
//                     //console.log("loc1--- P")
//                     reduxAction = actionTypes.ON_SOCKET_P_ACTION;
//                 }
//             }
//         }
//         //console.log("loc1--- 2")
//         if (action != null) {
//             //console.log("loc1--- 3")
//             dispatch({
//                 type: reduxAction
//             });
//         }

//     };
// };

export const onSocketCustomerAction = (custid, action) => {
    /**
     * xu li cac event theo custid
     * example: reload so lenh moi gioi
     * case1: action=OD
     */
    return (dispatch, getState) => {
        const state = getState();
        const userInfo = state.user.userInfo;
        let reduxAction = null;
        if (custid !== null && custid === userInfo.custid) {
            if (action === 'OD') {
                reduxAction = actionTypes.CUST_ON_SOCKET_OD_ACTION;
            };
        };
        if (reduxAction !== null) {
            dispatch({
                type: reduxAction
            });
        };
    };
};

export const setScreenExportData = (data = { filename: null, data: null }) => {
    return {
        type: actionTypes.SET_SCREEN_EXCEL_EXPORT_DATA,
        data: data
    }
};


export const updateAccountNotifyCounter = (custid) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.UPDATE_ACCOUNT_NOTIFY_COUNTER
        })
    };
};

export const updateAccountNotifyList = (custid) => {
    return (dispatch, getState) => {
        let id = custid || (getState().customer.currentCustomer ? getState().customer.currentCustomer.custid : getState().user.userInfo.custid);
        return new Promise((resolve, reject) => {
            notifyService.getAccountNotifyMessage(id)
                .then((data) => {
                    dispatch({
                        type: actionTypes.UPDATE_ACCOUNT_NOTIFY_LIST,
                        accountNotifyList: data
                    });
                    resolve();
                })
                .catch((error) => {
                    //ToastUtil.errorApi(error, 'common.fail-to-load-account-notification-list');
                    reject();
                });
        });
    };
};

export const updateAgreementOrderNotifyList = (custid) => {
    return (dispatch, getState) => {
        let id = custid || (getState().userInfo && getState().userInfo.custid);
        return new Promise((resolve, reject) => {
            notifyService.getAgreementOrderNotifyMessage(id)
                .then((data) => {
                    dispatch({
                        type: actionTypes.UPDATE_AGREEMENT_ORDER_NOTIFY_LIST,
                        agreementOrderNotifyList: data
                    });
                    resolve();
                })
                .catch((error) => {
                    //ToastUtil.errorApi(error, 'common.fail-to-load-account-notification-list');
                    reject();
                });
        });
    };
};



export const getConfirmOrdersInfor = (accountId) => {
    return (dispatch, getState) => {
        inquiryService.getConfirmOrdersInfor(accountId)
            .then((data) => {
                var arr = []
                data && data.forEach(item => {
                    item = {
                        createdAt: Date.now(),
                        updatedAt: Date.now(),
                        msgevent: { ...item },
                        afacctno: item.afacctno,
                        custid: item.custid,
                        templateid: "401A",
                    }
                    arr.push(item)
                })

                dispatch({
                    type: actionTypes.CONFIRM_ORDERS_INFOR,
                    confirm_orders_infor: arr,
                    accountId
                });
            })
            .catch((error) => {
            });
    };
};
export const deleteCurrentConfirmOrderInfo = () => {
    return {
        type: actionTypes.DELETE_CURRENT_CONFIRM_ORDERS_INFO
    }
}
export const toastToNoticeWhenReceiveMessage = () => {
    return (dispatch, getState) => {
        ToastUtil.success("common.receive-new-analytic-message");
        dispatch({
            type: actionTypes.TOAST_TO_NOTICE_WHEN_RECEIVE_MESSAGE
        })
    }
};

export const setConfirmModalProps = (isOpen, messageId, acceptHandle, cbProps, options = { msgParams: {} }, closeHandle, clProps) => {
    let confirmModalProps = {
        isOpen: isOpen,
        messageId: messageId,
        acceptHandle: acceptHandle,
        cbProps: cbProps,
        closeHandle: closeHandle,
        clProps: clProps,
        options
    };
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_CONFIRM_MODAL_PROP,
            confirmModalProps: confirmModalProps
        })
    }
};
// export const updateAnalyticNotifyList = () => {
//     return (dispatch, getState) => {
//         return new Promise((resolve, reject) => {
//             notifyService.getAnalyticMessage()
//                 .then((data) => {
//                     dispatch({
//                         type: actionTypes.UPDATE_ANALYTIC_NOTIFY_LIST,
//                         analyticNotifyList: data
//                     });
//                     resolve();
//                 })
//                 .catch((error) => {
//                     // ToastUtil.errorApi(error, 'common.fail-to-load-analytic-notification-list');  
//                     reject();
//                 });
//         });
//     };
// };
//dong modal
export const closeBrowserModal = () => ({
    type: actionTypes.CLOSE_BROWSER_MODAL
});
export const clearSymbolSuggestion = (isClearSymbolSuggestion) => ({
    type: actionTypes.CLEAR_SYMBOL_SUGGESTION,
    isClearSymbolSuggestion
})
// Trạng thái loading modal confirm đặt lệnh (vd : Đợi kết quả precheck)
export const setTradeAuthShowConfirm = (isShow) => ({
    type: actionTypes.SET_TRADE_AUTH_SHOW_CONFIRM,
    isShow
});

// Dữ liệu dòng chạy footer (Tin thị trường)
export const setFooterData = (data) => {
    return {
        type: actionTypes.SET_DATA_FOR_FOOTER,
        data: data
    }
};

export const setSymbolSearchDataFeed = (data) => {
    return {
        type: actionTypes.IS_CHECK_SYMBOL_DATA_FEED,
        data: data
    }
};

export const onChangeClickPromotion = (status) => {
    return {
        type: actionTypes.CHANGE_CLICK_PROMOTION,
        data: status
    }
};

// set trạng thái mở của modal chi tiết mã Ck
export const setSymbolDetailModalOpenState = (isOpen) => {
    return {
        type: actionTypes.SET_SYMBOL_DETAIL_OPEN_STATE,
        isOpen
    }
};
// set trạng thái mở của modal chi tiết mã Ck theo PriceChart
export const setSymbolDetailPriceChartShowFull = (isOpen) => {
    return {
        type: actionTypes.SET_SYMBOL_DETAIL_PRICE_CHART_SHOW_FULL,
        isOpen
    }
};

//Giao dịch thỏa thuận Khớp lệnh
export const updateDataPTMatch = (ptMatchs) => ({
    type: actionTypes.UPDATE_PTMATCH_DATA,
    ptMatchs
});
//Giao dịch thỏa thuận chào mua, chào bán
export const updateDataPutthrough = (putThroughs) => ({
    type: actionTypes.UPDATE_PUTTHROUGH_DATA,
    putThroughs
});

//Trình chiếu CK
export const autoScroll = () => ({
    type: actionTypes.SET_SHOW_AUTO_SCROLL_PRICE_BOARD
});

//SetTabActive Wiget
export const setTabActiveWiget = (tabActive) => ({
    type: actionTypes.SET_TAB_ACTIVE_WIGET,
    tabActive
});

//toogle drap and drop sổ lệnh tra cứu môi giới
export const toogleSettingDrapDropLookUpOrderNew = () => ({
    type: actionTypes.TOOGLE_SETTING_TABLE_DRAP_DROP_LOOK_UP_ORDER_NEW,
});

// Lấy dữ liệu cho Hỗ trợ
export const fetchContactInfo = () => {
    return (dispatch, getState) => {
        serverService.getContactInfo()
            .then((data) => {
                dispatch({
                    type: actionTypes.SET_CONTACT_INFO,
                    data: data
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.SET_CONTACT_INFO_FALSE
                });
            });


    };
};

export const setActiveTabBrowser = (isActiveTabBrowser) => ({
    type: actionTypes.SET_TAB_ACTIVE_BROWSER,
    isActiveTabBrowser: isActiveTabBrowser
});

