import actionTypes from '../actions/actionTypes';


const initialState = {
    connectionCount: 0
};

const serverReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CONNECT_COUNT:
            return {
                ...state,
                connectionCount: action.connectionCount
            };
        default:
            return state;
    }
};

export default serverReducer;

