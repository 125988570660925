import { en } from '../translations/en/index';
import { vi } from '../translations/vi/index';
import { kr } from '../translations/kr/index';
import { jp } from '../translations/jp/index';

const flattenMessages = ((nestedMessages, prefix = '') => {
    if (nestedMessages == null) {
        return {}
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'string') {
            Object.assign(messages, { [prefixedKey]: value })
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey))
        }

        return messages
    }, {})
});

const messages = {
    'vi': flattenMessages(vi),
    'en': flattenMessages(en),
    'kr': flattenMessages(kr),
    'jp': flattenMessages(jp),
};

export default class LanguageUtils {
    static getMessageByKey(key, lang) {
        return messages[lang][key] || key
    }

    static getFlattenedMessages() {
        return messages;
    }
}