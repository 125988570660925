import React from 'react';
import { toast } from 'react-toastify';

import axios from 'axios';

import CustomToast from "../components/CustomToast";
import { emitter } from 'utils/EventEmitter';

const TYPE_SUCCESS = 'SUCCESS';
const TYPE_INFO = 'INFO';
const TYPE_WARN = 'WARN';
const TYPE_ERROR = 'ERROR';
const nowClient = new Date().getTime();
let LAST_TIME_SHOW_NOTI = {
    status_500: nowClient,
    status_400_500: nowClient,
    status_400: nowClient,
    status_403: nowClient,
    status_401: nowClient,
    network_err: nowClient
}

const globalVar = window._env_
const TIME_BLOCK_NOTI = globalVar.constant.TIME_BLOCK_NOTI || 5000
let isUpdating = false;

emitter.on("waitingUpdateSystem", () => {
    isUpdating = true;
});


class ToastUtil {

    static success(title, message) {
        this.show(TYPE_SUCCESS, title, message);
    }

    static info(title, message) {
        this.show(TYPE_INFO, title, message);
    }

    static warn(title, message) {
        this.show(TYPE_WARN, title, message);
    }

    static error(title, message) {
        this.show(TYPE_ERROR, title, message);
    }

    static successRaw(title, message) {
        this.show(TYPE_SUCCESS, title, message, true);
    }

    static errorRaw(title, message, autoCloseDelay = 10000) {
        this.show(TYPE_ERROR, title, message, true, autoCloseDelay);
    }

    static is_Show_Noti(type) {
        let now = new Date().getTime()
        if (now > LAST_TIME_SHOW_NOTI[type] + TIME_BLOCK_NOTI) {
            LAST_TIME_SHOW_NOTI[type] = now;
            return true;
        }
        return false;
    }

    static errorApi(error, title = 'common.fail-to-load-data', autoCloseDelay = 10000) {
        if (axios.isCancel(error)) {
            // Do nothing if request was cancelled
            return;
        }
        let showNoti = true; // true nếu có hiển thị thông báo lỗi , false với case không cần bắn (VD: Case hết phiên đã có popup Thông báo,....)
        let message = null;
        let messageId = 'common.unknown-error';
        let trackLogId = error.Track_log_id

        if (isUpdating) {
            if (error.httpStatusCode && error.httpStatusCode !== 550) { // nếu httpcode khác 550 ==> reset lại trạng thái update để bắn lỗi như bình thường
                isUpdating = false;
            } else {
                return null; // đang update không bắn các lỗi liên quan đến api (do đang cập nhật)
            }
        }

        if (error.httpStatusCode >= 500) {
            messageId = 'common.internal-server-error';
            showNoti = this.is_Show_Noti("status_500")
        } else if (error.httpStatusCode < 500 && error.httpStatusCode >= 400) {
            showNoti = this.is_Show_Noti("status_400_500")
            if (error.httpStatusCode === 400) {
                showNoti = this.is_Show_Noti("status_400")
                messageId = 'common.bad-request';
            } else if (error.httpStatusCode === 403) {
                showNoti = this.is_Show_Noti("status_403")
                messageId = 'common.forbiden-request';
            } else if (error.httpStatusCode === 401) {
                showNoti = false;
                // messageId = 'common.session-timeout-err';
            }
        } else {
            // Request fail even server was returned a success response
            if (error.errorMessage) {
                message = error.errorMessage
            }
        }

        if (error.message === 'Network Error') { // Trường hợp mất mạng
            showNoti = this.is_Show_Noti("network_err")
            title = 'CM000009'
            message = null
            messageId = null
        }
        if (!showNoti) {
            return null;
        }

        let autoClose = autoCloseDelay
        if (globalVar.constant && globalVar.constant.AUTO_CLOSE_NOTI) {
            autoClose = globalVar.constant ? (globalVar.constant.AUTO_CLOSE_TOAST_ERROR_DELAY || autoCloseDelay) : autoCloseDelay
        } else {
            autoClose = false
        }

        if (showNoti) {
            toast.error(<CustomToast trackLogId={trackLogId} type="ERROR" titleId={title} message={message} messageId={messageId} time={new Date()} />, {
                position: toast.POSITION.BOTTOM_RIGHT,
                pauseOnHover: true,
                autoClose: autoClose
            });
        }
    }

    static show(type, title, message, rawMessage = false, autoCloseDelay = 10000) {
        const content = <CustomToast type={type} titleId={title} messageId={rawMessage ? null : message} message={rawMessage ? message : null} time={new Date()} />;
        let options = {
            position: toast.POSITION.BOTTOM_RIGHT,
            pauseOnHover: true,
            autoClose: autoCloseDelay
        };

        switch (type) {
            case TYPE_SUCCESS:
                if (globalVar.constant && globalVar.constant.AUTO_CLOSE_NOTI) {
                    options = {
                        ...options,
                        autoClose: globalVar.constant ? (globalVar.constant.AUTO_CLOSE_TOAST_SUCCESS_DELAY || autoCloseDelay) : autoCloseDelay
                    }
                } else {
                    options = {
                        ...options,
                        autoClose: false
                    }
                }
                toast.success(content, options);
                break;
            case TYPE_INFO:
                toast.info(content, options);
                break;
            case TYPE_WARN:
                toast.warn(content, options);
                break;
            case TYPE_ERROR:
                if (globalVar.constant && globalVar.constant.AUTO_CLOSE_NOTI) {
                    options = {
                        ...options,
                        autoClose: globalVar.constant ? (globalVar.constant.AUTO_CLOSE_TOAST_ERROR_DELAY || autoCloseDelay) : autoCloseDelay
                    }
                } else {
                    options = {
                        ...options,
                        autoClose: false
                    }
                }
                toast.error(content, options);
                break;
            default:
                break;
        }
    }
}

export default ToastUtil;