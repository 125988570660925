import { CommonUtils } from "../../utils";
import actionTypes from '../actions/actionTypes';

const initialState = {
    currentCustomer: null,
};

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_CUSTOMER:
            const newCustomer = action.customer;
            if (CommonUtils.isSameCustomer(state.currentCustomer, newCustomer)) {
                return state;
            }
            return {
                ...state,
                currentCustomer: action.customer
            };
        case actionTypes.USER_LOGOUT:
            return {
                ...initialState
            };
        case actionTypes.SINGLE_USER_LOGOUT:
            return {
                ...initialState
            };
        case actionTypes.CHANGE_CUSTOMER_INFO_EVENTTYPE:    // case khi nhận được các event thay đổi thông tin tài khoản từ socket
            return {
                ...state,
                customerEvent: action.event
            };
        case actionTypes.CHANGE_ACCOUNT_INFO_EVENTTYPE:    // case khi nhận được các event thay đổi thông tin  tiểu khoản từ socket
            return {
                ...state,
                accountEvent: action.event
            };
        case actionTypes.CHANGE_PASSWORD_INFO_EVENTTYPE:  // case khi nhận được các event thay đổi mật khẩu từ socket
            return {
                ...state,
                passwordEvent: action.event
            }
        default:
            return state;
    }
};

export default customerReducer;