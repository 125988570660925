import State from './State';

class StateStore {

    _prefix = 'auth_state_';

    set(key, value) {
        key = this._prefix + key;

        localStorage.setItem(key, value.toStorageString());
    }

    get(key) {
        key = this._prefix + key;

        let item = localStorage.getItem(key);
        return item ? State.fromStorageString(item) : null;
    }

    remove(key) {
        key = this._prefix + key;
        let item = localStorage.getItem(key);
        let state = item ? State.fromStorageString(item) : null;
        localStorage.removeItem(key);
        return state;
    }

    getAllKeys() {
        let keys = [];
        for (let index = 0; index < localStorage.length; index++) {
            let key = localStorage.key(index);
            if (key.indexOf(this._prefix) === 0) {
                keys.push(key.substr(this._prefix.length));
            }
        }
        return keys;
    }

    clearStaleState(age) {
        const cutoff = Date.now() / 1000 - age;
        const keys = this.getAllKeys();
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            const state = this.get(key);
            const remove = !state || state.created <= cutoff;
            if (remove) {
                this.remove(key);
            }
        }
    }
}

export default StateStore;