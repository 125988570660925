import { accountService } from "../../services";
import { LoadStatus, ToastUtil } from "../../utils";

import actionTypes from './actionTypes';

export const fetchDraftOrders = (forceReload, orderGroup, calcSum) => {
    return (dispatch, getState) => {
        const state = getState();
        const notLoaded = state.order.draftOrdersLoadStatus !== LoadStatus.LOADED;
        const notLoading = state.order.draftOrdersLoadStatus !== LoadStatus.LOADING;
        const loadedAccountId = state.order.draftOrdersAccountId;
        const currentAccount = state.account.currentAccount;

        if (notLoading && (notLoaded || forceReload || currentAccount.id !== loadedAccountId) && currentAccount) {
            dispatch({
                type: actionTypes.FETCH_DRAFT_ORDERS,
                account: currentAccount
            });
            accountService.getSavedOrders(currentAccount.id, orderGroup)
                .then((data) => {
                    data.forEach(item => {
                        item['totalValue'] = item['quantity'] * item['price'];
                    });
                    dispatch({
                        type: actionTypes.FETCH_DRAFT_ORDERS_SUCCESS,
                        orders: data
                    });
                    if (calcSum) {
                        calcSum(data);
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.FETCH_DRAFT_ORDERS_FAIL
                    });
                    ToastUtil.errorApi(error, 'common.fail-to-load-draft-orders');
                });
        }
    };
};

export const clearDraftOrder = () => {
    return {
        type: actionTypes.CLEAR_DRAFT_ORDER
    }
};

export const setShowReconfirmOrder = (status) => {
    return {
        type: actionTypes.SET_SHOW_RECONFIRM_ORDER,
        status: status,
    }
};

export const setListReconfirmOrder = (currentReconfirmOrderList) => {
    return {
        type: actionTypes.SET_LIST_RECONFIRM_ORDER,
        currentReconfirmOrderList: currentReconfirmOrderList
    }
};

export const clearListReconfirmOrder = () => {
    return {
        type: actionTypes.CLEAR_LIST_RECONFIRM_ORDER,
    }
};

export const changeCurrentAdvanceConditionOrder = (conditionOrder) => {
    return {
        type: actionTypes.CHANGE_ADVANCE_CONDITION_ORDER,
        conditionOrder: conditionOrder
    }
};

export const setOrderTransactionInfo = (orderTransactionInfo) => {
    return {
        type: actionTypes.SET_ORDER_TRANSACTION_INFO,
        orderTransactionInfo: orderTransactionInfo,
    }
};

export const setLookUpOrderNew = (lookUpOrderNew) => {
    return {
        type: actionTypes.SET_LOOKUP_ORDER_NEW,
        lookUpOrderNew: lookUpOrderNew
    }
};

export const setOrderPutThroughInfor = (orderPutthroughInfo) => {
    return {
        type: actionTypes.SET_ORDER_PUTTHROUGH_INFO,
        orderPutthroughInfo: orderPutthroughInfo,
    }
};

export const clearOrderPutThroughInfor = () => {
    return {
        type: actionTypes.CLEAR_ORDER_PUTTHROUGH_INFO,
    }
};